import Grid from "@mui/material/Grid";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import * as React from "react";
import AssistantIcon from "@mui/icons-material/Assistant";
import {useEffect, useState} from "react";
import {CommonFetch} from "../../../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../../useLocalStorage";

function CreateTaskSupportPanel({selectedObject,
                                stage, setStage,
                                group, setGroup,
                                workType, setWorkType,
                                    setTasksOptions,
                                    isTextFieldFocused
                                }) {

    const NUM_PERC = '4%';
    const NAME_PERC = '23%';
    const ENTITY_PERC = '3%';
    const QTY_PERC = '3%';
    const ENT_PRICE_PERC = '7%';
    const TOTAL_PERC = '8%';
    const QTY_MONEY = '3%';
    const DAILY_MONEY = '3%';

    let navigate = useNavigate();
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [objectSettings, setObjectSettings] = React.useState(false);
    const [yearItems, setYearItems] = useState([]);

    const [tasksToShow, setTasksToShow] = useState(null);
    const [forceRefreshRow, setForceRefreshRow] = useState(null);

    useEffect(() => {
        if (!selectedObject) {
            return;
        }
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/aworkgroups?`+ new URLSearchParams({
            token: accessToken,
            "year":2023,
            "id_project":selectedObject
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                    if (result && result.items) {
                        setYearItems(result.items)
                    }
                    console.log(result)
                },
                (error) => {
                    console.log('error: ', error)
                }
            );
    }, [selectedObject, stage, group, workType])



    useEffect(() => {
        if (stage && stage.id) {

            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getTasksForItem?`+ new URLSearchParams({
                token: accessToken,
                "id_etap":stage.id
            }), {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then((result) => {
                    if (result && result.items) {
                        let results = result.items;

                        setTasksToShow(
                            {
                                id: stage.id,
                                type: 'etap',
                                data: results
                            }
                        )
                        setTasksOptions(results)
                    }
                })

        } else {
            setTasksToShow(null)
        }

    }, [stage])


    useEffect(() => {
        if (group && group.id) {

            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getTasksForItem?`+ new URLSearchParams({
                token: accessToken,
                "id_aworkgroup":group.id
            }), {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then((result) => {
                    if (result && result.items) {
                        let results = result.items;

                        console.log('setting data  results', {
                            id: group.id,
                            type: 'group',
                            data: results
                        })
                        setTasksToShow(
                            {
                                id: group.id,
                                type: 'group',
                                data: results
                            }
                        )
                        setTasksOptions(results)

                        setForceRefreshRow(Math.random())
                    }
                })

        } else {
            setTasksToShow(null)
        }

    }, [group])


    useEffect(() => {
        if (workType && workType.id) {

            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getTasksForItem?`+ new URLSearchParams({
                token: accessToken,
                "id_work":workType.id
            }), {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then((result) => {
                    if (result && result.items) {
                        let results = result.items ;

                        setTasksToShow(
                            {
                                id: workType.id,
                                type: 'work',
                                data: results
                            }
                        )
                        setTasksOptions(results)

                    }
                })

        } else {
            setTasksToShow(null)
        }

    }, [workType])



    return (
        <Grid container>

            <Grid item xs={12}>
                <TableContainer>
                    {yearItems && yearItems.length > 0 ? <Table size={'small'} sx={ {border: 1}} aria-label="simple table">
                            <TableHead>


                                <TableRow>
                                    <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>№</b></TableCell>
                                    <TableCell sx={{border: 1}} width={NAME_PERC} align="left"></TableCell>

                                </TableRow>

                            </TableHead>
                            <TableBody>
                                {yearItems && yearItems
                                    .filter((etap) => stage ? etap.id === stage.id : true)
                                    /*.filter((etap) => tasksToShow ? (tasksToShow.id === etap.id || tasksToShow.type === 'group' || tasksToShow.type === 'work') : true) // Скрива етапите
                                    .filter((etap) => tasksToShow && tasksToShow.type === 'group' ? (etap.details??[]).some((group) => group.id === tasksToShow.id) : true) // Ако е избрана група, скива етапите, които не я съдържат
                                    .filter((etap) => tasksToShow && tasksToShow.type === 'work' ? (etap.details??[]).some((group) => (group.works??[]).some((work) => work.id === tasksToShow.id)) : true) // Ако е избрана работа, скива етапите, които не я съдържат
                                    */.map((row, index) => (
                                        <React.Fragment>
                                            <TableRow
                                                key={row.no}
                                                style={{background: '#63fff2'}}
                                            >
                                                <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>{index + 1}</b></TableCell>
                                                <TableCell sx={{border: 1}} width={NAME_PERC} align="left"
                                                           onClick={() => {
                                                               if (tasksToShow) {
                                                                   setTasksToShow(null)
                                                               } else {
                                                                   setTasksToShow(
                                                                       {
                                                                           id: row.id,
                                                                           type: 'etap',
                                                                           data: [{name: 'Сваляне шаблони за стъпала'}]
                                                                       }
                                                                   )
                                                               }
                                                           }}
                                                >
                                                    <Grid container justifyContent={'space-between'}>
                                                        <Grid item>
                                                            <b>{row.name_group}</b>
                                                        </Grid>

                                                        {row.task_count && row.task_count > 0 ? <Grid item>
                                                            <AssistantIcon/>
                                                        </Grid> : null}
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>

                                            {
                                                isTextFieldFocused && tasksToShow && tasksToShow.id === row.id && tasksToShow.data && tasksToShow.data.map((task) => {
                                                    return <React.Fragment>
                                                        <TableRow
                                                            key={row.no}
                                                            style={{background: 'white'}}
                                                        >
                                                            <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b> </b></TableCell>
                                                            <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{task.subject}</b></TableCell>

                                                        </TableRow>
                                                    </React.Fragment>
                                                })
                                            }

                                            {row && row.details && row.details
                                                .filter((groupIter) => stage ? group ? groupIter.id === group.id : true : false) //Ако не е избран етап, покажи всички етапи само. Ако не е избрана група, покажи всички групи.
                                                /*.filter((group) => tasksToShow && tasksToShow.type === 'group' ? tasksToShow.id === group.id : true) // Ако е избрана група, скива останалите групи
                                                .filter((group) => tasksToShow ? tasksToShow.type !== 'etap' : true) // Скрива всички, групи, ако е избран етап
                                                .filter((group) => tasksToShow && tasksToShow.type === 'work' ? (group.works??[]).some((work) => work.id === tasksToShow.id) : true) // Ако е избрана работа, скива етапите, които не я съдържат
*/
                                                .map((row, index) => (
                                                    <React.Fragment>

                                                        <TableRow
                                                            key={row.no}
                                                            style={{background: '#adadad'}}
                                                        >
                                                            <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>{index + 1}</b></TableCell>
                                                            <TableCell onClick={() => {
                                                                if (tasksToShow) {
                                                                    setTasksToShow(null)
                                                                } else {
                                                                    setForceRefreshRow(Math.random())
                                                                    setTasksToShow(
                                                                        {
                                                                            id: row.id,
                                                                            data: [{name: 'Сваляне шаблони за стъпала'}],
                                                                            type: 'group'
                                                                        }
                                                                    )
                                                                }
                                                            }}
                                                                       sx={{border: 1}} width={NAME_PERC} align="left">
                                                                <Grid container justifyContent={'space-between'}>
                                                                    <Grid item>
                                                                        <b>{row.name_group}</b>
                                                                    </Grid>

                                                                    <Grid item>
                                                                        {row.task_count && row.task_count > 0 ? <Grid item>
                                                                            <AssistantIcon/>
                                                                        </Grid> : null}
                                                                    </Grid>

                                                                </Grid>

                                                            </TableCell>
                                                        </TableRow>


                                                        {
                                                            isTextFieldFocused && forceRefreshRow && tasksToShow && tasksToShow.id === row.id && tasksToShow.data && tasksToShow.data.map((task) => {
                                                                return <React.Fragment>
                                                                    <TableRow
                                                                        key={row.no}
                                                                        style={{background: 'white'}}
                                                                    >
                                                                        <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b> </b></TableCell>
                                                                        <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{task.subject}</b></TableCell>
                                                                    </TableRow>
                                                                </React.Fragment>
                                                            })
                                                        }




                                                        {row && row.works && row.works
                                                            .filter((work) => work && workType ? work.id === workType.id : true)
                                                           /* .filter((work) => tasksToShow ? (tasksToShow.type !== 'etap' && tasksToShow.type !== 'group') : true) //ако е избрана група, то не се показват видовете работа
                                                            .filter((work) => tasksToShow && tasksToShow.type === 'work' ? tasksToShow.id === work.id : true) // Ако е избрана група, скива останалите групи
                                                          */  .filter((work) => stage && group)

                                                            .map((row, index) => (
                                                                <React.Fragment>
                                                                    <TableRow
                                                                        key={row.no}
                                                                        style={{background: '#d7d5d7'}}
                                                                        onClick={() => {
                                                                            /*setExternalWorkId(row.id_awork);
                                                                            setEmptyAnalysisDialog(row.id)*/

                                                                        }}
                                                                    >
                                                                        <TableCell sx={{border: 1}} width={NUM_PERC} align="left">{index + 1}</TableCell>
                                                                        <TableCell sx={{border: 1}} width={NAME_PERC} align="left"
                                                                                   onClick={() => {


                                                                                   }}>
                                                                            <Grid container justifyContent={'space-between'}>
                                                                                <Grid item>
                                                                                    {row.name_work}
                                                                                </Grid>

                                                                                <Grid item>
                                                                                    {row.task_count && row.task_count > 0 ? <Grid item>
                                                                                        <AssistantIcon/>
                                                                                    </Grid> : null}
                                                                                </Grid>

                                                                            </Grid>
                                                                        </TableCell>

                                                                    </TableRow>




                                                                    {
                                                                        isTextFieldFocused && forceRefreshRow && tasksToShow && tasksToShow.id === row.id && tasksToShow.data && tasksToShow.data.map((task) => {
                                                                            return <React.Fragment>
                                                                                <TableRow
                                                                                    key={row.no}
                                                                                    style={{background: 'white'}}
                                                                                >
                                                                                    <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b> </b></TableCell>
                                                                                    <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{task.subject}</b></TableCell>

                                                                                </TableRow>
                                                                            </React.Fragment>
                                                                        })
                                                                    }


                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </React.Fragment>
                                                ))
                                            }
                                        </React.Fragment>

                                    ))}
                            </TableBody>
                        </Table> :

                        <div style={{padding: 20}}>
                            <Typography>Няма резултати</Typography>
                        </div>
                    }
                </TableContainer>
            </Grid>
        </Grid>

    )
}

export default CreateTaskSupportPanel;