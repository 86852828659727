import Grid from "@mui/material/Grid";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import moment from "moment/moment";
import * as React from "react";
import {useEffect, useState} from "react";
import MessageDialog from "../../helper/MessageDialog";
import GraphicDaysTable from "./GraphicDaysTable";
import {getGraphicPerViewType} from "../../../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";

export default function GraphYearsWeekNumsCalendar({items, setConfirmMove, tab, setGraphicEditDialog,
                                                       selectedMonths, setSelectedMonths,
                                                       selectedWeeks, setSelectedWeeks,
                                                       selectedWeeksTemp, setSelectedWeeksTemp,
                                                       getMonthShortName, year, setYear, searchTerm,
                                                       selectedObject, filterAnex, selectedDay, setSelectedDay, setForceRefresh}) {
    const NUM_PERC = '4%';
    const NAME_PERC = '23%';
    const ENTITY_PERC = '3%';
    const QTY_PERC = '3%';
    const ENT_PRICE_PERC = '7%';
    const TOTAL_PERC = '8%';
    const QTY_MONEY = '5%';
    const DAILY_MONEY = '3%';

    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    const [messageDialog, setMessageDialog] = useState(null);

    const [monthItems, setMonthItems] = useState([{name: 'Къща Спасов - Довършване плочки и фугиране'}]);
    const [numWeeks, setNumWeeks] = useState([]);
    const [weeksSplitter, setWeeksSplitter] = useState([]);

    function getMonths() {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }

    function getMonthShortName(month) {
        switch (month) {
            case 1:
                return "ян";
            case 2:
                return "фев";
            case 3:
                return "март";
            case 4:
                return "апр";
            case 5:
                return "май";
            case 6:
                return "юни";
            case 7:
                return "юли";
            case 8:
                return "авг";
            case 9:
                return "септ";
            case 10:
                return "окт";
            case 11:
                return "нов";
            case 12:
                return "дек";
        }
    }

    useEffect(() => {
        if (selectedMonths.length <= 0) {
            return;
        }
        console.log('selectedMonths ', selectedMonths)

        let firstDate = new Date(year.format('YYYY'), selectedMonths[0] - 1, 1)
        let lastDate = new Date(year.format('YYYY'), selectedMonths[selectedMonths.length - 1], 0)

        getGraphicPerViewType(navigate, accessToken, setAccessToken, selectedObject,
            firstDate.getFullYear() + '-' + (firstDate.getMonth() + 1) + '-' + firstDate.getDate(),
            lastDate.getFullYear() + '-' + (lastDate.getMonth() + 1) + '-' + lastDate.getDate(),
            'months')
            .then((result) => {
                if (result && result.items) {
                    if (result && result.items) {


                    }
                    for (const resultElement of result.items) {
                        resultElement.name = resultElement.name_group;
                        resultElement.sub = resultElement.subgroups;
                        resultElement.details = resultElement.subgroups;
                        for (const resultElementElement of resultElement.sub) {
                            resultElementElement.name = resultElementElement.name_group;
                            for (const work of resultElementElement.works) {
                                work.name = work.name_work;
                                //work.entity = work.edm;
                                work.quantity = work.qty;
                                work.startDate = work.date_start;
                                work.endDate = work.date_end;
                            }
                        }
                    }

                    //setMonthItems(result.items)
                }

                if (result && result.weeks) {
                    setNumWeeks(result.weeks)
                }

                if (result && result.weeksplitter) {
                    setWeeksSplitter(result.weeksplitter)
                }
            })
    }, [])

    function ISO8601_week_no(dt)
    {
        var tdt = new Date(dt.valueOf());
        var dayn = (dt.getDay() + 6) % 7;
        tdt.setDate(tdt.getDate() - dayn + 3);
        var firstThursday = tdt.valueOf();
        tdt.setMonth(0, 1);
        if (tdt.getDay() !== 4)
        {
            tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
        }
        return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    }

    function getWeekNumsMonth(month, yearSelected) {
        let year = Number(yearSelected.format('YYYY'));

        console.log('months , year ', year)
        if (!month ) {
            return 1;
        }

        let firstDate = new Date(year, month - 1, 1)
        let lastDate = new Date(year, month, 0)

        let nums = [];

        let firstWeekStart = ISO8601_week_no(firstDate);
        if (firstWeekStart >= 52) {
            nums.push(firstWeekStart);
        }
        firstWeekStart = 1;


        for (let i = firstWeekStart; i <= ISO8601_week_no(lastDate); i++) {
            nums.push(i);
        }
        return nums.length;
    }

    function getWeekNumsForMonthsAndYear(months, yearSelected) {
        let year = Number(yearSelected.format('YYYY'));
        console.log('months ', months)
        console.log('months , year ', year)
        if (!months || months.length <= 0) {
            return {
                colspans: [],
                weeks: []
            };
        }
        let weekNums = new Set();


        let numOfWeeks = [];
        for (const month of months) {
            let firstDate = new Date(year, month - 1, 1)
            let lastDate = new Date(year, month, 0)
            console.log('firstDate ', firstDate)
            console.log('lastDate ', lastDate)

            let nums = [];

            console.log('ISO8601_week_no(firstDate) ', ISO8601_week_no(firstDate))
            console.log('ISO8601_week_no(lastDate) ', ISO8601_week_no(lastDate))


            let firstWeekStart = ISO8601_week_no(firstDate);
            if (firstWeekStart >= 52) {
                nums.push(firstWeekStart);
            }
            firstWeekStart = 1;

            console.log('ISO8601_week_no firstWeekStart ', firstWeekStart)
            console.log('ISO8601_week_no ', ISO8601_week_no(lastDate))
            for (let i = firstWeekStart; i <= ISO8601_week_no(lastDate); i++) {
                nums.push(i);
            }
            numOfWeeks.push(nums.length);

            nums.forEach((item) => weekNums.add(item));
        }
        console.log('weekNums ', weekNums)
        return {
            colspans: numOfWeeks,
            weeks: Array.from(weekNums)
        };
    }

    return (
        <React.Fragment>
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        {/*
                        <Grid item xs={1.2}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">
                                <DatePicker size={'small'} views={['year']} label="Година" value={year} onChange={(value) => {
                                    setYear(value)
                                }}/>
                            </LocalizationProvider>
                        </Grid>

                        {
                            getMonths()
                                .map((dateInList, index, array) => {
                                    return <Grid item xs={0.6}
                                                 onClick={() => {
                                                     if (selectedMonths.includes(dateInList)) {
                                                         let newSelectedMonths = selectedMonths.filter(function(item) {
                                                             return item !== dateInList
                                                         })

                                                         console.log('newSelectedMonths ', newSelectedMonths)
                                                         setSelectedMonths(newSelectedMonths)
                                                     } else {
                                                         if (selectedMonths.length<=2) {
                                                             setSelectedMonths([
                                                                 ...selectedMonths,
                                                                 dateInList
                                                             ])
                                                         } else {
                                                             setMessageDialog('Могат да се избират не повече от 3 месеца');
                                                         }

                                                     }
                                                 }} width={DAILY_MONEY} align="center"><TextField
                                                        sx={{background: selectedMonths.includes(dateInList) ? '#8888fc' : null}}
                                                        size={'small'} disabled label={'Месец'} value={getMonthShortName(dateInList)}/></Grid>
                                })
                        }*/}
                    </Grid>
                </Grid>

                <Grid item xs={12}>

                    {selectedWeeks.length > 0 ?
                        <GraphicDaysTable selectedDay={selectedDay} setSelectedDay={setSelectedDay} tab={tab} setGraphicEditDialog={setGraphicEditDialog} year={year}
                                          selectedWeeks={selectedWeeks} items={items} setConfirmMove={setConfirmMove}
                                          selectedObject={selectedObject} filterAnex={filterAnex}
                                          setForceRefresh={setForceRefresh} searchTerm={searchTerm}/>
                        :
                        <TableContainer>
                            {monthItems && monthItems.length > 0 ? <Table size={'small'} sx={ {border: 1}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>№</b></TableCell>
                                            <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>Наименование<span style={{color: '#ff5900', marginLeft: 5}}>График по договор</span></b></TableCell>
                                            <TableCell sx={{border: 1}} width={ENTITY_PERC} align="center"><b>М-ка</b></TableCell>
                                            <TableCell sx={{border: 1}} width={QTY_PERC} align="center"><b>Колич.</b></TableCell>
                                            <TableCell sx={{border: 1}} width={QTY_MONEY} align="center"><b>Раб дни</b></TableCell>
                                            {
                                                selectedMonths.sort()
                                                    .map((dateInList, index, array) => {
                                                        return <TableCell
                                                            colspan={index <= weeksSplitter.length - 1 ? weeksSplitter[index] : (numWeeks.length - weeksSplitter[index-1])}
                                                            style={{padding: 2}}
                                                            onClick={() => {
                                                                if (selectedMonths.includes(dateInList)) {
                                                                    let newSelectedMonths = selectedMonths.filter(function(item) {
                                                                        return item !== dateInList
                                                                    })

                                                                    console.log('newSelectedMonths ', newSelectedMonths)
                                                                    setSelectedMonths(newSelectedMonths)
                                                                } else {
                                                                    if (selectedMonths.length<=2) {
                                                                        setSelectedMonths([
                                                                            ...selectedMonths,
                                                                            dateInList
                                                                        ])
                                                                    } else {
                                                                        setMessageDialog('Могат да се избират не повече от 3 месеца');
                                                                    }

                                                                }
                                                            }}
                                                            sx={{border: 1, padding: 1, background: selectedMonths.includes(dateInList) ? '#8888fc' : null
                                                            }} width={DAILY_MONEY} align="center">
                                                            <Typography
                                                                sx={{background: selectedMonths.includes(dateInList) ? '#8888fc' : null}}
                                                                size={'small'}>{getMonthShortName(dateInList)}</Typography>
                                                        </TableCell>
                                                    })
                                            }
                                        </TableRow>


                                        <TableRow>
                                            <TableCell sx={{border: 1}} width={NUM_PERC} align="left"></TableCell>
                                            <TableCell sx={{border: 1}} width={NAME_PERC} align="left"></TableCell>
                                            <TableCell sx={{border: 1}} width={ENTITY_PERC} align="center"></TableCell>
                                            <TableCell sx={{border: 1}} width={QTY_PERC} align="center"></TableCell>
                                            <TableCell sx={{border: 1}} width={QTY_MONEY} align="center">Седмици</TableCell>
                                            {
                                                (numWeeks ?? [])
                                                    .map((weekNum, index, array) => {
                                                        return <TableCell
                                                            onClick={() => {
                                                                if (selectedWeeksTemp.includes(weekNum)) {
                                                                    let newSelectedWeeks = selectedWeeksTemp.filter(function(item) {
                                                                        return item !== weekNum
                                                                    })

                                                                    setSelectedWeeksTemp(newSelectedWeeks)
                                                                } else {
                                                                    if (selectedWeeksTemp.length<=1) {
                                                                        setSelectedWeeksTemp([
                                                                            ...selectedWeeksTemp,
                                                                            weekNum
                                                                        ])
                                                                    } else {
                                                                        setMessageDialog('Могат да се избират не повече от 2 седмици');
                                                                    }

                                                                }
                                                            }}
                                                            sx={{border: 1, background: selectedWeeksTemp.includes(weekNum) ? '#8888fc' : null}} width={DAILY_MONEY} align="center"><b>{weekNum}</b></TableCell>
                                                    })
                                            }
                                        </TableRow>

                                    </TableHead>
                                    <TableBody>
                                        {monthItems && monthItems
                                            .map((row, index) => (
                                                <React.Fragment>
                                                    <TableRow
                                                        key={row.no}
                                                        style={{background: '#63fff2'}}
                                                    >
                                                        <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>{index + 1}</b></TableCell>
                                                        <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{row.name}</b></TableCell>
                                                        <TableCell sx={{border: 1}} width={ENTITY_PERC} align="center"></TableCell>
                                                        <TableCell sx={{border: 1}} width={QTY_PERC} align="right">{row.qty ? row.qty.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                        <TableCell sx={{border: 1}} width={QTY_MONEY} align="center"></TableCell>
                                                        {
                                                            (numWeeks ?? []).map((dateInList, index, array) => {
                                                                return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center"></TableCell>
                                                            })
                                                        }
                                                    </TableRow>

                                                    {row && row.details && row.details.map((row, index) => (
                                                        <React.Fragment>

                                                            <TableRow
                                                                key={row.no}
                                                                style={{background: '#adadad'}}
                                                            >
                                                                <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>{index + 1}</b></TableCell>
                                                                <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{row.name_group}</b></TableCell>
                                                                <TableCell sx={{border: 1}} width={ENTITY_PERC} align="center">{row.entity}</TableCell>
                                                                <TableCell sx={{border: 1}} width={QTY_PERC} align="right">{row.qty ? row.qty.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                                <TableCell sx={{border: 1}} width={QTY_MONEY} align="right">{row.daily_money ? row.daily_money.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                                {
                                                                    (numWeeks ?? [])
                                                                        .map((dateInList, index, array) => {
                                                                            return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center"></TableCell>
                                                                        })
                                                                }
                                                            </TableRow>

                                                            {row && row.works && row.works
                                                                .filter((item) => filterAnex ? item.is_anex !== 1 : true)
                                                                .map((row, index) => (
                                                                <React.Fragment>
                                                                    <TableRow
                                                                        key={row.no}
                                                                        style={tab === 2 && row && row.details ? {background: '#d7d5d7'} : {}}
                                                                        onClick={() => {
                                                                            /*setExternalWorkId(row.id_awork);
                                                                            setEmptyAnalysisDialog(row.id)*/
                                                                            setGraphicEditDialog(row)
                                                                        }}
                                                                    >
                                                                        <TableCell sx={{border: 1}} width={NUM_PERC} align="left">{index + 1}</TableCell>
                                                                        <TableCell sx={{border: 1}} width={NAME_PERC} align="left">{row.name}</TableCell>
                                                                        <TableCell sx={{border: 1}} width={ENTITY_PERC} align="center">{(row.entity || row.edm) ? (row.entity || row.edm).toUpperCase() : ''}</TableCell>
                                                                        <TableCell sx={{border: 1}} width={QTY_PERC} align="right">{row.qty ? row.qty.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                                        <TableCell sx={{border: 1}} width={QTY_MONEY} align="right">{row.work_dates && row.work_dates.length > 0 ?
                                                                            row.work_dates.length : '-'}</TableCell>

                                                                        {
                                                                            (numWeeks ?? [])
                                                                                .map((dateInList, index, array) => {
                                                                                    let workWeeks = row.work_weeks ?? [];

                                                                                    return <TableCell sx={{border: 1,
                                                                                        background: workWeeks.includes(dateInList) ? '#8888fc' : null
                                                                                    }} width={DAILY_MONEY} align="center"
                                                                                                      onClick={(event) => {
                                                                                                          event.stopPropagation()
                                                                                                          event.nativeEvent.stopImmediatePropagation();

                                                                                                          setConfirmMove({
                                                                                                              id: row.id,
                                                                                                              date: moment().format('YYYY-MM-DD'),
                                                                                                              text: 'Наистина ли искате да изместите дейността \"' + row.name + '\" за '
                                                                                                                  + moment().format('YYYY-MM-DD')
                                                                                                          })
                                                                                                      }}>

                                                                                    </TableCell>
                                                                                })
                                                                        }
                                                                    </TableRow>


                                                                </React.Fragment>
                                                            ))
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                    }
                                                </React.Fragment>

                                            ))}
                                    </TableBody>
                                </Table> :

                                <div style={{padding: 20}}>
                                    <Typography>Няма резултати</Typography>
                                </div>
                            }
                        </TableContainer>

                    }



                    <MessageDialog
                        openAndText={messageDialog}
                        handleClose={() => setMessageDialog(null)}
                        positiveText={'Добре'}
                        positiveAction={() => {
                            setMessageDialog(false)
                        }}/>
                </Grid>

            </Grid>

        </React.Fragment>
    )
}
