import './App.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import {useLocation, useNavigate} from "react-router-dom";
import {useLocalStorage} from "./useLocalStorage";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ObjectDataDialog from "./components/ObjectDataDialog";
import CalculationsDialog from "./components/CalculationsDialog";
import CalculateIcon from '@mui/icons-material/Calculate';
import WorkTypeDialog from "./components/workType/WorkTypeDialog";
import EngineeringIcon from '@mui/icons-material/Engineering';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {editObject} from "./utils/NetworkUtils";
import AddDelivererDialog from "./components/addDeliverers/AddDelivererDialog";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ResourcesComponent from "./components/dialogs/resources/ResourcesComponent";

function RightOptionsSideMenu(props) {
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    let navigate = useNavigate();
    const location = useLocation()

    const [objectSettings, setObjectSettings] = React.useState(null);
    const [calculationsSettings, setCalculationsSettings] = React.useState(null);
    //const [analysisDialogOpen, setAnalysisDialogOpen] = React.useState(null);
    const [workTypeDialogOpen, setWorkTypeDialogOpen] = React.useState(null);
    const [emptyAnalysisDialog, setEmptyAnalysisDialog] = React.useState(null);
    const [deliverersOpen, setDeliverersOpen] = React.useState(null);
    const [resourcesOpen, setResourcesOpen] = React.useState(null);

    const list = (anchor) => (
        <Box
            style={{paddingLeft: 20}}
            sx={{ right: 20 }}
            role="presentation">
            <List>

                <ListItemButton onClick={() => {
                    setObjectSettings(true)
                }}>
                    <ListItemIcon >
                        <CorporateFareIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Данни за обект'} />
                </ListItemButton>


                <ListItemButton onClick={() => {
                    setCalculationsSettings(true)
                }}>
                    <ListItemIcon >
                        <CalculateIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Начисления'} />
                </ListItemButton>


              {/*  <ListItemButton onClick={() => {
                    setAnalysisDialogOpen(true)
                }}>
                    <ListItemIcon >
                        <SearchIcon />
                    </ListItemIcon>
                    <ListItemText primary={'СЕК'} />
                </ListItemButton>*/}



                <ListItemButton onClick={() => {
                    setWorkTypeDialogOpen(true)
                }}>
                    <ListItemIcon >
                        <EngineeringIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Видове работа'} />
                </ListItemButton>


                <ListItemButton onClick={() => {
                    setEmptyAnalysisDialog(true)
                }}>
                    <ListItemIcon >
                        <ManageSearchIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Нов анализ'} />
                </ListItemButton>

                <ListItemButton onClick={() => {
                    setDeliverersOpen(true)
                }}>
                    <ListItemIcon >
                        <DeliveryDiningIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Доставчици'} />
                </ListItemButton>

                <ListItemButton onClick={() => {
                    setDeliverersOpen(true)
                }}>
                    <ListItemIcon >
                        <DeliveryDiningIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Изпълнители'} />
                </ListItemButton>


                <ListItemButton onClick={() => {
                    setResourcesOpen(true)
                }}>
                    <ListItemIcon >
                        <DeliveryDiningIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Автопарк'} />
                </ListItemButton>

            </List>
        </Box>
    );

    return (
        <React.Fragment key={'right'}>
            <Drawer
                anchor={'right'}
                open={props.isOpen}
                onClose={() => {
                    props.setOpen(false)
                }}
            >
                {list('right')}
            </Drawer>

            <ObjectDataDialog
                editObject={props.selectedObject || props.selectedObjectOffer}
                open={objectSettings}
                handleClose={() => setObjectSettings(false)}
                onClick={(navigate, accessToken, setAccessToken,
                    name, shortName, client, address,
                          investor, description, area, workDays, techManager) => {
                    if (props.selectedObject || props.selectedObjectOffer) {
                        editObject(navigate, accessToken, setAccessToken, props.selectedObject || props.selectedObjectOffer,
                            name, shortName, client, address,
                            investor, description, area, workDays, techManager)
                            .then(r => {
                                if (props.setRefreshObjects) {
                                    props.setRefreshObjects(Math.random())
                                }
                                if (props.setSelectedObjectForOfferName) {
                                    props.setSelectedObjectForOfferName(name)
                                }
                                setObjectSettings(false)
                            })
                    }}
            }/>

            <CalculationsDialog
                open={calculationsSettings}
                handleClose={() => setCalculationsSettings(false)}/>

           {/* <SicDialog
                open={analysisDialogOpen}
                handleClose={() => setAnalysisDialogOpen(false)}/>
*/}
            <WorkTypeDialog
                open={workTypeDialogOpen}
                handleClose={() => setWorkTypeDialogOpen(false)}/>



            <AddDelivererDialog
                objectId={props.selectedObject || props.selectedObjectOffer}
                open={deliverersOpen}
                handleClose={() => setDeliverersOpen(false)}/>

            <ResourcesComponent
                open={resourcesOpen}
                handleClose={() => setResourcesOpen(false)}/>
        </React.Fragment>
    );
}

export default RightOptionsSideMenu;
