import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {CommonFetch} from "../../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import Typography from "@mui/material/Typography";
import AutocompleteUsers from "../../../inputs/AutocompleteUsers";
import moment from "moment";
import Divider from "@mui/material/Divider";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PartnerPickForAdditionalLabourLightweightDialog({open, handleClose, setForceRefresh, selectedObject, executionDotButtonType, partnersForExecution, idTaskOrAwork, selectedDate}) {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [executorOpen, setExecutorOpen] = useState(false);

    const [date, setDate] = useState(null);
    const [qty, setQty] = useState(null);

    const [executorId, setExecutorId] = useState(null);
    const [executor, setExecutor] = useState(null);

    const [executorTyped, setExecutorTyped] = useState(null);

    const [selectedDetail, setSelectedDetail] = useState(null);

    const [selectedVehicle, setSelectedVehicle] = useState(null);


    const [vehicles, setVehicle] = useState(null);


    const [numHours, setNumHours] = useState(null);
    const [pricePerHour, setPricePerHour] = useState(null);


    const [partners, setPartners] = useState([]);
    const [tempExecutor, setTempExecutor] = useState(null);
    const [tempExecutorId, setTempExecutorId] = useState(null);


    const [partnersForExecutionApi, setPartnersForExecutionApi] = useState([]);
    const [partnersForExecutionApiAdditional, setPartnersForExecutionApiAdditional] = useState([]);


    useEffect(() => {
        if (partnersForExecution) {
            setPartnersForExecutionApi([...partnersForExecution])
        }
    }, [partnersForExecution])

    useEffect(() => {
        if (selectedVehicle) {

        }
    }, [selectedVehicle])

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/getResources?`+ new URLSearchParams({
            token: accessToken,
            type: 1,
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setVehicle([...results])
                }
            })
    }, [open, executorTyped])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}>


            <Grid container spacing={1} style={{margin: 15}}>

                { executionDotButtonType === 'task' ?
                    partnersForExecutionApi.map((partner, index) => {
                        console.log(partner);
                        return (
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={10}>
                                        <TextField fullWidth label={'Изпълнител'} value={partner ? partner.partner_name : ''} shrink={true}/>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField label={'Брой часове'} value={partnersForExecutionApi[index].num_hours} onChange={(e) => {
                                            let newObject = partnersForExecutionApi[index];
                                            newObject.num_hours = e.target.value;
                                            let newArray = partnersForExecutionApi;
                                            newArray[index] = newObject;
                                            setPartnersForExecutionApi([...newArray])
                                            console.log('partnersForExecutionApi ', partnersForExecutionApi)
                                        }}/>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField label={'Цена на час'} value={partnersForExecutionApi[index].hour_pay} onChange={(e) => {
                                            let newObject = partnersForExecutionApi[index];
                                            newObject.hour_pay = e.target.value;
                                            let newArray = partnersForExecutionApi;
                                            newArray[index] = newObject;
                                            setPartnersForExecutionApi([...newArray])
                                            console.log('partnersForExecutionApi ', partnersForExecutionApi)
                                        }}/>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography>Общо: {!isNaN(partnersForExecutionApi[index].hour_pay) && !isNaN(partnersForExecutionApi[index].num_hours) ? Number(partnersForExecutionApi[index].hour_pay) * Number(partnersForExecutionApi[index].num_hours) : "0.00"}</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })

                    :

                    null
                }
                {
                    partnersForExecutionApiAdditional.map((partner, index) => {
                        console.log(partner);
                        return (
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={10}>
                                        <AutocompleteUsers
                                            idObject={selectedObject}
                                            executor={partner.exec} setExecutor={(name) => {
                                                let newObject = partnersForExecutionApiAdditional[index];
                                                newObject.exec = name;
                                                let newArray = partnersForExecutionApiAdditional;
                                                newArray[index] = newObject;
                                                setPartnersForExecutionApiAdditional([...newArray])
                                            }}
                                            setExecutorId={setTempExecutorId} onSelect={(prop) => {
                                            console.log('проппп ', prop)
                                            let newPartners = partnersForExecutionApiAdditional;
                                            newPartners[index] = {
                                                id_partner: prop.id
                                            }
                                            setPartnersForExecutionApiAdditional(newPartners)
                                        }
                                        }/>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField label={'Брой часове'} value={partnersForExecutionApiAdditional[index].num_hours} onChange={(e) => {
                                            let newObject = partnersForExecutionApiAdditional[index];
                                            newObject.num_hours = e.target.value;
                                            let newArray = partnersForExecutionApiAdditional;
                                            newArray[index] = newObject;
                                            setPartnersForExecutionApiAdditional([...newArray])
                                            console.log('partnersForExecutionApiAdditional ', partnersForExecutionApiAdditional)
                                        }}/>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField label={'Цена на час'} value={partnersForExecutionApiAdditional[index].hour_pay} onChange={(e) => {
                                            let newObject = partnersForExecutionApiAdditional[index];
                                            newObject.hour_pay = e.target.value;
                                            let newArray = partnersForExecutionApiAdditional;
                                            newArray[index] = newObject;
                                            setPartnersForExecutionApiAdditional([...newArray])
                                            console.log('partnersForExecutionApiAdditional ', partnersForExecutionApiAdditional)
                                        }}/>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography>Общо: {!isNaN(partnersForExecutionApiAdditional[index].hour_pay) && !isNaN(partnersForExecutionApiAdditional[index].num_hours) ? Number(partnersForExecutionApiAdditional[index].hour_pay) * Number(partnersForExecutionApiAdditional[index].num_hours) : "0.00"}</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                }



                <Grid item xs={2}>
                    <Button onClick={() => {
                        setPartnersForExecutionApiAdditional([...partnersForExecutionApiAdditional, {}])
                    }}>Добави изпълнител</Button>
                </Grid>

                <Grid item xs={12}>
                    <Button variant={'contained'}
                            //disabled={partnersForExecutionApi.some((part) => !part.hour_pay || !part.num_hours)}
                            onClick={() => {
                                let modifiedPartnersForExecutionApi = [...partnersForExecutionApi, ...partnersForExecutionApiAdditional];
                                for (let i = 0; i < modifiedPartnersForExecutionApi.length; i++) {
                                    console.log('modifiedPartnersForExecutionApi[i]' , modifiedPartnersForExecutionApi[i])
                                    modifiedPartnersForExecutionApi[i].id_partner = modifiedPartnersForExecutionApi[i].id || ((modifiedPartnersForExecutionApi[i].exec??{}).id)
                                    modifiedPartnersForExecutionApi[i].hours_work = modifiedPartnersForExecutionApi[i].num_hours
                                    modifiedPartnersForExecutionApi[i].price_hour = modifiedPartnersForExecutionApi[i].hour_pay
                                    if (executionDotButtonType === 'work') {
                                        modifiedPartnersForExecutionApi[i].code = null
                                    }
                                }

                                CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/addWorkReport`, {
                                    method: "POST",
                                    mode: 'cors',
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        "token": accessToken,
                                        ...(executionDotButtonType === 'work' ? {"id_awork": idTaskOrAwork} : {"id_task": idTaskOrAwork}),
                                        "partners": modifiedPartnersForExecutionApi.filter((part) => part.hour_pay && part.num_hours),
                                        "date": selectedDate.format("YYYY-MM-DD")
                                    })
                                })
                                    .then((result) => {
                                        setForceRefresh(Math.random())
                                        handleClose()
                                    })
                            }}>Запис</Button>
                </Grid>
            </Grid>

        </Dialog>
    );
}