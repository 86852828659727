import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {useState} from "react";
import Grid from "@mui/material/Grid";
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";
import TextField from "@mui/material/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function CalculationsDialog({open, handleClose}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [helping, setHelping] = useState(null);
    const [deliveryPrices, setDeliveryPrices] = useState(null);

    return  (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Начисления
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container padding={3}>
                <Grid item xs={6.5}>
                    <Typography variant="h5">Материали</Typography>
                </Grid><Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={helping}
                        onChange={(event) =>  {}
                        }
                        label="Спомагателни %"
                    />
                </Grid>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={deliveryPrices}
                        onChange={(event) =>  {}
                        }
                        label="Доставно складови %"
                    />
                </Grid>



                <Grid item xs={6.5}>
                    <Typography variant="h5">Труд</Typography>
                </Grid><Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={helping}
                        onChange={(event) =>  {}
                        }
                        label="Непреки разходи %"
                    />
                </Grid>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={deliveryPrices}
                        onChange={(event) =>  {}
                        }
                        label="Печалба %"
                    />
                </Grid>




                <Grid item xs={6.5}>
                    <Typography variant="h5">Механизация</Typography>
                </Grid><Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={helping}
                        onChange={(event) =>  {}
                        }
                        label="Непреки %"
                    />
                </Grid>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={deliveryPrices}
                        onChange={(event) =>  {}
                        }
                        label="Печалба %"
                    />
                </Grid>




                <Grid item xs={6.5}>
                    <Typography variant="h5">Външни услуги</Typography>
                </Grid><Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={helping}
                        onChange={(event) =>  {}
                        }
                        label="Непреки %"
                    />
                </Grid>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        value={deliveryPrices}
                        onChange={(event) =>  {}
                        }
                        label="Печалба %"
                    />
                </Grid>

            </Grid>


        </Dialog>
    )
}
export default CalculationsDialog;