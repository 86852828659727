import moment from "moment/moment";
import * as React from "react";
import {Paper, TableCell, TableRow, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircleStatusIcons from "../helper/CircleStatusIcons";
import Button from "@mui/material/Button";
import {CommonFetch} from "../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";

function ReportsDayMobileComponent({items, setTaskType, setCommentsOnlyOpen, setNameAddDialog,
                                       setNameObject, searchTerm, setSetStatus, selectedDay, setAssignWorkerOnlyOpen,
                                       setEmptyAnalysisDialog, setEntityAddDialog, setTotalPrice, setEmptyAnalysisDialogStatus, setExecutionDotButtonType}) {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    return (
        <Grid container>
            { items && Object.keys(items).map((item, index) =>
                items && items[item] && items[item]
                    .filter((subItem) => {
                        if (!searchTerm) {
                            return true;
                        }
                        let string = `${item} - ${subItem.hour_start ? moment(subItem.hour_start, 'YYYY-MM-DD HH:mm').format('HH:mm') + " - ": null}
                                        ${subItem.hour_end ? moment(subItem.hour_end, 'YYYY-MM-DD HH:mm').format('HH:mm') + " - ": null}
                                        ${subItem.name_group ? (subItem.name_group + ' - ') : null}${subItem.name_work ? (subItem.name_work + ' - ') : null}${subItem.subject}`
                        return string ? string.toUpperCase().includes(searchTerm.toUpperCase()) : false;

                    })
                    .map((subItem) => {
                        return <Grid item xs={12}>
                            <Paper elevation={6} onClick={() => {
                                if (subItem.id_task) {
                                    setTaskType("task")

                                    setCommentsOnlyOpen(subItem.id_task)
                                    setNameAddDialog(subItem.name_work)
                                    setNameObject(item)
                                } else {
                                    setTaskType("done")
                                    setCommentsOnlyOpen(subItem.id_awork)
                                    setNameAddDialog(subItem.name_work)
                                    setNameObject(item)
                                }
                            }}>

                                    <Grid container spacing={1} style={{margin: 10}}>

                                        <Grid item xs={0.5} onClick={(e) => {
                                            e.stopPropagation()
                                            e.nativeEvent.stopImmediatePropagation();


                                            if (subItem.id_task) {
                                                setExecutionDotButtonType('task')

                                                setEmptyAnalysisDialog(subItem.id_task)
                                                setEmptyAnalysisDialogStatus(subItem.status)
                                                setNameAddDialog(subItem.name_work)

                                            } else {
                                                CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/getSingleKSS?`+ new URLSearchParams({
                                                    token: accessToken,
                                                    id: subItem.id_awork
                                                }), {
                                                    method: "GET",
                                                    mode: 'cors',
                                                    headers: {
                                                        "Content-Type": "application/json",
                                                    }
                                                })
                                                    .then((result) => {
                                                            if (result && result.items && result.items[0]) {
                                                                setExecutionDotButtonType('work')

                                                                setEmptyAnalysisDialog(subItem.id_awork)
                                                                setEmptyAnalysisDialogStatus(subItem.status)

                                                                console.log('settin g , ', result.items[0])
                                                                setNameAddDialog(result.items[0].name_work)
                                                                setEntityAddDialog(result.items[0].edm)
                                                                setTotalPrice(result.items[0].qty)
                                                            }

                                                        },
                                                        (error) => {
                                                        })
                                            }

                                        }}>
                                            <div style={{marginRight: 10, marginTop: 10}}>
                                                <div style={{
                                                    position: 'relative',
                                                    left: '0px',
                                                    top: '10px'}}>
                                                              <span style={{position: 'absolute',
                                                                  top: '50%',
                                                                  left: '50%',
                                                                  transform: 'translate(-50%, -50%)',

                                                                  zIndex: 2
                                                              }}>
                                                                    <CircleStatusIcons taskState={subItem.type === 'graph' ? "0" : subItem.status + ""}/>
                                                              </span>


                                                    {subItem.comments > 0 ? <span style={{
                                                        position: 'absolute',
                                                        top: '51%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -48%)',

                                                        zIndex: 2
                                                    }}>
                                                                    <p style={{fontSize: 20}}><b>*</b></p>
                                                                </span> : null}

                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={10} style={{margin: 9}}>
                                            <Typography>{item} - {subItem.hour_start ? moment(subItem.hour_start, 'YYYY-MM-DD HH:mm').format('HH:mm') + " - ": null}
                                                {subItem.hour_end ? moment(subItem.hour_end, 'YYYY-MM-DD HH:mm').format('HH:mm') + " - ": null}
                                                {subItem.name_etap ? (subItem.name_etap + ' - ') : null}
                                                {subItem.name_group ? (subItem.name_group + ' - ') : null}{subItem.name_work ? (subItem.name_work + ' - ') : null}{subItem.subject}</Typography>
                                        </Grid>

                                    </Grid>

                            </Paper>

                        </Grid>

                    }))
            }
        </Grid>
    )

}
export default ReportsDayMobileComponent;