import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect} from "react";
import {getPartnersWithIndex} from "../../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";
import ContragentsExecutorTable from "../dialogs/resources/ContagentsExecutorTable";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function AddDelivererComponent({checked, setChecked, tab, setTab}) {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    const [tabItems, setTabItems] = React.useState([]);

    const handleChange = (event, newValue) => {
        setChecked([]);
        setTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    useEffect(() => {
        let type = 1;
        switch (tab) {
            case 0: type = 1; break;
            case 1: type = 3; break;
            case 2: type = 4; break;
            case 3: type = 2; break;
        }

        getPartnersWithIndex(navigate, accessToken, setAccessToken, type)
            .then((result) => {
                if (result && result.items) {
                    setTabItems(result.items)
                }
            })
    }, [tab])

    return (
        <Grid container spacing={1} style={{padding: 10}}>

            <ContragentsExecutorTable/>

        </Grid>
    )
}
export default AddDelivererComponent;