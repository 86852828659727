import Grid from "@mui/material/Grid";
import * as React from "react";
import GraphYearsCalendar from "../components/executorDialog/graphicObjects/GraphYearsCalendar";
import {useEffect, useState} from "react";
import moment from "moment";
import {getObjectsTasks} from "../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";
import CommentHistoryDialog
    from "../components/dialogs/dailyWorks/objectReportsGraph/commentHistory/CommentHistoryDialog";

function ReportsObjectsScreen() {
    let navigate = useNavigate();
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [selectedMonths, setSelectedMonths] = useState([]);

    const [graphOpen, setGraphOpen] = useState( null);

    const [graphicEditDialog, setGraphicEditDialog] = useState( null);
    const [selectedMonthsTemp, setSelectedMonthsTemp] = useState([]);

    const [tab, setTab] = useState(2);

    const [yearItems, setYearItems] = useState([]);

    const [selectedObject, setSelectedObject] = useState(null);


    const [objectName, setObjectName] = useState(null);
    const [isObjectOpen, setObjectOpen] = useState(null);
    const [confirmMove, setConfirmMove] = useState(null);

    const [yearSelected, setYearSelected] = useState(moment());

    const [forceRefresh, setForceRefresh] = useState(null);


    useEffect(() => {
        getObjectsTasks(navigate, accessToken, setAccessToken, setYearItems, () => {})
    }, [forceRefresh])

    return (
        <Grid container spacing={1} justifyContent={'flex-start'}>

            <Grid item xs={12}>

                <GraphYearsCalendar
                    contractMode
                    tab={tab} setTab={setTab}
                    yearItems={yearItems} setYearItems={setYearItems}
                    setConfirmMove={confirmMove} setGraphicEditDialog={setGraphicEditDialog}
                    year={yearSelected} setYear={setYearSelected}
                    selectedMonths={selectedMonths} setSelectedMonths={setSelectedMonths}
                    selectedMonthsTemp={selectedMonthsTemp} setSelectedMonthsTemp={setSelectedMonthsTemp}
                    setForceRefresh={setForceRefresh}
                    onClick={(id, name) => {
                        setSelectedObject({
                            id: id,
                            name: name
                        })

                    }}/>

            </Grid>


            <CommentHistoryDialog open={selectedObject ? selectedObject.id : null} handleClose={() => setSelectedObject(null)}
                                  selectedObject={selectedObject}/>


        </Grid>
    )
}
export default ReportsObjectsScreen;