import TextField from "@mui/material/TextField";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {useEffect, useState} from "react";
import {useLocalStorage} from "../useLocalStorage";
import {useNavigate} from "react-router-dom";
import CircleStatusIcons from "../components/helper/CircleStatusIcons";

function AutocompleteTasks({task, setTask, setTaskId, onSelect, additional,
                               stageId, groupId, workId, tasksOptions,
                               isTextFieldFocused, setIsTextFieldFocused, taskState}) {

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    let navigate = useNavigate();

    const [executorOpen, setExecutorOpen] = useState(false);

    const [taskTyped, setTaskTyped] = useState(null);


    const handleTextFieldFocus = () => {
        console.log('setIsTextFieldFocused(true)сдф')
        setIsTextFieldFocused(true);
    };

    const handleTextFieldBlur = () => {
        console.log('setIsTextFieldFocused(false)')
        setIsTextFieldFocused(false);
    };

    useEffect(() => {
        /*CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/firms?`+ new URLSearchParams({
            token: accessToken,
            ...(task ? { search: taskTyped } : {}),

        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setTasksOptions([...results])
                }
            })*/
    }, [taskTyped, stageId, workId, groupId])

    return (
        <Autocomplete
            id="contragents"
            freeSolo
            open={executorOpen}
            onOpen={() => {
                setExecutorOpen(true);
            }}
            onClose={() => {
                setExecutorOpen(false);
            }}
            value={task ? task && task.subject ? task : {subject: task} : null}
            filterOptions={(options, params) => {
                let newOptions = options.filter((option) => {
                    if (!option || !option.subject || !task || !taskTyped) {
                        return false;
                    }
                    return option.subject.toLowerCase().includes(taskTyped ? taskTyped.toLowerCase() : "")
                })

                const { inputValue } = params;

                // Suggest the creation of a new value
                const isExisting = newOptions.length > 0;
                if (inputValue !== '' && !isExisting) {
                    newOptions.push({
                        inputValue,
                        subject: `Добави "${inputValue}"`,
                    });
                }

                return newOptions;
            }}
            onChange={(event, prop) => {
                console.log('prop ', prop)
                if (prop) {
                    console.log(prop)
                    console.log(prop.id)

                    if (!prop.id) {
                        const personName = prop.subject.replace("Добави \"", "").replace("\"", "")


                        /*CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/add`, {
                            method: "POST",
                            mode: 'cors',
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                token: accessToken,
                                "name_firm":personName,
                                "phone":"",
                                "contact_person":personName,
                                "id_category":"2",
                            })
                        })
                            .then((result) => {
                                if (result && result.id) {
                                    setTaskId(result.id)
                                    setTask(personName)

                                    if (onSelect) {
                                        onSelect({
                                            id: result.id,
                                            name_firm: personName
                                        })
                                    }
                                }
                            })*/



                    } else {
                        if (onSelect) {
                            onSelect(prop)
                        }
                        setTaskId(prop && prop.id ? prop.id : prop)
                        setTask(prop && prop.name ? prop.name : prop)
                    }


                } else {
                    setTask("")
                }
            }}
            onInput={(event) => {
                setTaskTyped(event.target.value)
            }}
            getOptionLabel={(option) => option.subject}
            options={tasksOptions}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Задача"
                    value={taskTyped}
                    onChange={(e) => {
                        setTask(e.target.value)
                    }}
                    onFocus={handleTextFieldFocus}
                    onBlur={handleTextFieldBlur}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            taskState && Number(taskState) >= 0 ? <CircleStatusIcons style={{marginRight: -10}} size={20} taskState={taskState}/> : <React.Fragment>
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            {...additional}
        />
    )
}
export default AutocompleteTasks;