import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import AvtoparkComponent from "./AvtoparkComponent";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ResourcesComponent({open, handleClose}) {

    const [tab, setTab] = React.useState(0);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >

            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Автопарк
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container>
{/*
                <Grid item xs={12}>
                    <Tabs value={tab} onChange={(event, newValue) => {
                        setTab(newValue);
                    }} aria-label="basic tabs example">
                        <Tab label="Бригади" />
                        <Tab label="Автопарк" />
                    </Tabs>
                </Grid>*/}

       {/*         <Grid item xs={12}>
                    <TabPanel value={tab} index={0}>
                        <BrigadiComponent/>
                    </TabPanel>

                    <TabPanel value={tab} index={1}>*/}
                        <AvtoparkComponent/>
                  {/*  </TabPanel>
                </Grid>
*/}
            </Grid>

        </Dialog>
    )
}
export default ResourcesComponent;