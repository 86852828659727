import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import {
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField, ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {useEffect, useState} from "react";
import AutocompleteObjects from "../../../inputs/AutocompleteObjects";
import {
    addGroup, addGroupFromTasks, addWorkType, addWorkTypeFromTasks,
    fetchWorksDataStock,
    getActualUsers, getActualUsersPromise,
    getObjectsAll,
    getTasksByProject, getWorkingGroupsWithSetter, getWorkingTypes
} from "../../../utils/NetworkUtils";
import {useLocalStorage} from "../../../useLocalStorage";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {CommonFetch} from "../../../services/api-fetch";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircleIcon from '@mui/icons-material/Circle';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {AutocompleteProps} from "../../../inputs/AutocompleteProps";
import FullyManagedAssignExecutorToAnalysisWithoutQtyDialog
    from "./FullyManagedAssignExecutorToAnalysisWithoutQtyDialog";
import AutocompleteUsers from "../../../inputs/AutocompleteUsers";
import RepeatChooserDialog from "./RepeatChooserDialog";
import AutocompleteTasks from "../../../inputs/AutocompleteTasks";
import CreateTaskSupportPanel from "./objectReportsGraph/CreateTaskSupportPanel";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AddWorkDialog({open, handleClose, selectedDay, setForceRefresh, shouldShowAddWithProjectSelected}) {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    let navigate = useNavigate();

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [userId, setUserId] = useLocalStorage("userId", null);

    const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [displayName, setDisplayName] = useState("");

    const [title, setTitle] = useState("");

    const [executorTyped, setExecutorTyped] = useState(null);
    const [objectName, setObjectName] = useState(null);


    const [executorsList, setExecutorsList] = useState([]);

    const filter = createFilterOptions();
    const [taskState, setTaskState] = React.useState("2");

    const [usersWhichTrack, setUsersWhichTrack] = React.useState([]);
    const [userTracking, setUserTracking] = React.useState([]);


    const [taskDone, setTaskDone] = React.useState([]);
    const [taskInWork, setTaskInWork] = React.useState([]);
    const [waitTask, setWaitTask] = React.useState([]);

    const [objects, setObjects] = React.useState([]);
    const [selectedObject, setSelectedObject] = React.useState(null);


    const [autoCompleteWorkOpen, setAutoCompleteWorkOpen] = React.useState(false);

    const [addExectuorsOpen, setAddExectuorsOpen] = useState( null);
    const [partners, setPartners] = useState( []);



    const [workName, setWorkName] = useState( null);
    const [isPriority, setPriority] = useState( null);
    const [workEntity, setWorkEntity] = useState( null);
    const [workQuantity, setWorkQuantity] = useState( null);

    const [loadingWork, setLoadingWork] = React.useState(false);

    const [worksDataStock, setWorksDataStock] = React.useState([]);
    const [displayNameWork, setDisplayNameWork] = useState("");

    const [items, setItems] = useState([]);


    const [taskDate, setTaskDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [duplicateOn, setDuplicateOn] = useState(null);
    const [repeat, setRepeat] = useState(null);
    const [hour, setHour] = useState(null);
    const [hourEnd, setHourEnd] = useState(null);
    const [about, setAbout] = useState();
    const [taskId, setTaskId] = useState();
    const [tasksOptions, setTasksOptions] = useState([]);



    const [executorOpen, setExecutorOpen] = useState(false);
    const [executors, setExecutors] = useState([]);
    const [executorId, setExecutorId] = useState(null);
    const [executor, setExecutor] = useState(null);
    const [tempExecutor, setTempExecutor] = useState(null);
    const [tempExecutorId, setTempExecutorId] = useState(null);


    const [department, setDepartment] = React.useState(null);
    const [subObject, setSubObject] = React.useState(null);
    const [departmentShort, setDepartmentShort] = React.useState(null);
    const [stage, setStage] = React.useState(null);
    const [group, setGroup] = React.useState(null);
    const [workType, setWorkType] = React.useState(null);



    // Datasets
    const [workingGroups, setWorkingGroups] = React.useState([]);
    const [workingTypes, setWorkingTypes] = React.useState([]);


    // Repetition
    const [repeatOnOpen, setRepeatOnOpen] = React.useState(null);
    const [period, setPeriod] = React.useState(1);
    const [periodMul, setPeriodMult] = React.useState(1);
    const [repeatOn, setRepeatOn] = React.useState(null);
    const [endingOption, setEndingOption] = React.useState("0");
    const [endingDate, setEndingDate] = React.useState(null);
    const [endingNum, setEndingNum] = React.useState(null);


    const [isTextFieldFocused, setIsTextFieldFocused] = useState(null);

    useEffect(() => {
        // Paste code to be executed on subsequent renders:
        getWorkingGroupsWithSetter(objectName, navigate, accessToken, setAccessToken, setWorkingGroups)
    }, [objectName]);

    useEffect(() => {
        // Paste code to be executed on subsequent renders:
        if (objectName) {
            getWorkingTypes(objectName, navigate, accessToken, setAccessToken, group ? group : stage ? stage : "0", setWorkingTypes)
        }
    }, [stage, group]);


    useEffect(() => {
        getActualUsersPromise(navigate, accessToken, setAccessToken, setUsersWhichTrack, setUserTracking)
        setTaskDate(selectedDay)
    }, [selectedDay])

    const handleWorkSelect = (workName, repeat) => {
        console.log('workName ', workName)
        //if (workName) {
//            if (!workName.id) {
                // call the tasks api
                createATaskNoKss(workName ? workName.name_work : "", repeat)
  //          }
        //}
    }

    function createATaskNoKss(task) {
        let userIds = userTracking ? userTracking.map((obj) => { return { "user_id": obj.id } } ) : [];

        let status = "0";
        const today = moment(new Date()).startOf('day');

        let typePeriod = 0
        switch (repeat) {
            case 0:
                typePeriod = 0;
                break;
            case 1:
            case 2:
                typePeriod = 0;
                break;

        }

        console.log('stage ', stage)
        console.log('group ', group)
        console.log('workType ', workType)
        console.log('hour ? hour.format("HH:mm") : null ', hour ? hour.format("HH:mm") : null)

        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/add`, {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: accessToken,
                id: "0",
                id_user: userId,
                id_project: objectName ? objectName.id : null,
                id_etap: stage && stage.id ? stage.id : null,
                id_aworkgroup: group && group.id ? group.id : "0",
                id_work: workType && workType.id ? workType.id : "0",
                id_partner: executor ? executor.id : null,
                partners: partners,
                subject: about ? (about.subject ? about.subject : about) : null,
                status: status,
                repeat_period: null,//Number(period) * Number(periodMul),
                type_period: typePeriod,
                "dayofevent":repeatOn,
                "end_type":endingOption,
                "repeat_end_date": (endingOption === '0') ? null :
                                    (endingOption === '1' ?
                                        (endingDate ? endingDate.format('YYYY-MM-DD') : null) :
                                            ((endingOption === '2') ? null : null)),
                repeat_end_occurences: endingNum,
                date_start: taskDate ? taskDate.format("YYYY-MM-DD") : null,
                "date_end": endDate ? endDate.format('YYYY-MM-DD') : null,
                "duplicate_on": duplicateOn ? duplicateOn.format('YYYY-MM-DD') : null,

                hour_start: hour ? hour.format("HH:mm") : null,
                hour_end: hourEnd ? hourEnd.format("HH:mm") : null,
                id_creater: userId,
                users: userIds
            })
        })
            .then(
                (result) => {
                    console.log('taskDate херееее 1 ' , taskDate)

                    if (result && result.message === 'Task exists' && result.items && result.items[0]) {
                        let duplicateId = result.items[0].id;

                        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/setStatus`, {
                            method: "POST",
                            mode: 'cors',
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                id_task: duplicateId ,
                                "date": taskDate ? taskDate.format('YYYY-MM-DD') : null,
                                "status": taskState,
                                "type": 'task',
                                "hour_start": hour ? hour.format('HH:mm') : null,
                                "hour_end": hourEnd ? hourEnd.format("HH:mm") : null,
                                "partners": partners,
                                token: accessToken
                            })
                        }).then(() => {
                            setForceRefresh(Math.random())
                            handleClose()
                        });


                    } else {
                        console.log('taskDate херееее 2' , taskDate)

                        setForceRefresh(Math.random)
                        handleClose()
                        console.log('taskDate ' , taskDate)
                        console.log('endDate ' , endDate)
                        console.log('result.id ' , result.id)
                        if (result && result.id) {
                            let taskId = result.id;
                            if (taskDate && endDate) {
                                //setStatusIteration(taskId, taskDate.toDate(), endDate.toDate())
                            }
                        }
                    }

                },
                (error) => {
                    console.log('error: ', error)
                }
            );
    }

    var getDaysArray = function(s,e) {for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};

    function setStatusIteration(taskId, taskDate, dateEnd) {
        let dates = getDaysArray(taskDate, dateEnd);
        for (const date of dates) {
            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/setStatus`, {
                method: "POST",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id_task: taskId ,
                    "date": date ? moment(date).format('YYYY-MM-DD') : null,
                    "status": taskState,
                    "type": 'task',
                    "hour_start": hour ? hour.format('HH:mm') : null,
                    "hour_end": hourEnd ? hourEnd.format("HH:mm") : null,
                    "partners": partners,
                    token: accessToken
                })
            }).then(() => {
                setForceRefresh(Math.random())
                handleClose()
            });
        }
    }

    useEffect(() => {
        if (shouldShowAddWithProjectSelected) {
            setObjectName(shouldShowAddWithProjectSelected)
            setDisplayName(shouldShowAddWithProjectSelected.name_project)
        } else {
            setObjectName(null)
        }
    }, [shouldShowAddWithProjectSelected])

    useEffect(() => {
        getObjectsAll(navigate, accessToken, setAccessToken, setObjects, setSelectedObject)
    }, [])


    useEffect(() => {
        if (objectName) {
            console.log('objectName ', objectName)
            fetchWorksDataStock({id: objectName.id}, navigate, accessToken, setAccessToken, setWorksDataStock, displayNameWork)

            if (objectName && objectName.id) {
                getTasksByProject({id: objectName.id}, navigate, accessToken, setAccessToken, userId).then((result) => {
                    if (result && result.items) {
                        let taskDone = result.items.taskdone;
                        let taskInwork = result.items.taskinwork;
                        let waitTasks = result.items.waittask;

                        for (let i = 0; i < taskDone.length; i++) {
                            taskDone[i].name_work = taskDone[i].subject;
                        }
                        for (let i = 0; i < taskInwork.length; i++) {
                            taskInwork[i].name_work = taskInwork[i].subject;
                        }
                        for (let i = 0; i < waitTasks.length; i++) {
                            waitTasks[i].name_work = waitTasks[i].subject;
                        }


                        setTaskDone(taskDone)
                        setTaskInWork(taskInwork)
                        setWaitTask(waitTasks)
                    }
                })
            }

        }
    }, [objectName])

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/firms?`+ new URLSearchParams({
            token: accessToken,
            ...(executor ? { search: executorTyped } : {}),

        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setExecutors([...results])
                }
            })
    }, [executorTyped])


    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            maxWidth={objectName ? 'lg' : 'md'}
            TransitionComponent={Transition}
        >

            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Добави задача
                    </Typography>
                </Toolbar>
            </AppBar>


            <Grid container spacing={1}>


                <Grid item xs={objectName ? 7 : 12}>
                    <Grid container style={{margin: 10}} spacing={1}>

                <Grid item xs={8}>
                    <ToggleButtonGroup
                        color="primary"
                        value={taskState}
                        size={'small'}
                        exclusive
                        onChange={(event, newState) => {
                            setTaskState(newState);
                        }}
                        aria-label="task_state"
                    > <ToggleButton value="2"><CircleIcon style={{color: '#88c7fa'}}/>За работа</ToggleButton>
                        <ToggleButton value="0"><CircleIcon style={{color: '#f2c97f'}}/>В работа</ToggleButton>
                        <ToggleButton value="1"><CircleIcon style={{color: '#00e533'}}/>Приключена</ToggleButton>
                        <ToggleButton value="3"><CircleIcon style={{color: 'red'}}/>Важно</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

                <Grid item xs={5}>
                    {shouldShowAddWithProjectSelected ?
                        <TextField fullWidth label={'Обект'} value={shouldShowAddWithProjectSelected ? shouldShowAddWithProjectSelected.name_project : null}/>
                        :
                    <AutocompleteObjects
                        autoCompleteOpen={autoCompleteOpen} setAutoCompleteOpen={setAutoCompleteOpen}
                        stocksDataSet={objects} loading={loading} editId={null}
                        setObjectName={setObjectName} objectName={objectName} setLoading={setLoading}
                        displayName={displayName} setDisplayName={setDisplayName} makeAdding/>}
                </Grid>

                <Grid item xs={5}>
                    <AutocompleteProps
                        autocompleteProperty={subObject}
                        dataset={[]}
                        setAutocompleteProperty={setSubObject}
                        restApiAddPropsFunction={(name) => {

                        }}
                        title={"Подобект"}
                        propName={"name_object"}
                        additional={{
                            disabled: !objectName
                        }}/>
                </Grid>


                <Grid item xs={5} >
                    <AutocompleteProps
                        autocompleteProperty={stage}
                        dataset={workingGroups}
                        setAutocompleteProperty={setStage}
                        restApiAddPropsFunction={(name) => addGroupFromTasks(objectName, navigate,
                            accessToken, setAccessToken, setStage, setGroup,
                            name)}
                        title={"Етап"}
                        propName={"name_group"}
                        additional={{
                            disabled: !objectName
                        }}
                    />
                </Grid>

                <Grid item xs={5} >
                    <AutocompleteProps
                        autocompleteProperty={group}
                        dataset={stage && stage.subgroups ? stage.subgroups : []}
                        setAutocompleteProperty={setGroup}
                        restApiAddPropsFunction={(name) => addGroupFromTasks(objectName, navigate,
                            accessToken, setAccessToken, setStage, setGroup,
                            name, stage.id)}
                        title={"Група"}
                        propName={"name_group"}
                        additional={{
                            disabled: !stage
                        }}
                    />
                </Grid>



                <Grid item xs={5}>
                    <AutocompleteProps
                        autocompleteProperty={workType}
                        dataset={workingTypes}
                        setAutocompleteProperty={setWorkType}
                        restApiAddPropsFunction={(name) => addWorkTypeFromTasks(navigate, accessToken,
                            setAccessToken, setWorkType, group ? group : stage, name)}
                        title={"Вид работа"}
                        propName={"name_work"}
                        taskState={!about ? taskState : null}
                        additional={{
                            disabled: !stage
                        }}
                    />
                </Grid>


                <Grid item xs={5}>
                   <AutocompleteTasks
                        stageId={stage ? stage.id : null}
                        groupId={group ? group.id : null}
                        workId={workType ? workType.id : null}
                        taskState={about ? taskState : null}
                        isTextFieldFocused={isTextFieldFocused} setIsTextFieldFocused={setIsTextFieldFocused}
                        tasksOptions={tasksOptions} setTasksOptions={setTasksOptions}
                        task={about} setTask={setAbout} setTaskId={setTaskId}/>

                    {/*<TextField
                        required
                        id="description"
                        label="Задача"
                        fullWidth
                        multiline
                        InputLabelProps={{ shrink: about !== null && about !== "" }}
                        onChange={(event) => {
                            setAbout(event.target.value)
                        }}
                        InputProps={{
                            endAdornment: (
                                about && taskState && Number(taskState) >= 0 ? <CircleStatusIcons style={{marginRight: -10}} size={20} taskState={taskState}/> : <React.Fragment>
                                </React.Fragment>
                            ),
                        }}
                        value={about}
                        variant="outlined"
                    />*/}
                </Grid>

               {/* <Grid item xs={8}>
                    <AutocompleteWorks taskState={taskState} autoCompleteOpen={autoCompleteWorkOpen} setAutoCompleteOpen={(bool) => {
                        setAutoCompleteWorkOpen(bool)

                    }}
                                       onSelect={(name) => {setWorkName(name)}}
                                       stocksDataSet={[...taskDone, ...taskInWork,...waitTask, ...worksDataStock]}
                                       loading={false} editId={null}
                                       setWorkName={setWorkName} workName={workName} setLoading={setLoadingWork}
                                       displayName={displayNameWork} setDisplayName={setDisplayNameWork} makeAdding/>
                </Grid>*/}
                {/*<Grid item xs={1}>
                    {
                        isPriority ? <CircleIcon style={{color: 'red'}}/> : null
                    }
                </Grid>*/}




                <Grid item xs={5}>

                    {
                        partners.length === 0 ?
                            <AutocompleteUsers
                                idObject={objectName ? objectName.id : null}
                                executor={tempExecutor} setExecutor={setTempExecutor} setExecutorId={setTempExecutorId} onSelect={(prop) => {
                                    console.log('проппп ', prop)
                                    setPartners([...partners, {
                                        id_partner: prop.id,
                                        name_firm: prop.name_firm
                                    }])
                                }
                            }/>
                            :
                            <TextField
                                value={partners ? (partners.length > 1 ? (partners.length + ' работници') : (partners.length === 1 ? partners[0].name_firm : '')) : null}
                                fullWidth onClick={()=> setAddExectuorsOpen(true)} label={'Изпълнител'} contentEditable={false}/>

                    }

                       </Grid>

                <Grid item xs={5} >
                    <Autocomplete
                        id="trackingUser"
                        label="Видим за"
                        fullWidth
                        multiple

                        onChange={(event, newValue) => {
                            console.log(newValue)
                            setUserTracking(newValue);
                        }}

                        value={userTracking}

                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option.full_name} {...getTagProps({ index })} />
                            ))
                        }
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.full_name}

                        isOptionEqualToValue={(option, value) => {
                            return option.id === value.id
                        }}

                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.full_name}
                            </li>
                        )}

                        options={usersWhichTrack.map((option) => option)}
                        variant="outlined"

                        renderInput={(params) => <TextField {...params} label="Видим за"
                                                            variant="outlined"/>}
                    />
                </Grid>

                <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                            <DatePicker
                                label={'От'}
                                value={taskDate}
                                slotProps={{ textField: { fullWidth: true } }}
                                onChange={(newValue) => setTaskDate(newValue)}
                                views={['year', 'month', 'day']}
                            />
                        </LocalizationProvider>
                </Grid>
                <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                            <DatePicker
                                label={'До'}
                                value={endDate}
                                slotProps={{ textField: { fullWidth: true } }}
                                onChange={(newValue) => setEndDate(newValue)}
                                views={['year', 'month', 'day']}
                            />
                        </LocalizationProvider>
                </Grid>

                {/*<Grid item xs={3.33}>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                            <DatePicker
                                label={'Дублирай на'}
                                value={duplicateOn}
                                slotProps={{ textField: { fullWidth: true } }}
                                onChange={(newValue) => setDuplicateOn(newValue)}
                                views={['year', 'month', 'day']}
                            />
                        </LocalizationProvider>
                </Grid>*/}



                <Grid item xs={3.33}>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                            <TimePicker
                                id="hour"
                                label="Час от"
                                type="time"
                                ampm={false}
                                value={hour}
                                InputLabelProps={{ shrink: true }}
                                onChange={(newValue) => {
                                    setHour(newValue)
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>


                <Grid item xs={3.33}>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                            <TimePicker
                                id="hour"
                                label="Час до"
                                type="time"
                                ampm={false}
                                value={hourEnd}
                                InputLabelProps={{ shrink: true }}
                                onChange={(newValue) => {
                                    setHourEnd(newValue)
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>


                <Grid item xs={3.33}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Повторяемост</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={repeat}
                            label="Повторяемост"
                            onChange={(event) => {
                                if (event.target.value === 2 || event.target.value === 3) {
                                    setRepeatOnOpen(event.target.value)
                                }
                                setRepeat(event.target.value);
                            }}
                        >

                            <MenuItem value={0}>Няма</MenuItem>
                            <MenuItem value={1}>Всеки ден</MenuItem>
                            <MenuItem value={2}>Всяка седмица</MenuItem>
                            <MenuItem value={3}>Всеки месец</MenuItem>
                            <MenuItem value={4}>Всяка година</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={8}>
                    <Button variant={'contained'} onClick={() => {
                        handleWorkSelect(workName, repeat)
                    }}>Запази</Button>
                </Grid>

            </Grid>
                </Grid>


                {
                    objectName ?
                        <Grid item xs={5}>
                            <CreateTaskSupportPanel selectedObject={objectName ? objectName.id : null}
                                                    stage={stage} setStage={setStage}
                                                    group={group} setGroup={setGroup}
                                                    workType={workType} setWorkType={setWorkType}
                                                    setTasksOptions={setTasksOptions}
                                                    isTextFieldFocused={isTextFieldFocused}
                                                    shouldShowTasks={false}/>
                        </Grid> : null

                }


            </Grid>


            <RepeatChooserDialog
                open={repeatOnOpen}
                handleClose={() => setRepeatOnOpen(null)}
                period={period} setPeriod={setPeriod}
                periodMul={periodMul} setPeriodMult={setPeriodMult}
                repeatOn={repeatOn} setRepeatOn={setRepeatOn}
                endingOption={endingOption} setEndingOption={setEndingOption}
                endingDate={endingDate} setEndingDate={setEndingDate}
                endingNum={endingNum} setEndingNum={setEndingNum}/>

            <FullyManagedAssignExecutorToAnalysisWithoutQtyDialog
                open={addExectuorsOpen} handleClose={() => setAddExectuorsOpen(false)} onClick={() => {
                    console.log('partnersssss ', partners)
                    setAddExectuorsOpen(false)
                }}
                partners={partners} setPartners={setPartners}/>
        </Dialog>
    )
}
export default AddWorkDialog;