import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {CommonFetch} from "../../../services/api-fetch";
import {useLocalStorage} from "../../../useLocalStorage";
import {useNavigate} from "react-router-dom";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AssignExecutorToAnalysisForDailyTasksDialog({open, handleClose, onClick}) {
    let navigate = useNavigate();

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [executorOpen, setExecutorOpen] = useState(false);
    const [executors, setExecutors] = useState([]);

    const [executorId, setExecutorId] = useState(null);
    const [executor, setExecutor] = useState(null);

    const [executorTyped, setExecutorTyped] = useState(null);
    const [qty, setQty] = useState(null);

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/firms?`+ new URLSearchParams({
            token: accessToken,
            ...(executor ? { search: executorTyped } : {}),

        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setExecutors([...results])
                }
            })
    }, [open, executorTyped])


    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography>Назначи изпълнител</Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={1} style={{margin: 10}}>
                <Grid item xs={8}>

                    <Autocomplete
                        id="contragents"
                        freeSolo
                        open={executorOpen}
                        onOpen={() => {
                            setExecutorOpen(true);
                        }}
                        onClose={() => {
                            setExecutorOpen(false);
                        }}
                        value={executor ? executor && executor.name_firm ? executor : {name_firm: executor} : null}
                        filterOptions={(options, params) => {
                            let newOptions = options.filter((option) => {
                                if (!option || !option.name_firm || !executor || !executorTyped) {
                                    return false;
                                }
                                return option.name_firm.toLowerCase().includes(executorTyped ? executorTyped.toLowerCase() : "")
                            })

                            const { inputValue } = params;

                            // Suggest the creation of a new value
                            const isExisting = newOptions.length > 0;
                            if (inputValue !== '' && !isExisting) {
                                newOptions.push({
                                    inputValue,
                                    name_firm: `Добави "${inputValue}"`,
                                });
                            }

                            return newOptions;
                        }}
                        onChange={(event, prop) => {
                            console.log('prop ', prop)
                            if (prop) {
                                console.log(prop)
                                console.log(prop.id)

                                if (!prop.id) {
                                    const personName = prop.name_firm.replace("Добави \"", "").replace("\"", "")


                                    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/add`, {
                                        method: "POST",
                                        mode: 'cors',
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                            token: accessToken,
                                            "name_firm":personName,
                                            "phone":"",
                                            "contact_person":personName,
                                            "id_category":"2",
                                        })
                                    })
                                        .then((result) => {
                                            if (result && result.id) {
                                                setExecutorId(result.id)
                                                setExecutor(personName)
                                            }
                                        })



                                } else {
                                    setExecutorId(prop && prop.id ? prop.id : prop)
                                    setExecutor(prop && prop.name ? prop.name : prop)
                                }


                            } else {
                                setExecutor("")
                            }
                        }}
                        onInput={(event) => {
                            setExecutorTyped(event.target.value)
                        }}
                        getOptionLabel={(option) => option.name_firm}
                        options={executors}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Изпълнител"
                                value={executorTyped}
                                onChange={(e) => {
                                    setExecutor(e.target.value)
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>


                <Grid item xs={6}>
                    <Button onClick={() => {
                        onClick(executor)
                        setExecutor(null)
                    }}>Запази</Button>
                </Grid>
            </Grid>


        </Dialog>
    )
}

export default AssignExecutorToAnalysisForDailyTasksDialog;