import Grid from "@mui/material/Grid";
import CircleIcon from "@mui/icons-material/Circle";
import * as React from "react";

export default function CircleStatusIcons({taskState}) {
    return (
        taskState && Number(taskState) >= 0 ? <Grid item>
            <CircleIcon style={{color: taskState === "1" ? '#00e533' : taskState === "2" ? '88c7fa' : taskState === "0" ? '#f2c97f' : 'red'}}/>
        </Grid> : null
    )
}