import {CommonFetch} from "../services/api-fetch";
import TextField from "@mui/material/TextField";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {useEffect, useState} from "react";
import {useLocalStorage} from "../useLocalStorage";
import {useNavigate} from "react-router-dom";

function AutocompleteUsers({idObject, executor, setExecutor, setExecutorId, onSelect, additional}) {

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    let navigate = useNavigate();

    const [executorOpen, setExecutorOpen] = useState(false);
    const [executors, setExecutors] = useState([]);
    const [executorTyped, setExecutorTyped] = useState(null);

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners?`+ new URLSearchParams({
            token: accessToken,
            ... idObject ? {id_project: idObject} : {},
            ...(executor ? { search: executorTyped } : {}),

        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                   /* results = results.sort((a, b) => {
                        if (a.partner_type === 1 && b.partner_type !== 1) {
                            return -1;
                        }

                        if (a.partner_type !== 1 && b.partner_type === 1) {
                            return 1;
                        }

                        // For other cases, maintain the current order
                        return 0;
                    })*/

                    setExecutors([...results])
                }
            })
    }, [executorTyped])

    return (
        <Autocomplete
            id="contragents"
            freeSolo
            open={executorOpen}
            onOpen={() => {
                setExecutorOpen(true);
            }}
            onClose={() => {
                setExecutorOpen(false);
            }}
            value={executor ? executor && executor.name_firm ? executor : {name_firm: executor} : null}
            filterOptions={(options, params) => {
                let newOptions = options.filter((option) => {
                    if (!option || !option.name_firm || !executor || !executorTyped) {
                        return false;
                    }
                    return option.name_firm.toLowerCase().includes(executorTyped ? executorTyped.toLowerCase() : "")
                })

                const { inputValue } = params;

                // Suggest the creation of a new value
                const isExisting = newOptions.length > 0;
                if (inputValue !== '' && !isExisting) {
                    newOptions.push({
                        inputValue,
                        name_firm: `Добави "${inputValue}"`,
                    });
                }

                return newOptions;
            }}
            onChange={(event, prop) => {
                console.log('prop ', prop)
                if (prop) {
                    console.log(prop)
                    console.log(prop.id)

                    if (!prop.id) {
                        const personName = prop.name_firm.replace("Добави \"", "").replace("\"", "")


                        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/add`, {
                            method: "POST",
                            mode: 'cors',
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                token: accessToken,
                                "name_firm":personName,
                                "phone":"",
                                "contact_person":personName,
                                "id_category":"2",
                            })
                        })
                            .then((result) => {
                                if (result && result.id) {
                                    setExecutorId(result.id)
                                    setExecutor(personName)

                                    if (onSelect) {
                                        onSelect({
                                            id: result.id,
                                            name_firm: personName
                                        })
                                    }
                                }
                            })



                    } else {
                        if (onSelect) {
                            onSelect(prop)
                        }
                        setExecutorId(prop && prop.id ? prop.id : prop)
                        setExecutor(prop && prop.name ? prop.name : prop)
                    }


                } else {
                    setExecutor("")
                }
            }}
            onInput={(event) => {
                setExecutorTyped(event.target.value)
            }}
            getOptionLabel={(option) => option.name_firm}
            options={executors}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Изпълнител"
                    value={executorTyped}
                    onChange={(e) => {
                        setExecutor(e.target.value)
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            {...additional}
        />
    )
}
export default AutocompleteUsers;