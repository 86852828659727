import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import TextField from "@mui/material/TextField";
import {AutocompleteProps} from "../../../inputs/AutocompleteProps";
import {useEffect, useState} from "react";
import {getGoods, getPartners} from "../../../utils/NetworkUtils";
import AutocompleteStock from "../../../inputs/AutocompleteStock";
import {CommonFetch} from "../../../services/api-fetch";
import Box from "@mui/material/Box";
import {Tab, Tabs} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import PartnerPickEditDialog from "./PartnerPickEditDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AddEditWorkHistoryForExecutorDialog({selectedObject, edit, open, handleClose,
                               code, setCode,
                               name, setName,
                               entity, setEntity,
                               costRate, setCostRate,
                               totalPrice, setTotalPrice,
                               fira, setFira,
                               executor, setExecutor,
                               entityPrice, setEntityPrice,
                               workQuantity, forceRefresh, setForceRefresh,
                                selectedDate,
                               onClick
                           }) {

    const columns = [
        { field: 'name', headerName: 'Име', flex: 1},
        { field: 'name_detail', headerName: 'Дейност', flex: 1},
        { field: 'date', headerName: 'Дата', flex: 1,
            renderCell: (params) => (
                <strong>
                    <Typography size={'small'} label={''} InputLabelProps={{shrink: true}}>
                        {moment(params.value).format('DD.MM.YYYY')}
                    </Typography>

                </strong>
            )},
        { field: 'executed', headerName: 'Изпълнено', type: 'number', flex: 1 },
    ];

    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [goods, setGoods] = useState([]);

    const [executorOpen, setExecutorOpen] = useState(false);
    const [executors, setExecutors] = useState([]);
    const [addExecutorOpen, setAddExecutorOpen] = useState(false);

    const [executorId, setExecutorId] = useState(null);
    const [executedAmount, setExecutedAmount] = useState(null);
    const [executorSelected, setExecutorSelected] = useState(null);
    const [dateToTransfer, setDateToTransfer] = useState(null);
    const [qtyToTransfer, setQtyToTransfer] = useState(null);


    const [tab, setTab] = useState(0);

    const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [partners, setPartners] = React.useState([]);

    const [displayName, setDisplayName] = useState("");

    useEffect(() => {
        if (!open) {
            return;
        }
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/getPartnerInfo?`+ new URLSearchParams({
            token: accessToken,
            "id_awork":open.id_awork,
            "id_partner":open.id_partner,
            //...(selectedDate ? { 'date': selectedDate.format('YYYY-MM-DD') } : {})
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                    if (result && result.items){
                        let data = result.items;

                        for (const datum of data) {
                            datum.name = datum.partner_name;
                            datum.executed = datum.qty;
                        }
                        setPartners(data)
                    }
                },
                (error) => {

                }
            );

    }, [open, forceRefresh])

    useEffect(() => {
        if (open === 3) {
            let active = true;

            if (!loading) {
                return undefined;
            }

            (async () => {
                CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/goods/getGoods?`+ new URLSearchParams({
                    "token": accessToken,
                    'search': name
                }), {
                    method: "GET",
                    mode: 'cors',
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then((result) => {
                        if (result && result.items) {
                            setLoading(false)
                            if (active) {
                                console.log('setting options  to ', result.items)
                                setGoods([...result.items]);
                            }
                        }
                    })


            })();

            return () => {
                active = false;
            };

        }
    }, [name]);

    useEffect(() => {
        console.log('workQuantity ', workQuantity)
        console.log('totalPrice ', totalPrice)
        setCostRate(workQuantity > 0 ? (totalPrice / workQuantity) /** entityPrice*/  : costRate)
    }, [totalPrice])

    useEffect(() => {
        if (!name) {
            setCode("")
            setEntity("")
            setEntityPrice("")
            setCostRate("")
        }
    }, [name])

    useEffect(() => {
        if (open === 3) {
            getGoods(navigate, accessToken, setAccessToken, name)
                .then((result) => {
                    if (result && result.items) {
                        setGoods(result.items)
                    }
                })
        }
    }, [name])


   useEffect(() => {
        if (open === 1 || edit) {
            getPartners(navigate, accessToken, setAccessToken, "SERVICE")
                .then((result) => {
                    if (result && result.items) {
                        setExecutors(result.items)
                    }
                })
        }
    }, [open])

    let title;
    switch (open) {
        case 1:
            title = 'Труд';
            break;
        case 2:
            title = 'Механизация';
            break;
        case 3:
            title = 'Материали';
            break;
        case 4:
            title = 'Услуги';
            break;
    }

    return  (
        <Dialog
            open={open}
            fullWidth
            maxWidth={'lg'}
            onClose={handleClose}
            TransitionComponent={Transition}
        >

            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        История на изпълнение за изпълнител
                    </Typography>

                </Toolbar>
            </AppBar>

            <Grid container padding={3} spacing={1}>

                <Grid item xs={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={(event, newValue) => {
                            setTab(newValue);
                        }} aria-label="basic tabs example">
                            <Tab label="Труд" />
                            <Tab label="Материали" />
                            <Tab label="Услуги" />
                            <Tab label="Механизация" />
                        </Tabs>
                    </Box>
                </Grid>

                {
                    open !== 3 ?
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        margin="dense"
                                        id="client"
                                        value={name}
                                        label="Име"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Grid container spacing={1}>

                                <Grid item xs={12}>
                                    <AutocompleteStock
                                        autoCompleteOpen={autoCompleteOpen} setAutoCompleteOpen={setAutoCompleteOpen}
                                        stocksDataSet={goods} loading={loading}
                                        editId={null} setStockName={setName} setStockChiffer={setCode} setStockEntity={setEntity}
                                        setStockEntityPrice={setEntityPrice} setQtyAvail={() => {}}
                                        stockName={name} setLoading={setLoading}
                                        displayName={displayName} setDisplayName={setDisplayName}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        size={'small'}
                                        fullWidth
                                        id="client"
                                        value={code}
                                        onChange={(event) =>  {
                                            setCode(event.target.value)
                                        }}
                                        InputLabelProps={{ shrink: code }}
                                        label="Код"
                                    />
                                </Grid>


                            </Grid>
                        </Grid>
                }

                <Grid item xs={4} style={{marginTop: 8}}>
                    <AutocompleteProps
                        autocompleteProperty={entity}
                        dataset={[{name: "КВ.М"}, {name: "КГ"}, {name: "КУБ.М"}, {name: "ТОН"}, {name: "БР."}, {name: "М."}]}
                        setAutocompleteProperty={setEntity}
                        restApiAddPropsFunction={(name) => {
                        }}
                        title={"Мярка"}
                        propName={"name"}
                        additional={{
                            'size': 'small'
                        }}
                    />
                </Grid>


                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size={'small'}
                        margin="dense"
                        id="client"
                        value={totalPrice}
                        onChange={(event) =>  {
                            setTotalPrice(event.target.value)
                        }}
                        label="К-во по договор"
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        size={'small'}
                        value={partners ? partners.reduce((partialSum, a) => partialSum + a.executed, 0) : 0}
                        onChange={(event) =>  {
                            setExecutedAmount(event.target.value)
                        }}
                        InputLabelProps={{ shrink: entityPrice }}
                        label="Изпълнено"
                    />
                </Grid>


                <Grid item xs={12}>
                    <DataGrid showCellVerticalBorder showColumnVerticalBorder
                              hideFooter rows={partners} columns={columns}
                              onCellEditStop={(params, event) => {
                                  console.log(partners)
                              }}
                              onRowClick={(params, event, details) => {
                                  console.log(params.row)
                                  setAddExecutorOpen(params.row.id)
                                  setExecutorSelected(params.row.partner_name)
                                  setDateToTransfer(params.row.date)
                                  setQtyToTransfer(params.row.qty)
                              }}
                              onProcessRowUpdateError={() => {

                              }}/>
                </Grid>


                <PartnerPickEditDialog
                    editMode
                    totalPrice={totalPrice}
                    executor={executorSelected}
                    dateToTransfer={dateToTransfer}
                    qtyToTransfer={qtyToTransfer}
                    currentlyDone={0}
                    selectedObject={selectedObject}
                    open={addExecutorOpen}
                    handleClose={() => setAddExecutorOpen(false)}
                    onClick={(detailId, date, qty) => {

                        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/updatePartnerQty`, {
                            method: "POST",
                            mode: 'cors',
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                "token":accessToken,
                                "id_aworkdoneqty": detailId,
                                "date": date,
                                "qty": qty,
                            })
                        })
                            .then((result) => {
                                    setForceRefresh(Math.random())
                                    handleClose()
                                },
                                (error) => {
                                    console.log('error: ', error)
                                }
                            );
                    }}
                    onDelete={(detailId) => {
                        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/deletePartnerQty`, {
                            method: "POST",
                            mode: 'cors',
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                "token":accessToken,
                                "id_aworkdoneqty": detailId,
                            })
                        })
                            .then((result) => {
                                    setForceRefresh(Math.random())
                                    handleClose()
                                },
                                (error) => {
                                    console.log('error: ', error)
                                }
                            );
                    }}/>


            </Grid>
        </Dialog>
    )
}
export default AddEditWorkHistoryForExecutorDialog;
