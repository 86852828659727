import * as React from 'react';
import {useEffect, useState} from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {useNavigate} from "react-router-dom";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WaterIcon from '@mui/icons-material/Water';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TaskIcon from '@mui/icons-material/Task';
import {Collapse, Tooltip} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import RightOptionsSideMenu from "./RightOptionsSideMenu";
import AssessmentIcon from '@mui/icons-material/Assessment';
import UserTopRightComponent from "./systemComponents/UserTopRightComponent";
import {useLocalStorage} from "./useLocalStorage";
import DateRangeIcon from '@mui/icons-material/DateRange';
import TodayIcon from '@mui/icons-material/Today';

function AppBarComponentV2(props) {
    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [userId, setUserId] = useLocalStorage("userId", null);
    const [userName, setUserName] = useLocalStorage("userName", null);

    const [openInvoice, setOpenInvoice] = React.useState(null);

    const [title, setTitle] = React.useState(null);
    const [contragentOpen, setContragentOpen] = React.useState(false);
    const [salesOpen, setSalesOpen] = React.useState(false);
    const [deliverersOpen, setDeliverersOpen] = React.useState(false);


    const [subSelected, setSubSelected] = React.useState(null);

    const [isSettingsOpen, setSettingsOpen] = React.useState(null);


    const drawerWidth = 250;

    const [accessTokenState, setAccessTokenState] = useState(accessToken);

    const steps = [
        {
            selector: '#firms-select-form',
            content: 'Избери фирма, с която да работиш',
        },
        {
            selector: '#objects-select',
            content: 'Избери обект, с който да работиш',
        },
        // ...
    ]

    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );
    const navigate = useNavigate();

    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [selectedFirm, setSelectedFirm] = React.useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = React.useState(null);
    const [selectedOffice,      setSelectedOffice] = React.useState(null);

    const [analysisDialogOpen, setAnalysisDialogOpen] = React.useState(null);

    const [help, setHelp] = React.useState(false);


    const subItemStyle = (subSelectedIndex) => {return { ml: 0, background: props.selected === subSelectedIndex ? '#94fdf0' : null,
        color: props.selected === subSelectedIndex ? 'white' : null}}
    const subItemIconStyle = (subSelectedIndex) => {
        return {color: props.selected === subSelectedIndex ? 'white' : null}
    }


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setTitle('Задачи')
    }, [])


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon style={{color: 'black'}} />
                    </IconButton>

                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>

             {/*       <div style={{position: 'absolute', right: 40, height: '100%'}}>
                        <IconButton
                            color="inherit" style={{marginTop: 10}}>
                            <Typography >СИНИ ЕООД</Typography>
                        </IconButton>
                        <IconButton
                            color="inherit"
                        style={{position: 'absolute', marginBottom: 30}}>
                            <Typography>Х</Typography>
                        </IconButton>
                    </div>
*/}

                    {accessTokenState && (
                        <UserTopRightComponent
                            selectedFirm={selectedFirm} setSelectedFirm={setSelectedFirm}
                            selectedWarehouse={selectedWarehouse} setSelectedWarehouse={setSelectedWarehouse}
                            selectedOffice={selectedOffice}      setSelectedOffice={setSelectedOffice}/>
                    )}

                    { accessToken &&
                        <Tooltip title={"СЕК"}>
                            <IconButton onClick={() => {
                                setAnalysisDialogOpen(true)
                            }}>
                                <ListItemText style={{color: 'black'}} primary={'СЕК'} />
                            </IconButton>
                        </Tooltip>
                    }

                    { accessToken &&
                        <Tooltip title={"Помощ"}>
                            <IconButton onClick={() => {
                                setHelp(true)
                            }}>
                                <ListItemText style={{color: 'black'}} primary={'Помощ'} />
                            </IconButton>
                        </Tooltip>
                    }

                    { accessToken &&
                        <Tooltip title={"Още"}>
                            <IconButton
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                onClick={() => {
                                    setSettingsOpen(!isSettingsOpen)
                                }}
                                sx={{mr: 1}}
                            >
                                <SettingsIcon style={{color: 'black'}}/>
                            </IconButton>
                        </Tooltip>
                    }

                </Toolbar>
            </AppBar>


            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <div style={{width: '100%'}}>
                        <IconButton onClick={handleDrawerClose}>
                            <MenuIcon />
                        </IconButton>
                    </div>

                </DrawerHeader>
                <Divider />
                <List>

                    <ListItemButton sx={subItemStyle(0)} onClick={() => {
                        setTitle('Задачи')
                        navigate('/home')
                    }}>
                        <ListItemIcon>
                            <WaterIcon sx={subItemIconStyle(0)}/>
                        </ListItemIcon>
                        <ListItemText primary="Задачи" sx={subItemIconStyle(0)}/>
                    </ListItemButton>


                    <ListItemButton sx={subItemStyle(1)} onClick={() => {
                        setTitle('Обекти - задачи')
                        navigate('/reports/objects')
                    }}>
                        <ListItemIcon>
                            <WarehouseIcon sx={subItemIconStyle(1)}/>
                        </ListItemIcon>
                        <ListItemText primary="Обекти - задачи" sx={subItemIconStyle(1)}/>
                    </ListItemButton>

                    <ListItemButton sx={subItemStyle(2)} onClick={() => {
                        setSubSelected(2)
                        setTitle('Седмични задачи')
                        navigate('/reports/week')
                    }}>
                        <ListItemIcon>
                            <DateRangeIcon sx={subItemIconStyle(2)}/>
                        </ListItemIcon>
                        <ListItemText primary="Седмични задачи" sx={subItemIconStyle(2)}/>
                    </ListItemButton>


                    <ListItemButton sx={subItemStyle(3)} onClick={() => {
                        setSubSelected(3)
                        setTitle('Дневни задачи')
                        navigate('/reports/day')
                    }}>
                        <ListItemIcon>
                            <TodayIcon sx={subItemIconStyle(3)}/>
                        </ListItemIcon>
                        <ListItemText primary="Дневни задачи" sx={subItemIconStyle(3)}/>
                    </ListItemButton>





                    <ListItemButton sx={subItemStyle(5)} onClick={() => {
                        setSubSelected(5)
                        setTitle('Изпълнители')
                        navigate('/reports/executors')
                    }}>
                        <ListItemIcon>
                            <ManageAccountsIcon sx={subItemIconStyle(5)}/>
                        </ListItemIcon>
                        <ListItemText primary="Изпълнители" sx={subItemIconStyle(5)}/>
                    </ListItemButton>





                    <ListItemButton sx={subItemStyle(6)} onClick={() => {
                        setSubSelected(6)
                        setTitle('Документация')
                        navigate('/docs')
                    }}>
                        <ListItemIcon>
                            <ManageAccountsIcon sx={subItemIconStyle(6)}/>
                        </ListItemIcon>
                        <ListItemText primary="Документация" sx={subItemIconStyle(6)}/>
                    </ListItemButton>



                </List>
                <Divider />

                <List>
                    {['Изход'].map((text, index) => (
                        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                onClick={() => {
                                    setUserId(null)
                                    setUserName(null)
                                    setAccessToken(null)
                                    navigate('/login')
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

            </Drawer>
            <div>
                <RightOptionsSideMenu
                    setTitle={setTitle}
                    setSubSelected={setSubSelected}
                    companyId={selectedFirm}
                    isOpen={isSettingsOpen}
                    setOpen={setSettingsOpen}/>
            </div>


          {/*  <Tour
                steps={steps}
                isOpen={help}
                onRequestClose={() => setHelp(false)} />
*/}
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {props.component}
            </Box>
        </Box>
    );
}

export default AppBarComponentV2;
