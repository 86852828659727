import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {CommonFetch} from "../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";
import {FormHelperText, styled} from "@mui/material";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker, LocalizationProvider, PickersDay} from "@mui/x-date-pickers";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MessageDialog from "../../components/helper/MessageDialog";
import EditCommentDialog from "./EditCommentDialog";
import TaskStateDialog from "./TaskStateDialog";
import CircleStatusIcons from "../../components/helper/CircleStatusIcons";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";

const HighlightedDay = styled(PickersDay)(({ theme }) => ({
    "&.Mui-selected": {
        backgroundColor: '#a6eec6',
        color: theme.palette.primary.contrastText,
    },
}));
//higlight the dates in highlightedDays arra
const ServerDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
        !props.outsideCurrentMonth &&
        highlightedDays.includes(day.format("YYYY-MM-DD"));

    return (
        <HighlightedDay
            {...other}
            outsideCurrentMonth={outsideCurrentMonth}
            day={day}
            selected={isSelected}
        />
    );
};

export default function AddDetailDocDialog({openId, handleClose, openCommentFilter, commentToName}) {

    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [dateToShowComments, setDateToShowComments] = useState(moment())


    const [text, setText] = useState(null)
    const [file, setFile] = useState(null)
    const [fileUri, setFileUri] = useState(null)

    const [forceRefresh, setForceRefresh] = useState([])
    const [comments, setComments] = useState([])

    const [editCommentOpen, setEditCommentOpen] = useState(null);

    const [deleteConfirmationForDayOpen, setDeleteConfirmationForDayOpen] = useState(false);
    const [deleteConfirmationForDayOpen2, setDeleteConfirmationForDayOpen2] = useState(false);

    const [taskStateDialog, setTaskStateDialog] = useState(false);

    const [highlightedDays, setHighlitedDays] = useState([
        "2023-08-09",
    ]);

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/mywave/getDates?`+ new URLSearchParams({
            token: accessToken,
            id: openId,
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    let datesToSet = [];
                    if (results) {
                        for (const item of results) {
                            datesToSet.push(item.date_in)
                        }
                    }
                    setHighlitedDays(datesToSet)
                }
            })
    }, [openId, forceRefresh])

    const addDetail = () => {
        let body = {
            id: openId,
            token: accessToken,
            comment: text,
        };
        let formdata = new FormData();

        for (let key in body) {
            if (body.hasOwnProperty(key)) {
                if (body[key]) {
                    formdata.append(key, body[key]);
                }
            }
        }


        if (file) {
            for (const attachemntItem of file) {
                formdata.append("image", attachemntItem, attachemntItem.name);
            }
        }


        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/mywave/add`, {
            method: "POST",
            mode: 'cors',
            headers: {
            },
            body: formdata
        })
            .then((result) => {
                setForceRefresh(Math.random())
                setFileUri(null)
                setText("")
                setFile([])
            })

    }

    useEffect(() => {
        if (!openId) {
            return
        }
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/mywave/get?` + new URLSearchParams({
            id: openId,
            ...(openCommentFilter === null ? {} : { status: openCommentFilter }),
            token: accessToken
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
            },

        }).then((result) => {
            if (result.items) {
                setComments(result.items)
            }
        })
    }, [openId, openCommentFilter, forceRefresh])

    return (
        <Dialog open={openId} onClose={handleClose} fullWidth maxWidth={'lg'}>

            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Добави коментар за {commentToName}
                    </Typography>
                </Toolbar>
            </AppBar>

            <form>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={5}>
                            {
                                console.log('openCommentFilter', openCommentFilter)
                            }
                            {openCommentFilter === null ? <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                                <DatePicker
                                    label={'Коментари за дата'}
                                    value={dateToShowComments}
                                    renderDay={(day, selected, dayComponent) => {
                                        console.log('day.format(\'YYYY-MM-DD\') ', day.format('YYYY-MM-DD'))
                                        if (day.format('YYYY-MM-DD') === '2023-08-09') {
                                            return React.cloneElemnt(dayComponent, { className: 'background: blue' })
                                        }

                                        return dayComponent
                                    }}
                                    onChange={(newValue) => setDateToShowComments(newValue)}
                                    views={['year', 'month', 'day']}
                                    slots={{
                                        day: ServerDay,
                                    }}
                                    slotProps={{
                                        day: {
                                            highlightedDays,
                                        },
                                    }}
                                />
                            </LocalizationProvider> : null }
                        </Grid>

                        { fileUri ?
                            <Grid item xs={file ? 3 : 0}>
                                <img style={{width: 650, maxHeight: 800}} src={fileUri}/>
                            </Grid>
                            : null
                        }

                        <Grid item xs={12}>
                            <TextField fullWidth label={'Коментар'} multiline value={text} onChange={e => {
                                setText(e.target.value)
                            }}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                component="label">
                                Прикачи файл
                                <input
                                    type="file"
                                    onChange={(event => {
                                        event.stopPropagation();

                                        console.log(event)
                                        if (event && event.target && event.target.files && event.target.files.length > 0) {
                                            let currentFiles = [];

                                            // Add the new files to the array
                                            let arrayFiles = Array.from(event.target.files);
                                            arrayFiles.forEach(item => {
                                                // if they don't exist yet
                                                if (!currentFiles.some(el => el.name === item.name)) {
                                                    currentFiles.push(item)
                                                }
                                            })

                                            // Set the new attachments

                                            for (const file of currentFiles) {
                                                file.id = Math.random();
                                            }

                                            setFile(currentFiles)


                                            setFileUri(URL.createObjectURL(event.target.files[0]))
                                        }
                                    })}
                                    hidden
                                />
                            </Button>

                            <Button onClick={handleClose}>Отказ</Button>

                            <Button onClick={() => {
                                addDetail()
                            }}>Добави</Button>
                        </Grid>


                        <Grid item xs={12}>
                            <Grid container spacing={1}>

                            {
                                comments
                                    .filter((comment) => openCommentFilter === null ? comment.created_at.includes(dateToShowComments.format('YYYY-MM-DD')) : true)
                                    .map((comment) => {
                                    return <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={9.5}>

                                                { comment.file ? <img style={{width: 400}} src={'data:image/jpg;base64,' + comment.file}/> : null }

                                                <div dangerouslySetInnerHTML={{ __html: comment.comment }} />

                                                <FormHelperText sx={{
                                                    pt: 0, pb: 0
                                                }}>{
                                                    (comment.user ? "Създадена от " + comment.user : "")
                                                    + (comment.created_at ? " на " + moment(comment.created_at, 'YYYY-MM-DD HH:mm').format("ddd DD MMM YYYY HH:mm") : "")
                                                }</FormHelperText>
                                            </Grid>
                                            <Grid item xs={0.5} onClick={() => {
                                                setTaskStateDialog({
                                                    id: comment.id,
                                                    date: comment.date_in
                                                })
                                            }}>
                                                <CircleStatusIcons taskState={comment.status + ""}/>
                                            </Grid>

                                            <Grid item xs={0.5}>
                                                <IconButton onClick={() => {
                                                    setEditCommentOpen({
                                                        id: comment.id,
                                                        comment: comment.comment
                                                    })
                                                }}>
                                                    <EditIcon/>
                                                </IconButton>
                                            </Grid>


                                            <Grid item xs={0.5}>

                                                <IconButton onClick={() => {
                                                    setDeleteConfirmationForDayOpen(
                                                        {
                                                            id: comment.id,
                                                            text: 'Сигурни ли сте, че искате да изтриете коментара?'
                                                        }
                                                    )
                                                }}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                })
                            }
                            </Grid>
                        </Grid>

                    </Grid>

                </DialogContent>
            </form>


            <EditCommentDialog open={editCommentOpen ? editCommentOpen.id : null} handleClose={() => setEditCommentOpen(null)} setForceRefresh={setForceRefresh} comment={editCommentOpen ? editCommentOpen.comment : null}/>


            <MessageDialog
                openAndText={deleteConfirmationForDayOpen ? deleteConfirmationForDayOpen.text : null}
                handleClose={() => setDeleteConfirmationForDayOpen(null)}
                negativeText={'Не'}
                negativeAction={() => {
                    setDeleteConfirmationForDayOpen(null)
                }}
                positiveText={'Да'}
                positiveAction={() => {
                    setDeleteConfirmationForDayOpen2(
                        {
                            id: deleteConfirmationForDayOpen.id,
                            text: "Този коментар ще бъде напълно изтрит! Сигурни ли сте?"
                        })
                    setDeleteConfirmationForDayOpen(false)

                }}/>


            <MessageDialog
                openAndText={deleteConfirmationForDayOpen2 ? deleteConfirmationForDayOpen2.text : null}
                dangerMode
                handleClose={() => setDeleteConfirmationForDayOpen2(null)}
                negativeText={'Не'}
                negativeAction={() => {
                    setDeleteConfirmationForDayOpen2(null)
                }}
                positiveText={'Да'}
                positiveAction={() => {
                    setDeleteConfirmationForDayOpen2(null)


                    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/mywave/delete?`+ new URLSearchParams({

                    }), {
                        method: "POST",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            token: accessToken,
                            id: deleteConfirmationForDayOpen2 ? deleteConfirmationForDayOpen2.id : null
                        })
                    })
                        .then(
                            (result) => {
                                setForceRefresh(Math.random())
                                console.log(result)
                            },
                            (error) => {
                                console.log('error: ', error)
                            }
                        );
                }}/>

            <TaskStateDialog open={taskStateDialog} handleClose={() => setTaskStateDialog(null)} setForceRefresh={setForceRefresh}/>

        </Dialog>
    )

}