import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {CommonFetch} from "../../../services/api-fetch";
import {useLocalStorage} from "../../../useLocalStorage";
import {useNavigate} from "react-router-dom";
import Slide from "@mui/material/Slide";
import AssignExecutorToAnalysisForDailyTasksDialog
    from "../../executorDialog/deliverer/AssignExecutorToAnalysisForDailyTasksDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FullyManagedAssignExecutorToAnalysisWithoutQtyDialog({open, handleClose, onClick, partners, setPartners}) {
    let navigate = useNavigate();

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [executorOpen, setExecutorOpen] = useState(false);
    const [executors, setExecutors] = useState([]);

    const [executorId, setExecutorId] = useState(null);
    const [executor, setExecutor] = useState(null);

    const [executorTyped, setExecutorTyped] = useState(null);






    const [refreshExecList, setRefreshExecList] = useState(100);

    const [editAllowed, setEditAllowed] = useState(false);

    const [assignedPartners, setAssignedPartners] = useState(null);
    const [assignWorkerOpen, setAssignWorkerOpen] = useState(false);
    const [executorsList, setExecutorsList] = useState([]);




    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/firms?`+ new URLSearchParams({
            token: accessToken,
            ...(executor ? { search: executorTyped } : {}),

        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setExecutors([...results])
                }
            })
    }, [open, executorTyped])


    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography>Назначи изпълнител</Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={1} style={{margin: 10}}>

                {refreshExecList && [...partners].map((exec, index) => {
                    console.log('exe c ', exec)
                    return <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextField disabled={!editAllowed} fullWidth label={'Изпълнител'} value={exec ? (exec.name_firm || exec.partner_name) : null}/>
                            </Grid>


                            {([...partners ?? [], ...partners]).length > 0 ? <Grid item xs={1.4}>
                                <Button disabled={!editAllowed} onClick={() => {
                                    console.log('[...assignedPartners ?? [], ...executorsList]', partners)

                                    const previousSecondElementOfTheArray = partners.filter((item) => {
                                        console.log('checking item and exec ', item, ' ', exec)
                                        return item.id !== exec.id;
                                    });

                                    setPartners(previousSecondElementOfTheArray)
                                    console.log('previousSecondElementOfTheArray ', previousSecondElementOfTheArray)


                                }}>Изтрий</Button>
                            </Grid> : null}
                        </Grid>
                    </Grid>
                })}


                <Grid item xs={3}>
                    <Button onClick={() => {
                        setEditAllowed(!editAllowed)
                    }}>Редакция</Button>
                </Grid>

                <Grid item xs={6}>
                    <Button disabled={!editAllowed}
                            onClick={() => {
                                setAssignWorkerOpen(true)
                            }}>Добави изпълнител</Button>
                </Grid>


                <AssignExecutorToAnalysisForDailyTasksDialog
                    open={assignWorkerOpen}
                    handleClose={() => setAssignWorkerOpen(false)}
                    onClick={(executor) => {
                        console.log('executorrr ', executor)
                        setPartners([...partners, {
                            id_partner: executor.id,
                            name_firm: executor.name_firm
                        }])
                        setAssignWorkerOpen(false)
                    }}/>


                <Grid item xs={6}>
                    <Button onClick={() => {
                        onClick(executor)
                        setExecutor(null)
                    }}>Запази</Button>
                </Grid>
            </Grid>


        </Dialog>
    )
}

export default FullyManagedAssignExecutorToAnalysisWithoutQtyDialog;