import {CircularProgress, FormHelperText, Grid, TextField} from "@mui/material";
import * as React from "react";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import {useEffect, useState} from "react";
import CircleStatusIcons from "../components/helper/CircleStatusIcons";

export default function AutocompleteWorks({autoCompleteOpen, setAutoCompleteOpen, stocksDataSet, loading, editId,
                                              setWorkName, workName, setLoading, onSelect,
                                              displayName, setDisplayName, makeAdding, taskState}) {
    const [shouldShowRazdel, setShouldShowRazdel] = useState(false);
    const [stockRazdel, setStockRazdel] = useState("");

    const filter = createFilterOptions();

    useEffect(() => {
        if (!workName || !displayName) {
            return;
        }
        if (shouldShowRazdel) {
            console.log('shouldShowRazdel ', stockRazdel)
            if (!displayName.includes(stockRazdel)) {
                const withRazdel = stockRazdel + ' • ' + displayName;
                setDisplayName(withRazdel)
            }
        } else {
            console.log('should not ShowRazdel')
            setDisplayName(displayName.replace((stockRazdel + ' • '), ''))
        }

    }, [shouldShowRazdel])

    return (
        <Grid container>

            <Grid item xs={12}>
                <Autocomplete
                    id="asynchronous-demo"
                    freeSolo
                    open={autoCompleteOpen}
                    onOpen={() => {
                        setAutoCompleteOpen(true);
                    }}
                    onClose={() => {
                        setAutoCompleteOpen(false);
                    }}
                    filterOptions={(options, params) => {
                        let filtered = options;
                        if (makeAdding) {
                            console.log('makeAdding ', makeAdding)
                            filtered = filter(options, params);
                            const { inputValue } = params;

                            // Suggest the creation of a new value
                            const isExisting = options
                                .filter((option) => option.name_work ? option.name_work.includes(inputValue) : false)
                                .length > 0;
                            console.log('isExisting ', options)
                            console.log('isExistingisExisting ', isExisting)
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name_work: `Добави "${inputValue}"`,
                                });
                            }
                        }
                        console.log('filtered ', filtered)
                        return filtered;
                    }}
                    renderOption={(props, option) => (
                        <Grid container {...props}>
                            {option.name_work && !option.name_work.includes("Добави \"") ? <Grid item xs={12} style={{width: '100%'}}>
                                <FormHelperText>{((option.name_etap ?? '') + ' • ') + option.name_group}</FormHelperText>
                            </Grid> : null}
                            <div>
                                { makeAdding && stocksDataSet.length === 0 ?
                                    option.name_good || option.name_work
                                    :
                                    (option.name_work)
                                }
                            </div>
                        </Grid>
                    )}
                    getOptionLabel={(option) => {
                        if (makeAdding) {
                            return option.name_work ? option.name_work.replace("Добави \"", "").replace("\"", "") : '';
                        }
                        return option.name_work;
                    }}
                    options={stocksDataSet}
                    loading={loading}
                    onChange={(event, prop) => {
                        if (editId) {
                            return
                        }

                        console.log('on select onChange')

                        if (prop) {
                            console.log('setting prop ', prop)
                            let nameGood = prop.name_work;
                            if (nameGood.includes("Добави \"")) {
                                nameGood = nameGood.replace("Добави \"", "")
                                nameGood = nameGood.replace("\"", "")
                                console.log('setting prop nameGood ', nameGood)
                                prop.name_work = nameGood

                            }
                            onSelect(prop)

                        } else {

                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Избери работа"
                            value={workName}
                            onChange={(e) => {
                                //setLoading(true)
                                setWorkName(e.target.value)
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    taskState && Number(taskState) >= 0 ? <CircleStatusIcons size={20} taskState={taskState}/> : <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>

        </Grid>

    )
}