import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CreateAvtoparkDialog from "./createModals/CreateAvtoparkDialog";
import {useEffect, useState} from "react";
import {CommonFetch} from "../../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";

function AvtoparkComponent() {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [createOpen, setCreateOpen] = useState();

    const [items, setItems] = useState();


    const [forceRefresh, setForceRefresh] = useState();

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/getResources?`+ new URLSearchParams({
            token: accessToken,
            type: 2
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setItems(results);
                }
            })
    }, [forceRefresh])

    return (
        <Grid container spacing={1} justifyContent={'flex-end'} style={{padding: 10}}>

            <Grid item>
                <Button variant={'contained'} onClick={() => setCreateOpen(true)}>Създай</Button>
            </Grid>

            <Grid item xs={12}>
                <List>

                    {
                        items && items.map((item) => {
                            return <ListItem>
                                <ListItemText primary={item.resource_name} />
                            </ListItem>
                        })
                    }

                </List>
            </Grid>


            <CreateAvtoparkDialog
                open={createOpen}
                handleClose={() => setCreateOpen(false)}
                setForceRefresh={setForceRefresh}/>

        </Grid>
    )
}
export default AvtoparkComponent;