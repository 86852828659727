import List from "@mui/material/List";
import React from "react";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FormHelperText from "@mui/material/FormHelperText";
import AttachmentIcon from "@mui/icons-material/Attachment";
import Button from "@mui/material/Button";
import moment from "moment/moment";
import Divider from "@mui/material/Divider";
import {useLocalStorage} from "../../useLocalStorage";
import {useNavigate} from "react-router-dom";
import {deleteDetail} from "../../utils/NetworkUtils";
import EditCommentDialog from "./EditCommentDialog";

function CommentsList(props) {
    let navigate = useNavigate();

    const [userId, setUserId] = useLocalStorage("userId", null);
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [commentHover, setCommentHover] = React.useState(null);

    const [editComment, setEditComment] = React.useState(null);
    const [oldEditComment, setOldEditComment] = React.useState(null);
    const [additionalData, setAdditionalData] = React.useState(null);

    return (
        <List>
            {
                (props.popoverDetails && props.popoverDetails.length > 0) && props.popoverDetails
                    .filter((detail) => detail.comment)
                    .filter((detail) => detail.comment !== 'Приключена е')
                    .filter((detail) => props.showCommentHistory ? true : props.filterByDate ? detail.date_task === props.filterByDate.format('YYYY-MM-DD') : true)
                    .map((detail) => {
                    return (
                        <React.Fragment>
                            <Grid container>

                                <Grid item>

                                    <ListItem sx={{
                                        pb: 0.3
                                    }}>
                                        <Typography>{detail.comment}</Typography>
                                    </ListItem>
                                </Grid>
                                {
                                    detail.id_creater === userId &&

                                    <Grid item>
                                        <Tooltip title="Редактирай">
                                            <IconButton onClick={() => {
                                                setEditComment(detail.id)
                                                setOldEditComment(detail.comment)
                                                detail.type = props.taskOrAwork
                                                console.log('props.taskOrAwork ', props.taskOrAwork)
                                                setAdditionalData(detail)

                                            }}>
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                                {
                                    detail.id_creater === userId &&

                                    <Grid item xs={1}>
                                        <Tooltip title="Изтрий коментар">
                                            <IconButton onClick={() => {
                                                deleteDetail(navigate, accessToken, setAccessToken, detail.id)
                                                    .then(r => {
                                                        if (r && r.err && r.err === "0") {
                                                            props.setForceRefresh(Math.random())
                                                        }
                                                    });
                                            }}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                            </Grid>

                            {detail.qty &&
                                <FormHelperText sx={{
                                    p: 2,
                                    pt: 0, pb: 0
                                }}>Количество: {detail.qty}</FormHelperText>
                            }

                            {
                                detail.taskfiles &&
                                <Grid container style={{marginLeft: 15}}>
                                    {
                                        detail.taskfiles.map((attachment, index) => {
                                            const url = `${process.env.REACT_APP_URL}/tasks/getAttachment?id=${attachment.id}&token=${accessToken}`;
                                            if (attachment.filename && attachment.filename.toLowerCase().endsWith('.png') || attachment.filename.toLowerCase().endsWith('.jpg') || attachment.filename.toLowerCase().endsWith('.jpeg')) {
                                                return (
                                                    <Grid onMouseOver={() => setCommentHover(attachment.id)} onMouseOut={() => setCommentHover(null)}>
                                                        <Grid item xs={2} onClick={() => {
                                                            props.setAnchorEl(null)

                                                            console.log('ippp ', detail.taskfiles[index])

                                                        }}>
                                                            <img style={{width: 120, height: 110, marginRight: 10}} src={url}/>

                                                        </Grid>

                                                    </Grid>
                                                )
                                            } else {
                                                return (
                                                    <Grid onMouseOver={() => setCommentHover(index)} onMouseOut={() => setCommentHover(null)}>

                                                        <Grid item xs={2}>
                                                            <AttachmentIcon style={{width: 120, height: 120}} onClick={() => {
                                                                window.open(url, '_blank').focus();
                                                            }}/>
                                                            <Button variant={'outlined'} onClick={() => {

                                                            }}>Виж</Button>
                                                        </Grid>


                                                    </Grid>
                                                )
                                            }
                                        })
                                    }
                                </Grid>

                            }
                            {
                                detail.filename && detail.filename.includes('pdf') ?

                                    <Tooltip title={detail.filename}>
                                        <Button style={{marginLeft: 15}} variant={'contained'} onClick={() => {
                                            //window.open('data:application/pdf;base64,' + detail.file, '_blank').focus();
                                            let pdfWindow = window.open("")
                                            pdfWindow.document.write(
                                                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                encodeURI(detail.file) + "'></iframe>"
                                            )
                                        }}>Виж</Button>
                                    </Tooltip>


                                    :


                                    detail.filename && (detail.filename.toLowerCase().includes('jpg') || detail.filename.toLowerCase().includes('jpeg') || detail.filename.toLowerCase().includes('png') || detail.filename.toLowerCase().includes('bmp') || detail.filename.toLowerCase().includes('gif') || detail.filename.toLowerCase().includes('tif')) ?
                                (detail.file ?
                                    <Button style={{marginLeft: 15}} variant={'contained'} onClick={() => {
                                        //window.open('data:application/pdf;base64,' + detail.file, '_blank').focus();
                                        let pdfWindow = window.open("")
                                        pdfWindow.document.write(
                                            "<iframe width='100%' height='100%' src='data:image/jpg;base64, " +
                                            encodeURI(detail.file) + "'></iframe>"
                                        )
                                    }}>Виж</Button>
                                    /*<img style={{width: 400}} src={'data:image/jpg;base64,' + detail.file}/>*/
                                    : null)


                                        :


                                        detail.filename ?
                                        <Tooltip title={detail.filename}>
                                            <Button style={{marginLeft: 15}} variant={'contained'} onClick={() => {
                                                var a = document.createElement("a"); //Create <a>
                                                a.href = "data:image/png;base64," + detail.file; //Image Base64 Goes here
                                                a.download = detail.filename; //File name Here
                                                a.click(); //Downloaded file
                                            }}>Изтегли</Button>
                                        </Tooltip>
                                            : null
                            }

                            <FormHelperText sx={{
                                p: 2,
                                pt: 0, pb: 0
                            }}>{
                                (detail.name_creater ? "Създадена от " + detail.name_creater : "")
                                + (detail.date_create ? " на " + moment(detail.date_create).format("DD MMM YYYY dddd") + " в " + moment(moment.utc(detail.date_create).toDate()).local().format("HH:mm") + " и се отнася за "
                                    + [
                                        detail.date_task ? moment(detail.date_task).format('DD MMM YYYY dddd') : null,

                                        detail.hour_start ?
                                            detail.hour_start.length === 5 ? detail.hour_start
                                                :
                                                moment(detail.hour_start).format('HH:mm') : null
                                    ]
                                        .filter(Boolean).join(" ") : "")
                            }</FormHelperText>

                            <FormHelperText sx={{
                                p: 2,
                                pt: 0
                            }}>{

                            }</FormHelperText>
                            <Divider />

                            {editComment ? <EditCommentDialog open={editComment}
                                               handleClose={() => setEditComment(null)}
                                               comment={oldEditComment}
                                               additionalData={additionalData}
                                               taskId={props.taskId}
                                               setForceRefresh={props.setForceRefresh}/> : null}

                        </React.Fragment>
                    )
                })
            }

        </List>

    )
}
export default CommentsList;