import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from '@mui/icons-material/Menu';
import {useLocalStorage} from "./useLocalStorage";
import SideMenu from "./SideMenu";
import RightOptionsSideMenu from "./RightOptionsSideMenu";
import {Tooltip} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemText from "@mui/material/ListItemText";

function AppBarComponent(props) {
    const [auth, setAuth] = React.useState(true);
    const [isOpen, setOpen] = React.useState(false);
    const [isSettingsOpen, setSettingsOpen] = React.useState(false);
    const [analysisDialogOpen, setAnalysisDialogOpen] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [help, setHelp] = React.useState(false);

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [testClientName, setTestClientName] = useLocalStorage("testClientName", null);

    const [testClientNameState, setTestClientNameState] = React.useState(testClientName);

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const steps = [
        {
            selector: '#firms-select-form',
            content: 'Избери фирма, с която да работиш',
        },
        {
            selector: '#objects-select',
            content: 'Избери обект, с който да работиш',
        },
        // ...
    ]

    return (
        <AppBar position="static">
            <SideMenu
                isOpen={isOpen}
                setOpen={setOpen}
                setView={props.setView}/>

            <RightOptionsSideMenu
                setRefreshObjects={props.setRefreshObjects}
                selectedObject={props.selectedObject}
                selectedObjectOffer={props.selectedObjectOffer}
                setSelectedObjectForOfferName={props.setSelectedObjectForOfferName}
                isOpen={isSettingsOpen}
                setOpen={setSettingsOpen}/>

            <Toolbar>
                { accessToken &&
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            setOpen(true)
                        }}
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                }
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Управление на проекти
                </Typography>

                { accessToken &&
                    <Tooltip title={"СЕК"}>
                        <IconButton onClick={() => {
                            setAnalysisDialogOpen(true)
                        }}>
                            <ListItemText style={{color: 'black'}} primary={'СЕК'} />
                        </IconButton>
                    </Tooltip>
                }

                { accessToken &&
                    <Tooltip title={"Помощ"}>
                        <IconButton onClick={() => {
                            setHelp(true)
                        }}>
                            <ListItemText style={{color: 'black'}} primary={'Помощ'} />
                        </IconButton>
                    </Tooltip>
                }

                { accessToken &&
                    <Typography style={{color: 'black'}}>{testClientNameState}</Typography>
                }

                { accessToken &&
                    <Tooltip title={"Още"}>
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => {
                                setSettingsOpen(true)
                            }}
                            sx={{mr: 1}}
                        >
                            <SettingsIcon/>
                        </IconButton>
                    </Tooltip>
                }
            </Toolbar>
        </AppBar>
    );
}

export default AppBarComponent;
