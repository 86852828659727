import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {CommonFetch} from "../../services/api-fetch";
import {useLocalStorage} from "../../useLocalStorage";
import {useNavigate} from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EditCommentDialog({open, handleClose, setForceRefresh, comment}) {

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    let navigate = useNavigate();

    const [newComment, setNewComment] = useState(null);

    useEffect(() => {
        setNewComment(comment)
    }, [comment])

    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Редакция на коментар
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={1} style={{margin: 10}}>
                <Grid item xs={10}>
                    <TextField fullWidth multiline value={newComment} onChange={(e) => {
                        setNewComment(e.target.value)
                    }}/>
                </Grid>

                <Grid item xs={3}>
                    <Button variant={'contained'} onClick={() => {
                        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/mywave/update?`+ new URLSearchParams({
                        }), {
                            method: "POST",
                            mode: 'cors',
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                id: open,
                                comment: newComment,
                                token: accessToken
                            })
                        })
                            .then((result) => {
                                setForceRefresh(Math.random())
                                handleClose()
                            })
                    }}>Запис</Button>
                </Grid>
            </Grid>

        </Dialog>
    )
}
export default EditCommentDialog;