import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import * as React from "react";

function LabourMechServAddTable({open, partners, partnersForDialog, setHistoryDialogForExecutorOpen, style, setAddExecutorOpen}) {
    return (
        <Table aria-label="a dense table" size="small" style={{marginTop: 0}}>
            <TableHead>
                <TableRow>
                    <TableCell width="20%" style={style.tableCell}>Изпълнител</TableCell>
                    <TableCell width="20%" style={style.tableCell}>К-во по дог.</TableCell>
                    <TableCell width="20%"
                               style={Object.assign({}, style.tableCell, {})}>Изпълн. к-во</TableCell>
                    <TableCell width="20%"
                               style={Object.assign({}, style.tableCell, {})}>Дневно изп.</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    ([...partners, ...partnersForDialog]).map((row, index) => (

                        <React.Fragment>
                            <TableRow
                                key={row.id}
                                style={{height: 40}}
                                onClick={() => {
                                    setHistoryDialogForExecutorOpen({
                                        id_awork: open,
                                        id_partner: row.id_partner
                                    })
                                }}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell width="20%" component="th" scope="row" style={style.tableCell}>
                                    {row.name}
                                </TableCell>

                                <TableCell width="20%" component="th" scope="row" style={style.tableCell}>
                                    {row.qty_contract}
                                </TableCell>

                                <TableCell width="20%" component="th" scope="row" style={style.tableCell}>
                                    {row.executed}
                                </TableCell>

                                <TableCell width="20%" component="th" scope="row" style={style.tableCell}
                                           onClick={(event) => {
                                               event.stopPropagation()
                                               event.nativeEvent.stopImmediatePropagation();
                                               setAddExecutorOpen(row.id_partner)
                                           }}>
                                    {row.day_qty ? row.day_qty : 0}
                                </TableCell>

                            </TableRow>

                        </React.Fragment>

                    ))

                }

            </TableBody>
        </Table>
    )
}
export default LabourMechServAddTable;