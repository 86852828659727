import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from '@mui/material/IconButton';
import MessageDialog from "../../../helper/MessageDialog";
import {CommonFetch} from "../../../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../../useLocalStorage";

function AdditionalLabourTable({details, setForceRefresh, type}) {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [deleteConfirmationForDayOpen, setDeleteConfirmationForDayOpen] = useState(false);
    const [deleteConfirmationForDayOpen2, setDeleteConfirmationForDayOpen2] = useState(false);

    const style = {
        tableCell: {
            borderRightWidth: '0.5px',
            borderRightStyle: "solid",
            borderRightColor: "#969696",
            display: "tableRowGroup"
        }
    };
    return (
        <Table aria-label="a dense table" size="small" style={{marginTop: 0}}>
            <TableHead>
                <TableRow>
                    <TableCell width="30%" style={style.tableCell}>Контрагент</TableCell>
                    <TableCell width="30%"
                               style={Object.assign({}, style.tableCell, {})}>Часове</TableCell>

                    <TableCell width="30%"
                               style={Object.assign({}, style.tableCell, {})}>Цена/час</TableCell>
                    <TableCell width="10%"
                               style={Object.assign({}, style.tableCell, {})}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    (details ?? []).map((row, index) => (

                        <React.Fragment>
                            <TableRow
                                key={row.id}
                                style={{height: 40}}
                                onClick={() => {

                                }}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell width="30%" component="th" scope="row"
                                           style={style.tableCell}>
                                    {row.partner_name}
                                </TableCell>

                                <TableCell width="30%" component="th" scope="row"
                                           style={style.tableCell}>
                                    {row.hours_work}
                                </TableCell>


                                <TableCell width="30%" component="th" scope="row"
                                           style={style.tableCell}>
                                    {row.price_hour}
                                </TableCell>

                                <TableCell width="10%" component="th" scope="row"
                                           style={style.tableCell}>
                                    <IconButton onClick={() => {
                                        setDeleteConfirmationForDayOpen({
                                            id: row.id,
                                            text: 'Да се изтрие ли това изпълнение?'
                                        })
                                    }}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </TableCell>


                            </TableRow>

                        </React.Fragment>

                    ))

                }

            </TableBody>




            <MessageDialog
                openAndText={deleteConfirmationForDayOpen ? deleteConfirmationForDayOpen.text : null}
                handleClose={() => setDeleteConfirmationForDayOpen(null)}
                negativeText={'Не'}
                negativeAction={() => {
                    setDeleteConfirmationForDayOpen(null)
                }}
                positiveText={'Да'}
                positiveAction={() => {
                    setDeleteConfirmationForDayOpen2(
                        {
                            id: deleteConfirmationForDayOpen.id,
                            text: "Това изпълнение ще бъде напълно изтрито! Сигурни ли сте?"
                        })
                    setDeleteConfirmationForDayOpen(false)

                }}/>


            <MessageDialog
                openAndText={deleteConfirmationForDayOpen2 ? deleteConfirmationForDayOpen2.text : null}
                dangerMode
                handleClose={() => setDeleteConfirmationForDayOpen2(null)}
                negativeText={'Не'}
                negativeAction={() => {
                    setDeleteConfirmationForDayOpen2(null)
                }}
                positiveText={'Да'}
                positiveAction={() => {
                    setDeleteConfirmationForDayOpen2(null)
                    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/deleteWorkReport`, {
                        method: "POST",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            token: accessToken,
                            id: deleteConfirmationForDayOpen2 ? deleteConfirmationForDayOpen2.id : null,
                            "type": type
                        })
                    })
                        .then(
                            (result) => {
                                setForceRefresh(Math.random())
                                console.log(result)
                            },
                            (error) => {
                                console.log('error: ', error)
                            }
                        );
                }}/>
        </Table>
    )
}
export default AdditionalLabourTable;