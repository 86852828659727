import * as React from "react";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {FormControl, TextField} from "@mui/material";
import AutocompleteUsers from "../../../../inputs/AutocompleteUsers";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DuplicateOnForm({open, handleClose,
                             updateStatus,
                             partners, tempExecutor, setTempExecutor, setTempExecutorId, setPartners, setAddExectuorsOpen,
                             hour, setHour,
                             hourEnd, setHourEnd,
                             duplicateOn, setDuplicateOn,
                            }) {

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            maxWidth={'md'}
            TransitionComponent={Transition}
        >

            <Grid container spacing={1} style={{margin: 10}}>


                <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                        <DatePicker
                            label={'Дублирай на'}
                            value={duplicateOn}
                            slotProps={{ textField: { fullWidth: true } }}
                            onChange={(newValue) => setDuplicateOn(newValue)}
                            views={['year', 'month', 'day']}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={5}>

                    {
                        partners.length === 0 ?
                            <AutocompleteUsers
                                executor={tempExecutor} setExecutor={setTempExecutor} setExecutorId={setTempExecutorId} onSelect={(prop) => {
                                console.log('проппп ', prop)
                                setPartners([...partners, {
                                    id_partner: prop.id,
                                    name_firm: prop.name_firm
                                }])
                            }
                            }/>
                            :
                            <TextField
                                value={partners ? (partners.length > 1 ? (partners.length + ' работници') : (partners.length === 1 ? (partners[0].name_firm || partners[0].partner_name) : '')) : null}
                                fullWidth onClick={()=> setAddExectuorsOpen(true)} label={'Изпълнител'} contentEditable={false}/>

                    }

                </Grid>

                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                            <TimePicker
                                id="hour"
                                label="Час от"
                                type="time"
                                ampm={false}
                                value={hour}
                                InputLabelProps={{ shrink: true }}
                                onChange={(newValue) => {
                                    setHour(newValue)
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>


                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                            <TimePicker
                                id="hour"
                                label="Час до"
                                type="time"
                                ampm={false}
                                value={hourEnd}
                                InputLabelProps={{ shrink: true }}
                                onChange={(newValue) => {
                                    setHourEnd(newValue)
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>



                <Grid item>
                    <Button variant={'contained'} onClick={() => {
                        updateStatus()
                    }}>
                        Запис
                    </Button>
                </Grid>

            </Grid>


        </Dialog>
    )
}
export default DuplicateOnForm;