import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import moment from "moment/moment";
import * as React from "react";
import {useState} from "react";
import GraphYearsCalendar from "./GraphYearsCalendar";
import Grid from "@mui/material/Grid";

function MonthlyTasksDialog({selectedObject}) {

    const NUM_PERC = '3%';
    const NAME_PERC = '40%';
    const ENTITY_PERC = '4%';
    const QTY_PERC = '4%';
    const QTY_MONEY = '5%';
    const DAILY_MONEY = '3%';



    let startDate = new Date();
    startDate = startDate.setDate(27)

    let endDate = new Date();
    endDate = endDate.setDate(endDate.getDate() + 14)

    const [ranges, setRanges] = React.useState([{
        startDate: startDate,
        endDate: endDate,
        key: 'selection'
    }]);

    const [date, setDate] = useState(moment(new Date()));

    const [items, setItems] = useState([{name: 'Къща Спасов - Довършване плочки и фугиране'}]);

    const [yearSelected, setYearSelected] = useState(moment());

    const [selectedMonths, setSelectedMonths] = useState([]);
    const [selectedMonthsTemp, setSelectedMonthsTemp] = useState([]);


    const [selectedWeeks, setSelectedWeeks] = useState();
    const [selectedWeeksTemp, setSelectedWeeksTemp] = useState([]);



    function getDates(startDate, stopDate) {
        var dateArray = new Array();
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            dateArray.push(currentDate);
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }
    return (
            <React.Fragment>

                {
                    yearSelected ? <GraphYearsCalendar
                            filterAnex={false}
                            selectedObject={selectedObject}
                            year={yearSelected} setYear={setYearSelected}
                            selectedMonths={selectedMonths} setSelectedMonths={setSelectedMonths}
                            selectedMonthsTemp={selectedMonthsTemp} setSelectedMonthsTemp={setSelectedMonthsTemp}
                            selectedWeeks={selectedWeeks} setSelectedWeeks={setSelectedWeeks}
                            selectedWeeksTemp={selectedWeeksTemp} setSelectedWeeksTemp={setSelectedWeeksTemp}
                            items={items} setConfirmMove={() => {}} tab={1}
                            setGraphicEditDialog={() => {}}/>
                        :
                        <Grid item xs={12}>
                            <TableContainer>
                                {console.log('items.length ', items.length)}
                                {items && items.length > 0 ?
                                    <Table size={'small'} sx={{border: 1}} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{border: 1}} width={NUM_PERC}
                                                           align="left"><b>№</b></TableCell>
                                                <TableCell sx={{border: 1}} width={NAME_PERC}
                                                           align="left"><b>Наименование<span style={{
                                                    color: '#ff5900',
                                                    marginLeft: 5
                                                }}>График по договор</span></b></TableCell>
                                                <TableCell sx={{border: 1}} width={ENTITY_PERC}
                                                           align="center"><b>М-ка</b></TableCell>
                                                <TableCell sx={{border: 1}} width={QTY_PERC}
                                                           align="center"><b>Колич.</b></TableCell>
                                                <TableCell sx={{border: 1}} width={QTY_MONEY} align="center"><b>Раб дни</b></TableCell>
                                                {
                                                    getDates(ranges[0].startDate, ranges[0].endDate).map((dateInList, index, array) => {
                                                        return <TableCell sx={{border: 1}} width={DAILY_MONEY}
                                                                          align="center"><b>{moment(dateInList).format('DD')}</b></TableCell>
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {items && items
                                                .map((row, index) => (
                                                    <React.Fragment>
                                                        <TableRow
                                                            key={row.no}
                                                            style={{background: '#63fff2'}}
                                                        >
                                                            <TableCell sx={{border: 1}} width={NUM_PERC}
                                                                       align="left"><b>{index + 1}</b></TableCell>
                                                            <TableCell sx={{border: 1}} width={NAME_PERC}
                                                                       align="left"><b>{row.name}</b></TableCell>
                                                            <TableCell sx={{border: 1}} width={ENTITY_PERC}
                                                                       align="center"></TableCell>
                                                            <TableCell sx={{border: 1}} width={QTY_PERC}
                                                                       align="right">{row.qty ? row.qty.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                            <TableCell sx={{border: 1}} width={QTY_MONEY}
                                                                       align="center"></TableCell>
                                                            {
                                                                getDates(ranges[0].startDate, ranges[0].endDate).map((dateInList, index, array) => {
                                                                    return <TableCell sx={{border: 1}} width={DAILY_MONEY}
                                                                                      align="center"></TableCell>
                                                                })
                                                            }
                                                        </TableRow>

                                                    </React.Fragment>

                                                ))}
                                        </TableBody>
                                    </Table> :

                                    <div style={{padding: 20}}>
                                        <Typography>Няма резултати</Typography>
                                    </div>
                                }
                            </TableContainer>
                        </Grid>
                }

            </React.Fragment>
    )
}
export default MonthlyTasksDialog;