import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import * as React from "react";
import {CommonFetch} from "../../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import Button from "@mui/material/Button";

function AdditionalLabourTab() {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    const [executorOpen, setExecutorOpen] = useState(false);
    const [executors, setExecutors] = useState([]);
    const [addExecutorOpen, setAddExecutorOpen] = useState(false);
    const [executorTyped, setExecutorTyped] = useState(null);

    const [executorId, setExecutorId] = useState(null);
    const [executor, setExecutor] = useState(null);


    const [hours, setHours] = useState();
    const [price, setPrice] = useState();




    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners?`+ new URLSearchParams({
            token: accessToken,
            ...(executor ? { search: executorTyped } : {}),

        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setExecutors([...results])
                }
            })
    }, [executorTyped])


    return (
        <Grid container spacing={1}>
            <Grid item xs={10}>
                <TextField fullWidth label={'Група'}/>
            </Grid>
            <Grid item xs={2}>
                <Button>Добави група</Button>
            </Grid>

            <Grid item xs={8.1}>
                <Autocomplete
                    id="contragents"
                    freeSolo
                    open={executorOpen}
                    onOpen={() => {
                        setExecutorOpen(true);
                    }}
                    onClose={() => {
                        setExecutorOpen(false);
                    }}
                    value={executor ? executor && executor.name_firm ? executor : {name_firm: executor} : null}
                    filterOptions={(options, params) => {
                        let newOptions = options.filter((option) => {
                            if (!option || !option.name_firm || !executor || !executorTyped) {
                                return false;
                            }

                            return option.name_firm.toLowerCase().includes(executorTyped ? executorTyped.toLowerCase() : "")
                        })

                        const { inputValue } = params;

                        // Suggest the creation of a new value
                        const isExisting = newOptions.length > 0;
                        if (inputValue !== '' && !isExisting) {
                            newOptions.push({
                                inputValue,
                                name_firm: `Добави "${inputValue}"`,
                            });
                        }

                        return newOptions;
                    }}

                    onChange={(event, prop) => {
                        console.log('prop ', prop)
                        if (prop) {
                            console.log(prop)
                            setExecutorId(prop && prop.id ? prop.id : prop)
                            setExecutor(prop && prop.name ? prop.name : prop)


                            if (!prop.id) {

                            }

                        } else {
                            setExecutor("")
                        }
                    }}
                    onInput={(event) => {
                        setExecutorTyped(event.target.value)
                    }}
                    getOptionLabel={(option) => option.name_firm}
                    options={executors}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Контрагент"
                            value={executorTyped}
                            onChange={(e) => {
                                setExecutor(e.target.value)
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField label={'Часове'} value={hours} onChange={(e) => setHours(e.target.value)}/>
            </Grid>
            <Grid item xs={4}>
                <TextField label={'Цена/час'} value={price} onChange={(e) => setPrice(e.target.value)}/>
            </Grid>
            <Grid item xs={4}>
                <TextField label={'Общо'} value={!isNaN(hours) && !isNaN(price) ? Number(hours) * Number(price) : ""}/>
            </Grid>

        </Grid>
    )
}
export default AdditionalLabourTab;