import IconButton from "@mui/material/IconButton";
import {AccountCircle} from "@mui/icons-material";
import {Grid, Menu, Typography} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";
import FirmSelectComponent from "./FirmSelectComponent";
import {useLocalStorage} from "../useLocalStorage";
import {useNavigate} from "react-router-dom";
import {CommonFetch} from "../services/api-fetch";
import WarehouseSelectComponent from "./WarehouseSelectComponent";
import ObjectSelectComponent from "./ObjectSelectComponent";

function UserTopRightComponent({   selectedFirm,       setSelectedFirm,
                                   selectedWarehouse,  setSelectedWarehouse,
                                   selectedOffice,      setSelectedOffice}) {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [userId, setUserId] = useLocalStorage("userId", null);
    const [userName, setUserName] = useLocalStorage("userName", null);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [companies, setCompanies] = React.useState([]);
    const [warehouses, setWarehouses] = React.useState([]);

    const [testClientName, setTestClientName] = useLocalStorage("testClientName", null);
    const [testClientNameState, setTestClientNameState] = React.useState(testClientName);

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/company?`+new URLSearchParams(
            {
                "token": accessToken
            }
        ), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        }).then((result) => {
            if (result && result.items) {
                if (result.items.length > 0) {
                    setSelectedFirm(result.items[0].id)
                }
                setCompanies(result.items)
            }
        })

        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/warehouses?`+new URLSearchParams(
            {
                "token": accessToken
            }
        ), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        }).then((result) => {
            if (result && result.items) {
                setWarehouses(result.items)
            }
        })
    }, [anchorEl])


    return (
        <React.Fragment>
            {<div>
                <Typography>{testClientNameState}</Typography>
            </div>}

            <IconButton
                style={{marginRight: 10}}
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                }}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                style={{marginTop: 20, marginRight: 80}}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Grid container spacing={2} style={{minWidth: 300, maxWidth: 500, minHeight: 100, padding: 10}}>
                    <Grid item xs={12}>
                        Здравейте, {userName}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Избери фирма:</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <FirmSelectComponent
                                firms={companies}
                                selectedFirm={selectedFirm}
                                setSelectedFirm={setSelectedFirm}
                                setSelectedObject={() => {}}
                                setOffersMode={() => {}}
                                resetProps={() => {}}
                                label={'Фирма'}
                            />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Избери склад:</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <WarehouseSelectComponent
                            firms={warehouses}
                            selectedFirm={selectedWarehouse}
                            setSelectedFirm={setSelectedWarehouse}
                            setSelectedObject={() => {}}
                            setOffersMode={() => {}}
                            resetProps={() => {}}
                            label={'Склад'}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Избери обект:</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <ObjectSelectComponent
                            firms={[{id: 1, name: 'Офис СИНИ'}]}
                            selectedFirm={selectedOffice}
                            setSelectedFirm={setSelectedOffice}
                            setSelectedObject={() => {}}
                            setOffersMode={() => {}}
                            resetProps={() => {}}
                            label={'Обект'}
                        />
                    </Grid>
{/*
                    <Grid item xs={12}>
                        <Button variant={'contained'} onClick={() => {
                            setUserId(null)
                            setUserName(null)
                            setAccessToken(null)
                            navigate('/login')
                        }}>Изход</Button>
                    </Grid>*/}

                </Grid>
            </Menu>
        </React.Fragment>
    )
}
export default UserTopRightComponent;