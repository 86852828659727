import Grid from "@mui/material/Grid";
import {
    FormControl, InputLabel, MenuItem, Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import moment from "moment/moment";
import * as React from "react";
import {useEffect, useState} from "react";
import MessageDialog from "../../helper/MessageDialog";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {addObject} from "../../../utils/NetworkUtils";
import ObjectDataDialog from "../../ObjectDataDialog";
import GraphYearsWeekNumsCalendar from "./GraphYearsWeekNumsCalendar";
import Button from "@mui/material/Button";
import CircleIcon from "@mui/icons-material/Circle";
import {Group} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ObjectDataV2Dialog from "../../analysis/ObjectDataV2Dialog";
import {CommonFetch} from "../../../services/api-fetch";

export default function GraphYearsCalendar({yearItems, setYearItems, setConfirmMove, setGraphicEditDialog, year, setYear,
                                               selectedMonths, setSelectedMonths,
                                               selectedMonthsTemp, setSelectedMonthsTemp, contractMode, onClick,
                                               tab, setTab, setForceRefresh
                                           }) {


    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    const NUM_PERC = '4%';
    const NAME_PERC = '23%';
    const ENTITY_PERC = '3%';
    const QTY_PERC = '3%';
    const ENT_PRICE_PERC = '7%';
    const TOTAL_PERC = '8%';
    const QTY_MONEY = '3%';
    const DAILY_MONEY = '3%';
    const EDIT_DEL_ICONS = '2%';

    const [messageDialog, setMessageDialog] = useState(null);
    const [objectSettings, setObjectSettings] = React.useState(false);

    const [objectStatusEnded, setObjectStatusEnded] = useState(0);
    const [searchTerm, setSearchTerm] = useState();



    const [objectId, setObjectId] = useState();
    const [objectName, setObjectName] = useState();
    const [departmentLong, setDepartmentLong] = React.useState(null);
    const [departmentShort, setDepartmentShort] = React.useState(null);

    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [deleteDialogOpen2, setDeleteDialogOpen2] = React.useState(false);

    const [message, setMessage] = React.useState(false);


    function getMonths() {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }

    function getMonthShortName(month) {
        switch (month) {
            case 1:
                return "ян";
            case 2:
                return "фев";
            case 3:
                return "март";
            case 4:
                return "апр";
            case 5:
                return "май";
            case 6:
                return "юни";
            case 7:
                return "юли";
            case 8:
                return "авг";
            case 9:
                return "септ";
            case 10:
                return "окт";
            case 11:
                return "нов";
            case 12:
                return "дек";
        }
    }

    useEffect(() => {
        /*getGraphicPerViewType(navigate, accessToken, setAccessToken, selectedObject, '2023-1-1', '2023-12-21', 'months')
            .then((result) => {
                if (result && result.items) {

                    for (const resultElement of result.items) {
                        resultElement.name = resultElement.name_group;
                        resultElement.sub = resultElement.subgroups;
                        resultElement.details = resultElement.subgroups;
                        for (const resultElementElement of resultElement.sub) {
                            resultElementElement.name = resultElementElement.name_group;
                            for (const work of resultElementElement.works) {
                                work.name = work.name_work;
                                //work.entity = work.edm;
                                work.quantity = work.qty;
                                work.startDate = work.date_start;
                                work.endDate = work.date_end;
                            }
                        }
                    }

                    setYearItems(result.items)
                }
            })*/
    }, [])

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    return (
        selectedMonths.length > 0 ?
            <GraphYearsWeekNumsCalendar
                selectedMonths={selectedMonths}
                setSelectedMonths={setSelectedMonths}
                year={year} setYear={setYear}/>
            :
        <React.Fragment>
            <Grid container spacing={1} justifyContent={'space-between'}>

                <Grid item xs={1.2}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">
                        <DatePicker size={'small'} views={['year']} label="Година" value={year}
                                    onChange={(value) => {
                                        setYear(value)
                                    }}/>
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={10}>
                    <Grid container spacing={1} justifyContent={'flex-start'}>
                        <Grid item xs={2.5}>
                            <FormControl fullWidth>
                                <InputLabel id="status-select-label">Статус</InputLabel>
                                <Select
                                    labelId="status-select-label"
                                    id="status-select"
                                    value={objectStatusEnded}
                                    label="Статус"
                                    onChange={(e) => {
                                        setObjectStatusEnded(e.target.value)
                                    }}
                                >
                                    <MenuItem value={0}>В работа</MenuItem>
                                    <MenuItem value={1}>Приключен</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3.5}>
                            <TextField fullWidth label={'Търси'} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
                        </Grid>

                        <Grid item xs={2.5}>
                            <Button
                                fullWidth
                                variant={'contained'}
                                id={"save"}
                                onClick={(event) =>  {
                                    setObjectSettings(true)
                                }}
                            >Създай нов</Button>
                        </Grid>
                    </Grid>

                </Grid>


                <Grid item xs={12}>
                    <Tabs value={tab} onChange={(event, newValue) => {
                        setTab(newValue);
                    }} aria-label="basic tabs example">
                        <Tab label="Производствени" />
                        <Tab label="Други" />
                        <Tab label="Всички" />
                    </Tabs>
                </Grid>

                <Grid item xs={7}></Grid>
         {/*       {selectedMonthsTemp && selectedMonthsTemp.length > 0 ? <Grid item xs={2}>
                    <Button
                        fullWidth
                        variant={'contained'}
                        id={"show"}
                        onClick={(event) => {
                            setSelectedMonths(selectedMonthsTemp)
                        }}
                    >Покажи</Button>
                </Grid> : null}*/}

                <Grid item xs={12}>
                    <TableContainer>
                        {yearItems && yearItems.length > 0 ? <Table size={'small'} sx={ {border: 1}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>№</b></TableCell>
                                        <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>Обект</b></TableCell>
                                        {
                                            getMonths().map((dateInList, index, array) => {
                                                return <TableCell
                                                    style={{padding: 2}}
                                                    onClick={() => {
                                                        if (selectedMonthsTemp.includes(dateInList)) {
                                                            let newSelectedMonths = selectedMonthsTemp.filter(function(item) {
                                                                return item !== dateInList
                                                            })

                                                            console.log('newSelectedMonths ', newSelectedMonths)
                                                            setSelectedMonthsTemp(newSelectedMonths)
                                                        } else {
                                                            if (selectedMonthsTemp.length<=2) {
                                                                setSelectedMonthsTemp([
                                                                    ...selectedMonthsTemp,
                                                                    dateInList
                                                                ])
                                                            } else {
                                                                setMessageDialog('Могат да се избират не повече от 3 месеца');
                                                            }

                                                        }
                                                    }}
                                                    sx={{border: 1, padding: 1, background: selectedMonthsTemp.includes(dateInList) ? '#8888fc' : null
                                                    }} width={DAILY_MONEY} align="center">
                                                    <Typography
                                                        sx={{background: selectedMonths.includes(dateInList) ? '#8888fc' : null}}
                                                        size={'small'}>{getMonthShortName(dateInList)}</Typography>
                                                </TableCell>
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {yearItems && yearItems
                                        .filter((project) => {
                                            if (tab === 0) {
                                                return project.project_type === 1
                                            } else if (tab === 1) {
                                                return project.project_type === 0
                                            } else {
                                                return true
                                            }
                                        })
                                        .filter((item) => item.name_project)
                                        .filter((item) => objectStatusEnded === 0 ? !item.date_end : item.date_end)
                                        .filter((object) => searchTerm ? (object.name_project ?? "").toLowerCase().includes(searchTerm) : true)
                                        .map((row, index) => (
                                            <React.Fragment>

                                                <React.Fragment>
                                                            <TableRow
                                                                key={row.no}
                                                                onClick={() => {
                                                                    console.log('setting ', row.id)
                                                                    console.log('setting ', row.name_project)
                                                                    /*setExternalWorkId(row.id_awork);
                                                                    setEmptyAnalysisDialog(row.id)*/
                                                                    onClick(row.id, row.name_project)
                                                                }}
                                                            >
                                                                <TableCell sx={{border: 1}} width={NUM_PERC} align="left">{index + 1}</TableCell>
                                                                <TableCell sx={{border: 1}} width={NAME_PERC} align="left">{row.name_project}</TableCell>

                                                                {
                                                                    (getMonths())
                                                                        .map((dateInList, index, array) => {
                                                                            let workMonths = row.months ?? [];

                                                                            return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center"
                                                                                              onClick={(event) => {
                                                                                                  event.stopPropagation()
                                                                                                  event.nativeEvent.stopImmediatePropagation();

                                                                                                  setConfirmMove({
                                                                                                      id: row.id,
                                                                                                      date: moment().format('YYYY-MM-DD'),
                                                                                                      text: 'Наистина ли искате да изместите дейността \"' + row.name + '\" за '
                                                                                                          + moment().format('YYYY-MM-DD')
                                                                                                  })
                                                                                              }}>
                                                                                { workMonths.includes(zeroPad(dateInList, 2)) ? <CircleIcon style={{color: '#8888fc'}}/> : null }
                                                                            </TableCell>
                                                                        })
                                                                }

                                                                <TableCell sx={{border: 1}} width={EDIT_DEL_ICONS} align="center" style={{padding: 8}}>
                                                                    <IconButton style={{width: 20, height: 20}} onClick={(event) => {
                                                                        event.stopPropagation()
                                                                        event.nativeEvent.stopImmediatePropagation();

                                                                        setObjectId(row.id)
                                                                        setDepartmentLong(row.name_project)
                                                                        setDepartmentShort(row.shortprjname)
                                                                        setEditDialogOpen(row)
                                                                        setObjectSettings(row.id)
                                                                    }}>
                                                                        <EditIcon fontSize={'small'}/>
                                                                    </IconButton>
                                                                </TableCell>

                                                                <TableCell sx={{border: 1}} width={EDIT_DEL_ICONS} align="center" style={{padding: 8}}>
                                                                    <IconButton style={{width: 20, height: 20}} onClick={(event) => {
                                                                        event.stopPropagation()
                                                                        event.nativeEvent.stopImmediatePropagation();

                                                                        setDeleteDialogOpen({
                                                                            id: row.id,
                                                                            text: 'Наистина ли искате да изтриете обекта?'
                                                                        })
                                                                    }}>
                                                                        <DeleteIcon fontSize={'small'}/>
                                                                    </IconButton>
                                                                </TableCell>

                                                            </TableRow>


                                                        </React.Fragment>

                                            </React.Fragment>
                                            ))
                                            }
                                </TableBody>
                            </Table> :

                            <div style={{padding: 20}}>
                                <Typography>Няма резултати</Typography>
                            </div>
                        }
                    </TableContainer>
                </Grid>


            </Grid>


            <MessageDialog
                openAndText={messageDialog}
                handleClose={() => setMessageDialog(null)}
                positiveText={'Добре'}
                positiveAction={() => {
                    setMessageDialog(false)
                }}/>

            <ObjectDataDialog
                contractMode={contractMode}
                open={objectSettings}
                handleClose={() => {
                    setObjectSettings(false);
                    setForceRefresh(Math.random())
                }}
                setObjectSettings={setObjectSettings}
                onClick={addObject}/>









            <MessageDialog
                openAndText={deleteDialogOpen ? deleteDialogOpen.text : null}
                handleClose={() => setDeleteDialogOpen(null)}
                positiveText={'Изтрий обекта'}
                positiveAction={() => {
                    setDeleteDialogOpen2({
                            id: deleteDialogOpen ? deleteDialogOpen.id : null,
                            text: 'Внимание! Този обект ще бъде загубен за винаги! Сигурни ли сте, че искате да го унищожите?'
                        }
                    )
                    setDeleteDialogOpen(false)
                }}
                negativeText={'Отказ'}
                negativeAction={() => {
                    setDeleteDialogOpen(false)
                }}/>

            <MessageDialog
                openAndText={deleteDialogOpen2 ? deleteDialogOpen2.text : null}
                handleClose={() => setDeleteDialogOpen2(null)}
                positiveText={'Изтрий ЗАВИНАГИ!'}
                dangerMode
                positiveAction={() => {
                    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects/delete`, {
                        method: "POST",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            'token': accessToken,
                            'id': deleteDialogOpen2 ? deleteDialogOpen2.id : null,
                        })
                    })
                        .then((result) => {
                            if (result) {
                                setForceRefresh(Math.random())
                                setDeleteDialogOpen2(null)
                                if (result.err === "200") {
                                    setMessage('Обектът се използва! Не може да се изтрие!')
                                }
                            }
                        })
                }}
                negativeText={'Отказ'}
                negativeAction={() => {
                    setDeleteDialogOpen2(null)
                }}/>


            <ObjectDataV2Dialog
                contractMode
                open={objectSettings}
                editObject={editDialogOpen}
                handleClose={() => {
                    setObjectSettings(null);
                    setForceRefresh(Math.random())
                }}
                setObjectSettings={setObjectSettings}/>

            <MessageDialog
                openAndText={message}
                handleClose={() => setMessage(null)}
                positiveText={'Добре'}
                positiveAction={() => {
                    setMessage(null)
                }}/>

        </React.Fragment>
    )
}
