import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {CommonFetch} from "../../../services/api-fetch";
import {useLocalStorage} from "../../../useLocalStorage";
import {useNavigate} from "react-router-dom";
import Slide from "@mui/material/Slide";
import AutocompleteUsers from "../../../inputs/AutocompleteUsers";
import FullyManagedAssignExecutorToAnalysisWithoutQtyDialog
    from "../../dialogs/dailyWorks/FullyManagedAssignExecutorToAnalysisWithoutQtyDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AssignExecutorToAnalysisWithoutQtyDailyWeeklyDialog({open, handleClose, onClick, type}) {
    let navigate = useNavigate();

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [executorOpen, setExecutorOpen] = useState(false);
    const [executors, setExecutors] = useState([]);

    const [executorId, setExecutorId] = useState(null);
    const [executor, setExecutor] = useState(null);

    const [executorTyped, setExecutorTyped] = useState(null);






    const [refreshExecList, setRefreshExecList] = useState(100);

    const [editAllowed, setEditAllowed] = useState(false);

    const [assignedPartners, setAssignedPartners] = useState(null);
    const [assignWorkerOpen, setAssignWorkerOpen] = useState(false);
    const [executorsList, setExecutorsList] = useState([]);

    const [addExectuorsOpen, setAddExectuorsOpen] = useState( null);


    const [partners, setPartners] = useState([]);


    const [tempExecutor, setTempExecutor] = useState(null);
    const [tempExecutorId, setTempExecutorId] = useState(null);


    useEffect(() => {
        if (!open) {
            return;
        }

        console.log('type ', type)
        if (type === "done") {

            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getKSS?`+ new URLSearchParams({
                token: accessToken,
                id: open
            }), {
                method: "GET",
            })
                .then(
                    (result) => {
                        const task = result.items[0];


                        if (task.id_partner) {
                            setExecutorTyped(task.name_firm)
                            setExecutor({
                                name_firm: task.name_firm
                            })
                        } else {
                            setExecutorTyped(null)
                            setExecutor(null)
                        }

                        if (task.partners) {
                            console.log('setting task.partners ' + task.partners)
                            let taskPartners = task.partners;
                            for (let i = 0; i < taskPartners.length; i++) {
                                taskPartners[i].id_partner = taskPartners[i].id;
                            }

                            setPartners(taskPartners)
                        } else {
                            setPartners([])
                        }


                    },
                    (error) => {
                        console.log('error: ', error)
                    }
                );


        } else {
            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/get?`+ new URLSearchParams({
                token: accessToken,
                id: open
            }), {
                method: "GET",
            })
                .then(
                    (result) => {
                        const task = result.items[0];


                        if (task.id_partner) {
                            setExecutorTyped(task.name_firm)
                            setExecutor({
                                name_firm: task.name_firm
                            })
                        } else {
                            setExecutorTyped(null)
                            setExecutor(null)
                        }


                        if (task.partners) {
                            console.log('setting task.partners ', task.partners)
                            let taskPartners = task.partners;
                            for (let i = 0; i < taskPartners.length; i++) {
                                taskPartners[i].id_partner = taskPartners[i].id;
                            }

                            setPartners(taskPartners)
                        } else {
                            setPartners([])
                        }

                    },
                    (error) => {
                        console.log('error: ', error)
                    }
                );
        }


    }, [open])



    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/firms?`+ new URLSearchParams({
            token: accessToken,
            ...(executor ? { search: executorTyped } : {}),

        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setExecutors([...results])
                }
            })
    }, [open, executorTyped])


    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography>Назначи изпълнител</Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={1} style={{margin: 10}}>

                <Grid item xs={12}>

                    {
                        partners.length === 0 ?
                            <AutocompleteUsers
                                executor={tempExecutor} setExecutor={setTempExecutor} setExecutorId={setTempExecutorId} onSelect={(prop) => {
                                console.log('проппп ', prop)
                                setPartners([...partners, {
                                    id_partner: prop.id,
                                    name_firm: prop.name_firm
                                }])
                            }
                            }/>
                            :
                            <TextField
                                value={partners ? (partners.length > 1 ? (partners.length + ' работници') : (partners.length === 1 ? partners[0].name_firm : '')) : null}
                                fullWidth onClick={()=> setAddExectuorsOpen(true)} label={'Изпълнител'} contentEditable={false}/>

                    }

                </Grid>


                <Grid item xs={6}>
                    <Button onClick={() => {
                        onClick(partners)
                        setExecutor(null)
                        handleClose()
                    }}>Запази</Button>
                </Grid>
            </Grid>


            <FullyManagedAssignExecutorToAnalysisWithoutQtyDialog
                open={addExectuorsOpen} handleClose={() => setAddExectuorsOpen(false)} onClick={() => {
                console.log('partnersssss ', partners)
                setAddExectuorsOpen(false)
            }}
                partners={partners} setPartners={setPartners}/>


        </Dialog>
    )
}

export default AssignExecutorToAnalysisWithoutQtyDailyWeeklyDialog;