import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import * as React from "react";

function MaterialTable({open, materialDetails, setHistoryDialogForExecutorOpen, style,
                                 setAddExecutorMaterialOpen, setAddExecutorMaterialName, setAddExecutorMaterialContQty}) {
    return (
        <Table aria-label="a dense table" size="small" style={{marginTop: 0}}>
            <TableHead>
                <TableRow>
                    <TableCell width="20%" style={style.tableCell}>Mатериал</TableCell>
                    <TableCell width="10%" style={style.tableCell}>Мярка</TableCell>
                    <TableCell width="20%"
                               style={Object.assign({}, style.tableCell, {})}>К-во по дог.</TableCell>
                    <TableCell width="20%"
                               style={Object.assign({}, style.tableCell, {})}>Дост. к-во</TableCell>
                    <TableCell width="20%"
                               style={Object.assign({}, style.tableCell, {})}>Влож. к-во</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    (materialDetails).map((row, index) => (

                        <React.Fragment>
                            <TableRow
                                key={row.id}
                                style={{height: 40}}
                                onClick={() => {
                                    setHistoryDialogForExecutorOpen({
                                        id_awork: open,
                                        id_partner: row.id_partner
                                    })
                                }}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell width="20%" component="th" scope="row"
                                           style={style.tableCell}>
                                    {row.name}
                                </TableCell>

                                <TableCell width="10%" component="th" scope="row"
                                           style={style.tableCell}>
                                    {row.entity}
                                </TableCell>

                                <TableCell width="20%" component="th" scope="row"
                                           style={style.tableCell}>
                                    {row.total_quantity}
                                </TableCell>

                                <TableCell width="20%" component="th" scope="row"
                                           style={style.tableCell} onClick={(event) => {
                                    event.stopPropagation()
                                    event.nativeEvent.stopImmediatePropagation();
                                    setAddExecutorMaterialOpen(true)
                                    setAddExecutorMaterialName(row.name)
                                    setAddExecutorMaterialContQty(row.total_quantity)
                                }}>
                                    {row.qty_done}
                                </TableCell>

                                <TableCell width="20%" component="th" scope="row"
                                           style={style.tableCell}>
                                    {row.qty_done}
                                </TableCell>

                            </TableRow>

                        </React.Fragment>

                    ))

                }

            </TableBody>
        </Table>
    )
}
export default MaterialTable;