import Grid from "@mui/material/Grid";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import MessageDialog from "../../helper/MessageDialog";
import GraphYearsWeekNumsCalendar from "./GraphYearsWeekNumsCalendar";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import {getGraphicPerViewType} from "../../../utils/NetworkUtils";

export default function GraphYearsCalendar({items, setConfirmMove, tab, setGraphicEditDialog, year, setYear,
                                               selectedMonths, setSelectedMonths,
                                               selectedMonthsTemp, setSelectedMonthsTemp,
                                               selectedWeeks, setSelectedWeeks,
                                               selectedWeeksTemp, setSelectedWeeksTemp,
                                               selectedObject, filterAnex
                                           }) {


    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [yearItems, setYearItems] = useState([{name: 'test'}]);

    const NUM_PERC = '4%';
    const NAME_PERC = '23%';
    const ENTITY_PERC = '3%';
    const QTY_PERC = '3%';
    const ENT_PRICE_PERC = '7%';
    const TOTAL_PERC = '8%';
    const QTY_MONEY = '3%';
    const DAILY_MONEY = '3%';

    const [messageDialog, setMessageDialog] = useState(null);

    function getMonths() {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }

    function getMonthShortName(month) {
        switch (month) {
            case 1:
                return "ян";
            case 2:
                return "фев";
            case 3:
                return "март";
            case 4:
                return "апр";
            case 5:
                return "май";
            case 6:
                return "юни";
            case 7:
                return "юли";
            case 8:
                return "авг";
            case 9:
                return "септ";
            case 10:
                return "окт";
            case 11:
                return "нов";
            case 12:
                return "дек";
        }
    }

    useEffect(() => {
        getGraphicPerViewType(navigate, accessToken, setAccessToken, selectedObject, '2023-1-1', '2023-12-21', 'months')
            .then((result) => {
                if (result && result.items) {

                    for (const resultElement of result.items) {
                        resultElement.name = resultElement.name_group;
                        resultElement.sub = resultElement.subgroups;
                        resultElement.details = resultElement.subgroups;
                        for (const resultElementElement of resultElement.sub) {
                            resultElementElement.name = resultElementElement.name_group;
                            for (const work of resultElementElement.works) {
                                work.name = work.name_work;
                                //work.entity = work.edm;
                                work.quantity = work.qty;
                                work.startDate = work.date_start;
                                work.endDate = work.date_end;
                            }
                        }
                    }

                    //setYearItems(result.items)
                }
            })
    }, [])

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    return (
        selectedMonths.length > 0 ?
            <GraphYearsWeekNumsCalendar
                filterAnex={filterAnex}
                selectedObject={selectedObject}
                year={year} setYear={setYear}
                items={items} setConfirmMove={setConfirmMove} tab={tab} setGraphicEditDialog={setGraphicEditDialog}
                selectedWeeks={selectedWeeks} setSelectedWeeks={setSelectedWeeks}
                selectedWeeksTemp={selectedWeeksTemp} setSelectedWeeksTemp={setSelectedWeeksTemp}
                selectedMonths={selectedMonths} setSelectedMonths={setSelectedMonths} getMonthShortName={getMonthShortName}/>
            :
        <Grid item xs={12}>
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <TableContainer>
                        {yearItems && yearItems.length > 0 ? <Table size={'small'} sx={ {border: 1}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>№</b></TableCell>
                                        <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>Име задача</b></TableCell>
                                        {
                                            getMonths().map((dateInList, index, array) => {
                                                return <TableCell
                                                    style={{padding: 2}}
                                                    onClick={() => {
                                                        if (selectedMonthsTemp.includes(dateInList)) {
                                                            let newSelectedMonths = selectedMonthsTemp.filter(function(item) {
                                                                return item !== dateInList
                                                            })

                                                            console.log('newSelectedMonths ', newSelectedMonths)
                                                            setSelectedMonthsTemp(newSelectedMonths)
                                                        } else {
                                                            if (selectedMonthsTemp.length<=2) {
                                                                setSelectedMonthsTemp([
                                                                    ...selectedMonthsTemp,
                                                                    dateInList
                                                                ])
                                                            } else {
                                                                setMessageDialog('Могат да се избират не повече от 3 месеца');
                                                            }

                                                        }
                                                    }}
                                                    sx={{border: 1, padding: 1, background: selectedMonthsTemp.includes(dateInList) ? '#8888fc' : null
                                                    }} width={DAILY_MONEY} align="center">
                                                    <Typography
                                                        sx={{background: selectedMonths.includes(dateInList) ? '#8888fc' : null}}
                                                        size={'small'}>{getMonthShortName(dateInList)}</Typography>
                                                </TableCell>
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {yearItems && yearItems
                                        .map((row, index) => (
                                            <React.Fragment>
                                                <TableRow
                                                    key={row.no}
                                                >
                                                    <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>{index + 1}</b></TableCell>
                                                    <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{row.name}</b></TableCell>
                                                    {
                                                        (getMonths()).map((dateInList, index, array) => {
                                                            return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center"></TableCell>
                                                        })
                                                    }
                                                </TableRow>

                                            </React.Fragment>

                                        ))}
                                </TableBody>
                            </Table> :

                            <div style={{padding: 20}}>
                                <Typography>Няма резултати</Typography>
                            </div>
                        }
                    </TableContainer>
                </Grid>
            </Grid>


            <MessageDialog
                openAndText={messageDialog}
                handleClose={() => setMessageDialog(null)}
                positiveText={'Добре'}
                positiveAction={() => {
                    setMessageDialog(false)
                }}/>
        </Grid>
    )
}
