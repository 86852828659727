import * as React from 'react';
import {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {CommonFetch} from "../../../services/api-fetch";
import CommentsList from "../../popovers/CommentsList";
import AddDetailDialog from "../../popovers/AddDetailDialog";
import {
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select, Table, TableCell,
    TableHead, TableRow,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
    getActualUsers,
    getWorkingGroupsWithSetter,
    getWorkingTypes
} from "../../../utils/NetworkUtils";
import {AutocompleteProps} from "../../../inputs/AutocompleteProps";
import CircleStatusIcons from "../../helper/CircleStatusIcons";
import FullyManagedAssignExecutorToAnalysisWithoutQtyDialog
    from "../dailyWorks/FullyManagedAssignExecutorToAnalysisWithoutQtyDialog";
import MessageDialog from "../../helper/MessageDialog";
import AutocompleteUsers from "../../../inputs/AutocompleteUsers";
import DuplicateOnForm from "../dailyWorks/objectReportsGraph/DuplicateOnForm";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function CommentsOnlyForDailyTasksDialog({open, handleClose,
                               name, setName, selectedObject, selectedDay, setSelectedDay, onClick,
                                type, setForceRefreshMain
                           }) {
    const NUM_PERC = '4%';
    const NAME_PERC = '23%';
    const ENTITY_PERC = '3%';
    const DAILY_MONEY = '3%';

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [userId, setUserId] = useLocalStorage("userId", null);

    const [tab, setTab] = useState(-1);

    const [comments, setComments] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(null);

    const [addExectuorsOpen, setAddExectuorsOpen] = useState( null);

    //POPOVER
    const [popoverDetails, setPopoverDetails] = React.useState(null);

    // Add detail
    const [showCommentHistory, setShowCommentHistory] = React.useState(false);
    const [addDetailDialogOpen, setAddDetailDialogOpen] = React.useState(false);
    const [comment, setComment] = React.useState(null);
    const [detailDate, setDetailDate] = React.useState(moment(new Date()));
    const [detailHour, setDetailHour] = React.useState(null);
    const [detailQuantity, setDetailQuantity] = React.useState(null);
    // --- Road list
    const [isRoadListOpen, setRoadListOpen] = React.useState(false);
    const [roadAssignee, setRoadAssignee] = React.useState(null);
    const [fuelPrice, setFuelPrice] = React.useState(null);
    const [dashboardStart, setDashboardStart] = React.useState(null);
    const [dashboardEnd, setDashboardEnd] = React.useState(null);



    const [canEdit, setCanEdit] = React.useState(false);
    const [canEditForStatusToggle, setCanEditForStatusToggle] = React.useState(false);

    const [taskState, setTaskState] = React.useState("2");

    const [usersWhichTrack, setUsersWhichTrack] = React.useState([]);
    const [userTracking, setUserTracking] = React.useState([]);


    const [objects, setObjects] = React.useState([]);

    const [executorId, setExecutorId] = useState(null);
    const [executor, setExecutor] = useState(null);
    const [executorOpen, setExecutorOpen] = useState(false);

    const [executorTyped, setExecutorTyped] = useState(null);

    const [executors, setExecutors] = useState([]);

    const [taskProject, setTaskProject] = useState(null);

    const [taskDatePivot, setTaskDatePivot] = useState(null);
    const [taskDate, setTaskDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [duplicateOn, setDuplicateOn] = useState(null);
    const [duplicateOnOpen, setDuplicateOnOpen] = useState(null);

    const [hasWorkOnThatDay, setHasWorkOnThatDay] = useState(null);


    const [repeat, setRepeat] = useState(null);
    const [hour, setHour] = useState(null);
    const [hourEnd, setHourEnd] = useState(null);

    const [datesInWeek, setDatesInWeek] = React.useState([]);

    const [department, setDepartment] = React.useState(null);
    const [about, setAbout] = useState();

    const [subObject, setSubObject] = React.useState(null);
    const [departmentShort, setDepartmentShort] = React.useState(null);
    const [stage, setStage] = React.useState(null);
    const [group, setGroup] = React.useState(null);
    const [workType, setWorkType] = React.useState(null);
    const [workTypeId, setWorkTypeId] = React.useState(null);

    const [stageId, setStageId] = React.useState(null);
    const [stageName, setStageName] = React.useState(null);

    const [groupId, setGroupId] = React.useState(null);
    const [groupName, setGroupName] = React.useState(null);

    const [objectId, setObjectId] = React.useState(null);



    // Datasets
    const [workingGroups, setWorkingGroups] = React.useState([]);
    const [workingTypes, setWorkingTypes] = React.useState([]);
    const [partners, setPartners] = React.useState([]);


    const [objectName, setObjectName] = React.useState();
    const [refreshLastRow, setRefreshLastRow] = React.useState(true);

    const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [displayName, setDisplayName] = useState("");


    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteConfirmationOpen2, setDeleteConfirmationOpen2] = useState(false);

    const [deleteConfirmationForDayOpen, setDeleteConfirmationForDayOpen] = useState(false);
    const [deleteConfirmationForDayOpen2, setDeleteConfirmationForDayOpen2] = useState(false);

    const [tempExecutor, setTempExecutor] = useState(null);
    const [tempExecutorId, setTempExecutorId] = useState(null);

    const [datesRange, setDatesRange] = useState([]);

    function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }
    function getDatesRange(a, b) {
        let dates = [];
        for (var m = moment(a); m.isBefore(b); m.add(1, 'days')) {
            console.log('m is ', m.format('YYYY-MM-DD'));
            dates.push(m.clone())
        }
        console.log('m is ', m.format('YYYY-MM-DD'));
        dates.push(m.clone())

        return dates;

    }

    useEffect(() => {
        if (!open) {
            setCanEdit(false)
            setCanEditForStatusToggle(false)
        }
    }, [open])

    useEffect(() => {
        if (!taskDate) {
            return;
        }
        setTaskDatePivot(getMonday(taskDate.toDate()))
        let startDate = moment(getMonday(taskDate.toDate()));
        console.log('startDate ', startDate)

        let endDate = startDate.clone().add(6, 'days');

        setDatesRange(getDatesRange(startDate, endDate))
    }, [taskDate])

    function moveWeek(backward = false) {
        if (!taskDatePivot) {
            return;
        }
        let startDate = moment(getMonday(taskDatePivot)).add((backward ? -7 : 7), 'days');
        setTaskDatePivot(startDate.toDate())
        console.log('startDate ', startDate)

        let endDate = startDate.clone().add(6, 'days');

        setDatesRange(getDatesRange(startDate, endDate))
    }


    function jsonConcat(o1, o2) {
        for (var key in o2) {
            o1[key] = o2[key];
        }
        return o1;
    }

    useEffect(() => {
        // Paste code to be executed on subsequent renders:
        getWorkingGroupsWithSetter(objectName, navigate, accessToken, setAccessToken, setWorkingGroups)
    }, [objectName]);

    useEffect(() => {
        // Paste code to be executed on subsequent renders:
        if (objectName) {
            getWorkingTypes(objectName, navigate, accessToken, setAccessToken, group ? group : stage ? stage : "0", setWorkingTypes)
        }
    }, [stage, group]);

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/firms?`+ new URLSearchParams({
            token: accessToken,
            ...(executor ? { search: executorTyped } : {}),

        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setExecutors([...results])
                }
            })
    }, [open, executorTyped])


    useEffect(() => {
        getActualUsers(navigate, accessToken, setAccessToken, setUsersWhichTrack)
    }, [selectedDay])

    useEffect(() => {
        if (!open) {
            return;
        }

        console.log('type ', type)
        if (type === "done") {

            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getKSS?`+ new URLSearchParams({
                token: accessToken,
                id: open
            }), {
                method: "GET",
            })
                .then(
                    (result) => {
                        const task = result.items[0];
                        if (task) {
                            let commentsSorted = task.details.sort((a, b) => {
                                return (new Date(b.date_task) - new Date(a.date_task));
                            }).reverse();

                            console.log('commentsSorted ', commentsSorted)
                            setComments(commentsSorted)
                        }


                        if (task.id_project) {
                            setTaskProject(task.id_project)
                        }


                        if (task.id_partner) {
                            setExecutorTyped(task.name_firm)
                            setExecutor({
                                name_firm: task.name_firm
                            })
                        } else {
                            setExecutorTyped(null)
                            setExecutor(null)
                        }

                        if (task.date_start) {
                            setTaskDate(selectedDay)
                        }

                        if (task.date_end) {
                            setEndDate(moment(task.date_end))
                        } else {
                            setEndDate(null)
                        }

                       /* if (task.hour_start) {
                            const hourDate = moment(task.hour_start);
                            if (hourDate) {
                                setHour(hourDate)
                            }
                        } else {
                            setHour(null)
                        }
*/

                        if (task.users) {
                            setUserTracking(task.users)
                        }

                        //setTaskState(task.status + "")

                        if (task.details) {
                            let details = (task.details ?? []).filter(detail => detail.comment === null);
                            setDatesInWeek(details)

                            let statusObject = details.find((obj) => obj.date_task === selectedDay.format('YYYY-MM-DD'));
                            setTaskState(statusObject ? statusObject.status + "" : null)

                        } else {
                            setDatesInWeek(null)
                        }

                        if (task.subject) {
                            setAbout(task.subject)
                        } else {
                            setAbout(null)
                        }

                        if (task.id_work) {
                            setWorkTypeId(task.id_work)
                            setWorkType(task.name_work)
                        } else {
                            setWorkTypeId(null)
                            setWorkType(null)
                        }


                        if (task.id_etap) {
                            setStageId(task.id_etap)
                            setStageName(task.name_etap)
                        } else {
                            setStageId(null)
                            setStageName(null)
                        }

                        if (task.id_aworkgroup) {
                            setGroupId(task.id_aworkgroup)
                            setGroupName(task.name_group)
                        } else {
                            setGroupId(null)
                            setGroupName(null)
                        }


                        if (task.id_project) {
                            setObjectId(task.id_project)
                            setObjectName(task.project_name)
                        } else {
                            setObjectId(null)
                            setObjectName(null)
                        }

                    },
                    (error) => {
                        console.log('error: ', error)
                    }
                );





        } else {
            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/get?`+ new URLSearchParams({
                token: accessToken,
                id: open
            }), {
                method: "GET",
            })
                .then(
                    (result) => {
                        const task = result.items[0];
                        if (task) {
                            let commentsSorted = task.details.sort((a, b) => {
                                return new Date(b.date_task) - new Date(a.date_task);
                            }).reverse();

                            console.log('commentsSorted ', commentsSorted)
                            setComments(commentsSorted)
                        }


                        if (task.id_project) {
                            setTaskProject(task.id_project)
                        }

                        if (task.id_partner) {
                            setExecutorTyped(task.name_firm)
                            setExecutor({
                                name_firm: task.name_firm
                            })
                        } else {
                            setExecutorTyped(null)
                            setExecutor(null)
                        }

                        if (task.date_start) {
                            console.log('selectedDay ' , selectedDay)
                            if (selectedDay) {
                                setTaskDate(selectedDay)
                            } else {
                                let dateToSet = moment(task.date_start, 'YYYY-MM-DD')
                                console.log('selectedDay  dateToSet ' , dateToSet)

                                setTaskDate(dateToSet)
                            }
                        }

                        if (task.date_end) {
                            setEndDate(moment(task.date_end))
                        } else {
                            setEndDate(null)
                        }

                        /*console.log('task.hour_start ', task.hour_start)
                        if (task.hour_start) {
                            const hourDate = moment(task.hour_start);
                            if (hourDate) {
                                setHour(hourDate)
                            }
                        } else {
                            setHour(null)
                        }
                        if (task.hour_end) {
                            const hourDate = moment(task.hour_end);
                            if (hourDate) {
                                setHourEnd(hourDate)
                            }
                        } else {
                            setHourEnd(null)
                        }*/

                        if (task.id_work) {
                            setWorkTypeId(task.id_work)
                            setWorkType(task.name_work)
                        } else {
                            setWorkTypeId(null)
                            setWorkType(null)
                        }


                        if (task.id_etap) {
                            setStageId(task.id_etap)
                            setStageName(task.name_etap)
                        } else {
                            setStageId(null)
                            setStageName(null)
                        }

                        if (task.id_aworkgroup) {
                            setGroupId(task.id_aworkgroup)
                            setGroupName(task.name_group)
                        } else {
                            setGroupId(null)
                            setGroupName(null)
                        }

                        if (task.id_project) {
                            setObjectId(task.id_project)
                            setObjectName(task.project_name)
                        } else {
                            setObjectId(null)
                            setObjectName(null)
                        }




                        if (task.users) {
                            setUserTracking(task.users)
                        } else {
                            setUserTracking([])
                        }

                        //setTaskState(task.status + "")


                        if (task.details) {
                            let details = (task.details ?? []).filter(detail => detail.comment === null);
                            setDatesInWeek(details)

                            let statusObject = details.find((obj) => obj.date_task === selectedDay.format('YYYY-MM-DD'));
                            setTaskState(statusObject ? statusObject.status + "" : null)
                        } else {
                            setDatesInWeek(null)
                        }

                        if (task.subject) {
                            setAbout(task.subject)
                        } else {
                            setAbout(null)
                        }

                    },
                    (error) => {
                        console.log('error: ', error)
                    }
                );
        }



        if (selectedDay) {
            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getStatus?`+ new URLSearchParams({
                token: accessToken,
                id_task: open,
                type: type,
                date: selectedDay.format('YYYY-MM-DD')
            }), {
                method: "GET",
            }).then((result) => {

                if (result && result.items) {
                    let status = result.items;

                    setHasWorkOnThatDay(status.status)

                    if (status.hour_start) {
                        const hourDate = moment(status.hour_start);
                        if (hourDate) {
                            setHour(hourDate)
                        }
                    } else {
                        setHour(null)
                    }
                    if (status.hour_end) {
                        const hourDate = moment(status.hour_end);
                        if (hourDate) {
                            setHourEnd(hourDate)
                        }
                    } else {
                        setHourEnd(null)
                    }




                    if (status.partners) {
                        console.log('setting task.partners ', status.partners)
                        let taskPartners = status.partners;
                        for (let i = 0; i < taskPartners.length; i++) {
                            taskPartners[i].id_partner = taskPartners[i].id;
                        }

                        setPartners(taskPartners)
                    } else {
                        setPartners([])
                    }
                } else {
                    setHasWorkOnThatDay(null)

                    setHour(null)

                    setHourEnd(null)

                    setPartners([])

                }
            },
            (error) => {
                console.log('error: ', error)
                setHasWorkOnThatDay(null)

                setHour(null)

                setHourEnd(null)

                setPartners([])

            })
        }



    }, [open, tab, forceRefresh, selectedDay])



    const style = {
        tableCell: {
            borderRightWidth: '0.5px',
            borderRightStyle: "solid",
            borderRightColor: "#969696",
            display: "tableRowGroup"
        }
    };


    const editTask = (taskId) => {
        let userIds = userTracking ? userTracking.map((obj) => { return { "user_id": obj.id } } ) : [];

        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/update`, {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "id": taskId,
                "id_user": userTracking && userTracking.length > 0  && userTracking[0] && userTracking[0].user_id ? userTracking[0].user_id : userId,
                "id_project": taskProject,
                "id_etap": stageId ? stageId : null,
                "id_aworkgroup": groupId ? groupId : null,
                "id_work": workTypeId ? workTypeId : null,
                "id_partner": executor ? executor.id : null,
                "partners": partners,
                "subject": about,
                "status": taskState,
                "date_start": taskDate ? taskDate.format('YYYY-MM-DD') : null,
                "date_end": endDate ? endDate.format('YYYY-MM-DD') : null,
                "task_date_start": taskDate && endDate ? taskDate.format('YYYY-MM-DD') : null,
                "task_date_end": endDate ? endDate.format('YYYY-MM-DD') : null,
                //"duplicate_on": duplicateOn ? duplicateOn.format('YYYY-MM-DD') : null,
                "hour_start": hour ? hour.format('HH:mm') : null,
                "hour_end": hourEnd ? hourEnd.format("HH:mm") : null,
                "users": userIds,
                "token": accessToken
            })
        })
            .then(
                (result) => {
                    setForceRefresh(Math.random())
                    setForceRefreshMain(Math.random())
                    //handleClose()
                    console.log(result)
                },
                (error) => {
                    console.log('error: ', error)
                }
            );


        let newPartners = partners;

        // ако няма изпълнение в дадения ден, да намери първия ден, в който е работено, ако има такъв
        if (!hasWorkOnThatDay) {

            (datesRange ?? []).map((dateInList, index, array) => {
                let workDates = datesInWeek ?? [];
                let statusObjectForDateIndex = workDates.findIndex((obj) => obj.date_task === moment(dateInList).format('YYYY-MM-DD'));

                let partnersForDay = statusObjectForDateIndex > -1 ? workDates[statusObjectForDateIndex].partners : null;
                if (partnersForDay) {
                    for (const partnersForDayElement of partnersForDay) {
                        partnersForDayElement.id_partner = partnersForDayElement.id
                    }
                    console.log('partnersForDay ', partnersForDay)
                    newPartners = partnersForDay;
                }
            })
        }

        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/setStatus`, {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...(type === 'task' ? { id_task: open } : { id_awork: open}),
                "date": selectedDay.format('YYYY-MM-DD'),
                "status": taskState,
                "type": type,
                "hour_start":hour ? hour.format('HH:mm') : null,
                "hour_end":hourEnd ? hourEnd.format("HH:mm") : null,
                "partners":newPartners,
                token: accessToken
            })
        }).then(() => {
            setForceRefresh(Math.random())
            //handleClose()
        });
    }

    function updateStatus(taskId) {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/setStatus`, {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...(type === 'task' ? { id_task: open } : { id_awork: open}),
                "date": duplicateOn.format('YYYY-MM-DD'),
                "status": "2",
                "type": type,
                "hour_start":hour ? hour.format('HH:mm') : null,
                "hour_end":hourEnd ? hourEnd.format("HH:mm") : null,
                "partners":partners,
                token: accessToken
            })
        }).then(() => {
            setForceRefreshMain(Math.random())
            setForceRefresh(Math.random())
            //handleClose()
        });
    }


    function deleteTask(taskId) {
        CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/tasks/delete?`+ new URLSearchParams({
            token: accessToken,
            id: taskId
        }), {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(() => {
                setForceRefresh(Math.random())
                setForceRefreshMain(Math.random())
                handleClose()
            });
    }


    function deleteTaskForDay(taskId) {
        return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/deleteStatus`, {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...(type === 'task' ? { id_task: open } : { id_awork: open } ),
                "date": selectedDay.format('YYYY-MM-DD'),
                "type": type,
                token: accessToken
            })
        }).then(() => {
            setForceRefresh(Math.random())
            setForceRefreshMain(Math.random())
            //handleClose()
        });
    }

    return  (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth={'md'}
            TransitionComponent={Transition}
        >

            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {objectName} - {name}
                    </Typography>


                </Toolbar>
            </AppBar>

            <Grid container padding={3} spacing={1}>


                <Grid item xs={8}>
                    <ToggleButtonGroup
                        color="primary"
                        value={taskState}
                        size={'small'}
                        exclusive
                        onChange={(event, newState) => {
                            setTaskState(newState);
                            setCanEditForStatusToggle(true)
                        }}
                        aria-label="task_state"
                    >
                        <ToggleButton value="2"><CircleIcon style={{color: '#88c7fa'}}/>За работа</ToggleButton>
                        <ToggleButton value="0"><CircleIcon style={{color: '#f2c97f'}}/>В работа</ToggleButton>
                        <ToggleButton value="1"><CircleIcon style={{color: '#00e533'}}/>Приключена</ToggleButton>
                        <ToggleButton value="3"><CircleIcon style={{color: 'red'}}/>Важно</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

                <Grid item xs={5}>
                    <TextField
                        value={objectName} fullWidth label={'Обект'} InputLabelProps={{ shrink: objectName }}/>
                </Grid>

                <Grid item xs={5}>
                    <AutocompleteProps
                        autocompleteProperty={subObject}
                        dataset={[]}
                        setAutocompleteProperty={setSubObject}
                        restApiAddPropsFunction={(name) => {

                        }}
                        title={"Подобект"}
                        propName={"name_object"}
                        additional={{
                            disabled: !objectName
                        }}/>
                </Grid>


                <Grid item xs={5} >
                    <TextField
                        fullWidth
                        value={stageName}
                        label={"Етап"}
                        InputLabelProps={{ shrink: stageName }}
                    />
                </Grid>

                <Grid item xs={5} >
                    <TextField
                        fullWidth
                        value={groupName}
                        label={"Група"}
                        InputLabelProps={{ shrink: groupName }}
                    />
                </Grid>



                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        value={workType}
                        label={"Вид работа"}
                        InputLabelProps={{ shrink: workType }}
                    />
                </Grid>


                <Grid item xs={5}>
                    <TextField
                        required
                        id="description"
                        label="Задача"
                        fullWidth
                        multiline
                        disabled={!canEdit}
                        InputLabelProps={{ shrink: about !== null && about !== "" }}
                        onChange={(event) => {
                            setAbout(event.target.value)
                        }}
                        InputProps={{
                            endAdornment: (
                                about && taskState && Number(taskState) >= 0 ? <CircleStatusIcons style={{marginRight: -10}} size={20} taskState={taskState}/> : <React.Fragment>
                                </React.Fragment>
                            ),
                        }}
                        value={about}
                        variant="outlined"
                    />
                </Grid>

                {/* <Grid item xs={8}>
                    <AutocompleteWorks taskState={taskState} autoCompleteOpen={autoCompleteWorkOpen} setAutoCompleteOpen={(bool) => {
                        setAutoCompleteWorkOpen(bool)

                    }}
                                       onSelect={(name) => {setWorkName(name)}}
                                       stocksDataSet={[...taskDone, ...taskInWork,...waitTask, ...worksDataStock]}
                                       loading={false} editId={null}
                                       setWorkName={setWorkName} workName={workName} setLoading={setLoadingWork}
                                       displayName={displayNameWork} setDisplayName={setDisplayNameWork} makeAdding/>
                </Grid>*/}
                {/*<Grid item xs={1}>
                    {
                        isPriority ? <CircleIcon style={{color: 'red'}}/> : null
                    }
                </Grid>*/}



                <Grid item xs={5}>
                    {
                        partners.length === 0 ?
                            <AutocompleteUsers
                                idObject={selectedObject}
                                additional={{disabled: !canEdit}}
                                executor={tempExecutor} setExecutor={setTempExecutor} setExecutorId={setTempExecutorId} onSelect={(prop) => {
                                console.log('проппп ', prop)
                                setPartners([...partners, {
                                    id_partner: prop.id,
                                    name_firm: prop.name_firm
                                }])
                            }
                            }/>
                            :
                            <TextField
                                disabled={!canEdit}
                                value={partners ? (partners.length > 1 ? (partners.length + ' работници') : (partners.length === 1 ? (partners[0].name_firm || partners[0].partner_name) : '')) : null}
                                fullWidth onClick={()=> setAddExectuorsOpen(true)} label={'Изпълнител'} contentEditable={false}/>

                    }
                </Grid>
{/*
                <Grid item xs={5}>
                    <Autocomplete
                        id="contragents"
                        freeSolo
                        open={executorOpen}
                        onOpen={() => {
                            setExecutorOpen(true);
                        }}
                        onClose={() => {
                            setExecutorOpen(false);
                        }}
                        value={executor ? executor && executor.name_firm ? executor : {name_firm: executor} : null}
                        filterOptions={(options, params) => {
                            let newOptions = options.filter((option) => {
                                if (!option || !option.name_firm || !executor || !executorTyped) {
                                    return false;
                                }
                                return option.name_firm.toLowerCase().includes(executorTyped ? executorTyped.toLowerCase() : "")
                            })

                            const { inputValue } = params;

                            // Suggest the creation of a new value
                            const isExisting = newOptions.length > 0;
                            if (inputValue !== '' && !isExisting) {
                                newOptions.push({
                                    inputValue,
                                    name_firm: `Добави "${inputValue}"`,
                                });
                            }

                            return newOptions;
                        }}
                        onChange={(event, prop) => {
                            console.log('prop ', prop)
                            if (prop) {
                                console.log(prop)
                                setExecutorId(prop && prop.id ? prop.id : prop)
                                setExecutor(prop && prop.name ? prop.name : prop)

                            } else {
                                setExecutor("")
                            }
                        }}
                        onInput={(event) => {
                            setExecutorTyped(event.target.value)
                        }}
                        getOptionLabel={(option) => option.name_firm}
                        options={executors}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Изпълнител"
                                value={executorTyped}
                                onChange={(e) => {
                                    setExecutor(e.target.value)
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
*/}

                <Grid item xs={5} >
                    <Autocomplete
                        id="trackingUser"
                        label="Видим за"
                        fullWidth
                        multiple
                        disabled={!canEdit}
                        onChange={(event, newValue) => {
                            console.log(newValue)
                            setUserTracking(newValue);
                        }}

                        value={userTracking}

                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option.full_name} {...getTagProps({ index })} />
                            ))
                        }
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.full_name}

                        isOptionEqualToValue={(option, value) => {
                            return option.id === value.id
                        }}

                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.full_name}
                            </li>
                        )}

                        options={usersWhichTrack.map((option) => option)}
                        variant="outlined"

                        renderInput={(params) => <TextField {...params} label="Видим за"
                                                            variant="outlined"/>}
                    />
                </Grid>

                <Grid item xs={3.33}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                        <DatePicker
                            label={'От'}
                            disabled={!canEdit}
                            value={taskDate}
                            slotProps={{ textField: { fullWidth: true } }}
                            onChange={(newValue) => setTaskDate(newValue)}
                            views={['year', 'month', 'day']}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3.33}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                        <DatePicker
                            label={'До'}
                            disabled={!canEdit}
                            value={endDate}
                            slotProps={{ textField: { fullWidth: true } }}
                            onChange={(newValue) => setEndDate(newValue)}
                            views={['year', 'month', 'day']}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={3.33}>
                   {/* <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                        <DatePicker
                            label={'Дублирай на'}
                            disabled={!canEdit}
                            value={duplicateOn}
                            slotProps={{ textField: { fullWidth: true } }}
                            onChange={(newValue) => setDuplicateOn(newValue)}
                            views={['year', 'month', 'day']}
                        />
                    </LocalizationProvider>*/}
                    <Button disabled={!canEdit} onClick={() => setDuplicateOnOpen(open)}>Дублирай на</Button>
                </Grid>



                <Grid item xs={3.33}>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                            <TimePicker
                                id="hour"
                                label="Час от"
                                type="time"
                                ampm={false}
                                disabled={!canEdit}
                                value={hour}
                                InputLabelProps={{ shrink: true }}
                                onChange={(newValue) => {
                                    setHour(newValue)
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>




                <Grid item xs={3.33}>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                            <TimePicker
                                id="hour"
                                label="Час до"
                                type="time"
                                ampm={false}
                                disabled={!canEdit}
                                value={hourEnd}
                                InputLabelProps={{ shrink: true }}
                                onChange={(newValue) => {
                                    setHourEnd(newValue)
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>


                <Grid item xs={3.33}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Повторяемост</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disabled={!canEdit}
                            value={repeat}
                            label="Повторяемост"
                            onChange={(event) => {
                                setRepeat(event.target.value);
                            }}
                        >

                            <MenuItem value={0}>Няма</MenuItem>
                            <MenuItem value={1}>Всеки ден</MenuItem>
                            <MenuItem value={2}>Всяка седмица</MenuItem>
                            <MenuItem value={3}>Всеки месец</MenuItem>
                            <MenuItem value={4}>Всяка година</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={2}></Grid>
                <Grid item xs={0.5}>
                    <IconButton>
                        <ArrowBackIosIcon onClick={() => moveWeek(true)}/>
                    </IconButton>
                </Grid>
                <Grid item xs={9}>
                    <Table size={'small'} sx={{border: 1}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    (datesRange ?? []).map((dateInList, index, array) => {
                                        let workDates = datesInWeek ?? [];
                                        let statusObjectForDateIndex = workDates.findIndex((obj) => obj.date_task === moment(dateInList).format('YYYY-MM-DD'));

                                        let statusObjectForDate = statusObjectForDateIndex > -1 ? workDates[statusObjectForDateIndex].status : null;


                                        return <TableCell sx={{border: 1, padding: 0,}} width={DAILY_MONEY} align="center"
                                                          onClick={(event) => {
                                                              event.stopPropagation()
                                                              event.nativeEvent.stopImmediatePropagation();
                                                              console.log('dateInList ', dateInList)
                                                              setSelectedDay(dateInList)
                                                          }}>


                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <b>{moment(dateInList).format('dd DD')}</b>

                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CircleStatusIcons taskState={statusObjectForDate + ""}/>

                                                </Grid>
                                            </Grid>



                                        </TableCell>
                                    })
                                }
                            </TableRow>
                        </TableHead>
                    </Table>
                </Grid>
                <Grid item xs={0.5}>
                    <IconButton onClick={() => moveWeek()}>
                        <ArrowForwardIosIcon/>
                    </IconButton>
                </Grid>


                {/*Edit end*/}


                {!canEdit ? <Grid item xs={12}>
                    <CommentsList popoverDetails={comments} setForceRefresh={setForceRefresh}
                                  taskOrAwork={type}
                                  filterByDate={selectedDay} showCommentHistory={showCommentHistory} taskId={open}/>
                </Grid> : null}

                {!canEdit ? <Grid item>
                    <Button variant={'contained'} onClick={() => {
                        setCanEdit(!canEdit)
                    }}>
                        Редактирай
                    </Button>
                </Grid> : <Grid item xs={1}></Grid> }

                {(canEdit || canEditForStatusToggle) ? <Grid item>
                    <Button variant={'contained'} onClick={() => {
                        setCanEditForStatusToggle(false)
                        editTask(open)
                    }}>
                        Запис
                    </Button>
                </Grid> : null}

                {canEdit ? <Grid item>
                    <Button variant={'contained'} onClick={() => {
                        setDeleteConfirmationOpen("Наистина ли искате да изтриете тази задача?")
                    }}>
                        Изтрий
                    </Button>
                </Grid> : null}


                {canEdit ? <Grid item>
                    <Button variant={'contained'} onClick={() => {
                        setDeleteConfirmationForDayOpen("Наистина ли искате да изтриете тази задача за деня?")
                    }}>
                        Изтрий за деня
                    </Button>
                </Grid> : null}

                {!canEdit ? <Grid item>
                    <Button variant={'contained'} onClick={() => {
                        setShowCommentHistory(open)
                    }}>
                        История
                    </Button>
                </Grid> : null}

                {!canEdit ? <Grid item xs={2}>
                    <Button variant={'contained'} onClick={() => {
                        console.log('open ', open)
                        setAddDetailDialogOpen({
                            type: type === 'task' ? 'task' : 'graph',
                            ...type === 'task' ? {taskId: open} : {aworkId: open},
                        })
                    }}>
                        Добави коментар
                    </Button>
                </Grid> : null}


{/*
                <CommentHistoryDialog open={showCommentHistory} handleClose={() => setShowCommentHistory(false)} selectedObject={selectedObject}/>
*/}

                {addDetailDialogOpen ? <AddDetailDialog navigate={navigate}
                                        accessToken={accessToken}
                                        setAccessToken={setAccessToken}
                                        open={addDetailDialogOpen}
                                        handleClose={() => setAddDetailDialogOpen(false)}
                                        comment={comment}
                                        setComment={setComment}
                                        date={selectedDay ? selectedDay : null}
                                        setDate={setDetailDate}
                                        hour={detailHour}
                                        setHour={setDetailHour}
                                        userId={userId}
                                        taskId={open ? open.taskId || open.aworkId : null}
                                        details={popoverDetails}
                                        setPopoverDetails={setPopoverDetails}

                                        isRoadListOpen={isRoadListOpen}
                                        setRoadListOpen={setRoadListOpen}
                                        detailQuantity={detailQuantity}
                                        setDetailQuantity={setDetailQuantity}
                                        roadAssignee={roadAssignee}
                                        setRoadAssignee={setRoadAssignee}
                                        fuelPrice={fuelPrice}
                                        setFuelPrice={setFuelPrice}
                                        dashboardStart={dashboardStart}
                                        setDashboardStart={setDashboardStart}
                                        dashboardEnd={dashboardEnd}
                                        setDashboardEnd={setDashboardEnd}

                                        addDetail={(navigate, accessToken, setAccessToken,
                                                    userId, taskId, date, comment, quantity, hour, roadCard = null, attachment = null) => {
                                            console.log('open.type ', addDetailDialogOpen)

                                            let body = {
                                                token: accessToken,
                                                id_creater: userId,
                                                type: addDetailDialogOpen.type,
                                                ...addDetailDialogOpen.type === 'task' ? {id_task: addDetailDialogOpen.taskId} : {id_awork: addDetailDialogOpen.aworkId},
                                                date_task: date ? date.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                                                date: selectedDay ? selectedDay.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                                                comment: comment,
                                                qty: quantity,
                                                type_task: roadCard ? "3" : (quantity ? "2" : "1"),
                                                hour_start: hour
                                            };

                                            if (roadCard) {
                                                body = jsonConcat(body, roadCard);
                                            }

                                            let formdata = new FormData();

                                            for (let key in body) {
                                                if (body.hasOwnProperty(key)) {
                                                    if (body[key]) {
                                                        formdata.append(key, body[key]);
                                                    }
                                                }
                                            }


                                            if (attachment) {
                                                for (const attachemntItem of attachment) {
                                                    formdata.append("taskfiles[]", attachemntItem, attachemntItem.name);
                                                }
                                            }

                                            console.log('attachment ', attachment)
                                            if (attachment) {
                                                for (const attachemntItem of attachment) {
                                                    formdata.append("image", attachemntItem, attachemntItem.name);
                                                }
                                            }


                                            return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/addComment`, {
                                                method: "POST",
                                                mode: 'cors',
                                                headers: {
                                                },
                                                body: formdata
                                            })
                                                .then(() => {


                                                    if (taskState !== "1") {
                                                        return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/setStatus`, {
                                                            method: "POST",
                                                            mode: 'cors',
                                                            headers: {
                                                                "Content-Type": "application/json",
                                                            },
                                                            body: JSON.stringify({
                                                                type: addDetailDialogOpen.type,
                                                                ...addDetailDialogOpen.type === 'task' ? {id_task: addDetailDialogOpen.taskId} : {id_awork: addDetailDialogOpen.aworkId},
                                                                "date": date.format("YYYY-MM-DD"),
                                                                "status": 0,
                                                                token: accessToken
                                                            })
                                                        }).then(() => {
                                                            setComment("")
                                                            setForceRefresh(Math.random())
                                                            setForceRefreshMain(Math.random())
                                                            setAddDetailDialogOpen(false)
                                                        });
                                                    } else {
                                                        setComment("")
                                                        setForceRefresh(Math.random())
                                                        setForceRefreshMain(Math.random())
                                                        setAddDetailDialogOpen(false)
                                                    }







                                                })
                                        }}/> : null }

            </Grid>


            <FullyManagedAssignExecutorToAnalysisWithoutQtyDialog
                open={addExectuorsOpen} handleClose={() => setAddExectuorsOpen(false)} onClick={() => {
                console.log('partnersssss ', partners)
                setAddExectuorsOpen(false)
            }}
                partners={partners} setPartners={setPartners}/>



            <MessageDialog
                openAndText={deleteConfirmationOpen}
                handleClose={() => setDeleteConfirmationOpen(null)}
                negativeText={'Не'}
                negativeAction={() => {
                    setDeleteConfirmationOpen(null)
                }}
                positiveText={'Да'}
                positiveAction={() => {
                    setDeleteConfirmationOpen(false)
                    setDeleteConfirmationOpen2("Тази задача ще бъде изтрита НАВСЯКЪДЕ! Сигурни ли сте?")
                }}/>

            <MessageDialog
                openAndText={deleteConfirmationForDayOpen}
                handleClose={() => setDeleteConfirmationForDayOpen(null)}
                negativeText={'Не'}
                negativeAction={() => {
                    setDeleteConfirmationForDayOpen(null)
                }}
                positiveText={'Да'}
                positiveAction={() => {
                    setDeleteConfirmationForDayOpen(false)
                    setDeleteConfirmationForDayOpen2("Тази задача ще бъде изтрита ЗА ДЕН " + selectedDay.format("DD.MM.YYYY") + "! Сигурни ли сте?")
                }}/>

            <MessageDialog
                openAndText={deleteConfirmationOpen2}
                dangerMode
                handleClose={() => setDeleteConfirmationOpen2(null)}
                negativeText={'Не'}
                negativeAction={() => {
                    setDeleteConfirmationOpen2(null)
                }}
                positiveText={'Да'}
                positiveAction={() => {
                    setDeleteConfirmationOpen2(null)
                    deleteTask(open)
                }}/>
            <MessageDialog
                openAndText={deleteConfirmationForDayOpen2}
                dangerMode
                handleClose={() => setDeleteConfirmationForDayOpen2(null)}
                negativeText={'Не'}
                negativeAction={() => {
                    setDeleteConfirmationForDayOpen2(null)
                }}
                positiveText={'Да'}
                positiveAction={() => {
                    setCanEdit(false)
                    setCanEditForStatusToggle(false)
                    setDeleteConfirmationForDayOpen2(null)
                    deleteTaskForDay(open)
                }}/>


            <DuplicateOnForm
                open={duplicateOnOpen} handleClose={() => setDuplicateOnOpen(null)}
                updateStatus={updateStatus}
                partners={partners} tempExecutor={tempExecutor} setTempExecutor={setTempExecutor} setTempExecutorId={setTempExecutorId} setPartners={setPartners} setAddExectuorsOpen={setAddExectuorsOpen}
                hour={hour} setHour={setHour}
                hourEnd={hourEnd} setHourEnd={setHourEnd}
                duplicateOn={duplicateOn} setDuplicateOn={setDuplicateOn}/>

        </Dialog>
    )
}
export default CommentsOnlyForDailyTasksDialog;