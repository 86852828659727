import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import {Chip, FormControl, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function RepeatChooserDialog({open, handleClose,
                                 period, setPeriod,
                                 periodMul, setPeriodMult,
                                 repeatOn, setRepeatOn,
                                 endingOption, setEndingOption,
                                 endingDate, setEndingDate,
                                 endingNum, setEndingNum,
                             }) {
    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            maxWidth={'sm'}
            TransitionComponent={Transition}
        >

            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Избери повторяемост
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={1} style={{margin: 10}}>

                <Grid item>
                    <Typography>Повтаряй на всеки</Typography>
                </Grid>
                <Grid item>
                    <TextField label={'брой'}/>
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" shrink={periodMul}>Повторяемост</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={periodMul}
                            label="Повторяемост"
                            onChange={(event) => {
                                setPeriodMult(event.target.value);
                            }}
                        >

                            <MenuItem value={1}>Ден</MenuItem>
                            <MenuItem value={7}>Седмица</MenuItem>
                            <MenuItem value={30}>Месец</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Typography>Повтаряй на</Typography>
                </Grid>


                {open === 2 ? <Grid item xs={12}>
                    {
                        [
                            {id: 1, name: 'пон'},
                            {id: 2, name: 'вто'},
                            {id: 3, name: 'сря'},
                            {id: 4, name: 'чет'},
                            {id: 5, name: 'пет'},
                            {id: 6, name: 'съб'},
                            {id: 7, name: 'нед'},
                        ].map((c) =>
                            <Chip
                                key={c.id}
                                onClick={() => setRepeatOn(c.id)}
                                variant={repeatOn === (c.id) ? "filled" : "outlined"}
                                label={c.name}/>
                        )
                    }

                </Grid>
                    :

                    <Grid item xs={12}>
                        <TextField type={'number'} value={repeatOn} onChange={(e) => {
                            setRepeatOn(e.target.value)
                        }} label={'Ден от месеца'} InputProps={{ inputProps: { min: 0, max: 31 } }}
                        />

                    </Grid>

                }


                <Grid item xs={12}>
                    <Typography>Свършва на</Typography>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={endingOption}
                            onChange={(event) => {
                                setEndingOption(event.target.value);
                            }}
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="0" control={<Radio />} label="Никога" />
                            <FormControlLabel value="1" control={<Radio />} label="На" />
                            <FormControlLabel value="2" control={<Radio />} label="След" />
                        </RadioGroup>
                    </FormControl>

                </Grid>

                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{height: 30}}></Grid>

                        <Grid item xs={8}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">
                                <DatePicker
                                    label={'От'}
                                    value={endingDate}
                                    slotProps={{ textField: { size: 'small' } }}
                                    renderInput={(params) => <TextField size="small" {...params} />}                                    onChange={(newValue) => setEndingDate(newValue)}
                                    views={['year', 'month', 'day']}
                                />
                            </LocalizationProvider>
                        </Grid>



                        <Grid item xs={8}>
                            <TextField label={'Брой'} value={endingNum} size={'small'}
                                       onChange={(e) => setEndingNum(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>


        </Dialog>
    );
}
export default RepeatChooserDialog;