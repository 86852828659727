import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {CommonFetch} from "../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";
import moment from "moment/moment";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EditCommentDialog({open, handleClose, comment, setForceRefresh, additionalData, taskId}) {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [newComment, setNewComment] = useState();
    const [files, setFiles] = useState(null)

    useEffect(() => {
        setNewComment(comment)
    }, [comment])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Редакция на коментар
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={1} style={{margin: 10}}>

                <Grid item xs={10}>
                    <TextField fullWidth multiline label={'Коментар'} value={newComment} onChange={(e) => {
                        setNewComment(e.target.value)
                    }}/>
                </Grid>

                <Grid item xs={3}>
                    <Button
                        variant="outlined"
                        component="label">
                        Прикачи файл
                        <input
                            type="file"
                            onChange={(event => {
                                event.stopPropagation();

                                console.log(event)
                                if (event && event.target && event.target.files && event.target.files.length > 0) {
                                    // Create a new array from the current files
                                    let currentFiles = [];

                                    // Add the new files to the array
                                    let arrayFiles = Array.from(event.target.files);
                                    arrayFiles.forEach(item => {
                                        // if they don't exist yet
                                        if (!currentFiles.some(el => el.name === item.name)) {
                                            currentFiles.push(item)
                                        }
                                    })

                                    // Set the new attachments

                                    for (const file of currentFiles) {
                                        file.id = Math.random();
                                    }
                                    setFiles(currentFiles);


                                }
                            })}
                            hidden
                        />
                    </Button>
                </Grid>

                <Grid item xs={8} alignItems={'center'}>
                    <List key={files ? files.length : -1}>

                        {
                            files && files.map((item, index) => {
                                console.log('item ', item.id)
                                return <ListItem key={item.id}>
                                    <Button  variant="text" onClick={() => {
                                        let attachmentsAfterRemoval = files.filter(attachmentItem => attachmentItem.name !== item.name)

                                        setFiles(attachmentsAfterRemoval)
                                    }}
                                             startIcon={<AttachFileIcon/>}
                                             endIcon={<HighlightOffIcon/>}>
                                        {files ? item.name : ""}
                                    </Button></ListItem>
                            })

                        }
                    </List>

                </Grid>

                <Grid item xs={3}>
                    <Button
                        margin="dense"
                        variant={'contained'}
                        id="done"
                        onClick={(event) =>  {
                            let body = {
                                "id": open,
                                "token": accessToken,
                                id_creater: additionalData.id_creater,
                                type: additionalData.type,
                                id_tasks:taskId,
                                date: additionalData.date_task,
                                date_task: additionalData.date_task,
                                hour_start: additionalData.hour_start,
                                comment: newComment,
                                qty: additionalData.qty
                            };

                            let formdata = new FormData();

                            for (let key in body) {
                                if (body.hasOwnProperty(key)) {
                                    if (body[key]) {
                                        formdata.append(key, body[key]);
                                    }
                                }
                            }

                            console.log('filesss ', files)
                            if (files) {
                                for (const attachemntItem of files) {
                                    formdata.append("image", attachemntItem, attachemntItem.name);
                                }
                            }

                            return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/updateComment`, {
                                method: "POST",
                                mode: 'cors',
                                headers: {
                                },
                                body: formdata
                            })
                                .then(() => {
                                    handleClose()
                                    setForceRefresh(Math.random())
                                })

                        }}
                    >
                        Запази
                    </Button>
                </Grid>

            </Grid>


        </Dialog>
    )
}

export default EditCommentDialog;