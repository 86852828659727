import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import LoginScreen from "./screens/LoginScreen";
import {createTheme, ThemeProvider} from "@mui/material";
import AppBarComponentV2 from "./AppBarComponentV2";
import ReportsScreen from "./screens/ReportsScreen";
import ReportsWeekScreen from "./screens/ReportsWeekScreen";
import ReportsDayScreen from "./screens/ReportsDayScreen";
import ReportsObjectsScreen from "./screens/ReportsObjectsScreen";
import ReportsExecutorsScreen from "./screens/ReportsExecutorsScreen";
import DocsScreen from "./screens/DocsScreen";
import BeginningScreen from "./screens/BeginningScreen";

function App() {
    // Or Create your Own theme:
    const theme = createTheme({
        palette: {
            primary: {
                main: '#94fdf0'
            }
        },
        typography: {
            body1: {
                color: 'black'
            },
            body2: {
                color: 'black'
            },
            body3: {
                color: 'black'
            },
            h1: {
                color: 'black'
            },
            h2: {
                color: 'black'
            },
            h3: {
                color: 'black'
            },
            h6: {
                color: 'black'
            },
            p: {
                color: 'black'
            },
            button: {
                color: 'black'
            }
        }
    });

  return (
      <ThemeProvider theme={theme}>

        <div className="App">
          <Routes>
            <Route exact path="/" element={<Navigate to="/login"/>} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/home" element={<AppBarComponentV2 component={<BeginningScreen/>} selected={0}/>} />
            <Route path="/reports" element={<AppBarComponentV2 component={<ReportsScreen/>} selected={4}/>} />
            <Route path="/reports/week" element={<AppBarComponentV2 component={<ReportsWeekScreen/>} selected={2}/>} />
            <Route path="/reports/day" element={<AppBarComponentV2 component={<ReportsDayScreen/>} selected={3}/>} />
            <Route path="/reports/objects" element={<AppBarComponentV2 component={<ReportsObjectsScreen/>} selected={1}/>} />
            <Route path="/reports/executors" element={<AppBarComponentV2 component={<ReportsExecutorsScreen/>} selected={5}/>} />
            <Route path="/docs" element={<AppBarComponentV2 component={<DocsScreen/>} selected={6}/>} />
           </Routes>
        </div>
      </ThemeProvider>
  );
}

export default App;
