import {CircularProgress, FormHelperText, Grid, TextField} from "@mui/material";
import * as React from "react";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import {useEffect, useState} from "react";

export default function AutocompleteObjects({autoCompleteOpen, setAutoCompleteOpen, stocksDataSet, loading, editId,
                                              setObjectName, objectName, setLoading,
                                              displayName, setDisplayName, makeAdding}) {
    const [shouldShowRazdel, setShouldShowRazdel] = useState(false);
    const [stockRazdel, setStockRazdel] = useState("");

    const filter = createFilterOptions();

    useEffect(() => {
        if (!objectName || !displayName) {
            return;
        }
        if (shouldShowRazdel) {
            console.log('shouldShowRazdel ', stockRazdel)
            if (!displayName.includes(stockRazdel)) {
                const withRazdel = stockRazdel + ' • ' + displayName;
                setDisplayName(withRazdel)
            }
        } else {
            console.log('should not ShowRazdel')
            setDisplayName(displayName.replace((stockRazdel + ' • '), ''))
        }

    }, [shouldShowRazdel])

    return (
        <Grid container>

            <Grid item xs={12}>
                <Autocomplete
                    id="asynchronous-demo"
                    freeSolo
                    open={autoCompleteOpen}
                    onOpen={() => {
                        setAutoCompleteOpen(true);
                    }}
                    onClose={() => {
                        setAutoCompleteOpen(false);
                    }}
                    filterOptions={(options, params) => {
                        let filtered = options;
                        if (makeAdding) {
                            console.log('makeAdding ', makeAdding)
                            filtered = filter(options, params);
                            const { inputValue } = params;

                            // Suggest the creation of a new value
                            const isExisting = options.length > 0;
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name_good: `Добави "${inputValue}"`,
                                });
                            }
                        }
                        return filtered;
                    }}
                    renderOption={(props, option) => (
                        <Grid container {...props}>
                            <Grid item xs={12} style={{width: '100%'}}>
                                <FormHelperText>{option.name_project}</FormHelperText>
                            </Grid>
                            {/*<div>
                                { makeAdding && stocksDataSet.length === 0 ?
                                    option.name_good
                                    :
                                    ((option.name_group ?? '') + ' • ') + option.name_good
                                }
                            </div>*/}
                        </Grid>
                    )}
                    getOptionLabel={(option) => {
                        if (makeAdding) {
                            return option.name_project ? option.name_project.replace("Добави \"", "").replace("\"", "") : '';
                        }
                        return option.name_project;
                    }}
                    options={stocksDataSet}
                    loading={loading}
                    onChange={(event, prop) => {
                        if (editId) {
                            return
                        }
                        if (prop) {
                            console.log('setting prop ', prop)
                            let nameGood = prop.name_project;
                            if (nameGood.includes("Добави \"")) {
                                nameGood = nameGood.replace("Добави \"", "")
                                nameGood = nameGood.replace("\"", "")
                                console.log('setting prop nameGood ', nameGood)

                            }
                            setObjectName(prop)

                        } else {

                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Избери обект"
                            value={objectName || displayName}
                            onChange={(e) => {
                                //setLoading(true)
                                setObjectName(e.target.value)
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>

        </Grid>

    )
}