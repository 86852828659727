import Grid from "@mui/material/Grid";
import {Popover, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {useEffect, useState} from "react";
import {fetchWorksDataStock, getObjects} from "../utils/NetworkUtils";
import {useLocalStorage} from "../useLocalStorage";
import {useNavigate} from "react-router-dom";
import AutocompleteWorks from "../inputs/AutocompleteWorks";
import moment from "moment";
import Typography from "@mui/material/Typography";
import {CommonFetch} from "../services/api-fetch";
import TextField from "@mui/material/TextField";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import AddEditWorkForExecutionWithCombinedHistoryForDailyTasksDialog
    from "../components/dialogs/addDetailContent/AddEditWorkForExecutionWithCombinedHistoryForDailyTasksDialog";
import AddDetailDialog from "../components/dialogs/AddDetailDialog";
import TaskPopover from "../components/popovers/TaskPopover";
import CommentsOnlyForDailyTasksDialog from "../components/dialogs/addDetailContent/CommentsOnlyForDailyTasksDialog";
import AddWorkDialog from "../components/dialogs/dailyWorks/AddWorkDialog";
import CircleStatusIcons from "../components/helper/CircleStatusIcons";
import SetStatusDialog from "../components/graphsComponent/weeklyTasks/SetStatusDialog";
import AssignExecutorToAnalysisWithoutQtyDailyWeeklyDialog
    from "../components/executorDialog/deliverer/AssignExecutorToAnalysisWithoutQtyDailyWeeklyDialog";
import ReportsDayMobileComponent from "../components/tasks/ReportsDayMobileComponent";

function ReportsDayScreen() {
    const NUM_PERC = '2%';
    const NAME_PERC = '55%';
    const ENTITY_PERC = '6%';
    const QTY_PERC = '8%';
    const ENT_PRICE_PERC = '7%';
    const TOTAL_PERC = '8%';

    const [userId, setUserId] = useLocalStorage("userId", null);

    const OBJ_WIDTH = '30%';
    const EXEC_WIDTH = '20%';
    const DAY_WIDTH = '5%';

    const DAYS = ['пон', 'вт', 'ср', 'четв', 'пет', 'съб', 'нед'];

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    let navigate = useNavigate();


    const [searchTerm, setSearchTerm] = useState(null);


    const [pickObjectOpen, setPickObjectOpen] = useState(null);
    const [addDetailDialogOpen, setAddDetailDialogOpen] = React.useState(false);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [items, setItems] = useState([]);



    const [objectChosenToWork, setObjectChosenToWork] = useState(null);


    const [selectedDay, setSelectedDay] = useState(moment());
    const [taskType, setTaskType] = useState(moment());



    const [objectToAddTo, setObjectToAddTo] = useState(null);




    const [objects, setObjects] = React.useState([]);
    const [selectedObject, setSelectedObject] = React.useState(0);
    const [objectName, setObjectName] = React.useState(0);
    const [refreshLastRow, setRefreshLastRow] = React.useState(true);

    const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [displayName, setDisplayName] = useState("");



    const [worksDataStock, setWorksDataStock] = React.useState([]);
    const [autoCompleteWorkOpen, setAutoCompleteWorkOpen] = React.useState(false);
    const [loadingWork, setLoadingWork] = React.useState(false);
    const [displayNameWork, setDisplayNameWork] = useState("");
    const [workName, setWorkName] = useState(0);



    const [executorOpen, setExecutorOpen] = useState(false);
    const [executors, setExecutors] = useState([]);
    const [executorId, setExecutorId] = useState(null);
    const [executor, setExecutor] = useState(null);
    const [taskExecutor, setTaskExecutor] = useState(null);

    const [executorTyped, setExecutorTyped] = useState(null);

    const [assignWorkerOpen, setAssignWorkerOpen] = useState(false);
    const [assignWorkerOnlyOpen, setAssignWorkerOnlyOpen] = useState(false);



    const [commentsOnlyOpen, setCommentsOnlyOpen] = React.useState(null);

    const [executionDotButtonType, setExecutionDotButtonType] = React.useState(null);
    const [emptyAnalysisDialog, setEmptyAnalysisDialog] = React.useState(null);
    const [emptyAnalysisDialogAwork, setEmptyAnalysisDialogAwork] = React.useState(null);
    const [emptyAnalysisDialogStatus, setEmptyAnalysisDialogStatus] = React.useState(null);
    const [forceRefresh, setForceRefresh] = useState(0);

    const [codeDialog, setCodeDialog] = useState(null);
    const [nameAddDialog, setNameAddDialog] = useState(null);
    const [partnersForExecution, setPartnersForExecutuion] = useState(null);
    const [nameObject, setNameObject] = useState(null);
    const [entityAddDialog, setEntityAddDialog] = useState(null);
    const [costRateAddDialog, setCostRateAddDialog] = useState(null);
    const [firaAddDialog, setFiraAddDialog] = useState(null);
    const [entityPriceDialogAddDialog, setEntityPriceDialogAddDialog] = useState(null);
    const [entityPrice, setEntityPrice] = useState(null);
    const [totalPrice, setTotalPrice] = useState(entityPrice);
    const [fira, setFira] = useState(null);

    const [setStatus, setSetStatus] = useState(null);

    const [objectToWorkWith, setObjectToWorkWith] = useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);


    const [addWorkOpen, setAddWorkOpen] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openAddWork = Boolean(anchorEl);
    const id = openAddWork ? 'simple-popover' : undefined;


    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/firms?`+ new URLSearchParams({
            token: accessToken,
            ...(executor ? { search: executorTyped } : {}),

        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setExecutors([...results])
                }
            })
    }, [executorTyped])



    useEffect(() => {
        getObjects(navigate, accessToken, setAccessToken, setObjects, setSelectedObject)
    }, [])

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getbyProject?`+ new URLSearchParams({
            token: accessToken,
            "from_date": selectedDay.format('YYYY-MM-DD'),
            "to_date": selectedDay.format('YYYY-MM-DD')
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.data) {
                    let results = result.data;
                    setItems(results)
                }
            })
    }, [forceRefresh, selectedDay])


    useEffect(() => {
        if (objectName) {
            fetchWorksDataStock({id: objectName.id}, navigate, accessToken, setAccessToken, setWorksDataStock, displayNameWork)
        }
    }, [objectName])

    useEffect(() => {
        console.log('objectName ', objectName)
        if (objectName) {
            let newItems = items;
            newItems[objectName.name_project] = [

            ]
            setItems(newItems)
            setDisplayName(null)
        }

    }, [objectName])


    function createATaskNoKss(task) {
        let userIds = [];

        let status = "0";
        const today = moment(new Date()).startOf('day');


        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/add`, {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: accessToken,
                id: "0",
                id_user: userId,
                id_project: objectToWorkWith,
                id_etap: null,
                id_aworkgroup: "0",
                id_work: "0",
                id_partner: null,
                subject: task,
                status: status,
                date_start: selectedDay.format("YYYY-MM-DD") ,
                date_task_end: toDate ? toDate.format("YYYY-MM-DD") : null,
                id_creater: userId,
                users: userIds
            })
        })
            .then(
                (result) => {
                    setForceRefresh(Math.random)
                    setAnchorEl(null)
                },
                (error) => {
                    console.log('error: ', error)
                }
            );
    }

    const handleWorkSelect = (workName) => {
        console.log('workName ', workName)
        if (workName) {

            if (!workName.id) {
                // call the tasks api
                createATaskNoKss(workName ? workName.name_work : "")


            }  else {
                let newItems = items;
                let taskIndex = newItems.findIndex((task) => {
                    return task.id === objectToAddTo
                })
                newItems[taskIndex].subs = [
                    ...(newItems[taskIndex].subs ?? []),
                    {
                        name: workName.name_work
                    }
                ]
                console.log('setting ', newItems)

                setItems(newItems)
            }


            setWorkName(0)
        }

    }
    let globIndex = 1;

    return (
        <Grid container spacing={1} justifyContent={'flex-start'}>


            <Grid item xs={2}>
                <IconButton onClick={(event) => {
                   /* console.log('items[item] ', items[item])
                    fetchWorksDataStock({id: items[item] && items[item][0] ? items[item][0].id_project : null}, navigate, accessToken, setAccessToken, setWorksDataStock, displayNameWork)
                    setObjectToWorkWith(items[item] && items[item][0] ? items[item][0].id_project : null)
                   */ //handleClick(event)
                    setAddWorkOpen(true)
                }}><AddCircleOutlineIcon/></IconButton>
            </Grid>

            <Grid item sm={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                    <DatePicker
                        label={'Ден'}
                        value={selectedDay}
                        onChange={(newValue) => setSelectedDay(newValue)}
                        views={['year', 'month', 'day']}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={11} md={6}>
                <TextField value={searchTerm} onChange={(e) => {
                    setSearchTerm(e.target.value)
                }} fullWidth label={'Търсене'} InputProps={{
                    startAdornment:
                        <IconButton><SearchIcon/></IconButton>,
                }}/>
            </Grid>


            <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' }}}>
                <ReportsDayMobileComponent
                    setExecutionDotButtonType={setExecutionDotButtonType}
                    items={items} setTaskType={setTaskType} setCommentsOnlyOpen={setCommentsOnlyOpen} setNameAddDialog={setNameAddDialog}
                    setNameObject={setNameObject} searchTerm={searchTerm} setSetStatus={setSetStatus} selectedDay={selectedDay} setAssignWorkerOnlyOpen={setAssignWorkerOnlyOpen}
                    setEmptyAnalysisDialog={setEmptyAnalysisDialog} setEntityAddDialog={setEntityAddDialog} setTotalPrice={setTotalPrice} setEmptyAnalysisDialogStatus={setEmptyAnalysisDialogStatus}/>
            </Grid>


            <Grid item xs={12} sx={{ display: { xs: 'none', md: 'block' }}}>
                {items && Object.keys(items).length > 0 ?
                    <Table size={'small'} sx={{border: 1}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{border: 1}} width={NUM_PERC}
                                           align="left"><b>№</b></TableCell>
                                <TableCell sx={{border: 1}} width={NAME_PERC}
                                           align="left"><b>Обект - задача</b></TableCell>
                                <TableCell sx={{border: 1}} width={ENTITY_PERC}
                                           align="center"><b>Изпълн.</b></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { items && Object.keys(items).map((item, index) =>
                            items && items[item] && items[item]
                                    .filter((subItem) => {
                                        if (!searchTerm) {
                                            return true;
                                        }
                                        let string = `${item} - ${subItem.hour_start ? moment(subItem.hour_start, 'YYYY-MM-DD HH:mm').format('HH:mm') + " - ": null}
                                        ${subItem.hour_end ? moment(subItem.hour_end, 'YYYY-MM-DD HH:mm').format('HH:mm') + " - ": null}
                                        ${subItem.name_group ? (subItem.name_group + ' - ') : null}${subItem.name_work ? (subItem.name_work + ' - ') : null}${subItem.subject}`
                                        return string ? string.toUpperCase().includes(searchTerm.toUpperCase()) : false;

                                    })
                                    .map((subItem) => {
                                    return <React.Fragment>
                                        <TableRow
                                            key={globIndex}
                                        >
                                            <TableCell sx={{border: 1}} width={NUM_PERC}
                                                       align="left">

                                                <Grid item onClick={(e) => {


                                                    setSetStatus({
                                                        id_task: subItem.id_task,
                                                        id_awork: subItem.id_awork,
                                                        name: subItem.name_work ? (subItem.name_work + ' - ') : null + subItem.subject,
                                                        partners: subItem.partners,
                                                        hour: subItem.hour,
                                                        hour_end: subItem.hour_end,
                                                        date: selectedDay.format('YYYY-MM-DD'),
                                                        "type": subItem.type === "task" ? "task" : "graph"
                                                    })


                                                }}>
                                                    <div>
                                                        <div style={{
                                                            position: 'relative',
                                                            left: '0px',
                                                            top: '0px'}}>
                                                              <span style={{position: 'absolute',
                                                                  top: '50%',
                                                                  left: '50%',
                                                                  transform: 'translate(-50%, -50%)',

                                                                  zIndex: 2
                                                              }}>
                                                                    <CircleStatusIcons taskState={subItem.type === 'graph' ? "0" : subItem.status + ""}/>
                                                              </span>


                                                            {subItem.comments > 0 ? <span style={{
                                                                position: 'absolute',
                                                                top: '51%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -48%)',

                                                                zIndex: 2
                                                            }}>
                                                                    <p style={{fontSize: 20}}><b>*</b></p>
                                                                </span> : null}

                                                        </div>
                                                    </div>
                                                </Grid>

                                            </TableCell>
                                            <TableCell sx={{border: 1}} width={NAME_PERC}
                                                       style={{padding: 0}}
                                                       align="left" onClick={() => {
                                                if (subItem.id_task) {
                                                    setTaskType("task")

                                                    setCommentsOnlyOpen(subItem.id_task)
                                                    setNameAddDialog(subItem.name_work)
                                                    setNameObject(item)
                                                } else {
                                                    setTaskType("done")
                                                    setCommentsOnlyOpen(subItem.id_awork)
                                                    setNameAddDialog(subItem.name_work)
                                                    setNameObject(item)
                                                }
                                            }}>
                                                <Grid container justifyContent={'space-between'}>
                                                    <Grid item xs={12} style={{margin: 9}}>
                                                        <Typography><b>{item} - {subItem.hour_start ? moment(subItem.hour_start, 'YYYY-MM-DD HH:mm').format('HH:mm') + " - ": null}
                                                            {subItem.hour_end ? moment(subItem.hour_end, 'YYYY-MM-DD HH:mm').format('HH:mm') + " - ": null}
                                                            {subItem.name_etap ? (subItem.name_etap + ' - ') : null}
                                                            {subItem.name_group ? (subItem.name_group + ' - ') : null}{subItem.name_work ? (subItem.name_work + ' - ') : null}{subItem.subject}</b></Typography>
                                                    </Grid>

                                                </Grid>
                                            </TableCell>



                                            <TableCell sx={{border: 1}} width={ENTITY_PERC}
                                                       align="left"
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            e.nativeEvent.stopImmediatePropagation();

                                                            if (subItem.id_task) {
                                                                setExecutionDotButtonType('task')
                                                                setEmptyAnalysisDialog(subItem.id_task)
                                                                setEmptyAnalysisDialogAwork(subItem.id_awork)
                                                                setEmptyAnalysisDialogStatus(subItem.status)
                                                                setNameAddDialog(subItem.name_work)
                                                                setPartnersForExecutuion(subItem.partners)
                                                                setTaskExecutor(subItem.partners && subItem.partners.length>0 ? subItem.partners[0] : null)

                                                            } else {
                                                                setExecutionDotButtonType('work')
                                                                CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/getSingleKSS?`+ new URLSearchParams({
                                                                    token: accessToken,
                                                                    id: subItem.id_awork
                                                                }), {
                                                                    method: "GET",
                                                                    mode: 'cors',
                                                                    headers: {
                                                                        "Content-Type": "application/json",
                                                                    }
                                                                })
                                                                    .then((result) => {
                                                                            if (result && result.items && result.items[0]) {
                                                                                setEmptyAnalysisDialog(subItem.id_awork)
                                                                                setEmptyAnalysisDialogAwork(subItem.id_awork)
                                                                                setEmptyAnalysisDialogStatus(subItem.status)

                                                                                console.log('settin g , ', result.items[0])
                                                                                setNameAddDialog(result.items[0].name_work)
                                                                                setEntityAddDialog(result.items[0].edm)
                                                                                setTotalPrice(result.items[0].qty)
                                                                                setPartnersForExecutuion(result.items[0].partners_bywork)
                                                                                setTaskExecutor(null)
                                                                            }

                                                                        },
                                                                        (error) => {
                                                                        })
                                                            }
                                                        }}

                                            >   {subItem.partners && subItem.partners.length>0 ?
                                                <Tooltip
                                                    classes={{ tooltip: {
                                                            backgroundColor: 'rgba(126,255,248,0.7)'
                                                        } }}
                                                    title={
                                                    <div style={{ whiteSpace: 'pre-line' }}>
                                                        <p style={{fontSize: 15}}>{subItem.partners ?
                                                            (subItem.partners.map( (item) => item.partner_name) ?? []).join('\n')
                                                            : null}</p>
                                                    </div>
                                                }>

                                                    <Typography
                                                        sx={{
                                                            display: '-webkit-box',
                                                            overflow: 'hidden',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: 1,
                                                        }} style={{textAlign: 'left'}}>
                                                        {subItem.partners.length === 1 ?
                                                            ((subItem.partners[0].partner_name ?? " ").split(" ").length > 0 ? (subItem.partners[0].partner_name ?? " ").split(" ")[0] : null)
                                                            : subItem.partners.length + ' изпълнители'}
                                                    </Typography>
                                                </Tooltip>

                                                :

                                                <Button>Отчет</Button>}

                                            </TableCell>

                                        </TableRow>

                                    </React.Fragment>

                                }))
                            }
                        </TableBody>
                    </Table> :

                    <div style={{padding: 20}}>
                        <Typography>Няма резултати</Typography>
                    </div>
                }
            </Grid>



            {false && refreshLastRow && objectName ? <TableRow>
                <TableCell width={OBJ_WIDTH} sx={{border: 0}}>
                    <AutocompleteWorks autoCompleteOpen={autoCompleteWorkOpen}
                                       setAutoCompleteOpen={(bool) => {
                                           setAutoCompleteWorkOpen(bool)
                                           console.log('objectName ', objectName)
                                           if (bool) {
                                               setObjectToAddTo(objectName ? objectName.id : null)
                                           } else {
                                               setObjectToAddTo(null)
                                           }
                                       }}
                                       onSelect={(name) => {
                                           handleWorkSelect(name)
                                           setWorkName(null)
                                           setDisplayName(null)

                                           console.log('work name to null')
                                       }}
                                       stocksDataSet={worksDataStock}
                                       loading={false} editId={null}
                                       setWorkName={setWorkName} workName={workName}
                                       setLoading={setLoadingWork}
                                       displayName={displayNameWork} setDisplayName={setDisplayNameWork}
                                       makeAdding/>
                </TableCell>
                <TableCell width={EXEC_WIDTH} sx={{border: 0}}></TableCell>
                <TableCell width={DAY_WIDTH} sx={{border: 0}}></TableCell>
            </TableRow> : null}

            <Popover
                id={id}
                open={openAddWork}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: -20,
                    horizontal: 'right',

                }}
            >

                <Grid style={{width: 400, height: 70, padding: 10, background: 'transparent'}} container spacing={1}>
                    <Grid item xs={7}>
                        <AutocompleteWorks autoCompleteOpen={autoCompleteWorkOpen}
                                           setAutoCompleteOpen={(bool) => {
                                               setAutoCompleteWorkOpen(bool)
                                               console.log('objectName ', objectName)
                                               if (bool) {
                                                   setObjectToAddTo(objectName ? objectName.id : null)
                                               } else {
                                                   setObjectToAddTo(null)
                                               }
                                           }}
                                           onSelect={(name) => {
                                               handleWorkSelect(name)
                                               setWorkName(null)
                                               setDisplayName(null)

                                               console.log('work name to null')
                                           }}
                                           stocksDataSet={worksDataStock}
                                           loading={false} editId={null}
                                           setWorkName={setWorkName} workName={workName}
                                           setLoading={setLoadingWork}
                                           displayName={displayNameWork} setDisplayName={setDisplayNameWork}
                                           makeAdding/>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant={'contained'} onClick={() => setAddDetailDialogOpen(true)}>Други</Button>

                    </Grid>

                </Grid>
            </Popover>


            { assignWorkerOnlyOpen ? <AssignExecutorToAnalysisWithoutQtyDailyWeeklyDialog
                open={assignWorkerOnlyOpen}
                handleClose={() => setAssignWorkerOnlyOpen(false)}
                type={taskType}
                onClick={(partners) => {

                    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/get?`+ new URLSearchParams({
                        token: accessToken,
                        id: assignWorkerOnlyOpen.id_task
                    }), {
                        method: "GET",
                    })
                        .then((result) => {
                            const task = result.items[0];

                            if (task) {
                                let usersToSet = task.users;
                                if (usersToSet) {
                                    for (let i = 0; i < usersToSet.length; i++) {
                                        usersToSet[i].user_id = usersToSet[i].id
                                    }
                                }


                                CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getStatus?`+ new URLSearchParams({
                                    token: accessToken,
                                    id_task: task.id,
                                    type: 'task',
                                    date: selectedDay.format('YYYY-MM-DD')
                                }), {
                                    method: "GET",
                                }).then((result) => {
                                    let status = result.items ?? {}
                                    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/setStatus`, {
                                        method: "POST",
                                        mode: 'cors',
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                            "id_task": task.id,
                                            "date": selectedDay ? selectedDay.format('YYYY-MM-DD') : null,
                                            "status": status.status,
                                            "type": 'task',
                                            "hour_start": status.hour_start,
                                            "hour_end": status.hour_end,
                                            "partners": partners,
                                            token: accessToken
                                        })
                                    }).then(() => {
                                        setForceRefresh(Math.random())
                                        handleClose()
                                    });

                                })
                                    .catch((e) => {


                                        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/setStatus`, {
                                            method: "POST",
                                            mode: 'cors',
                                            headers: {
                                                "Content-Type": "application/json",
                                            },
                                            body: JSON.stringify({
                                                "id_task": task.id,
                                                "date": selectedDay ? selectedDay.format('YYYY-MM-DD') : null,
                                                "status": 2,
                                                "type": 'task',
                                                "hour_start": null,
                                                "hour_end": null,
                                                "partners": partners,
                                                token: accessToken
                                            })
                                        }).then(() => {
                                            setForceRefresh(Math.random())
                                            handleClose()
                                        });




                                    })

                                /*CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/tasks/update`, {
                                    method: "POST",
                                    mode: 'cors',
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        "id": task.id,
                                        "id_user": task.id_user,
                                        "id_project": task.id_project,
                                        "id_etap": task.id_etap,
                                        "id_aworkgroup": task.id_aworkgroup,
                                        "id_work": task.id_work,
                                        "id_partner": executor ? executor.id : null,
                                        "subject": task.subject,
                                        "status": task.status,
                                        "date_start": task.date_start,
                                        "hour_start": task.hour_start,
                                        "users": usersToSet,
                                        "partners": partners,
                                        "token": accessToken
                                    })
                                })
                                    .then(
                                        (result) => {
                                            console.log(result)
                                            setForceRefresh(Math.random())
                                        },
                                        (error) => {
                                            console.log('error: ', error)
                                        }
                                    );*/
                            }


                                console.log('executor ', executor)
                                setAssignWorkerOnlyOpen(false)
                        })

                }}/> : null }


            {emptyAnalysisDialog ? <AddEditWorkForExecutionWithCombinedHistoryForDailyTasksDialog
                edit
                selectedDay={selectedDay}
                executionDotButtonType={executionDotButtonType}
                selectedObject={selectedObject}
                open={emptyAnalysisDialog}
                idAwork={emptyAnalysisDialogAwork}
                statusChosen={emptyAnalysisDialogStatus}
                handleClose={() => {setEmptyAnalysisDialog(false)}}
                code={codeDialog}
                setCode={setCodeDialog}
                name={nameAddDialog}
                setName={setNameAddDialog}
                entity={entityAddDialog}
                setEntity={setEntityAddDialog}
                costRate={costRateAddDialog}
                setCostRate={setCostRateAddDialog}
                fira={firaAddDialog}
                setFira={setFiraAddDialog}
                entityPrice={entityPriceDialogAddDialog}
                setEntityPrice={setEntityPriceDialogAddDialog}
                partnersForExecution={partnersForExecution}
                totalPrice={totalPrice}
                taskExecutor={taskExecutor}
                executor={executor}
                setExecutor={setExecutor}
                setTotalPrice={setTotalPrice}
                forceRefresh={forceRefresh}
                setForceRefresh={setForceRefresh}
                onClick={(entity) => {


                }}/> : null }

            {commentsOnlyOpen ? <CommentsOnlyForDailyTasksDialog
                open={commentsOnlyOpen}
                name={nameAddDialog}
                objectName={nameObject}
                selectedObject={selectedObject}
                handleClose={() => setCommentsOnlyOpen(false)}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                setForceRefreshMain={setForceRefresh}
                type={taskType}/> : null}

            <AddDetailDialog
                navigate={navigate}
                accessToken={accessToken}
                setAccessToken={setAccessToken}
                open={addDetailDialogOpen}
                handleClose={() => setAddDetailDialogOpen(false)}

            />

            <TaskPopover/>

            <SetStatusDialog
                open={setStatus}
                handleClose={() => {
                    setSetStatus(null)
                }}
                setForceRefresh={setForceRefresh}/>


            {addWorkOpen ? <AddWorkDialog
                open={addWorkOpen} handleClose={() => setAddWorkOpen(false)} selectedDay={selectedDay}
                setForceRefresh={setForceRefresh}/> : null
            }
        </Grid>
    )
}
export default ReportsDayScreen;