import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {useLocalStorage} from "../../useLocalStorage";
import {CommonFetch} from "../../services/api-fetch";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {addObject, editObjectApi} from "../../utils/NetworkUtils";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function ObjectDataV2Dialog({open, handleClose, onClick, setObjectSettings, editObject,
                              setSelectedObjectForOffer, setSelectedObjectForOfferName,
                              objectNameOutside}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [client, setClient] = useState(null);
    const [orderer, setOrderer] = useState(null);
    const [objectName, setObjectName] = useState(null);
    const [address, setAddress] = useState(null);
    const [description, setDescription] = useState(null);
    const [responsible, setResponsible] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [area, setArea] = useState(null);
    const [workingDays, setWorkingDays] = useState(null);

    const [objectStatusEnded, setObjectStatusEnded] = useState(0);

    useEffect(() => {
        if (editObject) {
            console.log('editObject ', editObject)
            setObjectName(editObject.name_project)
        } else {
            setOrderer("")
            setObjectName("")
            setAddress("")
            setDescription("")
            setResponsible("")
            setArea("")
        }

    }, [editObject, open])

    useEffect(() => {
        if (editObject) {
            //editObject.id
            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects/getEx?`+ new URLSearchParams({
                id: editObject.id,
                token: accessToken
            }), {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then((result) => {
                    if (result && result.items) {
                        if (result.items.date_end) {
                            setObjectStatusEnded(1)
                        } else {
                            setObjectStatusEnded(0)
                        }
                        setOrderer(result.items.investor ?? "")
                        setArea(result.items.area ?? "")
                        setAddress(result.items.address)
                        setResponsible(result.items.technical_manager)
                        setWorkingDays(result.items.wrkdays)

                    }
                })
        }

    }, [editObject, open])

    useEffect(() => {
        setObjectName(objectNameOutside)
    }, [objectNameOutside])

    return  (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Данни за обект {editObject ? objectName : null}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container padding={2} spacing={1}>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="orderer"
                        value={orderer}
                        onChange={(event) =>  {setOrderer(event.target.value)}}
                        label="Възложител"
                    />
                </Grid>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="objectName"
                        value={objectName}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) =>  {
                            setObjectName(event.target.value)
                        }}
                        label="Име на обект"
                    />
                </Grid>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="address"
                        value={address}
                        onChange={(event) =>  {setAddress(event.target.value)}}
                        label="Адрес"
                    />
                </Grid>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="description"
                        value={description}
                        onChange={(event) =>  {setDescription(event.target.value)}}
                        label="Описание"
                    />
                </Grid>
                <Grid item xs={6.5}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="responsible"
                        value={responsible}
                        onChange={(event) =>  {setResponsible(event.target.value)}}
                        label="Отговорник"
                    />
                </Grid>
                <Grid item xs={5}>
                </Grid>




                <Grid item xs={2.75}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="from"
                        value={fromDate}
                        onChange={(event) =>  {}
                        }
                        label="От"
                    />
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={2.75}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="to"
                        value={toDate}
                        onChange={(event) =>  {}
                        }
                        label="До"
                    />
                </Grid>





                <Grid item xs={5}>

                </Grid>
                <Grid item xs={2.75}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="area"
                        value={area}
                        InputLabelProps={{ shrink: area }}
                        onChange={(event) =>  {setArea(event.target.value)}}
                        label="Площ (кв. м.)"
                    />
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={2.75}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="workingDays"
                        value={workingDays}
                        onChange={(event) =>  {setWorkingDays(event.target.value)}
                        }
                        label="Работни дни"
                    />
                </Grid>

                <Grid item xs={6.5}>
                    <FormControl fullWidth>
                        <InputLabel id="status-select-label">Статус</InputLabel>
                        <Select
                            labelId="status-select-label"
                            id="status-select"
                            value={objectStatusEnded}
                            label="Статус"
                            onChange={(e) => {
                                setObjectStatusEnded(e.target.value)
                            }}
                        >
                            <MenuItem value={0}>В работа</MenuItem>
                            <MenuItem value={1}>Приключен</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}/>

                <Grid item xs={2.75}>
                    <Button
                        fullWidth
                        variant={'contained'}
                        id={"save"}
                        onClick={(event) =>  {
                            if (editObject) {
                                editObjectApi(navigate, accessToken, setAccessToken,
                                    (editObject ? editObject.id : null), objectName, objectName.substring(0, 2),
                                    orderer, address, area, workingDays, objectStatusEnded, responsible)


                                //projects/setProjectType
                                return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects/setProjectType`, {
                                    method: "POST",
                                    mode: 'cors',
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        "id": editObject.id,
                                        "token": accessToken,
                                        project_type: orderer === null || orderer === "" || orderer.toLowerCase() === "не" ? 0 : 1
                                    })
                                })
                                    .then(() => {
                                        handleClose()
                                    })

                            } else {

                                //likely addObject
                                addObject(navigate, accessToken, setAccessToken,
                                    objectName, objectName.substring(0, 2), orderer)
                                    .then((result) => {
                                        setObjectSettings(false)
                                        handleClose()
                                    })
                            }

                        }}
                    >Запис</Button>
                </Grid>
            </Grid>


        </Dialog>
    )
}
export default ObjectDataV2Dialog;