import {Checkbox, CircularProgress, FormControlLabel, FormHelperText, Grid, TextField} from "@mui/material";
import * as React from "react";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import {useEffect, useState} from "react";

export default function AutocompleteStock({autoCompleteOpen, setAutoCompleteOpen, stocksDataSet, loading, editId,
                                              setStockName, setStockChiffer, setStockEntity, setStockEntityPrice, setQtyAvail,
                                              stockName, setLoading,
                                              displayName, setDisplayName,
                                          makeAdding}) {
    const [shouldShowRazdel, setShouldShowRazdel] = useState(false);
    const [stockRazdel, setStockRazdel] = useState("");

    const filter = createFilterOptions();

    useEffect(() => {
        if (!stockName || !displayName) {
            return;
        }
        if (shouldShowRazdel) {
            console.log('shouldShowRazdel ', stockRazdel)
            if (!displayName.includes(stockRazdel)) {
                const withRazdel = stockRazdel + ' • ' + displayName;
                setDisplayName(withRazdel)
            }
        } else {
            console.log('should not ShowRazdel')
            setDisplayName(displayName.replace((stockRazdel + ' • '), ''))
        }

    }, [shouldShowRazdel])

    return (
        <Grid container>

            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox checked={shouldShowRazdel} onChange={(event) => {
                            setShouldShowRazdel(event.target.checked);
                        }} name="showRazdel" />
                    }
                    label="Покажи раздел"
                />
            </Grid>


            <Grid item xs={12}>
                <Autocomplete
                    id="asynchronous-demo"
                    freeSolo
                    open={autoCompleteOpen}
                    onOpen={() => {
                        setAutoCompleteOpen(true);
                    }}
                    onClose={() => {
                        setAutoCompleteOpen(false);
                    }}
                    filterOptions={(options, params) => {
                        let filtered = options;
                        if (makeAdding) {
                            console.log('makeAdding ', makeAdding)
                            filtered = filter(options, params);
                            const { inputValue } = params;

                            // Suggest the creation of a new value
                            const isExisting = options.length > 0;
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name_good: `Добави "${inputValue}"`,
                                });
                            }
                        }
                        return filtered;
                    }}
                    renderOption={(props, option) => (
                        <Grid container {...props}>
                            <Grid item xs={12} style={{width: '100%'}}>
                                <FormHelperText>{option.code} {option.name_razdel}</FormHelperText>
                            </Grid>
                            <div>
                                { makeAdding && stocksDataSet.length === 0 ?
                                    option.name_good
                                    :
                                    ((option.name_group ?? '') + ' • ') + option.name_good
                                }
                                {makeAdding && stocksDataSet.length === 0 ?
                                    <FormHelperText style={{color: 'red'}}>Няма такава стока в склада</FormHelperText>
                                    : null
                                }

                            </div>
                        </Grid>
                    )}
                    getOptionLabel={(option) => {
                        if (makeAdding) {
                            return option.name_good.replace("Добави \"", "").replace("\"", "");
                        }
                        return option.name_group + ' • ' + option.name_good
                    }}
                    options={stocksDataSet}
                    loading={loading}
                    onChange={(event, prop) => {
                        if (editId) {
                            return
                        }
                        if (prop) {
                            console.log('setting prop ', prop)
                            let nameGood = prop.name_good;
                            if (nameGood.includes("Добави \"")) {
                                nameGood = nameGood.replace("Добави \"", "")
                                nameGood = nameGood.replace("\"", "")
                                console.log('setting prop nameGood ', nameGood)

                            }

                            setStockRazdel(prop.name_razdel)

                            setDisplayName( (shouldShowRazdel ? ((prop.name_razdel ? prop.name_razdel : '') + ' • ') : '') + prop.name_group + ' • ' + nameGood)

                            setStockName(prop && prop.name ? prop.name :
                                prop && nameGood ? nameGood : prop)
                            setStockChiffer(prop ? prop.code : "")
                            setStockEntity(prop ? prop.entity : "")
                            setStockEntityPrice(prop ? prop.price_last : "")
                            setQtyAvail(prop ? prop.qty_avail : "")
                        } else {
                            setStockChiffer('')
                            setStockEntity('')
                            setStockEntityPrice('')
                            setQtyAvail('')
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Търси стока"
                            value={stockName}
                            onChange={(e) => {
                                setLoading(true)
                                setStockName(e.target.value)
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>

            {displayName && stockName ? <Grid item xs={12}>
                <FormHelperText>Ще добавим: {displayName}</FormHelperText>
            </Grid> : null}

        </Grid>

    )
}