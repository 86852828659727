import Grid from "@mui/material/Grid";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import moment from "moment/moment";
import * as React from "react";
import {useEffect} from "react";
import {getGraphicPerViewType} from "../../../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import CircleStatusIcons from "../../helper/CircleStatusIcons";

function GraphicDaysTable({items, tab, setGraphicEditDialog, setConfirmMove, selectedObject, year, selectedWeeks, filterAnex}) {
    const NUM_PERC = '5%';
    const NAME_PERC = '60%';
    const ENTITY_PERC = '8%';
    const QTY_PERC = '7%';
    const ENT_PRICE_PERC = '8%';
    const TOTAL_PERC = '10%';
    const QTY_MONEY = '3%';
    const DAILY_MONEY = '3%';


    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    let startDate = new Date();
    startDate = startDate.setDate(27)

    let endDate = new Date();
    endDate = endDate.setDate(endDate.getDate() + 14)

    const [ranges, setRanges] = React.useState([{
        startDate: startDate,
        endDate: endDate,
        key: 'selection'
    }]);

    function getDates(startDate, stopDate) {
        let dates = [];
        for (const selectedWeek of selectedWeeks) {
            let daysInWeek = getDaysInWeek(selectedWeek, Number(year.format('YYYY')));
            for (const day of daysInWeek) {
                dates.push(moment(day))
            }
        }
        return dates;

    }

    function getDaysInWeek(weekNumber, year) {
        var firstDateOfWeek = getFirstDateOfWeek(weekNumber, year);
        firstDateOfWeek.setDate(firstDateOfWeek.getDate() + 7);

        var days = [];

        for (var i = 0; i < 7; i++) {
            var currentDate = new Date(firstDateOfWeek.getFullYear(), firstDateOfWeek.getMonth(), firstDateOfWeek.getDate() + i);
            days.push(currentDate);
        }

        return days;
    }

    function getFirstDateOfWeek(weekNumber, year) {
        // Create a new date object for January 1st of the specified year
        var firstDayOfYear = new Date(year, 0, 1);

        // Calculate the offset to the first day of the first week
        var offset = firstDayOfYear.getDay() - 1;
        if (offset === -1) {
            offset = 6;  // Adjust for Sunday being the first day of the week
        }

        // Calculate the number of days to add to the first day of the year to get to the first day of the specified week
        var daysToAdd = (weekNumber - 1) * 7 - offset;

        // Create a new date object by adding the calculated number of days to the first day of the year
        return new Date(year, 0, 1 + daysToAdd);
    }


    useEffect(() => {
        if (selectedWeeks.length <= 0) {
            return;
        }

        let firstDate = getFirstDateOfWeek(selectedWeeks[0], Number(year.format('YYYY')))
        let lastDate = getFirstDateOfWeek(selectedWeeks[selectedWeeks.length - 1], Number(year.format('YYYY')));

        getGraphicPerViewType(navigate, accessToken, setAccessToken, selectedObject,
            firstDate.getFullYear() + '-' + (firstDate.getMonth() + 1) + '-' + firstDate.getDate(),
            lastDate.getFullYear() + '-' + (lastDate.getMonth() + 1) + '-' + lastDate.getDate(),
            'days')
            .then((result) => {
                if (result && result.items) {
                    for (const resultElement of result.items) {
                        resultElement.name = resultElement.name_group;
                        resultElement.sub = resultElement.subgroups;
                        resultElement.details = resultElement.subgroups;
                        for (const resultElementElement of resultElement.sub) {
                            resultElementElement.name = resultElementElement.name_group;
                            for (const work of resultElementElement.works) {
                                work.name = work.name_work;
                                //work.entity = work.edm;
                                work.quantity = work.qty;
                                work.startDate = work.date_start;
                                work.endDate = work.date_end;
                            }
                        }
                    }

                    //setYearItems(result.items)
                }
            })
    }, [])


    return (
        <Grid item xs={12}>
            <TableContainer>
                {items && items.length > 0 ? <Table size={'small'} sx={ {border: 1}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>№</b></TableCell>
                                <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>Наименование</b></TableCell>
                                <TableCell sx={{border: 1}} width={'10%'} align="left"><b>Изпълнител</b></TableCell>
                                {
                                    getDates(ranges[0].startDate, ranges[0].endDate).map((dateInList, index, array) => {
                                        return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center">
                                            <b>
                                                {moment(dateInList).format('DD')}
                                            </b>
                                            <br/>
                                            {moment(dateInList).format('dd')}

                                        </TableCell>
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items && items
                                .map((row, index) => (
                                    <React.Fragment>
                                        <TableRow
                                            key={row.no}
                                        >
                                            <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>{index + 1}</b></TableCell>
                                            <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{row.name}</b></TableCell>
                                            <TableCell sx={{border: 1}} width={'10%'} align="left"></TableCell>
                                            {
                                                getDates(ranges[0].startDate, ranges[0].endDate).map((dateInList, index, array) => {
                                                    return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center">
                                                        <CircleStatusIcons taskState={(array.length - 1 === index ? "1" : "2").toString()}/>
                                                    </TableCell>
                                                })
                                            }
                                        </TableRow>

                                        {row && row.details && row.details.map((row, index) => (
                                            <React.Fragment>

                                                <TableRow
                                                    key={row.no}
                                                    style={{background: '#adadad'}}
                                                >
                                                    <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>{index + 1}</b></TableCell>
                                                    <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{row.name_group}</b></TableCell>
                                                    <TableCell sx={{border: 1}} width={ENTITY_PERC} align="center">{row.entity}</TableCell>
                                                    <TableCell sx={{border: 1}} width={QTY_PERC} align="right">{row.qty ? row.qty.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                    <TableCell sx={{border: 1}} width={QTY_MONEY} align="right">{row.daily_money ? row.daily_money.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                    {
                                                        getDates(ranges[0].startDate, ranges[0].endDate).map((dateInList, index, array) => {
                                                            return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center"></TableCell>
                                                        })
                                                    }
                                                </TableRow>

                                                {row && row.works && row.works
                                                    .filter((item) => filterAnex ? item.is_anex !== 1 : true)
                                                    .map((row, index) => (
                                                    <React.Fragment>
                                                        <TableRow
                                                            key={row.no}
                                                            style={tab === 2 && row && row.details ? {background: '#d7d5d7'} : {}}
                                                            onClick={() => {
                                                                /*setExternalWorkId(row.id_awork);
                                                                setEmptyAnalysisDialog(row.id)*/
                                                                setGraphicEditDialog(row)
                                                            }}
                                                        >
                                                            <TableCell sx={{border: 1}} width={NUM_PERC} align="left">{index + 1}</TableCell>
                                                            <TableCell sx={{border: 1}} width={NAME_PERC} align="left">{row.name}</TableCell>
                                                            <TableCell sx={{border: 1}} width={ENTITY_PERC} align="center">{(row.entity || row.edm) ? (row.entity || row.edm).toUpperCase() : ''}</TableCell>
                                                            <TableCell sx={{border: 1}} width={QTY_PERC} align="right">{row.qty ? row.qty.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                            <TableCell sx={{border: 1}} width={QTY_MONEY} align="right">{row.work_dates && row.work_dates.length > 0 ?
                                                                row.work_dates.length : '-'}</TableCell>

                                                            {
                                                                getDates(ranges[0].startDate, ranges[0].endDate).map((dateInList, index, array) => {
                                                                    let workDates = row.work_dates ?? [];

                                                                    return <TableCell sx={{border: 1,
                                                                        background: workDates.includes(moment(dateInList).format('YYYY-MM-DD')) ? '#8888fc' : null
                                                                    }} width={DAILY_MONEY} align="center"
                                                                                      onClick={(event) => {
                                                                                          event.stopPropagation()
                                                                                          event.nativeEvent.stopImmediatePropagation();

                                                                                          setConfirmMove({
                                                                                              id: row.id,
                                                                                              date: moment(dateInList).format('YYYY-MM-DD'),
                                                                                              text: 'Наистина ли искате да изместите дейността \"' + row.name + '\" за ' +  moment(dateInList).format('DD.MM.YYYY')
                                                                                          })
                                                                                      }}>

                                                                    </TableCell>
                                                                })
                                                            }
                                                        </TableRow>


                                                    </React.Fragment>
                                                ))
                                                }
                                            </React.Fragment>
                                        ))
                                        }
                                    </React.Fragment>

                                ))}
                        </TableBody>
                    </Table> :

                    <div style={{padding: 20}}>
                        <Typography>Няма резултати</Typography>
                    </div>
                }
            </TableContainer>

        </Grid>

    )
}
export default GraphicDaysTable;