import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import moment from "moment";
import bg from 'moment/locale/bg'
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import TextField from "@mui/material/TextField";
import {AutocompleteProps} from "../../../inputs/AutocompleteProps";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {addGoods, getGoods, getPartners} from "../../../utils/NetworkUtils";
import AutocompleteStock from "../../../inputs/AutocompleteStock";
import {CommonFetch} from "../../../services/api-fetch";
import Box from "@mui/material/Box";
import {
    Tab,
    Tabs,
    ToggleButton, ToggleButtonGroup
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import AddEditWorkHistoryForExecutorDialog from "../../analysis/FromScratchAnalysisWithExecuting/AddEditWorkHistoryForExecutorDialog";
import AddDetailDialog from "../AddDetailDialog";
import PartnerPickLightweightDialog from "./PartnerPickLightweightDialog";
import PartnerPickLightweightForMaterialDialog from "./PartnerPickLightweightForMaterialDialog";
import TransportTable from "./tables/TransportTable";
import LabourMechServAddTable from "./tables/LabourMechServAddTable";
import MaterialTable from "./tables/MaterialTable";
import PartnerPickForTransportLightweightDialog from "./PartnerPickForTransportLightweightDialog";
import PartnerPickForAdditionalLabourLightweightDialog from "./PartnerPickForAdditionalLabourLightweightDialog";
import CommentHistoryDialog from "../../popovers/commentHistory/CommentHistoryDialog";
import CircleStatusIcons from "../../helper/CircleStatusIcons";
import AdditionalLabourTable from "./tables/AdditionalLabourTable";
import PartnerPickDialog from "../../analysis/FromScratchAnalysisWithExecuting/PartnerPickDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AddEditWorkForExecutionWithCombinedHistoryForDailyTasksDialog({selectedObject, edit, open, handleClose, statusChosen,
                                                                           idAwork,
                                                                           code, setCode,
                               name, setName,
                               entity, setEntity,
                               costRate, setCostRate,
                               totalPrice, setTotalPrice,
                               fira, setFira,
                               executor, setExecutor,
                               taskExecutor,
                               entityPrice, setEntityPrice, partnersForExecution,
                               workQuantity, forceRefresh, setForceRefresh,
                                                              selectedDay, executionDotButtonType,
                           }) {

    const [taskWorkName, setTaskWorkName] = useState("");

    const [displayName, setDisplayName] = useState("");

    const [trudDetails, setTrudDetails] = useState([]);
    const [materialDetails, setMaterialDetails] = useState([]);
    const [uslugiDetails, setUslugiDetails] = useState([]);
    const [mechDetails, setMechDetails] = useState([]);

    const columns = [
        { field: 'name', headerName: 'Име', flex: 1},
        { field: 'date', headerName: 'Дата', flex: 1, editable: true,
            renderCell: (params) => (
                <strong>
                    <TextField size={'small'} type={'date'} label={'От'} InputLabelProps={{shrink: true}}
                        value={params.value}
                        onChange={(event) => {
                            console.log(params.row)
                            let tempPartners = partners;
                            for (let i = 0; i < tempPartners.length; i++) {
                                if (tempPartners[i].id === params.row.id) {
                                    tempPartners[i].date = event.target.value;
                                }
                            }
                            console.log('tempPartners ', tempPartners)
                            setPartners(tempPartners)
                        }}/>

                </strong>
            ),},
        { field: 'executed', headerName: 'Изпълнено', type: 'number', editable: true, flex: 1 },
    ];

    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [goods, setGoods] = useState([]);

    const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
    const [showCommentHistory, setShowCommentHistory] = React.useState(false);

    const [executorOpen, setExecutorOpen] = useState(false);
    const [executors, setExecutors] = useState([]);

    const [addExecutorOpen, setAddExecutorOpen] = useState(false);
    const [addTransportOpen, setAddTransportOpen] = useState(false);
    const [addAdditionalLabourOpen, setAddAdditionalLabourOpen] = useState(false);

    const [addExecutorMaterialOpen, setAddExecutorMaterialOpen] = useState(false);
    const [addExecutorMaterialName, setAddExecutorMaterialName] = useState();
    const [addExecutorMaterialContQty, setAddExecutorMaterialContQty] = useState();

    const [executorId, setExecutorId] = useState(null);
    const [executedAmount, setExecutedAmount] = useState(null);


    const [tab, setTab] = useState(0);


    const [addMaterialToWarehouseOpen, setAddMaterialToWarehouseOpen] = useState(false);
    const [addMaterialToWarehouseName, setAddMaterialToWarehouseName] = useState(null);
    const [addMaterialToWarehouseEntity, setAddMaterialToWarehouseEntity] = useState(null);
    const [addMaterialToWarehouseCode, setAddMaterialToWarehouseCode] = useState(null);
    const [addMaterialToWarehouseGroup, setAddMaterialToWarehouseGroup] = useState(null);


    const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [isMainSelected, setMainSelected] = React.useState(null);
    const [qtyDone, setQtyDone] = React.useState(0);
    const [qtyExecuted, setQtyExecuted] = React.useState(0);

    const [partners, setPartners] = React.useState([]);

    const [partnersForDialog, setPartnersForDialog] = React.useState([]);

    const [historyDialogForExecutorOpen, setHistoryDialogForExecutorOpen] = React.useState(false);


    // Add detail
    const [taskState, setTaskState] = React.useState("2");

    const [reports, setReports] = React.useState("2");

    const [addDetailDialogOpen, setAddDetailDialogOpen] = React.useState(false);
    const [comment, setComment] = React.useState(null);
    const [detailDate, setDetailDate] = React.useState(moment(new Date()));
    const [detailHour, setDetailHour] = React.useState(null);
    const [detailQuantity, setDetailQuantity] = React.useState(null);
    // --- Road list
    const [isRoadListOpen, setRoadListOpen] = React.useState(false);
    const [roadAssignee, setRoadAssignee] = React.useState(null);
    const [fuelPrice, setFuelPrice] = React.useState(null);
    const [dashboardStart, setDashboardStart] = React.useState(null);
    const [dashboardEnd, setDashboardEnd] = React.useState(null);


    const [assignedPartner, setAssignedPartner] = useState(null);

    useEffect(() => {
        console.log(partners)
    }, [partners])

    useEffect(() => {
        setTaskState(statusChosen + "")
    }, [statusChosen])

    useEffect(() => {
        if (!open) {
            setCode(null)
            setName(null)
            setEntity(null)
            setCostRate(null)
            setTotalPrice(null)
            setFira(null)
            setExecutor(null)
            setEntityPrice(null)
            setPartners([])
        }
    }, [open])


    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getReport?`+ new URLSearchParams({
            token: accessToken,
            "id_task": open
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                    if (result && result.items) {
                        //setYearItems(result.items)
                    }
                    console.log(result)
                },
                (error) => {
                    console.log('error: ', error)
                }
            );
    }, [tab, forceRefresh])

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    useEffect(() => {
        if (!open) {
            return;
        }
        if (executionDotButtonType === 'task') {
            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/get?`+ new URLSearchParams({
                token: accessToken,
                id: open
            }), {
                method: "GET",
            })
                .then(
                    (result) => {
                        const task = result.items[0];
                        if (task) {
                            setTaskWorkName(task.subject)
                            setReports(task.reports)
                        }

                    },
                    (error) => {
                        console.log('error: ', error)
                    }
                );
        } delay(60); if (idAwork) {
            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/getSingleKSS?`+ new URLSearchParams({
                ...(selectedDay ? { date: selectedDay.format('YYYY-MM-DD') } : {}),
                token: accessToken,
                id: idAwork
            }), {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then((result) => {
                        if (result && result.items && result.items[0]) {
                            setMainSelected(result.items[0].is_selected === 1)
                            setQtyDone(result.items[0].qty_done)
                        }

                        setTaskWorkName(result.items[0].name_work)
                        if (!reports || reports.length === 0) {
                            setReports(result.items[0].reports)
                        }

                        const tabIndex = getType(tab)
                        if (result && result.items && result.items[0] && result.items[0].subs && result.items[0].subs[tabIndex]){
                            let data = result.items[0].subs[tabIndex].partners;
                            for (const datum of data) {
                                datum.id = datum.id_partner;
                                datum.name = datum.partner_name;
                                datum.executed = datum.qty;
                            }
                            setPartnersForDialog(data)
                            console.log('setting setPartnersForDialog ', result.items[0].subs[tabIndex])

                        }
                        if (result && result.items && result.items[0] && result.items[0].details) {
                            let details = result.items[0].details;
                            setTrudDetails(details.filter((item) => item.id_detail_type === 1))
                            setMaterialDetails(details.filter((item) => item.id_detail_type === 3))
                            setUslugiDetails(details.filter((item) => item.id_detail_type === 4))
                            setMechDetails(details.filter((item) => item.id_detail_type === 2))
                        }
                    },
                    (error) => {

                    }
                );

        }


    /*    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/contract/getSingleKSS?`+ new URLSearchParams({
            id: open,
            token: accessToken
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        }).then((result) => {
            if (result && result.items && result.items[0] && result.items[0].subs) {
                let details = result.items[0].subs;
                setTrudDetails(details.filter((item) => item.id_detail_type === 1))
                setMaterialDetails(details.filter((item) => item.id_detail_type === 3))
                setUslugiDetails(details.filter((item) => item.id_detail_type === 4))
                setMechDetails(details.filter((item) => item.id_detail_type === 2))
            }

        });*/

    }, [open, tab, forceRefresh])

    useEffect(() => {
        if (open === 3) {
            let active = true;

            if (!loading) {
                return undefined;
            }

            (async () => {
                CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/goods/getGoods?`+ new URLSearchParams({
                    "token": accessToken,
                    'search': name
                }), {
                    method: "GET",
                    mode: 'cors',
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then((result) => {
                        if (result && result.items) {
                            setLoading(false)
                            if (active) {
                                console.log('setting options  to ', result.items)
                                setGoods([...result.items]);
                            }
                        }
                    })


            })();

            return () => {
                active = false;
            };

        }
    }, [name]);

    useEffect(() => {
        console.log('workQuantity ', workQuantity)
        console.log('totalPrice ', totalPrice)
        setCostRate(workQuantity > 0 ? (totalPrice / workQuantity) /** entityPrice*/  : costRate)
    }, [totalPrice])

    useEffect(() => {
        if (!name) {
            setCode("")
            setEntity("")
            setEntityPrice("")
            setCostRate("")
        }
    }, [name])

    useEffect(() => {
        if (open === 3) {
            getGoods(navigate, accessToken, setAccessToken, name)
                .then((result) => {
                    if (result && result.items) {
                        setGoods(result.items)
                    }
                })
        }
    }, [name])


   useEffect(() => {
        if (open === 1 || edit) {
            getPartners(navigate, accessToken, setAccessToken, "SERVICE")
                .then((result) => {
                    if (result && result.items) {
                        setExecutors(result.items)
                    }
                })
        }
    }, [open])

    const style = {
        tableCell: {
            borderRightWidth: '0.5px',
            borderRightStyle: "solid",
            borderRightColor: "#969696",
            display: "tableRowGroup"
        }
    };

    let title;
    switch (open) {
        case 1:
            title = 'Труд';
            break;
        case 2:
            title = 'Механизация';
            break;
        case 3:
            title = 'Материали';
            break;
        case 4:
            title = 'Услуги';
            break;
    }

    function getDetails(tab) {
        if (tab === 0) {
            return trudDetails;
        } else if (tab === 1) {
            return materialDetails;
        } else if (tab === 2) {
            return uslugiDetails;
        } else if (tab === 3) {
            return mechDetails
        }
        return null;
    }

    function getType(number) {
        switch (number) {
            case 0:
                return 0;
            case 1:
                return 2;
            case 2:
                return 3;
            case 3:
                return 1;
        }

    }

    return  (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth={'md'}
            TransitionComponent={Transition}
        >

            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Отчет за ден {selectedDay.format('DD.MM.YYYY')}
                    </Typography>


                </Toolbar>
            </AppBar>

            <Grid container padding={3} spacing={1} justifyContent={'flex-end'}>

                <Grid item>
                    <ToggleButtonGroup
                        color="primary"
                        value={taskState}
                        size={'small'}
                        exclusive
                        onChange={(event, newState) => {
                            setTaskState(newState);
                        }}
                        aria-label="task_state"
                    >
                        <ToggleButton value="2"><CircleIcon style={{color: '#88c7fa'}}/>За работа</ToggleButton>
                        <ToggleButton value="0"><CircleIcon style={{color: '#f2c97f'}}/>В работа</ToggleButton>
                        <ToggleButton value="1"><CircleIcon style={{color: '#00e533'}}/>Приключена</ToggleButton>
                        <ToggleButton value="3"><CircleIcon style={{color: 'red'}}/>Важно</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>


                {
                    open !== 3 ?
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        margin="dense"
                                        id="client"
                                        value={taskWorkName}
                                        shrink={taskWorkName}
                                        label="Име"
                                        InputProps={{
                                            endAdornment:
                                                <CircleStatusIcons taskState={taskState}/>
                                            ,
                                        }}
                                    />
                                </Grid>


                            </Grid>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Grid container spacing={1}>

                                <Grid item xs={12}>
                                    <AutocompleteStock
                                        makeAdding
                                        autoCompleteOpen={autoCompleteOpen} setAutoCompleteOpen={setAutoCompleteOpen}
                                        stocksDataSet={goods} loading={loading}
                                        editId={null} setStockName={setName} setStockChiffer={setCode} setStockEntity={setEntity}
                                        setStockEntityPrice={setEntityPrice} setQtyAvail={() => {}}
                                        stockName={name} setLoading={setLoading}
                                        displayName={displayName} setDisplayName={setDisplayName}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        size={'small'}
                                        fullWidth
                                        id="client"
                                        value={code}
                                        onChange={(event) =>  {
                                            setCode(event.target.value)
                                        }}
                                        InputLabelProps={{ shrink: code }}
                                        label="Код"
                                    />
                                </Grid>


                            </Grid>
                        </Grid>
                }

                {idAwork ? <Grid item xs={4} style={{marginTop: 8}}>
                    <AutocompleteProps
                        autocompleteProperty={entity}
                        dataset={[{name: "КВ.М"}, {name: "КГ"}, {name: "КУБ.М"}, {name: "ТОН"}, {name: "БР."}, {name: "М."}]}
                        setAutocompleteProperty={setEntity}
                        restApiAddPropsFunction={(name) => {
                        }}
                        title={"Мярка"}
                        propName={"name"}
                        additional={{
                            'size': 'small'
                        }}
                    />
                </Grid> : null }


                {idAwork ? <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size={'small'}
                        margin="dense"
                        id="client"
                        value={totalPrice}
                        onChange={(event) =>  {
                            setTotalPrice(event.target.value)
                        }}
                        label="К-во по договор"
                    />
                </Grid> : null }

                {idAwork ? <Grid item xs={4}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id="client"
                        size={'small'}
                        value={qtyDone}
                        InputLabelProps={{ shrink: true }}
                        label="Изпълнено"
                    />
                </Grid> : null }

                <Grid item xs={12}>

                </Grid>


                <Grid item xs={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={(event, newValue) => {
                            setTab(newValue);
                        }} aria-label="basic tabs example">
                            { idAwork ? <Tab label="Труд" value={0}/> : null}
                            <Tab label="Допълнителен труд" value={1}/>
                            { idAwork ? <Tab label="Материали" value={2}/> : null }
                            { idAwork ? <Tab label="Услуги" value={3}/> : null }
                            { idAwork ? <Tab label="Механизация" value={4}/> : null }
                            <Tab label="Транспорт" value={5}/>
                        </Tabs>
                    </Box>
                </Grid>

                {
                    console.log('reports' , reports)
                }
                {tab >= 0 ? <Grid item xs={12}>
                    {
                        tab === 1 ? <AdditionalLabourTable details={(reports??[]).filter(report => report.date_task === selectedDay.format("YYYY-MM-DD"))}
                                                           setForceRefresh={setForceRefresh} type={executionDotButtonType}/> :

                        tab === 5 ? <TransportTable
                                type={executionDotButtonType}
                                setForceRefresh={setForceRefresh}
                                details={(reports??[]).filter(report => report.kilometers && report.date_task === selectedDay.format("YYYY-MM-DD"))}/> :

                        tab !== 2 ? <LabourMechServAddTable open={open} partners={partners} partnersForDialog={partnersForDialog} setHistoryDialogForExecutorOpen={setHistoryDialogForExecutorOpen} style={style} setAddExecutorOpen={setAddExecutorOpen}/>

                        :
                        <MaterialTable open={open} materialDetails={materialDetails} setHistoryDialogForExecutorOpen={setHistoryDialogForExecutorOpen} style={style}
                                       setAddExecutorMaterialOpen={setAddExecutorMaterialOpen} setAddExecutorMaterialName={setAddExecutorMaterialName} setAddExecutorMaterialContQty={setAddExecutorMaterialContQty}/>
                    }
                </Grid> : null}

                <Grid item xs={9}></Grid>
                <Grid item xs={2}><Button variant={'contained'} onClick={() => {
                    if (tab === 5) {
                        setAddTransportOpen(true)
                    } else if (tab === 1) {
                        setAddAdditionalLabourOpen(true)
                    } else {
                        let partnersAssigned = [...partners, ...partnersForDialog, taskExecutor];
                        console.log('partnez ', partnersAssigned)
                        if (partnersAssigned && partnersAssigned.length >= 1) {
                            setAssignedPartner(partnersAssigned[0])
                        }
                        setAddExecutorOpen(true)
                    }
                }}>Добави</Button></Grid>


                {open === 3 &&
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size={'small'}
                            margin="dense"
                            id="client"
                            value={fira}
                            onChange={(event) => {
                                setFira(event.target.value)
                            }}
                            label="Фира"
                        />
                    </Grid>
                }


                <Grid item xs={12}>
                    <Button
                        fullWidth
                        margin="dense"
                        variant={'contained'}
                        id="done"
                        onClick={(event) =>  {

                            CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/setStatus`, {
                                method: "POST",
                                mode: 'cors',
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    ...(executionDotButtonType === 'task' ? {"id_task": open} : {"id_awork": open}),
                                    "date": selectedDay ? selectedDay.format('YYYY-MM-DD') : null,
                                    "status": taskState,
                                    "type": executionDotButtonType === 'task' ? 'task' : 'graph',
                                    "hour_start": null,
                                    "hour_end": null,
                                    token: accessToken
                                })
                            }).then(() => {
                                setForceRefresh(Math.random())
                                handleClose()
                            });
                        }}
                    >
                        Запази
                    </Button>
                </Grid>

            </Grid>

            <PartnerPickDialog
                details={getDetails(tab)}
                serviceMode
                nameWork={name}
                entity={entity}
                totalPrice={totalPrice}
                currentlyDone={partners ? Number(qtyDone) + partners.reduce((partialSum, a) => partialSum + Number(a.executed), 0) : 0}
                selectedObject={selectedObject}
                assignedPartner={assignedPartner}
                open={addExecutorOpen}
                handleClose={() => setAddExecutorOpen(false)}
                onClick={(executorId, executor, date, qty, selectedDetail) => {
                    console.log(executor)
                    let newPartners = [...partners, {
                        id: executor.id || executorId,
                        id_awork_detail: selectedDetail,
                        id_partner: executor.id || executorId,
                        name: executor.name_firm,
                        date: date,
                        executed: qty
                    }];
                    setPartners(newPartners)



                    let data = newPartners;
                    for (let i = 0; i < data.length; i++) {
                        data[i].id_partner = data[i].id;
                        data[i].qty = data[i].executed;
                    }
                    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/setPartnerDetailQty`, {
                        method: "POST",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            "token":accessToken,
                            "id_awork":open,
                            "id_detail_type": getType(tab + 1),
                            "data": data,
                            "date": moment().format('YYYY-MM-DD')
                        })
                    })
                        .then((result) => {
                                setPartners([])
                                setForceRefresh(Math.random())
                                handleClose()
                            },
                            (error) => {
                                console.log('error: ', error)
                            }
                        );

                }}/>

        {/*    <AddMaterialToWarehouseDialog
                open={addMaterialToWarehouseOpen}
                handleClose={() => setAddMaterialToWarehouseOpen(false)}
                name={addMaterialToWarehouseName}
                setName={setAddMaterialToWarehouseName}
                entity={addMaterialToWarehouseEntity}
                setEntity={setAddMaterialToWarehouseEntity}
                code={addMaterialToWarehouseCode}
                setCode={setAddMaterialToWarehouseCode}
                group={addMaterialToWarehouseGroup}
                setGroup={setAddMaterialToWarehouseGroup}
                onClick={() => {
                    addGoods(navigate, accessToken, setAccessToken, addMaterialToWarehouseName, addMaterialToWarehouseEntity, addMaterialToWarehouseCode)
                        .then(() => {
                            setName(addMaterialToWarehouseName)
                            setEntity(addMaterialToWarehouseEntity)
                        })
                }}/>*/}

{/*
            <PartnerPickDialog
                details={getDetails(tab)}
                serviceMode
                nameWork={name}
                entity={entity}
                totalPrice={totalPrice}
                currentlyDone={partners ? Number(qtyDone) + partners.reduce((partialSum, a) => partialSum + Number(a.executed), 0) : 0}
                selectedObject={selectedObject}
                open={addExecutorOpen}
                handleClose={() => setAddExecutorOpen(false)}
                onClick={(executorId, executor, qty, selectedDetail) => {
                    console.log(executor)

                    let newPartners = [...partners, {
                        id: addExecutorOpen,
                        id_awork_detail: selectedDetail,
                        id_partner: addExecutorOpen,
                        date: selectedDay.format('YYYY-MM-DD'),
                        executed: qty
                    }]


                    let data = newPartners;
                    for (let i = 0; i < data.length; i++) {
                        data[i].id_partner = data[i].id;
                        data[i].qty = data[i].executed;
                    }
                    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/setPartnerDetailQty`, {
                        method: "POST",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            "token":accessToken,
                            "id_awork":idAwork,
                            "id_detail_type": getType(tab + 1),
                            "data": data,
                            "date": moment().format('YYYY-MM-DD')
                        })
                    })
                        .then((result) => {
                                setPartners([])
                                setForceRefresh(Math.random())
                                handleClose()
                            },
                            (error) => {
                                console.log('error: ', error)
                            }
                        );

                }}/>
*/}

            <PartnerPickForTransportLightweightDialog
                idTaskOrAwork={open}
                executionDotButtonType={executionDotButtonType}
                details={getDetails(tab)}
                serviceMode
                nameWork={name}
                entity={entity}
                totalPrice={totalPrice}
                currentlyDone={partners ? Number(qtyDone) + partners.reduce((partialSum, a) => partialSum + Number(a.executed), 0) : 0}
                selectedObject={selectedObject}
                open={addTransportOpen}
                handleClose={() => setAddTransportOpen(false)}
                onClick={(executorId, executor, date, qty, selectedDetail) => {
                    console.log(executor)


                }}/>

            <PartnerPickForAdditionalLabourLightweightDialog
                idTaskOrAwork={open}
                executionDotButtonType={executionDotButtonType}
                selectedDate={selectedDay}
                details={getDetails(tab)}
                serviceMode
                nameWork={name}
                entity={entity}
                totalPrice={totalPrice}
                currentlyDone={partners ? Number(qtyDone) + partners.reduce((partialSum, a) => partialSum + Number(a.executed), 0) : 0}
                selectedObject={selectedObject}
                open={addAdditionalLabourOpen}
                handleClose={() => setAddAdditionalLabourOpen(false)}
                setForceRefresh={setForceRefresh}
                partnersForExecution={partnersForExecution}
                onClick={(executorId, executor, date, qty, selectedDetail) => {
                    console.log(executor)


                }}/>

            <PartnerPickLightweightForMaterialDialog
                details={getDetails(tab)}
                serviceMode
                materialName={addExecutorMaterialName}
                materialQty={addExecutorMaterialContQty}
                nameWork={name}
                entity={entity}
                totalPrice={totalPrice}
                currentlyDone={partners ? Number(qtyDone) + partners.reduce((partialSum, a) => partialSum + Number(a.executed), 0) : 0}
                selectedObject={selectedObject}
                open={addExecutorMaterialOpen}
                handleClose={() => setAddExecutorMaterialOpen(false)}
                onClick={(executorId, executor, date, qty, selectedDetail) => {
                    console.log(executor)

                    let newPartners = [...partners, {
                        id: executor.id,
                        id_awork_detail: selectedDetail,
                        id_partner: executor.id,
                        name: executor.name_firm,
                        date: date,
                        executed: qty
                    }]
                    setPartners(newPartners)




                    let data = newPartners;
                    for (let i = 0; i < data.length; i++) {
                        data[i].id_partner = data[i].id;
                        data[i].qty = data[i].executed;
                    }
                    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/setPartnerDetailQty`, {
                        method: "POST",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            "token":accessToken,
                            "id_awork":open,
                            "id_detail_type": getType(tab + 1),
                            "data": data,
                            "date": moment().format('YYYY-MM-DD')
                        })
                    })
                        .then((result) => {
                                setPartners([])
                                setForceRefresh(Math.random())
                                handleClose()
                            },
                            (error) => {
                                console.log('error: ', error)
                            }
                        );

                }}/>


            <AddEditWorkHistoryForExecutorDialog
                selectedObject={selectedObject}
                open={historyDialogForExecutorOpen}
                handleClose={() => setHistoryDialogForExecutorOpen(false)}
                code={code}
                setCode={setCode}
                name={name}
                setName={setName}
                entity={entity}
                setEntity={setEntity}
                costRate={costRate}
                setCostRate={setCostRate}
                fira={fira}
                setFira={setFira}
                entityPrice={entityPrice}
                setEntityPrice={setEntityPrice}
                totalPrice={totalPrice}
                executor={executor}
                setExecutor={setExecutor}
                setTotalPrice={setTotalPrice}
                forceRefresh={forceRefresh}
                setForceRefresh={setForceRefresh}
                selectedDate={selectedDay}
                onClick={(entity) => {


                }}/>





            <AddDetailDialog
                navigate={navigate}
                accessToken={accessToken}
                setAccessToken={setAccessToken}
                open={addDetailDialogOpen}
                handleClose={() => setAddDetailDialogOpen(false)}
                comment={comment}
                setComment={setComment}
                date={detailDate}
                setDate={setDetailDate}
                hour={detailHour}
                setHour={setDetailHour}
                userId={null}
                taskId={null}
                details={[]}
                setPopoverOpen={true}
                setPopoverDetails={[]}

                isRoadListOpen={isRoadListOpen}
                setRoadListOpen={setRoadListOpen}
                detailQuantity={detailQuantity}
                setDetailQuantity={setDetailQuantity}
                roadAssignee={roadAssignee}
                setRoadAssignee={setRoadAssignee}
                fuelPrice={fuelPrice}
                setFuelPrice={setFuelPrice}
                dashboardStart={dashboardStart}
                setDashboardStart={setDashboardStart}
                dashboardEnd={dashboardEnd}
                setDashboardEnd={setDashboardEnd}

                addDetail={() => {

                }}
            />

            <CommentHistoryDialog open={showCommentHistory} handleClose={() => setShowCommentHistory(false)} selectedObject={selectedObject}/>


        </Dialog>
    )
}
export default AddEditWorkForExecutionWithCombinedHistoryForDailyTasksDialog;