import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import MessageDialog from "../../helper/MessageDialog";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import {CommonFetch} from "../../../services/api-fetch";

function GraphYearsWeekNumsCalendar({selectedMonths, setSelectedMonths}) {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const NUM_PERC = '5%';
    const NAME_PERC = '40%';
    const ENTITY_PERC = '8%';
    const QTY_PERC = '7%';
    const ENT_PRICE_PERC = '8%';
    const TOTAL_PERC = '10%';
    const QTY_MONEY = '3%';
    const DAILY_MONEY = '3%';

    const [selectedWeeks, setSelectedWeeks] = useState();
    const [monthItems, setMonthItems] = useState();
    const [selectedWeeksTemp, setSelectedWeeksTemp] = useState([]);
    const [numWeeks, setNumWeeks] = useState([]);
    const [weeksSplitter, setWeeksSplitter] = useState([]);

    const [messageDialog, setMessageDialog] = useState(null);


    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/aworkgroups?`+ new URLSearchParams({
            token: accessToken,
            id_project: 1,
            "from_date":'2023-08-01',
            "to_date":'2023-08-31',
            "typeView": 'week',

        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {

                    if (result && result.weeks) {
                        setNumWeeks(result.weeks)
                    }

                    if (result && result.weeksplitter) {
                        setWeeksSplitter(result.weeksplitter)
                    }

                    setMonthItems(result.items)
                }
            })
    }, [selectedMonths])

    function getMonthShortName(month) {
        switch (month) {
            case 1:return "ян";
            case 2:return "фев";
            case 3:return "март";
            case 4:return "апр";
            case 5:return "май";
            case 6:return "юни";
            case 7:return "юли";
            case 8:return "авг";
            case 9:return "септ";
            case 10:return "окт";
            case 11:return "нов";
            case 12:return "дек";
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={1}>


                <Grid item xs={12}>

                    {selectedWeeks && selectedWeeks.length > 0 ?
                       null

                        :
                        <TableContainer>
                            {monthItems && monthItems.length > 0 ? <Table size={'small'} sx={ {border: 1}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>

                                            <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>№</b></TableCell>
                                            <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>Обект</b></TableCell>
                                            {
                                                selectedMonths.sort()
                                                    .map((dateInList, index, array) => {
                                                        return <TableCell
                                                            colspan={index <= weeksSplitter.length - 1 ? weeksSplitter[index] : (numWeeks.length - weeksSplitter[index-1])}
                                                            style={{padding: 2}}
                                                            onClick={() => {
                                                                if (selectedMonths.includes(dateInList)) {
                                                                    let newSelectedMonths = selectedMonths.filter(function(item) {
                                                                        return item !== dateInList
                                                                    })

                                                                    console.log('newSelectedMonths ', newSelectedMonths)
                                                                    setSelectedMonths(newSelectedMonths)
                                                                } else {
                                                                    if (selectedMonths.length<=2) {
                                                                        setSelectedMonths([
                                                                            ...selectedMonths,
                                                                            dateInList
                                                                        ])
                                                                    } else {
                                                                        setMessageDialog('Могат да се избират не повече от 3 месеца');
                                                                    }

                                                                }
                                                            }}
                                                            sx={{border: 1, padding: 1, background: selectedMonths.includes(dateInList) ? '#8888fc' : null
                                                            }} width={DAILY_MONEY} align="center">
                                                            <Typography
                                                                sx={{background: selectedMonths.includes(dateInList) ? '#8888fc' : null}}
                                                                size={'small'}>{getMonthShortName(dateInList)}</Typography>
                                                        </TableCell>
                                                    })
                                            }
                                        </TableRow>


                                        <TableRow>
                                            <TableCell sx={{border: 1}} width={NUM_PERC} align="left"></TableCell>
                                            <TableCell sx={{border: 1}} width={NAME_PERC} align="left"></TableCell>
                                            {
                                                (numWeeks ?? [])
                                                    .map((weekNum, index, array) => {
                                                        return <TableCell
                                                            onClick={() => {
                                                                if (selectedWeeksTemp.includes(weekNum)) {
                                                                    let newSelectedWeeks = selectedWeeksTemp.filter(function(item) {
                                                                        return item !== weekNum
                                                                    })

                                                                    setSelectedWeeksTemp(newSelectedWeeks)
                                                                } else {
                                                                    if (selectedWeeksTemp.length<=1) {
                                                                        setSelectedWeeksTemp([
                                                                            ...selectedWeeksTemp,
                                                                            weekNum
                                                                        ])
                                                                    } else {
                                                                        setMessageDialog('Могат да се избират не повече от 2 седмици');
                                                                    }

                                                                }
                                                            }}
                                                            sx={{border: 1, background: selectedWeeksTemp.includes(weekNum) ? '#8888fc' : null}} width={DAILY_MONEY} align="center"><b>{weekNum}</b></TableCell>
                                                    })
                                            }
                                        </TableRow>

                                    </TableHead>
                                    <TableBody>
                                        {monthItems && monthItems
                                            .map((row, index) => (
                                                <React.Fragment>
                                                    <TableRow
                                                        key={row.no}
                                                        style={{background: '#63fff2'}}
                                                    >
                                                        <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>{index + 1}</b></TableCell>
                                                        <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{row.name}</b></TableCell>
                                                        <TableCell sx={{border: 1}} width={ENTITY_PERC} align="center"></TableCell>
                                                        <TableCell sx={{border: 1}} width={QTY_PERC} align="right">{row.qty ? row.qty.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                        <TableCell sx={{border: 1}} width={QTY_MONEY} align="center"></TableCell>
                                                        {
                                                            (numWeeks ?? []).map((dateInList, index, array) => {
                                                                return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center"></TableCell>
                                                            })
                                                        }
                                                    </TableRow>

                                                    {row && row.details && row.details.map((row, index) => (
                                                        <React.Fragment>

                                                            <TableRow
                                                                key={row.no}
                                                                style={{background: '#adadad'}}
                                                            >
                                                                <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>{index + 1}</b></TableCell>
                                                                <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{row.name_group}</b></TableCell>
                                                                <TableCell sx={{border: 1}} width={ENTITY_PERC} align="center">{row.entity}</TableCell>
                                                                <TableCell sx={{border: 1}} width={QTY_PERC} align="right">{row.qty ? row.qty.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                                <TableCell sx={{border: 1}} width={QTY_MONEY} align="right">{row.daily_money ? row.daily_money.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null}</TableCell>
                                                                {
                                                                    (numWeeks ?? [])
                                                                        .map((dateInList, index, array) => {
                                                                            return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center"></TableCell>
                                                                        })
                                                                }
                                                            </TableRow>
                                                        </React.Fragment>
                                                    ))
                                                    }
                                                </React.Fragment>

                                            ))}
                                    </TableBody>
                                </Table> :

                                <div style={{padding: 20}}>
                                    <Typography>Няма резултати</Typography>
                                </div>
                            }
                        </TableContainer>

                    }



                    <MessageDialog
                        openAndText={messageDialog}
                        handleClose={() => setMessageDialog(null)}
                        positiveText={'Добре'}
                        positiveAction={() => {
                            setMessageDialog(false)
                        }}/>
                </Grid>

            </Grid>

        </React.Fragment>

    )
}
export default GraphYearsWeekNumsCalendar;