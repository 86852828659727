import '../App.css';
import Grid from "@mui/material/Grid";
import React, {useEffect} from "react";
import AppBarComponent from "./../AppBarComponent";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useLocalStorage} from "../useLocalStorage";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function LoginScreen() {
    let navigate = useNavigate();

    // login data
    const [testClientName, setTestClientName] = useLocalStorage("testClientName", null);

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [userId, setUserId] = useLocalStorage("userId", null);

    const [email, setEmail] = React.useState(null);
    const [password, setPassword] = React.useState(null);

    const [errorOpen, setErrorOpen] = React.useState(false);

    function Copyright(props) {
        return (
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                Всички права запазени © 1990-{new Date().getFullYear()}&nbsp;
                <Link color="inherit" href="https://www.sini-bg.com//" target="_blank">
                СИНИ ЕООД
                </Link>{' '}
            </Typography>
        );
    }

    useEffect(() => {
        if (accessToken) {
            navigate('/reports/day')
        }
    }, [])

    return (
        <React.Fragment>
            <AppBarComponent/>
            <br/>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Вход
                    </Typography>
                    <Box noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value)
                            }}
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={password}
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                            name="password"
                            label="Парола"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Запомни ме"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                fetch(`${process.env.REACT_APP_URL}/login`, {
                                    method: "POST",
                                    mode: 'cors',
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        email: email,
                                        password: password
                                    })
                                })
                                    .then(response => response.json())
                                    .then(
                                        (result) => {
                                            if (result && result.items && result.items.token) {
                                                console.log(result)
                                                setTestClientName(result.items.client_name);
                                                setAccessToken(result.items.token);
                                                setUserId(result.items.userid);
                                                navigate('/reports/day');
                                            } else {
                                                setErrorOpen(true)
                                            }

                                        },
                                        (error) => {
                                            console.log('error: ', error)
                                        }
                                    );


                            }}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Вход
                        </Button>
                        <Snackbar open={errorOpen} autoHideDuration={6000} onClose={() => {
                            setErrorOpen(false)
                        }}>
                            <Alert onClose={() => {
                                setErrorOpen(false)
                            }} severity="error" sx={{ width: '100%' }}>
                                Невалидни входни данни!
                            </Alert>
                        </Snackbar>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Забравена парола?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Нямаш акаунт? Регистрирай се"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>

        </React.Fragment>
    );

}

export default LoginScreen;
