import Grid from "@mui/material/Grid";
import * as React from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {useEffect, useState} from "react";
import {fetchWorksDataStock, getObjects, getObjectsAll} from "../utils/NetworkUtils";
import {useLocalStorage} from "../useLocalStorage";
import {useNavigate} from "react-router-dom";
import {CommonFetch} from "../services/api-fetch";
import moment from "moment/moment";
import WeeklyTasksDialog from "../components/graphsComponent/weeklyTasks/WeeklyTasksDialog";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddWorkDialog from "../components/dialogs/dailyWorks/AddWorkDialog";

function ReportsWeekScreen() {
    const OBJ_WIDTH = '30%';
    const EXEC_WIDTH = '20%';
    const DAY_WIDTH = '5%';

    const DAYS = ['пон', 'вт', 'ср', 'четв', 'пет', 'съб', 'нед'];

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    let navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState(null);

    const [pickObjectOpen, setPickObjectOpen] = useState(null);


    const [items, setItems] = useState([]);



    const [objectToAddTo, setObjectToAddTo] = useState(null);




    const [shouldShowAddWithProjectSelected, setShouldShowAddWithProjectSelected] = React.useState([]);

    const [objects, setObjects] = React.useState([]);
    const [selectedObject, setSelectedObject] = React.useState(0);
    const [objectName, setObjectName] = React.useState(0);

    const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [displayName, setDisplayName] = useState("");



    const [worksDataStock, setWorksDataStock] = React.useState([]);
    const [autoCompleteWorkOpen, setAutoCompleteWorkOpen] = React.useState(false);
    const [loadingWork, setLoadingWork] = React.useState(false);
    const [displayNameWork, setDisplayNameWork] = useState("");
    const [workName, setWorkName] = useState(0);

    const [addWorkOpen, setAddWorkOpen] = React.useState(null);

    const [selectedDay, setSelectedDay] = useState(moment());

    const [forceRefresh, setForceRefresh] = useState();

    function getFirstDateOfWeek(date) {
        // Clone the moment date object to avoid modifying the original object
        var firstDayOfWeek = date.clone();

        // Set the day to Monday (1) as the start of the week
        firstDayOfWeek.day(1);

        // Return the formatted date as YYYY-MM-DD
        return firstDayOfWeek;
    }

    function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    useEffect(() => {
        getObjectsAll(navigate, accessToken, setAccessToken, setObjects, setSelectedObject)
    }, [])

    useEffect(() => {
        if (!searchTerm){
            setShouldShowAddWithProjectSelected(null)
            return
        }
        let objectsFound = objects.filter((object) => object && object.name_project ? object.name_project.toLowerCase().includes(searchTerm.toLowerCase()) : false)
        console.log('objectsFound ', objectsFound)

        if (objectsFound && objectsFound.length === 1) {
            setShouldShowAddWithProjectSelected(objectsFound[0])
        } else {
            setShouldShowAddWithProjectSelected(null)
        }
    }, [searchTerm])

    useEffect(() => {
        console.log('selectedDay ', selectedDay)
        let startDate = moment(getMonday(selectedDay.toDate()));
        console.log('startDate ', startDate)

        let endDate = startDate.clone().add(6, 'days');

        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getWeekReport?`+ new URLSearchParams({
            token: accessToken,
            "from_date": startDate.format('YYYY-MM-DD'),
            "to_date": endDate.format('YYYY-MM-DD')
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.data) {
                    let results = result.data;

                    let newResult = {

                    };

                    if (results) {
                        console.log('Object.keys(results) ', Object.keys(results))
                        for (let i = 0; i < Object.keys(results).length; i++) {
                            const keyName = Object.keys(results)[i];
                            newResult[keyName] = [

                            ];
                            console.log('keyName ', keyName)

                            for (let j = 0; j < results[keyName].length; j++) {
                                const task = results[keyName][j];
                                newResult[keyName].push(task);


                                /*console.log('newResult[keyName]  ', newResult[keyName])
                                console.log('newResult[keyName]. task.name_work ', task.name_work)
                                if (!newResult[keyName].some((check) => check.name_work === task.name_work)) {
                                    newResult[keyName].push(task);
                                } else {
                                    console.log('task.dates ', task.dates)
                                    console.log('newResult[keyName].dates ', newResult[keyName].dates)

                                    let indexOf = newResult[keyName].findIndex((check) => check.name_work === task.name_work)
                                    console.log('indexOf ', newResult[keyName][indexOf])
                                    newResult[keyName][indexOf].dates = [...task.dates, ...newResult[keyName][indexOf].dates ?? []]
                                }*/

                            }

                        }
                    }
                    console.log('newResult ', results)


                    setItems(newResult)
                }
            })
    }, [selectedDay, forceRefresh])


    useEffect(() => {
        if (objectName) {
            fetchWorksDataStock({id: objectName.id}, navigate, accessToken, setAccessToken, setWorksDataStock, displayNameWork)
        }
    }, [objectName])

    useEffect(() => {
        console.log('objectName ', objectName)
        if (objectName) {
            setItems([...items, {
                id: objectName.id,
                object_name: objectName.name_project
            }])
            setObjectName(0)
            setDisplayName(null)
        }

    }, [objectName])


    const handleWorkSelect = (workName) => {
        console.log('workName ', workName)
        if (workName) {

            let newItems = items;
            console.log('newItems ', newItems)

            let taskIndex = newItems.findIndex((task) => {
                return task.id === objectToAddTo
            })

            console.log('taskIndex ', taskIndex)
            console.log('newItems[taskIndex] ', newItems[taskIndex])
            newItems[taskIndex].subs = [
                ...(newItems[taskIndex].subs ?? []),
                {
                    name: workName.name_work
                },
                {
                    name: null
                }
            ]
            console.log('setting ', newItems)

            setItems(newItems)
            setWorkName(0)
        }

    }

    return (
        <Grid container spacing={1} justifyContent={'flex-start'}>

            <Grid item xs={2}>
                {shouldShowAddWithProjectSelected ?
                    <IconButton onClick={(event) => {
                    /* console.log('items[item] ', items[item])
                     fetchWorksDataStock({id: items[item] && items[item][0] ? items[item][0].id_project : null}, navigate, accessToken, setAccessToken, setWorksDataStock, displayNameWork)
                     setObjectToWorkWith(items[item] && items[item][0] ? items[item][0].id_project : null)
                    */ //handleClick(event)
                    setAddWorkOpen(true)
                }}><AddCircleOutlineIcon/></IconButton> : null}
            </Grid>

            <Grid item>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                    <DatePicker
                        label={'От'}
                        value={selectedDay}
                        onChange={(newValue) => setSelectedDay(newValue)}
                        views={['year', 'month', 'day']}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={6}>
                <TextField value={searchTerm} onChange={(e) => {
                    setSearchTerm(e.target.value)
                }} fullWidth label={'Търсене'} InputProps={{
                    startAdornment:
                        <IconButton><SearchIcon/></IconButton>,
                }}/>
            </Grid>

            <Grid item xs={12}>
                <WeeklyTasksDialog selectedDay={selectedDay} setSelectedDay={setSelectedDay} selectedObject={selectedObject} items={items} setItems={setItems} setForceRefresh={setForceRefresh} searchTerm={searchTerm}/>
            </Grid>

            {addWorkOpen ? <AddWorkDialog
                open={addWorkOpen} handleClose={() => setAddWorkOpen(false)} selectedDay={selectedDay}
                setForceRefresh={setForceRefresh} shouldShowAddWithProjectSelected={shouldShowAddWithProjectSelected}/> : null
            }

        </Grid>
    )
}
export default ReportsWeekScreen;