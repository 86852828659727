import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import moment from "moment/moment";
import Grid from "@mui/material/Grid";
import CircleIcon from "@mui/icons-material/Circle";
import Button from "@mui/material/Button";
import {CommonFetch} from "../../services/api-fetch";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../useLocalStorage";
import {useEffect} from "react";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TaskStateDialog({open, handleClose, setForceRefresh}) {


    let navigate = useNavigate();

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [taskState, setTaskState] = React.useState("2");


    const [editAllowed, setEditAllowed] = React.useState(false);


    useEffect(() => {
        if (!open) {
            setEditAllowed(false)
        }
    }, [open])


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={'sm'}
            fullWidth
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Задай статус за ден {open ? moment(open.date, 'YYYY-MM-DD').format('DD.MM.YYYY') : null}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={2} style={{margin: 10}}>
                <Grid item xs={12}>
                    <ToggleButtonGroup
                        disabled={!editAllowed}
                        color="primary"
                        value={taskState}
                        size={'small'}
                        exclusive
                        onChange={(event, newState) => {
                            setTaskState(newState);
                        }}
                        aria-label="task_state"
                    >
                        <ToggleButton value="2"><CircleIcon style={{color: '#88c7fa'}}/>За работа</ToggleButton>
                        <ToggleButton value="0"><CircleIcon style={{color: '#f2c97f'}}/>В работа</ToggleButton>
                        <ToggleButton value="1"><CircleIcon style={{color: '#00e533'}}/>Приключена</ToggleButton>
                        <ToggleButton value="3"><CircleIcon style={{color: 'red'}}/>Важно</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item>
                    <Button variant={'contained'} onClick={() => {
                        setEditAllowed(!editAllowed)
                    }}>Редакция</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!editAllowed} variant={'contained'} onClick={() => {

                    }}>Изтрий за деня</Button>
                </Grid>
                <Grid item>
                    <Button disabled={!editAllowed || !taskState} variant={'contained'} onClick={() => {

                        if (open) {


                            CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/mywave/setStatus`, {
                                method: "POST",
                                mode: 'cors',
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    token: accessToken,
                                    id: open.id,
                                    status: taskState
                                })
                            })
                                .then((result) => {
                                    handleClose()
                                    setForceRefresh(Math.random())
                                })
                        }
                    }}>Запис</Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}
export default TaskStateDialog;