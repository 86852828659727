import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import * as React from "react";
import {useEffect, useState} from "react";
import {CommonFetch} from "../../../services/api-fetch";
import {useLocalStorage} from "../../../useLocalStorage";
import {useNavigate} from "react-router-dom";

function ContragentsExecutorTable({name, bulstad, bulstadNo, phone, setNewMode, setForceRefresh}) {
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [locationView, setLocationView] = useLocalStorage("locationView", false);
    let navigate = useNavigate();
    const [viewContragentOpen, setViewContragentOpen] = useState();
    const [executorName, setExecutorName] = useState();
    const [items, setItems] = useState([]);

    function filterItems(item) {
        return true;
    }

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners?`+ new URLSearchParams({
            token: accessToken,
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setItems(results)
                }
            })
    }, [])

    return (
        <Grid container spacing={1} style={{padding: 10}}>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    {items
                        .filter(filterItems) && items
                        .filter(filterItems).length > 0 ? <Table sx={{}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell width={"40%"} align="left"><b>Име на контрагент</b></TableCell>
                                <TableCell width={"17.5%"} align="left"><b>Булстат</b></TableCell>
                                <TableCell width={"17.5%"} align="left"><b>ИН по ЗДДС</b></TableCell>
                                <TableCell width={"35%"} align="left"><b>МО лице</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items && items
                                .filter(filterItems)
                                .map((row) => (
                                    <TableRow
                                        key={row.no}
                                        onClick={() => {
                                            setViewContragentOpen(row.id);
                                            setExecutorName(row.name_firm)
                                        }}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell width={"40%"} align="left">{row.name_firm}</TableCell>
                                        <TableCell width={"17.5%"} align="left">{row.bulstat}</TableCell>
                                        <TableCell width={"17.5%"} align="left">{row.vatno}</TableCell>
                                        <TableCell width={"35%"} align="left">{row.mol}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table> :

                    <div style={{padding: 20}}>
                        <Typography>Няма резултати</Typography>
                    </div>
                    }
                </TableContainer>
            </Grid>

        {/*    <ViewContragentExecutorDialog open={viewContragentOpen} handleClose={() => {
                setViewContragentOpen(false)
            }} setForceRefresh={setForceRefresh}
              executorName={executorName} setExecutorName={setExecutorName}/>*/}
        </Grid>
    )
}

export default ContragentsExecutorTable;