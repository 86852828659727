import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {useEffect, useState} from "react";
import {CommonFetch} from "../../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import Button from "@mui/material/Button";

function TransportTab() {

    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    const [executorOpen, setExecutorOpen] = useState(false);
    const [executors, setExecutors] = useState([]);
    const [addExecutorOpen, setAddExecutorOpen] = useState(false);
    const [executorTyped, setExecutorTyped] = useState(null);

    const [executorId, setExecutorId] = useState(null);
    const [executor, setExecutor] = useState(null);

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/firms?`+ new URLSearchParams({
            token: accessToken,
            ...(executor ? { search: executorTyped } : {}),

        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setExecutors([...results])
                }
            })
    }, [executorTyped])



    return (
        <Grid container spacing={1}>
            <Grid item xs={10}>
                <TextField fullWidth label={'Група'}/>
            </Grid>
            <Grid item xs={2}>
                <Button>Добави група</Button>
            </Grid>
            <Grid item xs={4}>
                <TextField label={'Автомобил марка'}/>
            </Grid>
            <Grid item xs={4}>
                <TextField label={'Автомобил номер'}/>
            </Grid>
            <Grid item xs={4}>
                <TextField label={'Среден разход'}/>
            </Grid>
            <Grid item xs={4}>
                <TextField label={'Цена/км'}/>
            </Grid>
            <Grid item xs={4}>
                <TextField label={'Цена гориво/л'}/>
            </Grid>


            <Grid item xs={12}>
                <Typography>Маршрут</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth label={'От'}/>
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth label={'До'}/>
            </Grid>
            <Grid item xs={4}>
                <TextField fullWidth label={'Км'}/>
            </Grid>
            <Grid item xs={4}>
                <TextField fullWidth label={'Разход за гориво'}/>
            </Grid>



            <Grid item xs={12}>
                <Typography>Изпълнител</Typography>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    id="contragents"
                    freeSolo
                    open={executorOpen}
                    onOpen={() => {
                        setExecutorOpen(true);
                    }}
                    onClose={() => {
                        setExecutorOpen(false);
                    }}
                    value={executor ? executor && executor.name_firm ? executor : {name_firm: executor} : null}
                    filterOptions={(options) => {
                        return options.filter((option) => {
                            if (!option || !option.name_firm || !executor || !executorTyped) {
                                return false;
                            }
                            return option.name_firm.toLowerCase().includes(executorTyped ? executorTyped.toLowerCase() : "")
                        })
                    }}
                    onChange={(event, prop) => {
                        console.log('prop ', prop)
                        if (prop) {
                            console.log(prop)
                            setExecutorId(prop && prop.id ? prop.id : prop)
                            setExecutor(prop && prop.name ? prop.name : prop)

                        } else {
                            setExecutor("")
                        }
                    }}
                    onInput={(event) => {
                        setExecutorTyped(event.target.value)
                    }}
                    getOptionLabel={(option) => option.name_firm}
                    options={executors}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Контрагент"
                            value={executorTyped}
                            onChange={(e) => {
                                setExecutor(e.target.value)
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField label={'Брой часове'}/>
            </Grid>
            <Grid item xs={3}>
                <TextField label={'Цена/час'}/>
            </Grid>
            <Grid item xs={3}>
                <TextField label={'Заработка'}/>
            </Grid>


        </Grid>
    )

}
export default TransportTab;