import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import moment from "moment";
import bg from 'moment/locale/bg'
import {getObject} from "../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";
import TextField from "@mui/material/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function ObjectDataDialog({open, handleClose, onClick, setObjectSettings, editObject,
                              setSelectedObjectForOffer, setSelectedObjectForOfferName,
                              objectNameOutside, contractMode, editMode}) {
    moment.locale("bg"); // it is required to select default locale manually
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [client, setClient] = useState(null);
    const [orderer, setOrderer] = useState(null);
    const [objectName, setObjectName] = useState(null);
    const [address, setAddress] = useState(null);
    const [description, setDescription] = useState(null);
    const [responsible, setResponsible] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [area, setArea] = useState(null);
    const [workingDays, setWorkingDays] = useState(null);

    const [trudNepreki, setTrudNepreki] = useState(30);
    const [materialNepreki, setMaterialNepreki] = useState(10);
    const [uslugiNepreki, setUslugiNepreki] = useState(10);
    const [mechNepreki, setMechNepreki] = useState(10);


    const [trudProfit, setTrudProfit] = useState(10);
    const [materialProfit, setMaterialProfit] = useState(10);
    const [uslugiProfit, setUslugiProfit] = useState(10);
    const [mechProfit, setMechProfit] = useState(10);

    const [materialDostSklad, setMaterialDostSklad] = useState(10);


    const [editEnabled, setEditEnabled] = useState(false);


    const [deliveryPrices, setDeliveryPrices] = useState(null);

    useEffect(() => {
        console.log('editObject ', editObject)
        if(editObject) {
            getObject(navigate, accessToken, setAccessToken, editObject)
                .then((result) => {
                    if (result && result.items) {
                        setOrderer(result.items.investor)
                        setObjectName(result.items.project_name)
                        setAddress(result.items.address)
                        setResponsible(result.items.technical_manager)
                    }
                })
        }
    }, [editObject, open])

    useEffect(() => {
        setObjectName(objectNameOutside)
    }, [objectNameOutside])

    return  (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Данни за обект
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container padding={3}>

                <Grid item xs={7}>
                    <Grid container>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="orderer"
                                value={orderer}
                                disabled={editMode ? !editEnabled : false}
                                onChange={(event) =>  {setOrderer(event.target.value)}}
                                label="Възложител"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="objectName"
                                value={objectName}
                                disabled={editMode ? !editEnabled : false}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) =>  {
                                    setObjectName(event.target.value)
                                }}
                                label="Име на обект"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="address"
                                disabled={editMode ? !editEnabled : false}
                                value={address}
                                onChange={(event) =>  {setAddress(event.target.value)}}
                                label="Адрес"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="description"
                                value={description}
                                disabled={editMode ? !editEnabled : false}
                                onChange={(event) =>  {setDescription(event.target.value)}}
                                label="Описание"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="responsible"
                                value={responsible}
                                disabled={editMode ? !editEnabled : false}
                                onChange={(event) =>  {setResponsible(event.target.value)}}
                                label="Отговорник"
                            />
                        </Grid>
                        <Grid item xs={10}>
                        </Grid>




                        <Grid item xs={3.5}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="from"
                                value={fromDate}
                                disabled={editMode ? !editEnabled : false}
                                onChange={(event) =>  {}
                                }
                                label="От"
                            />
                        </Grid>
                        <Grid item xs={1}/>
                        <Grid item xs={3.5}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="to"
                                value={toDate}
                                disabled={editMode ? !editEnabled : false}
                                onChange={(event) =>  {}
                                }
                                label="До"
                            />
                        </Grid>





                        <Grid item xs={10}>

                        </Grid>
                        <Grid item xs={3.5}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="area"
                                value={area}
                                disabled={editMode ? !editEnabled : false}
                                onChange={(event) =>  {setArea(event.target.value)}}
                                label="Площ (кв. м.)"
                            />
                        </Grid>
                        <Grid item xs={1}/>
                        <Grid item xs={3.5}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="workingDays"
                                value={workingDays}
                                disabled={editMode ? !editEnabled : false}
                                onChange={(event) =>  {setWorkingDays(event.target.value)}
                                }
                                label="Работни дни"
                            />
                        </Grid>

                        <Grid item xs={12}/>

                        <Grid item xs={2.75}>
                            <Button
                                fullWidth
                                variant={'contained'}
                                id={"save"}
                                disabled={editMode ? !editEnabled : false}
                                onClick={(event) =>  {
                                    if (onClick) {
                                        //likely addObject
                                        onClick(navigate, accessToken, setAccessToken,
                                            objectName, objectName.substring(0, 2), orderer, address,
                                            "investor", description, area, workingDays, responsible,
                                            contractMode ? 'contract' : "offer")
                                            .then((result) => {
                                                console.log('closing ggg')
                                                if (setSelectedObjectForOffer) {
                                                    setSelectedObjectForOffer(result.id)
                                                }
                                                if (setSelectedObjectForOfferName) {
                                                    setSelectedObjectForOfferName(objectName)
                                                }
                                                setObjectSettings(false)
                                                handleClose()
                                            })
                                    }
                                }}
                            >Запис</Button>
                        </Grid>

                        <Grid item xs={2.75} style={{marginLeft: 15}}>
                            <Button
                                fullWidth
                                variant={'contained'}
                                id={"edit"}
                                onClick={(event) =>  {
                                    setEditEnabled(!editEnabled)
                                }}
                            >Редакция</Button>
                        </Grid>

                    </Grid>
                </Grid>


                <Grid item xs={5}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>


                            <Grid item xs={6.5}>
                                <Typography variant="h5">Труд</Typography>
                            </Grid><Grid item xs={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="client"
                                value={trudNepreki}
                                disabled={editMode ? !editEnabled : false}
                                onChange={(event) => setTrudNepreki(event.target.value)}
                                label="Непреки разходи %"
                            />
                        </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="client"
                                    value={trudProfit}
                                    disabled={editMode ? !editEnabled : false}
                                    onChange={(event) =>  setTrudProfit(event.target.value)}
                                    label="Печалба %"
                                />
                            </Grid>




                            <Grid item xs={9}>
                                <Typography variant="h5">Материали</Typography>
                            </Grid><Grid item xs={4}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="client"
                                value={materialNepreki}
                                disabled={editMode ? !editEnabled : false}
                                onChange={(event) =>  setMaterialNepreki(event.target.value)}
                                label="Спомагателни %"
                            />
                        </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="client"
                                    value={materialDostSklad}
                                    disabled={editMode ? !editEnabled : false}
                                    onChange={(event) =>  setMaterialDostSklad(event.target.value)}
                                    label="Доставно складови %"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="client"
                                    value={materialProfit}
                                    disabled={editMode ? !editEnabled : false}
                                    onChange={(event) =>  setMaterialProfit(event.target.value)}
                                    label="Печалба %"
                                />
                            </Grid>



                            <Grid item xs={6.5}>
                                <Typography variant="h5">Външни услуги</Typography>
                            </Grid><Grid item xs={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="client"
                                value={uslugiNepreki}
                                disabled={editMode ? !editEnabled : false}
                                onChange={(event) =>  setUslugiNepreki(event.target.value)}
                                label="Непреки %"
                            />
                        </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="client"
                                    value={uslugiProfit}
                                    disabled={editMode ? !editEnabled : false}
                                    onChange={(event) =>  setUslugiProfit(event.target.value)}
                                    label="Печалба %"
                                />
                            </Grid>




                            <Grid item xs={6.5}>
                                <Typography variant="h5">Механизация</Typography>
                            </Grid><Grid item xs={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="client"
                                value={mechNepreki}
                                disabled={editMode ? !editEnabled : false}
                                onChange={(event) =>  setMechNepreki(event.target.value)}
                                label="Непреки %"
                            />
                        </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="client"
                                    value={mechProfit}
                                    disabled={editMode ? !editEnabled : false}
                                    onChange={(event) =>  setMechProfit(event.target.value)}
                                    label="Печалба %"
                                />
                            </Grid>





                        </Grid>
                    </Grid>
                </Grid>

            </Grid>


        </Dialog>
    )
}
export default ObjectDataDialog;