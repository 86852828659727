import Grid from "@mui/material/Grid";
import * as React from "react";

function ReportsExecutorsScreen() {

    return (
        <Grid container spacing={1} justifyContent={'flex-start'}>


        </Grid>
    )
}
export default ReportsExecutorsScreen;