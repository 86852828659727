import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {getUsers} from "../../utils/NetworkUtils";
import {Tab, Tabs} from "@mui/material";
import TabPanel from "../helper/TabPanel";
import TransportTab from "./addDetailContent/TransportTab";
import AdditionalLabourTab from "./addDetailContent/AdditionalLabourTab";

// open, handleSubmit, handleClose, comment, setComment, date, setDate, userId, taskId, details, setDetails addDetail
export default function AddDetailDialog(props) {

    const [users, setUsers] = React.useState([]);

    const [attachment, setAttachment] = React.useState(null);
    const [count, setCount] = React.useState(1);

    const [tab, setTab] = useState(0);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        getUsers(props.navigate, props.accessToken, props.setAccessToken, setUsers)
    }, []);

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <form>
                <DialogTitle>Добави коментар</DialogTitle>
                <DialogContent>
                    <Grid container direction="row" spacing={2}>

                        <Grid item xs={12}>
                            <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Допълнителен труд" {...a11yProps(0)} />
                                <Tab label="Транспорт" {...a11yProps(2)} />
                            </Tabs>
                        </Grid>

                        <TabPanel value={tab} index={0}>
                            <AdditionalLabourTab/>
                        </TabPanel>

                        <TabPanel value={tab} index={1}>
                            <TransportTab/>
                        </TabPanel>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Отказ</Button>

                    <Button onClick={() => {
                        return;
                        let roadCard = null;
                        if (props.isRoadListOpen) {
                            roadCard = {
                                "id_partner": props.roadAssignee ? props.roadAssignee.id : 0,
                                "dashboard_begin": props.dashboardStart,
                                "dashboard_end": props.dashboardEnd,
                                "price_fuel": props.fuelPrice,
                            }
                        }

                        props.addDetail(props.navigate, props.accessToken, props.setAccessToken, props.userId, props.taskId, props.date, props.comment, props.detailQuantity, props.hour, roadCard, attachment)
                            .then((response) => {
                                const newDetails = props.details ?? [];
                                newDetails.push({
                                    id: response.id,
                                    id_creater: props.userId,
                                    comment: props.comment,
                                    qty: props.detailQuantity,
                                    date_task: moment(props.date).format("YYYY-MM-DD"),
                                    hour: props.hour,
                                    hour_start: props.hour
                                })
                                props.setPopoverDetails(newDetails)
                                props.setPopoverOpen(null)
                                props.setComment(null)
                                props.setDetailQuantity(null)
                                props.setRoadAssignee(null)
                                props.setDashboardStart(null)
                                props.setDashboardEnd(null)
                                props.setFuelPrice(null)
                                props.setDate(moment(new Date()))
                            })
                        props.handleClose();
                    }} disabled={(attachment && attachment.length > 0 ? false : !props.comment) ||
                            (props.isRoadListOpen && (!props.roadAssignee || !props.fuelPrice || !props.dashboardStart || !props.dashboardEnd))
                    }>Добави</Button>
                </DialogActions>
            </form>
        </Dialog>
    )

}