import Grid from "@mui/material/Grid";
import React, {useEffect} from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import WaterIcon from "@mui/icons-material/Water";
import ListItemText from "@mui/material/ListItemText";
import {Collapse} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TodayIcon from "@mui/icons-material/Today";
import AddDetailDocDialog from "../documentation/internalUI/AddDetailDialog";
import CommentIcon from '@mui/icons-material/Comment';
import CircleStatusIcons from "../components/helper/CircleStatusIcons";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";
import {CommonFetch} from "../services/api-fetch";

function DocsScreen() {

    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);


    const open = true;

    const [openInvoice, setOpenInvoice] = React.useState(null);

    const [title, setTitle] = React.useState(null);


    const [subSelected, setSubSelected] = React.useState(null);



    const [openReportsDay, setOpenReportsDay] = React.useState(null);

    const [availableStatuses, setAvailableStatuses] = React.useState();


    const [openCommentId, setOpenCommentId] = React.useState(null);
    const [openCommentFilter, setOpenCommentFilter] = React.useState(null);
    const [commentToName, setCommentToName] = React.useState(null);

    const subItemStyle = (subSelectedIndex) => {return { ml: 4, background: subSelected === subSelectedIndex ? '#1b73ca' : null,
        color: subSelected === subSelectedIndex ? 'white' : null}}
    const subItemIconStyle = (subSelectedIndex) => {
        return {color: subSelected === subSelectedIndex ? 'white' : null}
    }

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/mywave/getStatuses?`+ new URLSearchParams(), {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(
                {
                    "token": accessToken,
                    'id': [
                        {"id":5},
                        {"id":1},
                        {"id":2},
                        {"id":3},
                    ]
                }
            )
        })
            .then((result) => {
                if (result && result.items) {
                    setAvailableStatuses(result.items)
                }
            })
    }, [openCommentId])

    function isStatusAvailable(id, statusId) {
        if (availableStatuses) {
            let itemWithStatuses = availableStatuses.find((item) => item.id === id);
            if (itemWithStatuses && itemWithStatuses.statuses) {
                return itemWithStatuses.statuses.find(status => status.status === statusId)
            }
            return false;

        }

        return false;
    }


    return (
        <Grid container justifyContent={"flex-start"}>

            <Grid item xs={12}>

                <ListItemButton sx={subItemStyle(0)} onClick={() => {
                    setOpenInvoice(!openInvoice)
                }}>
                    <Grid container>

                        <Grid item xs={1} sm={0.5}>
                            <ListItemIcon>
                                <WaterIcon sx={subItemIconStyle(0)}/>
                            </ListItemIcon>
                        </Grid>

                        <Grid item xs={6}>
                            <ListItemText primary="Задачи" />
                        </Grid>



                        <Grid item xs={1} sm={0.5}>
                            <ListItemButton onClick={(e) => {
                                e.stopPropagation()
                                e.nativeEvent.stopImmediatePropagation();
                                setOpenCommentId(5)
                                setCommentToName('Задачи')
                            }}> <CommentIcon/>
                            </ListItemButton>
                        </Grid>

                        <Grid item xs={1} sm={0.5}>
                            {isStatusAvailable(5, 2) ?
                                <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(5)
                                    setOpenCommentFilter(2)
                                    setCommentToName('Задачи')
                                }}> <CircleStatusIcons taskState={"2"}/>
                                </ListItemButton> : null}

                        </Grid>

                        <Grid item xs={1} sm={0.5}>
                            {isStatusAvailable(5, 0) ? <ListItemButton onClick={(e) => {
                                e.stopPropagation()
                                e.nativeEvent.stopImmediatePropagation();

                                setOpenCommentId(5)
                                setOpenCommentFilter(0)
                                setCommentToName('Задачи')
                            }}> <CircleStatusIcons taskState={"0"}/>
                            </ListItemButton> : null}
                        </Grid>

                        <Grid item xs={1} sm={0.5}>
                            {isStatusAvailable(5, 1) ? <ListItemButton onClick={(e) => {
                                e.stopPropagation()
                                e.nativeEvent.stopImmediatePropagation();

                                setOpenCommentId(5)
                                setOpenCommentFilter(1)
                                setCommentToName('Задачи')
                            }}> <CircleStatusIcons taskState={"1"}/>
                            </ListItemButton> : null }
                        </Grid>

                        <Grid item xs={1} sm={0.5}>
                            {isStatusAvailable(5, 3) ? <ListItemButton onClick={(e) => {
                                e.stopPropagation()
                                e.nativeEvent.stopImmediatePropagation();

                                setOpenCommentId(5)
                                setOpenCommentFilter(3)
                                setCommentToName('Задачи')
                            }}> <CircleStatusIcons taskState={"3"}/>
                            </ListItemButton> : null}
                        </Grid>
                    </Grid>

                </ListItemButton>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={openInvoice && open} timeout="auto" unmountOnExit>

                    <List component="div" disablePadding style={{marginLeft: 16}}>
                    <ListItemButton sx={subItemStyle(0)} onClick={() => {

                    }}>
                        <Grid container>

                            <Grid item xs={1} sm={0.5}>
                                <ListItemIcon>
                                    <CalendarMonthIcon sx={subItemIconStyle(0)}/>
                                </ListItemIcon>
                            </Grid>


                            <Grid item xs={6}>
                                <ListItemText primary="Обекти - задачи" />
                            </Grid>


                            <Grid item xs={1} sm={0.5}>
                                <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();
                                    setOpenCommentId(1)
                                    setCommentToName('Обекти - задачи')

                                }}> <CommentIcon/>
                                </ListItemButton>
                            </Grid>


                            <Grid item xs={1} sm={0.5}>
                                {isStatusAvailable(1, 2) ? <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(1)
                                    setOpenCommentFilter(2)
                                    setCommentToName('Обекти - задачи')
                                }}> <CircleStatusIcons taskState={"2"}/>
                                </ListItemButton> : null }
                            </Grid>

                            <Grid item xs={1} sm={0.5}>
                                {isStatusAvailable(1, 0) ? <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(1)
                                    setOpenCommentFilter(0)
                                    setCommentToName('Обекти - задачи')
                                }}> <CircleStatusIcons taskState={"0"}/>
                                </ListItemButton> : null }
                            </Grid>

                            <Grid item xs={1} sm={0.5}>
                                {isStatusAvailable(1, 1) ? <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(1)
                                    setOpenCommentFilter(1)
                                    setCommentToName('Обекти - задачи')
                                }}> <CircleStatusIcons taskState={"1"}/>
                                </ListItemButton> : null }
                            </Grid>

                            <Grid item xs={1} sm={0.5}>
                                {isStatusAvailable(1, 3) ? <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(1)
                                    setOpenCommentFilter(3)
                                    setCommentToName('Обекти - задачи')
                                }}> <CircleStatusIcons taskState={"3"}/> : null }
                                </ListItemButton> : null }
                            </Grid>

                        </Grid>

                    </ListItemButton>

                    <ListItemButton sx={subItemStyle(1)} onClick={() => {
                        setSubSelected(1)
                        setTitle('Седмични задачи')
                    }}>
                        <Grid container>

                            <Grid item xs={1} sm={0.5}>
                                <ListItemIcon>
                                    <DateRangeIcon sx={subItemIconStyle(1)}/>
                                </ListItemIcon>
                            </Grid>


                            <Grid item xs={6}>
                                <ListItemText primary="Седмични задачи" sx={subItemIconStyle(1)}/>
                            </Grid>


                            <Grid item xs={1} sm={0.5}>
                                <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();
                                    setOpenCommentId(2)
                                    setCommentToName('Седмични задачи')

                                }}> <CommentIcon/>
                                </ListItemButton>
                            </Grid>


                            <Grid item xs={1} sm={0.5}>
                                {isStatusAvailable(2, 2) ? <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(2)
                                    setOpenCommentFilter(2)
                                    setCommentToName('Седмични задачи')
                                }}> <CircleStatusIcons taskState={"2"}/>
                                </ListItemButton> : null }
                            </Grid>

                            <Grid item xs={1} sm={0.5}>
                                {isStatusAvailable(2, 0) ? <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(2)
                                    setOpenCommentFilter(0)
                                    setCommentToName('Седмични задачи')
                                }}> <CircleStatusIcons taskState={"0"}/>
                                </ListItemButton> : null }
                            </Grid>

                            <Grid item xs={1} sm={0.5}>
                                {isStatusAvailable(2, 1) ? <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(2)
                                    setOpenCommentFilter(1)
                                    setCommentToName('Седмични задачи')
                                }}> <CircleStatusIcons taskState={"1"}/>
                                </ListItemButton> : null }
                            </Grid>



                            <Grid item xs={1} sm={0.5}>
                                {isStatusAvailable(2, 3) ? <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(2)
                                    setOpenCommentFilter(3)
                                    setCommentToName('Седмични задачи')
                                }}> <CircleStatusIcons taskState={"3"}/>
                                </ListItemButton> : null }
                            </Grid>

                        </Grid>

                    </ListItemButton>


                    <ListItemButton sx={subItemStyle(2)} onClick={() => {
                        setSubSelected(2)
                        setTitle('Дневни задачи')
                        setOpenReportsDay(true)
                    }}>

                        <Grid container>

                            <Grid item xs={1} sm={0.5}>
                                <ListItemIcon>
                                    <TodayIcon sx={subItemIconStyle(2)}/>
                                </ListItemIcon>
                            </Grid>


                            <Grid item xs={6}>
                                <ListItemText primary="Дневни задачи" sx={subItemIconStyle(2)}/>
                            </Grid>


                            <Grid item xs={1} sm={0.5}>
                                <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();
                                    setOpenCommentId(3)
                                    setCommentToName('Дневни задачи')

                                }}> <CommentIcon/>
                                </ListItemButton>
                            </Grid>


                            <Grid item xs={1} sm={0.5}>
                                {isStatusAvailable(3, 2) ? <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(3)
                                    setOpenCommentFilter(2)
                                    setCommentToName('Дневни задачи')
                                }}> <CircleStatusIcons taskState={"2"}/>
                                </ListItemButton> : null }
                            </Grid>

                            <Grid item xs={1} sm={0.5}>
                                {isStatusAvailable(3, 0) ? <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(3)
                                    setOpenCommentFilter(0)
                                    setCommentToName('Дневни задачи')
                                }}> <CircleStatusIcons taskState={"0"}/>
                                </ListItemButton> : null}
                            </Grid>

                            <Grid item xs={1} sm={0.5}>
                                {isStatusAvailable(3, 1) ? <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(3)
                                    setOpenCommentFilter(1)
                                    setCommentToName('Дневни задачи')
                                }}> <CircleStatusIcons taskState={"1"}/>
                                </ListItemButton> : null }
                            </Grid>



                            <Grid item xs={1} sm={0.5}>
                                {isStatusAvailable(3, 3) ? <ListItemButton onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation();

                                    setOpenCommentId(3)
                                    setOpenCommentFilter(3)
                                    setCommentToName('Дневни задачи')
                                }}> <CircleStatusIcons taskState={"3"}/>
                                </ListItemButton> : null }
                            </Grid>


                        </Grid>
                    </ListItemButton>


                </List>
                </Collapse>
            </Grid>


            <AddDetailDocDialog openId={openCommentId} handleClose={() => {
                setOpenCommentId(null)
                setOpenCommentFilter(null)
            }} commentToName={commentToName} openCommentFilter={openCommentFilter}/>
        </Grid>
    );
}
export default DocsScreen;