import Grid from "@mui/material/Grid";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import moment from "moment/moment";
import * as React from "react";
import {useEffect, useState} from "react";
import {getGraphicPerViewType} from "../../../utils/NetworkUtils";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import CircleStatusIcons from "../../helper/CircleStatusIcons";
import {CommonFetch} from "../../../services/api-fetch";
import CommentsOnlyForDailyTasksDialog from "../../dialogs/addDetailContent/CommentsOnlyForDailyTasksDialog";
import CommentIcon from '@mui/icons-material/Comment';
import SetStatusDialog from "./SetStatusDialog";
import AssignExecutorToAnalysisWithoutQtyDialog
    from "../../executorDialog/deliverer/AssignExecutorToAnalysisWithoutQtyDialog";

function GraphicDaysTable({selectedDay, setSelectedDay, items, tab, setGraphicEditDialog, setConfirmMove, selectedObject, year, selectedWeeks, filterAnex, setForceRefresh, searchTerm}) {
    const NUM_PERC = '5%';
    const NAME_PERC = '75%';
    const ENTITY_PERC = '8%';
    const QTY_PERC = '7%';
    const ENT_PRICE_PERC = '8%';
    const TOTAL_PERC = '10%';
    const QTY_MONEY = '3%';
    const DAILY_MONEY = '1%';


    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [datesRange, setDatesRange] = useState([]);

    const [assignWorkerOpen, setAssignWorkerOpen] = useState(false);

    const [taskType, setTaskType] = useState(moment());
    const [commentsOnlyOpen, setCommentsOnlyOpen] = React.useState(null);
    const [nameAddDialog, setNameAddDialog] = useState(null);
    const [nameObject, setNameObject] = useState(null);


    const [selectedDayForTask, setSelectedDayForTask] = useState(null);
    const [setStatus, setSetStatus] = useState(null);
    const [setStatusType, setSetStatusType] = useState(null);

    let startDate = new Date();
    startDate = startDate.setDate(27)

    let endDate = new Date();
    endDate = endDate.setDate(endDate.getDate() + 14)

    function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    useEffect(() => {
        if (!selectedDay) {
            return;
        }
        let startDate = moment(getMonday(selectedDay.toDate()));
        console.log('startDate ', startDate)

        let endDate = startDate.clone().add(6, 'days');

        setDatesRange(getDatesRange(startDate, endDate))
    }, [selectedDay])

    function getDatesRange(a, b) {
        let dates = [];
        for (var m = moment(a); m.isBefore(b); m.add(1, 'days')) {
            console.log('m is ', m.format('YYYY-MM-DD'));
            dates.push(m.clone())
        }
        console.log('m is ', m.format('YYYY-MM-DD'));
        dates.push(m.clone())

        return dates;

    }

    function getDaysInWeek(weekNumber, year) {
        var firstDateOfWeek = getFirstDateOfWeek(weekNumber, year);
        firstDateOfWeek.setDate(firstDateOfWeek.getDate() + 7);

        var days = [];

        for (var i = 0; i < 7; i++) {
            var currentDate = new Date(firstDateOfWeek.getFullYear(), firstDateOfWeek.getMonth(), firstDateOfWeek.getDate() + i);
            days.push(currentDate);
        }

        return days;
    }

    function getFirstDateOfWeek(weekNumber, year) {
        // Create a new date object for January 1st of the specified year
        var firstDayOfYear = new Date(year, 0, 1);

        // Calculate the offset to the first day of the first week
        var offset = firstDayOfYear.getDay() - 1;
        if (offset === -1) {
            offset = 6;  // Adjust for Sunday being the first day of the week
        }

        // Calculate the number of days to add to the first day of the year to get to the first day of the specified week
        var daysToAdd = (weekNumber - 1) * 7 - offset;

        // Create a new date object by adding the calculated number of days to the first day of the year
        return new Date(year, 0, 1 + daysToAdd);
    }


    useEffect(() => {
        if (selectedWeeks.length <= 0) {
            return;
        }

        let firstDate = getFirstDateOfWeek(selectedWeeks[0], Number(year.format('YYYY')))
        let lastDate = getFirstDateOfWeek(selectedWeeks[selectedWeeks.length - 1], Number(year.format('YYYY')));

        getGraphicPerViewType(navigate, accessToken, setAccessToken, selectedObject,
            firstDate.getFullYear() + '-' + (firstDate.getMonth() + 1) + '-' + firstDate.getDate(),
            lastDate.getFullYear() + '-' + (lastDate.getMonth() + 1) + '-' + lastDate.getDate(),
            'days')
            .then((result) => {
                if (result && result.items) {
                    for (const resultElement of result.items) {
                        resultElement.name = resultElement.name_group;
                        resultElement.sub = resultElement.subgroups;
                        resultElement.details = resultElement.subgroups;
                        for (const resultElementElement of resultElement.sub) {
                            resultElementElement.name = resultElementElement.name_group;
                            for (const work of resultElementElement.works) {
                                work.name = work.name_work;
                                //work.entity = work.edm;
                                work.quantity = work.qty;
                                work.startDate = work.date_start;
                                work.endDate = work.date_end;
                            }
                        }
                    }

                    //setYearItems(result.items)
                }
            })
    }, [])

/*    {
        getDates(ranges[0].startDate, ranges[0].endDate).map((dateInList, index, array) => {
            return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center">
                <b>
                    {moment(dateInList).format('DD')}
                </b>
                <br/>
                {moment(dateInList).format('dd')}

            </TableCell>
        })









    }*/

    let globIndex = 1;

    return (
        <Grid item xs={12}>
            <TableContainer sx={{ maxHeight: "76vh" }}>

                <Grid item xs={12}>
                    {items && Object.keys(items).length > 0 ?
                        <Table stickyHeader size={'small'} sx={{border: 0.2}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{border: 1}} width={NUM_PERC}
                                               align="left"><b>№</b></TableCell>
                                    <TableCell sx={{border: 1}} width={NAME_PERC}
                                               align="left"><b>Обект - задача</b></TableCell>
                                   {/* <TableCell sx={{border: 1}} width={ENTITY_PERC}
                                               align="center"><b>Изпълнител</b></TableCell>*/}
                                    {
                                        datesRange.map((dateInList, index, array) => {
                                            return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center">
                                                <b>
                                                    {moment(dateInList).format('DD')}
                                                </b>
                                                <br/>
                                                {moment(dateInList).format('dd')}

                                            </TableCell>
                                        })
                                    }

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { items && Object.keys(items).map((item, index) =>
                                    items && items[item] && items[item]
                                        .filter((subItem) => {
                                            if (!searchTerm) {
                                                return true;
                                            }
                                            let string = `${item} - ${subItem.hour_start ? moment(subItem.hour_start, 'YYYY-MM-DD HH:mm').format('HH:mm') + " - ": null}
                                                ${subItem.hour_end ? moment(subItem.hour_end, 'YYYY-MM-DD HH:mm').format('HH:mm') + " - ": null}
                                                ${subItem.name_group ? (subItem.name_group + ' - ') : null}${subItem.name_work ? (subItem.name_work + ' - ') : null}${subItem.subject}`
                                            return string ? string.toUpperCase().includes(searchTerm.toUpperCase()) : false;

                                        })
                                        .map((subItem) => {
                                        return <React.Fragment>
                                            <TableRow
                                                key={globIndex}
                                            >
                                                <TableCell sx={{border: 1}} width={NUM_PERC}
                                                           align="left"><b>{globIndex++}</b></TableCell>
                                                <TableCell sx={{border: 1}} width={NAME_PERC}
                                                           align="left" onClick={() => {
                                                   if (subItem.id_task) {
                                                       setTaskType("task")

                                                       setCommentsOnlyOpen(subItem.id_task)
                                                       setNameAddDialog(subItem.name_work)
                                                       setNameObject(item)
                                                    } else {
                                                       setTaskType("done")
                                                       setCommentsOnlyOpen(subItem.id_awork)
                                                       setNameAddDialog(subItem.name_work)
                                                       setNameObject(item)
                                                    }
                                                }}>
                                                    <Grid container>
                                                        <Grid item xs={11}>
                                                            <Typography><b>{item} - {subItem.name_etap ? (subItem.name_etap + ' - ') : null}
                                                                {subItem.name_group ? (subItem.name_group + ' - ') : null}{subItem.name_work ? (subItem.name_work + ' - ') : null}{subItem.subject}</b></Typography>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>



                                                {/*<TableCell sx={{border: 1}} width={ENTITY_PERC}
                                                           align="left">   {subItem.partners && subItem.partners.length>0 ?

                                                    <Tooltip
                                                        classes={{ tooltip: {
                                                                backgroundColor: 'rgba(126,255,248,0.7)'
                                                            } }}
                                                        title={
                                                            <div style={{ whiteSpace: 'pre-line' }}>
                                                                <p style={{fontSize: 15}}>{subItem.partners ?
                                                                    (subItem.partners.map( (item) => item.partner_name) ?? []).join('\n')
                                                                    : null}</p>
                                                            </div>
                                                        }
                                                    >
                                                        <Typography sx={{
                                                            display: '-webkit-box',
                                                            overflow: 'hidden',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: 1,
                                                        }} style={{textAlign: 'left'}} onClick={() => {
                                                            setAssignWorkerOpen(subItem.id_task)
                                                        }}>
                                                            {subItem.partners.length === 1 ?
                                                                ((subItem.partners[0].partner_name ?? " ").split(" ").length > 0 ? (subItem.partners[0].partner_name ?? " ").split(" ")[0] : null)
                                                                : subItem.partners.length + ' изпълнители'}
                                                        </Typography>
                                                    </Tooltip>

                                                    :

                                                    <Button onClick={() => {
                                                        setAssignWorkerOpen(subItem.id_task)
                                                    }}>Изпълнител</Button>}

                                                </TableCell>*/}




                                                {
                                                    datesRange.map((dateInList, index, array) => {
                                                        let workDates = subItem.dates ?? [];
                                                        let statusObjectForDateIndex = workDates.findLastIndex((obj) => obj.date === moment(dateInList).format('YYYY-MM-DD'));

                                                        if (subItem.id_task === 2515) {
                                                            console.log('statusObjectForDateIndex ', statusObjectForDateIndex)
                                                            console.log('statusObjectForDateIndex workDates ', workDates)
                                                            console.log('statusObjectForDateIndex moment(dateInList ', moment(dateInList).format('YYYY-MM-DD'))
                                                            console.log('statusObjectForDateIndex --------------------')

                                                        }
                                                        let statusObjectForDate = statusObjectForDateIndex > -1 ? workDates[statusObjectForDateIndex].status : null;
                                                        let commentsForDate = statusObjectForDateIndex > -1 ? workDates[statusObjectForDateIndex].comments : null;


                                                        return <TableCell sx={{border: 1, padding: 0,}} width={DAILY_MONEY} align="center"
                                                                          onClick={(event) => {
                                                                              event.stopPropagation()
                                                                              event.nativeEvent.stopImmediatePropagation();

                                                                              let partners = subItem.partners;
                                                                              for (const partner of partners) {
                                                                                  partner.id_partner = partner.id;
                                                                              }

                                                                              /*setSetStatus(subItem.id_task || subItem.id_awork)
                                                                              setSetStatusType(subItem.type)*/
                                                                              setSelectedDayForTask(moment(dateInList))
                                                                              setSetStatus({
                                                                                  id_task: subItem.id_task,
                                                                                  id_awork: subItem.id_awork,
                                                                                  name: subItem.name_work ? (subItem.name_work + ' - ') : null + subItem.subject,
                                                                                  partners: partners,
                                                                                  hour: subItem.hour,
                                                                                  hour_end: subItem.hour_end,
                                                                                  date: moment(dateInList).format('YYYY-MM-DD'),
                                                                                  "type": subItem.type === "task" ? "task" : "graph"
                                                                              })
                                                                          }}>

                                                            <div style={{
                                                                position: 'relative',
                                                                left: '0px',
                                                                top: '0px'}}>


                                                                <span style={{position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',

                                                                }}>
                                                                    <CircleStatusIcons taskState={statusObjectForDate + ""}/>
                                                                </span>

                                                                {commentsForDate > 0 ? <span style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',

                                                                    zIndex: 2
                                                                }}>
                                                                    <b>*</b>
                                                                </span> : null}
                                                            </div>

                                                        </TableCell>
                                                    })
                                                }

                                            </TableRow>

                                        </React.Fragment>

                                    }))
                                }
                            </TableBody>
                        </Table> :

                        <div style={{padding: 20}}>
                            <Typography>Няма резултати</Typography>
                        </div>
                    }
                </Grid>

            </TableContainer>


            {commentsOnlyOpen ? <CommentsOnlyForDailyTasksDialog
                open={commentsOnlyOpen}
                name={nameAddDialog}
                objectName={nameObject}
                selectedObject={selectedObject}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                type={taskType}
                setForceRefreshMain={setForceRefresh}
                handleClose={() => setCommentsOnlyOpen(false)}/> : null}

            <SetStatusDialog
                open={setStatus}
                partners={setStatus ? setStatus.partners : null}
                hour={setStatus ? setStatus.hour : null}
                hourEnd={setStatus ? setStatus.hour_end : null}
                handleClose={() => {
                    setSetStatus(null)
                }}
                setForceRefresh={setForceRefresh}/>


{/*
            <CommentsOnlyForDailyTasksDialog
                open={setStatus}
                name={nameAddDialog}
                type={setStatusType}
                handleClose={() => setSetStatus(false)}
                setForceRefreshMain={setForceRefresh}
                selectedDay={selectedDayForTask}
                setSelectedDay={setSelectedDayForTask}/>
*/}


            <AssignExecutorToAnalysisWithoutQtyDialog
                open={assignWorkerOpen}
                handleClose={() => setAssignWorkerOpen(false)}
                onClick={(executor) => {

                    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/get?`+ new URLSearchParams({
                        token: accessToken,
                        id: assignWorkerOpen
                    }), {
                        method: "GET",
                    })
                        .then((result) => {
                            const task = result.items[0];

                            if (task) {
                                CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/tasks/update`, {
                                    method: "POST",
                                    mode: 'cors',
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        "id": task.id,
                                        "id_user": task.id_user,
                                        "id_project": task.id_project,
                                        "id_etap": task.id_etap,
                                        "id_aworkgroup": task.id_aworkgroup,
                                        "id_work": task.id_work,
                                        "id_partner": executor ? executor.id : null,
                                        "subject": task.subject,
                                        "status": task.status,
                                        "date_start": task.date_start,
                                        "hour_start": task.hour_start,
                                        "users": task.users,
                                        "token": accessToken
                                    })
                                })
                                    .then(
                                        (result) => {
                                            console.log(result)
                                            setForceRefresh(Math.random())
                                        },
                                        (error) => {
                                            console.log('error: ', error)
                                        }
                                    );
                            }


                            console.log('executor ', executor)
                            setAssignWorkerOpen(false)
                        })

                }}/>

        </Grid>

    )
}
export default GraphicDaysTable;