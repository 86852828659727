import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";
import React from "react";
import Grid from "@mui/material/Grid";

function BeginningScreen() {
    const SERVICE = 'SERVICE';
    const MATERIAL = 'MATERIAL';
    const color = 'rgba(164,62,227,0.49)';

    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [objects, setObjects] = React.useState([]);
    const [refreshObjects, setRefreshObjects] = React.useState([]);


    const [selectedObject, setSelectedObject] = React.useState(0);

    return (
        <Grid container>
            <img style={{marginLeft: '2%', width: '90%'}} src={'https://img.capital.bg/shimg/zx1278_4315731.jpg'}/>
        </Grid>
    )
}
export default BeginningScreen;
