import Dialog from "@mui/material/Dialog";
import * as React from "react";
import Slide from "@mui/material/Slide";
import AddDelivererComponent from "./AddDelivererComponent";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AddDelivererDialog({open, handleClose, objectId}) {
    const [checked, setChecked] = React.useState([]);
    const [tab, setTab] = React.useState(0);


    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}>

            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Изпълнители / Бригади
                    </Typography>


                </Toolbar>
            </AppBar>

            <AddDelivererComponent open={open}
                                   tab={tab} setTab={setTab}
                                   checked={checked} setChecked={setChecked}/>
        </Dialog>
    )
}

export default AddDelivererDialog;