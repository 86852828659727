import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import React from "react";
import Popover from "@mui/material/Popover";
import CommentsList from "./CommentsList";

function TaskPopover(props) {

    const open = Boolean(props.anchorEl);

    return (
    <Popover
        id={'value'}
        open={open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
    >
        <Grid container>

            <Grid item xs={12}>

                {
                    (props.popoverDetails && props.popoverDetails.length > 0) &&


                    <CommentsList taskId={open} {...props}/>
                }

                <Button sx={{
                    p: 2,
                    pt: 1,
                }} onClick={() => {
                    if (props.setAddDetailDialogOpen) {
                        props.setAddDetailDialogOpen(true)
                    }
                }}>Добави коментар</Button>
            </Grid>
        </Grid>
    </Popover>
    )
}

export default TaskPopover;