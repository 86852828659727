import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {CommonFetch} from "../../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import moment from "moment";
import AutocompleteUsers from "../../../inputs/AutocompleteUsers";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PartnerPickForTransportLightweightDialog({open, handleClose, onClick,
                                                                     executionDotButtonType, idTaskOrAwork, selectedObject,
                                                                     totalPrice, currentlyDone}) {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [executorOpen, setExecutorOpen] = useState(false);

    const [date, setDate] = useState(null);
    const [qty, setQty] = useState(null);

    const [executorId, setExecutorId] = useState(null);
    const [executor, setExecutor] = useState(null);

    const [executorTyped, setExecutorTyped] = useState(null);

    const [selectedDetail, setSelectedDetail] = useState(null);

    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [dashboardBegin, setDashboardBegin] = useState(null);
    const [fuelConsumption, setFuelConsumption] = useState(null);
    const [dashboardEnd, setDashboardEnd] = useState(null);
    const [priceFuel, setPriceFuel] = useState(null);
    const [comment, setComment] = useState(null);
    const [partnerId, setPartnerId] = useState(null);


    const [vehicles, setVehicle] = useState(null);

    const [tempExecutor, setTempExecutor] = useState(null);
    const [tempExecutorId, setTempExecutorId] = useState(null);


    useEffect(() => {
        if (selectedVehicle) {

        }
    }, [selectedVehicle])

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/getResources?`+ new URLSearchParams({
            token: accessToken,
            type: 2,
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setVehicle([...results])
                }
            })
    }, [open, executorTyped])

    let distance = Number(dashboardEnd) - Number(dashboardBegin);
    let litersFuel = (distance / fuelConsumption)
    let litersPerKilometer = litersFuel / distance;


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}>

            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Добави транспорт
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={1} style={{margin: 15}}>

                <Grid item xs={10}>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Номер</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedVehicle}
                                label="Номер"
                                onChange={(event, child) => {
                                    console.log('selecting ', event.target.value)
                                    setSelectedVehicle(event.target.value)
                                    console.log('vehicles.find(id => id===event.target.value)', vehicles)
                                    setDashboardBegin(vehicles.find(vehicle => vehicle.id===event.target.value).last_dashboard)
                                    setFuelConsumption(vehicles.find(vehicle => vehicle.id===event.target.value).fuelconsumation)
                                }}
                            >
                                {vehicles && vehicles.map(detail => {
                                    return <MenuItem value={detail.id}>
                                        <Grid container>
                                            <Grid item xs={10}>
                                                <b>{detail.resource_name}</b>
                                            </Grid>

                                            <Grid item xs={2}>
                                                <FormHelperText>{detail.reg_plate}</FormHelperText>
                                            </Grid>
                                        </Grid>

                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>

                </Grid>


                <Grid item xs={6}>
                    <TextField label={'От'}/>
                </Grid>

                <Grid item xs={6}>
                    <TextField label={'До'}/>
                </Grid>

                <Grid item xs={10}>
                    <AutocompleteUsers
                        idObject={selectedObject}
                        executor={tempExecutor} setExecutor={setTempExecutor} setExecutorId={setTempExecutorId} onSelect={(prop) => {
                        console.log('проппп ', prop)
                        setPartnerId(prop.id)
                    }
                    }/>
                </Grid>

                <Grid item xs={6}>
                    <TextField label={'Километраж начало'} value={dashboardBegin} onChange={(e)=> {
                        setDashboardBegin(e.target.value)
                    }}/>
                </Grid>


                <Grid item xs={6}>
                    <TextField label={'Километраж край'} value={dashboardEnd} onChange={(e)=> {
                        setDashboardEnd(e.target.value)
                    }}/>
                </Grid>



                <Grid item xs={6}>
                    <TextField label={'Изминати километри'} value={distance}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField label={'Нужно гориво'} value={litersFuel}/>
                </Grid>



                <Grid item xs={6}>
                    <TextField label={'Цена на литър гориво'} value={priceFuel} onChange={(e)=> {
                        setPriceFuel(e.target.value)
                    }}/>
                </Grid>


                <Grid item xs={6}>
                    <TextField label={'Обща цена'} value={!isNaN(dashboardBegin) && !isNaN(dashboardEnd) ?
                        ((Number(dashboardEnd) - Number(dashboardBegin)) / Number(fuelConsumption)) * Number(priceFuel)
                        : null}/>
                </Grid>


                <Grid item xs={10}>
                    <TextField fullWidth label={'Коментар'} value={comment} onChange={(e)=> {
                        setComment(e.target.value)
                    }}/>
                </Grid>


                <Grid item xs={12}>
                    <Button variant={'contained'}
                            disabled={
                        !fuelConsumption ||
                        !partnerId ||
                        isNaN(Number(dashboardEnd) - Number(dashboardBegin)) ||
                        (Number(dashboardEnd) - Number(dashboardBegin)) <= 0 ||
                                (Number(dashboardEnd) - Number(dashboardBegin)) * Number(priceFuel) <= 0

                    }
                            onClick={() => {

                                CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/addWorkReport`, {
                                    method: "POST",
                                    mode: 'cors',
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        "token": accessToken,
                                        ...(executionDotButtonType === 'work' ? {"id_awork": idTaskOrAwork} : {"id_task": idTaskOrAwork}),
                                        "date":moment().format("YYYY-MM-DD"),
                                        "comment": comment,
                                        "dashboard_begin":dashboardBegin,
                                        "dashboard_end":dashboardEnd,
                                        "kilometers":Number(dashboardEnd) - Number(dashboardBegin),
                                        "id_partner":selectedVehicle,
                                        "price_fuel":priceFuel,
                                        "price_transport": (litersPerKilometer * priceFuel), //cena kilometar
                                        "id_driver":partnerId,
                                    })
                                })
                                    .then((result) => {
                                        //setForceRefresh(Math.random())
                                        handleClose()
                                    })

                    }}>Запис</Button>
                </Grid>
            </Grid>

        </Dialog>
    );
}