import {CommonFetch} from "../services/api-fetch";
import moment from "moment";

export const getObjects = (navigate, accessToken, setAccessToken, setObjects, setSelectedObject, type) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/getProjects?`+ new URLSearchParams({
        ...(type === 'offer' ? { is_offer: 1 } : {is_contract: 1}),
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((result) => {
                setObjects(result.items)
                if (result.items && result.items.length > 0) {
                    //setSelectedObject(result.items[0].id)
                }
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}
export const getObjectsAllAworks = (navigate, accessToken, setAccessToken, setObjects, setSelectedObject, type) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/getProjects?`+ new URLSearchParams({
//        ...(type === 'offer' ? { is_offer: 1 } : {is_contract: 1}),
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((result) => {
                setObjects(result.items)
                if (result.items && result.items.length > 0) {
                    //setSelectedObject(result.items[0].id)
                }
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}

export const getObjectsAll = (navigate, accessToken, setAccessToken, setObjects, setSelectedObject, type) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((result) => {
                setObjects(result.items)
                if (result.items && result.items.length > 0) {
                    //setSelectedObject(result.items[0].id)
                }
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}

export const getObjectsTasks = (navigate, accessToken, setAccessToken, setObjects, year) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getProjects?`+ new URLSearchParams({
        token: accessToken,
        year: "2023"
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((result) => {
                setObjects(result.items)
                if (result.items && result.items.length > 0) {
                    //setSelectedObject(result.items[0].id)
                }
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}

export const getObjectsTasksPromise = (navigate, accessToken, setAccessToken) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getProjects?`+ new URLSearchParams({
        token: accessToken,
        year: "2023"
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export const getObjectsPromise = (navigate, accessToken, setAccessToken) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/getProjects?`+ new URLSearchParams({
        is_contract: 1,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}


export const getProjectTasks = (navigate, accessToken, setAccessToken, setObjects) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects?`+ new URLSearchParams({
        token: accessToken,
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((result) => {
                let object = result.items;

                for (var i in object) {
                    if (object[i].name_project === 'Недко Войвода') {
                        object[i].id = 3;
                        break;
                    }
                }

                setObjects(object)
                if (result.items && result.items.length > 0) {
                    //setSelectedObject(result.items[0].id)
                }
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}

export const getObject = (navigate, accessToken, setAccessToken, id) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects/getEx?`+ new URLSearchParams({
        id: id,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const editObject = (navigate, accessToken, setAccessToken, id, name, shortName, client, address,
                           investor, description, area, workDays, techManager) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects/updateEx?`+ new URLSearchParams({
        id: id,
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id":id,
            "code":"2",
            "project_name":name,
            "shortprjname":shortName,
            "investor": client,
            "address": address,
            "status":"0",
            "description": description,
            "area": area,
            "wrkdays": workDays,
            "date_start": "2022-10-1",
            "date_end": "2023-9-1",
            "technical_manager": techManager,
            token: accessToken,
        })
    });
}

export const getOfferObjects = (navigate, accessToken, setAccessToken, setObjects, setSelectedObject, type, offerOrContract='offer') => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/getProjects?`+ new URLSearchParams({
        ...(type === 'offer' ? { is_offer: 1 } : {is_contract: 1}),
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((result) => {
                setObjects(result.items)
                if (result.items && result.items.length > 0) {
                    //setSelectedObject(result.items[0].id)
                }
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}


export const getWorkingGroups = (department, navigate, accessToken, setAccessToken) => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworkgroups?`+ new URLSearchParams({
        id_project: department.id,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const fetchWorksDataStock = (department, navigate, accessToken, setAccessToken, setWorksDataStock, displayNameWork) => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/getWorks?`+ new URLSearchParams({
        search: displayNameWork,
        id_project: department.id,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((result) => {
            if (result && result.items) {
                setWorksDataStock(result.items)
            }
        });
}

export const getTasksByProject = (department, navigate, accessToken, setAccessToken, userId) => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/byProject?`+ new URLSearchParams({
        project_id: department.id,
        userID: userId,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}
export const getActualUsers = (navigate, accessToken, setAccessToken, setUsers) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/users?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(
            (result) => {
                setUsers(result.items)
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}

export const getActualUsersPromise = (navigate, accessToken, setAccessToken, setUsers, setUserTracking) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/users?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(
            (result) => {
                setUsers(result.items)
                setUserTracking(result.items)
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}
const getWorkType = (type) => {
  switch (type) {
      case 1:
          return 1;
      case 2:
          return 3;
      case 3:
          return 4;
      case 4:
          return 2;

  }
}

const getNachislenie = (tabType, additionalExpenses) => {
  switch (tabType) {
      case 0:
          return additionalExpenses === 1 ? 'result' : null;
      case 1:
          return additionalExpenses === 1 ? 'perc_work_add_costs' : additionalExpenses === 2 ? 'perc_work_profit' : 'neto';
      case 2:
          return additionalExpenses === 1 ? 'perc_goods_accsessory' : additionalExpenses === 2 ? 'perc_goods_store' : additionalExpenses === 3 ? 'perc_good_profit' : additionalExpenses === 3.9 ? 'delivery' : 'neto';
      case 3:
          return additionalExpenses === 1 ? 'perc_services_add_costs' : additionalExpenses === 2 ? 'perc_services_profit' : 'neto';
      case 4:
          return additionalExpenses === 1 ? 'perc_mech_add_costs' : additionalExpenses === 2 ? 'perc_mech_profit' : 'neto';
  }
}

export const getWorkingGroupsExecution = (department, navigate, accessToken, setAccessToken, tabType, additionalExpenses) => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    if (additionalExpenses === 5) {
        return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/getPlanSmet?`+ new URLSearchParams({
            id_project: department.id,
            token: accessToken,
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        });
    }
    if (additionalExpenses === 3.9) {
        return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/getDeliveryRep?`+ new URLSearchParams({
            id_project: department.id,
            token: accessToken,
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/aworkgroups?`+ new URLSearchParams({
        id_project: department.id,
        token: accessToken,
        showworks: 1,
        ...(additionalExpenses > 0 ? { view_type: getNachislenie(tabType, additionalExpenses) } : {}),
        ...(tabType > 0 ? { detail_type: getWorkType(tabType) } : {}),
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}



export const getWorkingGroupsExecutionByPartnerV2 = (department, navigate, accessToken, setAccessToken, tabType, additionalExpenses, parnterId) => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    if (additionalExpenses === 5) {
        return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/getPlanSmet?`+ new URLSearchParams({
            id_project: department.id,
            token: accessToken,
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/aworkgroupsEx?`+ new URLSearchParams({
        id_project: department.id,
        token: accessToken,
        id_partner: parnterId,
        showworks: 1,
        ...(additionalExpenses > 0 ? { view_type: getNachislenie(tabType, additionalExpenses) } : {}),
        ...(tabType > 0 ? { detail_type: getWorkType(tabType) } : {}),
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}



export const getWorkingGroupsExecutionByPartner = (department, navigate, accessToken, setAccessToken, tabType, additionalExpenses, partnerId) => {
    if (!department || !department.id){
        return
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/execution/aworkgroupsEx?`+ new URLSearchParams({
        id_project: department.id,
        token: accessToken,
        showworks: 1,
        //id_partner: partnerId,
        ...(additionalExpenses > 0 ? { view_type: getNachislenie(tabType, additionalExpenses) } : {}),
        ...(tabType > 0 ? { detail_type: getWorkType(tabType) } : {}),
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}
export const getOfferWorkingGroups = (department, navigate, accessToken, setAccessToken, serviceMaterial = null, offerOrContract='offer') => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    const type = getTypeFromString(serviceMaterial);

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/aworkgroups?`+ new URLSearchParams({
        id_project: department.id,
        ...(serviceMaterial ? { detail_type: type } : {}),
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const getKss = (department, navigate, accessToken, setAccessToken, offerOrContract = 'offer') => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/getKSS?`+ new URLSearchParams({
        id_project: department.id,
        token: accessToken,
        stage: offerOrContract
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const getReportDeliverer = (department, navigate, accessToken, setAccessToken, detailType, etap, offerOrContract = 'offer') => {

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/reports/getPartnersForWork?`+ new URLSearchParams({
        id_project: department.id,
        token: accessToken,
        "detail_type":getTypeFromString(detailType),
        "stage":offerOrContract,
        "id_etap": etap ? etap.id : 0
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const getPartnerWorks = (department, group, serviceMaterial, navigate, accessToken, setAccessToken, offerOrContract = 'offer') => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    const type = getTypeFromString(serviceMaterial);
console.log('typeee ', type)
console.log('typeee serviceMaterial', serviceMaterial)
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/getPartnerWorks?`+ new URLSearchParams({
        id_project: department.id,
        token: accessToken,
        stage: offerOrContract,
        detail_type:type,
        id_aworkgroup:group
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const getPartnerWorksForPartner = (department, group, serviceMaterial, navigate, accessToken, setAccessToken, partnerId, offerOrContract = 'offer') => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    const type = getTypeFromString(serviceMaterial);
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/getPartnerWorks?`+ new URLSearchParams({
        id_project: department.id,
        token: accessToken,
        stage: offerOrContract,
        detail_type:type,
        id_aworkgroup:group,
        id_partner:partnerId,
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}


export const selectSec = (department, navigate, accessToken, setAccessToken) => {

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/selectSEC?`+ new URLSearchParams({
        token: accessToken,
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const selectFromProject = (department, navigate, accessToken, setAccessToken) => {

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/selectFromProject?`+ new URLSearchParams({
        token: accessToken,
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const importWorkingGroupsFromFile = (department, data, navigate, accessToken, setAccessToken, offerOrContract = 'offer') => {
    if (!department || !department.id){
        return
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/importFile?`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            project_id: department.id,
            token: accessToken,
            data: data
        })
    });
}

export const getReference = (department, etap, group, navigate, accessToken, setAccessToken) => {
    console.log('getWorkingGroups dep ', department)
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/getProjectDocs?`+ new URLSearchParams({
        project_id: department,
        id_etap: etap ? etap.id : 0,
        id_group: group ? group.id : group,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}


export const getMaterials = (department, navigate, accessToken, setAccessToken, stage, group) => {
    const stageId = stage ? stage.id : 0;
    const groupId = group ? group.id : 0;

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/getDocGoods?`+ new URLSearchParams({
        project_id: department.id,
        id_etap: stageId || 0,
        id_group: groupId || 0,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const getServices = (department, navigate, accessToken, setAccessToken, stage, group) => {
    const stageId = stage ? stage.id : 0;
    const groupId = group ? group.id : 0;

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/getDocServices?`+ new URLSearchParams({
        project_id: department.id,
        id_etap: stageId || 0,
        id_group: groupId || 0,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const getPartners = (navigate, accessToken, setAccessToken, serviceMaterial) => {
    const type = serviceMaterial ?
        serviceMaterial === "MATERIAL" ? 3 : "SERVICE" ? 1  : "EXT_SERVICE" ? 3 : 4
        : 1;

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners?`+ new URLSearchParams({
        id_category: type,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const deleteDetail = (navigate, accessToken, setAccessToken, commentId) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/deleteDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id": commentId,
            "token": accessToken
        })
    })
}

export const getPartnersWithIndex = (navigate, accessToken, setAccessToken, type) => {

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners?`+ new URLSearchParams({
        id_category: type,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const getPartnersForDeliverers = (navigate, accessToken, setAccessToken, serviceMaterial, department) => {
    const type = getTypeFromString(serviceMaterial);

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/getProjectPartners?`+ new URLSearchParams({
        detail_type: type,
        id_project: department,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}


export const getPartnersByMaterial = (department, navigate, accessToken, setAccessToken, stage, group, good, isService) => {
    const stageId = stage ? stage.id : 0;
    const groupId = group ? group.id : 0;
    const goodId = good ? good.id : 0;

    return CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/docs/getProjectPartners?` + new URLSearchParams({
        project_id: department.id,
        id_etap: stageId || 0,
        id_group: groupId || 0,
        id_good: goodId || 0,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const addPartnerToProject = (department, navigate, accessToken, setAccessToken, stage, group, good, partnerId, typePartner='goods') => {
    const stageId = stage ? stage.id : 0;
    const groupId = group ? group.id : 0;
    const goodId = good ? good.id : 0;

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/addPartnerToProject`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            project_id: department.id,
            id_etap: stageId || 0,
            id_group: groupId || 0,
            id_good: goodId || 0,
            partner_id: partnerId,
            type_partner: typePartner,
            token: accessToken
        })
    });
}


export const addMaterial = (department, navigate, accessToken, setAccessToken, name, stage, group) => {
    const stageId = stage ? stage.id : 0;
    const groupId = group ? group.id : 0;

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/addGoods`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            project_id: department.id,
            good_name: name,
            id_etap: stageId || 0,
            id_group: groupId || 0,
            token: accessToken
        })
    });
}


export const addService = (department, navigate, accessToken, setAccessToken, name, stage, group) => {
    const stageId = stage ? stage.id : 0;
    const groupId = group ? group.id : 0;

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/addServices`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            project_id: department.id,
            service_name: name,
            id_etap: stageId || 0,
            id_group: groupId || 0,
            token: accessToken
        })
    });
}

export const addPartner = (navigate, accessToken, setAccessToken, name, serviceMaterial) => {
    const type = serviceMaterial ?
        serviceMaterial === "MATERIAL" ? 3 : "SERVICE" ? 1  : "EXT_SERVICE" ? 3 : 4
        : 1;

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/add`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            id_category: type,
            name_firm: name,
            bulstat: name,
            token: accessToken
        })
    });
}

export const addPartnerToWork = (navigate, accessToken, setAccessToken, serviceMaterial, detailId, partnerId, quantity, group, offerOrContract = 'offer') => {
    const type = getTypeFromString(serviceMaterial);

    let groupId =  group ? group.id : null;
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/addPartner`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "token":accessToken,
            "id": detailId,
            "partner_id":partnerId,
            "detail_type":type,
            "qty":quantity,
            ...(type === 3 ? { id_aworkgroup: groupId } : {}),
            "price": ""

        })
    });
}


export const addGroup = (department, navigate, accessToken, setAccessToken, setStage, setGroup, name, idAworkgroup="0") => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworkgroups/add?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id_aworkgroup":idAworkgroup,  //0 Главна група, иначе сочи към друга група за дървовидна структура
            "code":"1",
            "name_group":name,
            "id_project":department.id,
            "token": accessToken
        })
    })
        .then(
            (result) => {
                console.log('resss ', result)
                const value = {
                    "id": result && result.id ? result.id : 0,
                    "name_group": name,
                    "status":"0"
                };

                if (idAworkgroup === "0") {
                    setStage(value);
                } else {
                    setGroup(value)
                }
            },
            (error) => {
                console.log('error: ', error)
            }
        ).catch((e) => {
        console.log(e)
    });
}
export const addGroupFromTasks = (department, navigate, accessToken, setAccessToken, setStage, setGroup, name, idAworkgroup="0") => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworkgroups/add?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id_aworkgroup":idAworkgroup,  //0 Главна група, иначе сочи към друга група за дървовидна структура
            "code":"1",
            "name_group":name,
            "additional":"1",
            "id_project":department.id,
            "token": accessToken
        })
    })
        .then(
            (result) => {
                console.log('resss ', result)
                const value = {
                    "id": result && result.id ? result.id : 0,
                    "name_group": name,
                    "status":"0"
                };

                if (idAworkgroup === "0") {
                    setStage(value);
                } else {
                    setGroup(value)
                }
            },
            (error) => {
                console.log('error: ', error)
            }
        ).catch((e) => {
        console.log(e)
    });
}



export const addGroupOffer = (department, navigate, accessToken, setAccessToken, setStage, setGroup, name, idAworkgroup="0") => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/offer/aworkgroups/add?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id_aworkgroup":idAworkgroup,  //0 Главна група, иначе сочи към друга група за дървовидна структура
            "code":"1",
            "name_group":name,
            "id_project":department.id,
            "token": accessToken
        })
    })
        .then(
            (result) => {
                const value = {
                    "id": result && result.id ? result.id : 0,
                    "name_group": name,
                    "status":"0"
                };

                if (idAworkgroup === "0") {
                    setStage(value);
                } else {
                    setGroup(value)
                }
            },
            (error) => {
                console.log('error: ', error)
            }
        ).catch((e) => {
        console.log(e)
    });
}


export const createPartner = (navigate, accessToken, setAccessToken, setAssignee, name, category = 2) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/add?`+ new URLSearchParams({
        token: accessToken,
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "name_firm": name,
            "phone":"",
            "contact_person":name,
            "bulstat":name,
            "vatno":name,
            "mol":name,
            "address":name,
            "email":name,
            "id_category":category,
            "token": accessToken
        })
    });
}

export const createPartnerForDeliverers = (navigate, accessToken, setAccessToken, department, name, serviceMaterial) => {
    const type = getTypeFromString(serviceMaterial);

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/addPartnersToProject?`+ new URLSearchParams({
        token: accessToken,
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "token":accessToken,
            "id_project":department,
            "detail_type":type,
            "name": name
        })
    });
}

export const getPartnersWorks = (navigate, accessToken, setAccessToken, idProject, serviceMaterial, group, offerOrContract = 'offer') => {
    const type = getTypeFromString(serviceMaterial);

    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/getWorksByPartner?`+ new URLSearchParams({
        token: accessToken,
        "id_project": idProject,
        "detail_type":type,
        "id_aworkgroup":group
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}




export const getDocState = (department, navigate, accessToken, setAccessToken, docName) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/getDocState?`+ new URLSearchParams({
        project_id: department.id,
        doc: docName,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}



export const getDocNums = (department, navigate, accessToken, setAccessToken, docName) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/getDocNumbers?`+ new URLSearchParams({
        project_id: department.id,
        doc: docName,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}



export const setDocState = (department, navigate, accessToken, setAccessToken, docName, file, type = "physical") => {
    let finalBody = null;

    if (type === "physical") {
        finalBody = JSON.stringify({
            doc: docName,
            token: accessToken,
            project_id: department.id,
            ...file
        });
    } else {
        finalBody = new FormData();
        finalBody.append("doc", docName);
        finalBody.append("token", accessToken);
        finalBody.append("project_id", department.id);
        finalBody.append(type, file, file.name);
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/docs/setDocState`, {
        method: "POST",
        mode: 'cors',
        headers: type === "physical" ? {
            "Content-Type": "application/json",
        } : {},
        body: finalBody
    });
}



export const changePercent = (id, type, newPercent, navigate, accessToken, setAccessToken) => {
    const data = {
        "token": accessToken,
        "id": id
    }
    switch (type) {
        case 1:
            data.perc_work = newPercent;
            break;
        case 2:
            data.perc_mech = newPercent;
            break;
        case 3:
            data.perc_goods = newPercent;
            break;
        case 4:
            data.perc_services = newPercent;
            break;
        case 5:
            data.perc_work_profit = newPercent;
            break;
        case 6:
            data.perc_mech_profit = newPercent;
            break;
        case 7:
            data.perc_good_profit = newPercent;
            break;
        case 8:
            data.perc_services_profit = newPercent;
            break;
        case 9:
            data.perc_goods_store = newPercent;
            break;
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/smr/updatePercents`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}
export const changePercentWork = (id, type, newPercent, navigate, accessToken, setAccessToken, offerOrContract = 'offer') => {
    const data = {
        "token": accessToken,
        "id_work": id
    }
    switch (type) {
        case 1:
            data.perc_work_add_costs = newPercent;
            break;
        case 2:
            data.perc_mech_add_costs = newPercent;
            break;
        case 3:
            data.perc_goods_accsessory = newPercent;
            break;
        case 4:
            data.perc_services_add_costs = newPercent;
            break;
        case 5:
            data.perc_work_profit = newPercent;
            break;
        case 6:
            data.perc_mech_profit = newPercent;
            break;
        case 7:
            data.perc_good_profit = newPercent;
            break;
        case 8:
            data.perc_services_profit = newPercent;
            break;
        case 9:
            data.perc_goods_store = newPercent;
            break;
        case 10:
            data.perc_services_add_costs = newPercent;
            break;
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/updatePercents`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}

export const updateDetail = (id, name, entity, qty, perc_fira, cost_rate, price, amount, navigate, accessToken, setAccessToken) => {
    const data = {
        "token": accessToken,
        "id": id,
        "name": name,
        "entity": entity,
        "qty": qty,
        "perc_fira": perc_fira,
        "cost_rate": cost_rate,
        "price": price,
        "amount": amount
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/smr/updateDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}


export const addAnalysisDetail = (idSmrWorks, type,
                                  name, entity, qty, perFira,
                                  costRate, price, amount,
                                  navigate, accessToken, setAccessToken) => {
    const data = {
        "token": accessToken,
        "id_smr_works": idSmrWorks,
        "id_detail_type": type,
        "name": name,
        "entity": entity,
        "qty": qty,
        "perc_fira": perFira,
        "cost_rate": costRate,
        "price": price,
        "amount": amount
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/smr/addDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}

export const addAnalysisDetailFromScratch = (id, type,
                                  name, entity, qty, perFira,
                                  costRate, price, amount, total,
                                  navigate, accessToken, setAccessToken, offerOrContract = 'offer', executor, isMainDetail, code, executors) => {
    const data = {
        "token": accessToken,
        "id_awork": id,
        "id_partner": executor ? executor.id : null,
        "id_detail_type": type,
        "name": name && name.name_good ? name.name_good : name,
        "entity": entity ? (entity.name ? entity.name : entity) : null,
        "code": type === 3 ? code : null,
        "qty": qty,
        "perc_fira": perFira,
        "cost_rate": costRate,
        "price": price,
        "amount": amount,
        "ismain": isMainDetail ? 1 : 0,
        "total_quantity": total,
        "partners": executors
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/addDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}

export const deleteAnalysisDetailFromScratch = (id,
                                  navigate, accessToken, setAccessToken, offerOrContract = 'offer') => {
    const data = {
        "token": accessToken,
        "id": id
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/deleteDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}

export const updateAnalysisDetailFromScratch = (id,
                                  name, entity, qty, perFira,
                                  costRate, price, amount, total,
                                  navigate, accessToken, setAccessToken, offerOrContract = 'offer', executor, isMainDetail, executors, idDetailType) => {
    console.log('executor ', executor)
    const data = {
        "token": accessToken,
        "id": id,
        "id_detail_type": idDetailType,
        "id_awork": id,
        "name": name,
        "entity": entity ? (entity.name ? entity.name : entity) : null,
        "id_partner": executor ? executor.id : null,
        "qty": qty,
        "perc_fira": perFira,
        "cost_rate": costRate,
        "price": price,
        "amount": amount,
        "ismain": isMainDetail ? 1 : 0,
        "total_quantity": total,
        "partners": executors
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/updateDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}

export const addWork = (groupId, name, code, entity, quantity,
                                  navigate, accessToken, setAccessToken, offerOrContract = 'offer') => {
    const data = {
        "token": accessToken,
        "id_aworkgroup": groupId,
        "code": code,
        "name_work": name,
        "edm": entity,
        "qty": quantity,
        "price": 0,
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/aworkgroups/add`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}
export const addWork2 = (groupId, name, code, entity, quantity,
                                  navigate, accessToken, setAccessToken, offerOrContract = 'offer', aworksType = false) => {
    const data = {
        "token": accessToken,
        "id_aworkgroup": groupId,
        "code": code,
        "name_work": name,
        "edm": entity,
        "qty": quantity,
        "price": 0,
        ...(aworksType ? {aworks_type: 1} : {})
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/addKSS`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
}

export const editObjectApi = (navigate, accessToken, setAccessToken,
                              objectId, longNme, shortName, investor, address, area, workdays, isEndedStatus, technicalManager) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects/updateEx`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id": objectId,
            "code":"1",
            "project_name": longNme,
            "shortprjname": shortName,
            "address": address,
            "investor": investor,
            "description": "",
            "status":"0",
            "area": area,
            "wrkdays": workdays,
            "technical_manager": technicalManager,
            "date_start": moment().format('YYYY-MM-DD'),
            "date_end": isEndedStatus === 1 ? moment().format('YYYY-MM-DD') : null,
            "token": accessToken
        })
    })
}


export const getGraphicPerViewType = (navigate, accessToken, setAccessToken, selectedObject, fromDate, toDate, viewType = 'days') => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/graph/aworkgroups?`+ new URLSearchParams({
        token: accessToken,
        id_project: selectedObject,
        "from_date":fromDate,
        "to_date":toDate,
        "typeView": viewType,

    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export const updateAwork = (id, name, entity, quantity, price, idAworkgroup, navigate, accessToken, setAccessToken, offerOrContract = 'offer') => {

    console.log('entity', entity)
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/updateKSS`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "token": accessToken,
            "id": id,
            "id_aworkgroup": idAworkgroup,
            "code":"",
            "name_work":name,
            "edm":entity && entity.name ? entity.name : typeof entity === 'string' ? entity : "",
            "qty":quantity,
            "price":price
        })
    });
}
export const deleteAwork = (id, navigate, accessToken, setAccessToken, offerOrContract = 'offer') => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/aworkgroups/delete`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "token": accessToken,
            "id": id,
        })
    });
}
export const deleteWork = (id, navigate, accessToken, setAccessToken, offerOrContract = 'offer') => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/${offerOrContract}/deleteKSS`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "token": accessToken,
            "id": id,
        })
    });
}


export const addGroupKss = (department, navigate, accessToken, setAccessToken,
                         name, idAworkgroup="0") => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworkgroups/add?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id_aworkgroup":idAworkgroup,  //0 Главна група, иначе сочи към друга група за дървовидна структура
            "code":"1",
            "name_group":name,
            "id_project":department.id,
            "token": accessToken
        })
    });
}


export const addObject = (navigate, accessToken, setAccessToken, name, shortName, type) => {
   return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects/add?`+ new URLSearchParams({
        token: accessToken,
       ...(type === 'offer' ? { is_offer: 1 } : {is_contract: 1}),
   }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id":"0",
            "code":"1",
            "project_name": name,
            "shortprjname": shortName,
            "status":"0",
            "token": accessToken
        })
    });
}

export const addObjectBody = (navigate, accessToken, setAccessToken, name, shortName, type) => {
   return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects/add?`+ new URLSearchParams({
        token: accessToken,
   }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id":"0",
            "code":"1",
            "project_name": name,
            "shortprjname": shortName,
            "status":"0",
            "token": accessToken,
            ...(type === 'offer' ? { is_offer: 1 } : {is_contract: 1}),

        })
    });
}


export const getGoods = (navigate, accessToken, setAccessToken, name) => {
   return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/goods/getGoods?`+ new URLSearchParams({
        token: accessToken,
        search: name
   }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const addGoods = (navigate, accessToken, setAccessToken, name, entity, code) => {
   return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/goods/addGood?`+ new URLSearchParams({
        token: accessToken,
   }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
       body: JSON.stringify({
           id_goods_groups: 0,
           code: code,
           name_good: name,
           entity: entity,
           token: accessToken,
       })
    });
}

export const getUsers = (navigate, accessToken, setAccessToken, setUsers) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(
            (result) => {
                setUsers(result.items)
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}


export const getWorkingGroupsWithSetter = (department, navigate, accessToken, setAccessToken, setWorkingGroups) => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworkgroups?`+ new URLSearchParams({
        id_project: department.id,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(
            (result) => {
                setWorkingGroups(result.items)
                console.log(result)
                return result.items
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}

export const getWorkingTypes = (department, navigate, accessToken, setAccessToken, stage, setWorkingTypes) => {
    console.log('getWorkingTypes dep ', department)
    if (!department || !department.id){
        return
    }
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks?`+ new URLSearchParams({
        id_aworkgroup: stage ? stage.id : null,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(
            (result) => {
                setWorkingTypes(result.items)
                console.log('setWorkingTypes ', result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}


export const addWorkType = (navigate, accessToken, setAccessToken, setWorkType, stage, name) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/add?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id_aworkgroup": stage.id,  //0 Главна група, иначе сочи към друга група за дървовидна структура
            "code": "1",
            "name_work": name,
            "edm": "",
            "qty": "0",
            "price": 0,
            "token": accessToken
        })
    })
        .then(
            (result) => {
                setWorkType({
                    "id": result && result.id ? result.id : 0,
                    "name_work": name,
                    "status":"0"
                })
            },
            (error) => {
                console.log('error: ', error)
            }
        ).catch((e) => {
        console.log(e)
    });
}


export const addWorkTypeFromTasks = (navigate, accessToken, setAccessToken, setWorkType, stage, name) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/add?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id_aworkgroup": stage.id,  //0 Главна група, иначе сочи към друга група за дървовидна структура
            "aworks_type": "1",
            "code": "1",
            "name_work": name,
            "edm": "",
            "qty": "0",
            "price": 0,
            "token": accessToken
        })
    })
        .then(
            (result) => {
                setWorkType({
                    "id": result && result.id ? result.id : 0,
                    "name_work": name,
                    "status":"0"
                })
            },
            (error) => {
                console.log('error: ', error)
            }
        ).catch((e) => {
        console.log(e)
    });
}

export const getFile = (navigate, accessToken, setAccessToken, id) => {
    const url = `${process.env.REACT_APP_URL}/docs/getAttachment?id=${id}&token=${accessToken}`;
    window.open(url, '_blank').focus();
}
const getTypeFromString = (serviceMaterial) => {
    console.log('serviceMaterial ', serviceMaterial)

    switch (serviceMaterial) {
        case "MATERIAL":
            return 3;
        case "SERVICE":
            return 1;
        case "MECH":
            return 2;
        case "EXT_SERVICE":
            return 4;
        default:
            return 1;
    }
}