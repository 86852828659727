import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import {getObjects} from "../../utils/NetworkUtils";
import {useLocalStorage} from "../../useLocalStorage";
import {useNavigate} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import AutocompleteObjects from "../../inputs/AutocompleteObjects";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PickObjectDialog({open, handleClose}) {
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    let navigate = useNavigate();

    const [objects, setObjects] = React.useState([]);
    const [selectedObject, setSelectedObject] = React.useState(0);
    const [objectName, setObjectName] = React.useState(0);

    const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [displayName, setDisplayName] = useState("");

    useEffect(() => {
        if (open) {
            getObjects(navigate, accessToken, setAccessToken, setObjects, setSelectedObject)
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={'sm'}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Избери обект
                    </Typography>
                </Toolbar>
            </AppBar>

            <Grid container sx={{padding: 5}}>
                <Grid item xs={12}>
                    <AutocompleteObjects
                        autoCompleteOpen={autoCompleteOpen} setAutoCompleteOpen={setAutoCompleteOpen}
                        stocksDataSet={objects} loading={loading} editId={null}
                        setObjectName={setObjectName} objectName={objectName} setLoading={setLoading}
                        displayName={displayName} setDisplayName={setDisplayName} makeAdding/>
                </Grid>
            </Grid>

        </Dialog>
    )
}
export default PickObjectDialog;