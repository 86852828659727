import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {CommonFetch} from "../../../services/api-fetch";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../useLocalStorage";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, Tooltip} from "@mui/material";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PartnerPickDialog({details, selectedObject, serviceMode, nameWork, entity, open,
                                              handleClose, onClick, totalPrice, currentlyDone, assignedPartner}) {
    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [executorOpen, setExecutorOpen] = useState(false);
    const [executors, setExecutors] = useState([]);

    const [date, setDate] = useState(null);
    const [qty, setQty] = useState(null);

    const [executorId, setExecutorId] = useState(null);
    const [executor, setExecutor] = useState(null);

    const [executorTyped, setExecutorTyped] = useState(null);

    const [selectedDetail, setSelectedDetail] = useState(null);

    useEffect(() => {
        if (details && details.length === 1 && details[0]) {
            setSelectedDetail(details[0].id)
        }
    }, [details])

    useEffect(() => {
        if (assignedPartner) {
            setExecutor(assignedPartner.partner_name)
            setExecutorId(assignedPartner.id)
            setExecutorTyped(assignedPartner.partner_name || assignedPartner.name)
        }
    }, [open, assignedPartner])


    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/firms?`+ new URLSearchParams({
            token: accessToken,
            ...(executor ? { search: executorTyped } : {}),

        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                if (result && result.items) {
                    let results = result.items;
                    setExecutors([...results])
                }
            })
    }, [open, executorTyped])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}>


            <Grid container style={{padding: 15}} spacing={1}>


                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size={'small'}
                        margin="dense"
                        id="client"
                        value={nameWork}
                        label="Име"
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size={'small'}
                        margin="dense"
                        id="entity"
                        value={entity}
                        label="Мярка"
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size={'small'}
                        margin="dense"
                        id="client"
                        value={totalPrice}
                        label="К-во по договор"
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size={'small'}
                        margin="dense"
                        id="client"
                        value={currentlyDone}
                        label="Изпълнено към момента"
                    />
                </Grid>

                <Grid item xs={12}>
                   {/* <Autocomplete
                        id="executor"
                        freeSolo
                        open={open}
                        onOpen={() => {
                            setExecutorOpen(true);
                        }}
                        onClose={() => {
                            setExecutorOpen(false);
                        }}
                        value={executor ? executor && executor.name_firm ? executor : {name_firm: executor} : null}
                        filterOptions={(options) => {
                            return options.filter((option) => {
                                if (!option || !option.name_firm || !executor) {
                                    return true;
                                }
                                return option.name_firm.toLowerCase().includes(executorTyped ? executorTyped.toLowerCase() : "")
                            })
                        }}
                        onChange={(event, prop) => {
                            console.log('prop ', prop)
                            if (prop) {
                                console.log(prop)
                                setExecutorId(prop && prop.id ? prop.id : prop)
                                setExecutor(prop && prop.name ? prop.name : prop)
                                onClick(prop && prop.id ? prop.id : prop,
                                    prop && prop.name ? prop.name : prop
                                )
                                handleClose()
                            } else {
                                setExecutor("")
                            }

                        }}
                        onInput={(event) => {
                            setExecutorTyped(event.target.value)
                        }}
                        getOptionLabel={(option) => option.name_firm}
                        options={executors}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Изпълнител"
                                value={executor}
                                onChange={(e) => {
                                    setExecutor(e.target.value)
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
*/}


                    {serviceMode ? <Grid item xs={12} style={{marginBottom: 10}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Вид</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedDetail}
                                label="Age"
                                onChange={(event, child) => {
                                    console.log('selecting ', event.target.value)
                                    setSelectedDetail(event.target.value)
                                }}
                            >
                                {details && details.map(detail => {
                                    return <MenuItem value={detail.id}>
                                        <Grid container>
                                            <Grid item xs={6.2}>
                                                <b>{detail.name}</b>
                                            </Grid>
                                            {detail.ismain ? <Grid item xs={0.8}>
                                                    <Tooltip title={<p style={{fontSize: 15}}>Дейност, определяща
                                                        количеството извършена работа за обекта</p>}>
                                                        <CheckCircleIcon/>
                                                    </Tooltip>
                                            </Grid> : null}
                                            <Grid item xs={2}>
                                                <FormHelperText>{detail.entity}</FormHelperText>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormHelperText>Изпълнено {detail.qty_done}</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormHelperText style={{fontSize: 14}}>Количество по договор: {detail.total_quantity}</FormHelperText>
                                            </Grid>
                                        </Grid>

                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid> : null}

                    <Autocomplete
                        id="contragents"
                        freeSolo
                        open={executorOpen}
                        onOpen={() => {
                            setExecutorOpen(true);
                        }}
                        onClose={() => {
                            setExecutorOpen(false);
                        }}
                        value={executor ? executor && executor.name_firm ? executor : {name_firm: executor} : null}
                        filterOptions={(options) => {
                            return options.filter((option) => {
                                if (!option || !option.name_firm || !executor || !executorTyped) {
                                    return false;
                                }
                                return option.name_firm.toLowerCase().includes(executorTyped ? executorTyped.toLowerCase() : "")
                            })
                        }}
                        onChange={(event, prop) => {
                            console.log('prop ', prop)
                            if (prop) {
                                console.log(prop)
                                setExecutorId(prop && prop.id ? prop.id : prop)
                                setExecutor(prop && prop.name ? prop.name : prop)

                            } else {
                                setExecutor("")
                            }
                        }}
                        onInput={(event) => {
                            setExecutorTyped(event.target.value)
                        }}
                        getOptionLabel={(option) => option.name_firm}
                        options={executors}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Контрагент"
                                value={executorTyped}
                                onChange={(e) => {
                                    setExecutor(e.target.value)
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField type={'date'} label={'Дата'} InputLabelProps={{shrink: true}}
                               InputProps={{inputProps: { max: moment().format('YYYY-MM-DD')} }}
                               value={date}
                               fullWidth
                               onChange={(event) => {
                                  setDate(event.target.value)
                               }}/>
                </Grid>

                <Grid item xs={6}>
                    <TextField label={'Количество'} InputLabelProps={{shrink: true}}
                               value={qty}
                               disabled={!date}
                               fullWidth
                               onChange={(event) => {
                                  setQty(event.target.value)
                               }}/>
                </Grid>

                <Grid item xs={12}>
                    <Button variant={'contained'}
                            disabled={!date || !qty}
                            onClick={() => {
                        let tempExecutor = executor;
                        let tempExecutorId = executorId;
                        let tempDate = date;
                        let tempQty = qty;
                        let tempSelectedDetail = selectedDetail;
                        onClick(tempExecutorId, tempExecutor, tempDate, tempQty, tempSelectedDetail)
                        handleClose()
                        setExecutor(null)
                        setExecutorId(null)
                        setDate(null)
                        setQty(null)
                        setSelectedDetail(null)
                    }}>Запис</Button>
                </Grid>
            </Grid>

        </Dialog>
    );
}