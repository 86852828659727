import Grid from "@mui/material/Grid";
import {
    Button,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import GraphYearsWeekNumsCalendar from "./GraphYearsWeekNumsCalendar";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../../../useLocalStorage";
import {CommonFetch} from "../../../../../services/api-fetch";
import {getKss, importWorkingGroupsFromFile} from "../../../../../utils/NetworkUtils";
import moment from "moment";
import AssistantIcon from '@mui/icons-material/Assistant';
import Box from "@mui/material/Box";
import TabPanel from "../../../../helper/TabPanel";
import CircleIcon from "@mui/icons-material/Circle";
import CommentsOnlyForDailyTasksDialog from "../../../addDetailContent/CommentsOnlyForDailyTasksDialog";

export default function GraphYearsCalendar({items, setConfirmMove, tab, setGraphicEditDialog, year, setYear,
                                               selectedMonths, setSelectedMonths,
                                               selectedMonthsTemp, setSelectedMonthsTemp,
                                               selectedWeeks, setSelectedWeeks,
                                               selectedWeeksTemp, setSelectedWeeksTemp,
                                               selectedObject, filterAnex
                                           }) {


    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [forceRefreshRow, setForceRefreshRow] = useState(null);

    const [tasksToShow, setTasksToShow] = useState(null);
    const [yearItems, setYearItems] = useState([{name: 'test'}]);

    const [treeItems, setTreeItems] = useState([]);

    const NUM_PERC = '4%';
    const NAME_PERC = '23%';
    const ENTITY_PERC = '3%';
    const QTY_PERC = '3%';
    const ENT_PRICE_PERC = '7%';
    const TOTAL_PERC = '8%';
    const QTY_MONEY = '3%';
    const DAILY_MONEY = '3%';

    const [messageDialog, setMessageDialog] = useState(null);

    const [tabForObject, setTabForObject] = useState(1);


    const [commentsOnlyOpen, setCommentsOnlyOpen] = React.useState(null);
    const [nameAddDialog, setNameAddDialog] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(null);

    function getMonths() {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }

    function getMonthShortName(month) {
        switch (month) {
            case 1:
                return "ян";
            case 2:
                return "фев";
            case 3:
                return "март";
            case 4:
                return "апр";
            case 5:
                return "май";
            case 6:
                return "юни";
            case 7:
                return "юли";
            case 8:
                return "авг";
            case 9:
                return "септ";
            case 10:
                return "окт";
            case 11:
                return "нов";
            case 12:
                return "дек";
        }
    }

    useEffect(() => {
        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/aworkgroups?`+ new URLSearchParams({
            token: accessToken,
            "year":2023,
            ...tabForObject === 2 ? {"waiting":true} : {},
            "id_project":selectedObject ? selectedObject.id : null
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((result) => {
                    if (result && result.items) {
                        setYearItems(result.items)
                    }
                    console.log(result)
                },
                (error) => {
                    console.log('error: ', error)
                }
            );
    }, [forceRefresh, tabForObject])


    useEffect(() => {
        if (!selectedObject) {
            return
        }
        getKss({id: selectedObject.id}, navigate, accessToken, setAccessToken, 'contract')
            .then((result) => {

                if (result && result.items) {
                    for (const resultElement of result.items) {
                        resultElement.title = resultElement.name_group;
                        resultElement.name = resultElement.name_group;
                        resultElement.sub = resultElement.subgroups;
                        resultElement.children = resultElement.subgroups;
                        for (const resultElementElement of resultElement.sub) {
                            resultElementElement.name = resultElementElement.name_group;
                            resultElementElement.title = resultElementElement.name_group;
                            resultElementElement.children = resultElementElement.works;
                            for (const work of resultElementElement.works) {
                                work.name = work.name_work;
                                work.title = work.name_work;
                                work.entity = work.edm;
                                work.quantity = work.qty;
                            }
                        }
                    }
                    setTreeItems(result.items)
                }


            })
            .catch((error) => {

            })
    }, [tabForObject])

    function reformatAndSend() {
        const formattedItems = treeItems;
        if (formattedItems) {
            for (const resultElement of formattedItems) {
                resultElement.name_group = resultElement.title;
                resultElement.sub = resultElement.children;
                for (const resultElementElement of resultElement.sub) {
                    resultElementElement.name_group = resultElementElement.title;
                    resultElementElement.works = resultElementElement.children;
                    for (const work of resultElementElement.works) {
                        work.name_work = work.title;
                        work.edm = work.entity;
                        work.qty = work.quantity;
                    }
                }
            }
            setTreeItems(formattedItems)
            importWorkingGroupsFromFile({id: selectedObject}, formattedItems, navigate, accessToken, setAccessToken, 'aworks')
                .then(() => {
                    window.alert('Успех!')
                    //setKssDialogOpen(true)
                    setForceRefresh(Math.random())
                })
        }
    }


    const zeroPad = (num, places) => String(num).padStart(places, '0')

    return (
        selectedMonths.length > 0 ?
            <GraphYearsWeekNumsCalendar
                filterAnex={filterAnex}
                selectedObject={selectedObject}
                year={year} setYear={setYear}
                items={items} setConfirmMove={setConfirmMove} tab={tab} setGraphicEditDialog={setGraphicEditDialog}
                selectedWeeks={selectedWeeks} setSelectedWeeks={setSelectedWeeks}
                selectedWeeksTemp={selectedWeeksTemp} setSelectedWeeksTemp={setSelectedWeeksTemp}
                selectedMonths={selectedMonths} setSelectedMonths={setSelectedMonths} getMonthShortName={getMonthShortName}/>
            :
            <Grid item xs={12}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabForObject} onChange={(event, newValue) => {
                        setTabForObject(newValue);
                    }} aria-label="basic tabs example">
                        <Tab label="Опис работа" />
                        <Tab label="Задачи" />
                        <Tab label="Предстоящи" />
                    </Tabs>
                </Box>

             {/*   <TabPanel value={tabForObject} index={0}>
                    <Button variant={'contained'} onClick={() => {
                        reformatAndSend()
                    }}>Запис</Button>

                    <DescriptionWorkComponent
                        selectedObject={selectedObject ? selectedObject.id : null}
                        items={treeItems}
                        setItems={setTreeItems}
                        kssDialogOpen={false}
                        setKssDialogOpen={() => {}}/>

                </TabPanel>
*/}

                {tab === 1 || tab === 2 ?
                    <TableContainer>
                        {yearItems && yearItems.length > 0 ? <Table size={'small'} sx={ {border: 1}} aria-label="simple table">
                                <TableHead>


                                    <TableRow>
                                        <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b>№</b></TableCell>
                                        <TableCell sx={{border: 1}} width={NAME_PERC} align="left" onClick={() => {
                                            if (tasksToShow && tasksToShow.type === 'object') {
                                                setTasksToShow(null)
                                            } else {
                                                CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getTasksForItem?`+ new URLSearchParams({
                                                    token: accessToken,
                                                    "id_project":selectedObject ? selectedObject.id : null
                                                }), {
                                                    method: "GET",
                                                    mode: 'cors',
                                                    headers: {
                                                        "Content-Type": "application/json",
                                                    }
                                                })
                                                    .then((result) => {
                                                        if (result && result.items) {
                                                            let results = result.items;

                                                            setTasksToShow(
                                                                {
                                                                    id: selectedObject,
                                                                    type: 'object',
                                                                    data: results
                                                                }
                                                            )
                                                        }
                                                    })
                                            }
                                        }}><b>{selectedObject ? selectedObject.name : null}</b></TableCell>
                                        {
                                            (getMonths())
                                                .map((weekNum, index, array) => {
                                                    return <TableCell
                                                        onClick={() => {
                                                            if (selectedWeeksTemp.includes(weekNum)) {
                                                                let newSelectedWeeks = selectedWeeksTemp.filter(function(item) {
                                                                    return item !== weekNum
                                                                })

                                                                setSelectedWeeksTemp(newSelectedWeeks)
                                                            } else {
                                                                if (selectedWeeksTemp.length<=1) {
                                                                    setSelectedWeeksTemp([
                                                                        ...selectedWeeksTemp,
                                                                        weekNum
                                                                    ])
                                                                } else {
                                                                    setMessageDialog('Могат да се избират не повече от 2 седмици');
                                                                }

                                                            }
                                                        }}
                                                        sx={{border: 1, background: selectedWeeksTemp.includes(weekNum) ? '#8888fc' : null}} width={DAILY_MONEY} align="center"><b>{weekNum}</b></TableCell>
                                                })
                                        }
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    {
                                        tasksToShow && tasksToShow.type === 'object' && tasksToShow.data && tasksToShow.data.map((task) => {
                                            return <React.Fragment>
                                                <TableRow
                                                    key={task.id}
                                                    style={{background: 'white'}}
                                                    onClick={() => {
                                                        setSelectedDay(moment(task.date_start))

                                                        setCommentsOnlyOpen(task.id)
                                                        setNameAddDialog(task.subject)
                                                    }}
                                                >
                                                    <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b> </b></TableCell>
                                                    <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{task.subject}</b></TableCell>
                                                    {
                                                        (getMonths()).map((dateInList, index, array) => {
                                                            let workDates = task.months ?? []

                                                            return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center">

                                                                { workDates.some((date) => Number(date.date) === dateInList)
                                                                    ? <CircleIcon style={{color: '#8888fc'}}/> : null }


                                                            </TableCell>
                                                        })
                                                    }
                                                </TableRow>
                                            </React.Fragment>
                                        })
                                    }



                                    {yearItems && yearItems
                                        .filter((etap) => tasksToShow ? (tasksToShow.id === etap.id || tasksToShow.type === 'group' || tasksToShow.type === 'work') : true) // Скрива етапите
                                        .filter((etap) => tasksToShow && tasksToShow.type === 'group' ? (etap.details??[]).some((group) => group.id === tasksToShow.id) : true) // Ако е избрана група, скива етапите, които не я съдържат
                                        .filter((etap) => tasksToShow && tasksToShow.type === 'work' ? (etap.details??[]).some((group) => (group.works??[]).some((work) => work.id === tasksToShow.id)) : true) // Ако е избрана работа, скива етапите, които не я съдържат
                                        .map((row, index) => (
                                            <React.Fragment>
                                                <TableRow
                                                    key={row.no}>
                                                    <TableCell sx={{border: 1, background: '#63fff2'}} width={NUM_PERC} align="left"><b>{index + 1}</b></TableCell>
                                                    <TableCell sx={{border: 1, background: '#63fff2'}} width={NAME_PERC} align="left"
                                                               onClick={() => {
                                                                   if (tasksToShow) {
                                                                       setTasksToShow(null)
                                                                   } else {
                                                                       CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getTasksForItem?`+ new URLSearchParams({
                                                                           token: accessToken,
                                                                           "id_etap":row.id
                                                                       }), {
                                                                           method: "GET",
                                                                           mode: 'cors',
                                                                           headers: {
                                                                               "Content-Type": "application/json",
                                                                           }
                                                                       })
                                                                           .then((result) => {
                                                                               if (result && result.items) {
                                                                                   let results = result.items;

                                                                                   setTasksToShow(
                                                                                       {
                                                                                           id: row.id,
                                                                                           type: 'etap',
                                                                                           data: results
                                                                                       }
                                                                                   )
                                                                               }
                                                                           })
                                                                   }
                                                               }}
                                                    >
                                                        <Grid container justifyContent={'space-between'}>
                                                            <Grid item>
                                                                <b>{row.name_group}</b>
                                                            </Grid>

                                                            {row.task_count && row.task_count > 0 ? <Grid item>
                                                                <AssistantIcon/>
                                                            </Grid> : null}
                                                        </Grid>
                                                    </TableCell>
                                                    {
                                                        (getMonths()).map((dateInList, index, array) => {
                                                            return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center"></TableCell>
                                                        })
                                                    }
                                                </TableRow>

                                                {
                                                    tasksToShow && tasksToShow.id === row.id && tasksToShow.data && tasksToShow.data.map((task) => {
                                                        return <React.Fragment>
                                                            <TableRow
                                                                key={row.no}
                                                                style={{background: 'white'}}
                                                                onClick={() => {
                                                                    setSelectedDay(moment(task.date_start))

                                                                    setCommentsOnlyOpen(task.id)
                                                                    setNameAddDialog(task.subject)
                                                                }}
                                                            >
                                                                <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b> </b></TableCell>
                                                                <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{task.subject}</b></TableCell>
                                                                {
                                                                    (getMonths()).map((dateInList, index, array) => {
                                                                        let workDates = task.months ?? []

                                                                        return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center">

                                                                            { workDates.some((date) => Number(date.date) === dateInList)
                                                                                ? <CircleIcon style={{color: '#8888fc'}}/> : null }


                                                                        </TableCell>
                                                                    })
                                                                }
                                                            </TableRow>
                                                        </React.Fragment>
                                                    })
                                                }

                                                {console.log('tasksToShow ', tasksToShow)}
                                                {row && row.details && row.details
                                                    .filter((group) => tasksToShow && tasksToShow.type === 'group' ? tasksToShow.id === group.id : true) // Ако е избрана група, скива останалите групи
                                                    .filter((group) => tasksToShow ? true : false) // Скрива всички, групи, ако е избран етап
                                                    .filter((group) => tasksToShow && tasksToShow.type === 'work' ? (group.works??[]).some((work) => work.id === tasksToShow.id) : true) // Ако е избрана работа, скива етапите, които не я съдържат

                                                    .map((row, index) => (
                                                        <React.Fragment>

                                                            <TableRow
                                                                key={row.no}>
                                                                <TableCell sx={{border: 1, background: '#adadad'}} width={NUM_PERC} align="left"><b>{index + 1}</b></TableCell>
                                                                <TableCell onClick={() => {
                                                                    if (tasksToShow.id === row.id) {
                                                                        setTasksToShow({
                                                                            id: row.id_aworkgroup,
                                                                            type: 'etap',
                                                                        })
                                                                    } else {
                                                                        CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getTasksForItem?`+ new URLSearchParams({
                                                                            token: accessToken,
                                                                            "id_aworkgroup":row.id
                                                                        }), {
                                                                            method: "GET",
                                                                            mode: 'cors',
                                                                            headers: {
                                                                                "Content-Type": "application/json",
                                                                            }
                                                                        })
                                                                            .then((result) => {
                                                                                if (result && result.items) {
                                                                                    let results = result.items;

                                                                                    setTasksToShow(
                                                                                        {
                                                                                            id: row.id,
                                                                                            type: 'group',
                                                                                            data: results
                                                                                        }
                                                                                    )
                                                                                    setForceRefreshRow(Math.random())

                                                                                }
                                                                            })

                                                                    }
                                                                }}
                                                                           sx={{border: 1, background: '#adadad'}} width={NAME_PERC} align="left">
                                                                    <Grid container justifyContent={'space-between'}>
                                                                        <Grid item>
                                                                            <b>{row.name_group}</b>
                                                                        </Grid>

                                                                        <Grid item>
                                                                            {row.task_count && row.task_count > 0 ? <Grid item>
                                                                                <AssistantIcon/>
                                                                            </Grid> : null}
                                                                        </Grid>

                                                                    </Grid>

                                                                </TableCell>
                                                                {
                                                                    (getMonths())
                                                                        .map((dateInList, index, array) => {
                                                                            let workDates = row.months
                                                                            return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center">
                                                                                { workDates.some((date) => Number(date.date) === dateInList)
                                                                                    ? <CircleIcon style={{color: '#8888fc'}}/> : null }

                                                                            </TableCell>
                                                                        })
                                                                }
                                                            </TableRow>


                                                            {
                                                                forceRefreshRow && tasksToShow && tasksToShow.id === row.id && tasksToShow.data && tasksToShow.data.map((task) => {
                                                                    return <React.Fragment>
                                                                        <TableRow
                                                                            key={row.no}
                                                                            style={{background: 'white'}}
                                                                            onClick={() => {
                                                                                setSelectedDay(moment(task.date_start))

                                                                                setCommentsOnlyOpen(task.id)
                                                                                setNameAddDialog(task.subject)
                                                                            }}
                                                                        >
                                                                            <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b> </b></TableCell>
                                                                            <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{task.subject}</b></TableCell>
                                                                            {
                                                                                (getMonths()).map((dateInList, index, array) => {
                                                                                    let workDates = task.months ?? []

                                                                                    return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center">

                                                                                        { workDates.some((date) => Number(date.date) === dateInList)
                                                                                            ? <CircleIcon style={{color: '#8888fc'}}/> : null }


                                                                                    </TableCell>
                                                                                })
                                                                            }
                                                                        </TableRow>
                                                                    </React.Fragment>
                                                                })
                                                            }




                                                            {row && row.works && row.works
                                                                .filter((work) => tasksToShow ? (tasksToShow.type !== 'etap') : true) //ако е избрана група, то не се показват видовете работа
                                                                .filter((item) => filterAnex ? item.is_anex !== 1 : true)
                                                                .filter((work) => tasksToShow && tasksToShow.type === 'work' ? tasksToShow.id === work.id : true) // Ако е избрана работа, скива останалите работи

                                                                .map((row, index) => (
                                                                    <React.Fragment>
                                                                        <TableRow
                                                                            key={row.no}
                                                                            style={tab === 2 && row && row.details ? {background: '#d7d5d7'} : {}}
                                                                            onClick={() => {
                                                                                /*setExternalWorkId(row.id_awork);
                                                                                setEmptyAnalysisDialog(row.id)*/
                                                                                setGraphicEditDialog(row)
                                                                            }}
                                                                        >
                                                                            <TableCell sx={{border: 1, background: '#e0e0e0'}} width={NUM_PERC} align="left">{index + 1}</TableCell>
                                                                            <TableCell sx={{border: 1, background: '#e0e0e0'}} width={NAME_PERC} align="left"
                                                                                       onClick={() => {
                                                                                           if (tasksToShow.id === row.id) {
                                                                                               setTasksToShow({
                                                                                                   id: row.id_aworkgroup,
                                                                                                   type: 'group'
                                                                                               })
                                                                                           } else {
                                                                                               CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/getTasksForItem?`+ new URLSearchParams({
                                                                                                   token: accessToken,
                                                                                                   "id_work":row.id
                                                                                               }), {
                                                                                                   method: "GET",
                                                                                                   mode: 'cors',
                                                                                                   headers: {
                                                                                                       "Content-Type": "application/json",
                                                                                                   }
                                                                                               })
                                                                                                   .then((result) => {
                                                                                                       if (result && result.items) {
                                                                                                           let results = result.items;

                                                                                                           setTasksToShow(
                                                                                                               {
                                                                                                                   id: row.id,
                                                                                                                   type: 'work',
                                                                                                                   data: results
                                                                                                               }
                                                                                                           )
                                                                                                           setForceRefreshRow(Math.random())

                                                                                                       }
                                                                                                   })
                                                                                           }
                                                                                       }}>
                                                                                <Grid container justifyContent={'space-between'}>
                                                                                    <Grid item>
                                                                                        {row.name_work}
                                                                                    </Grid>

                                                                                    <Grid item>
                                                                                        {row.task_count && row.task_count > 0 ? <Grid item>
                                                                                            <AssistantIcon/>
                                                                                        </Grid> : null}
                                                                                    </Grid>

                                                                                </Grid>
                                                                            </TableCell>

                                                                            {
                                                                                (getMonths())
                                                                                    .map((dateInList, index, array) => {
                                                                                        let workWeeks = row.months ?? [];

                                                                                        return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center"
                                                                                                          onClick={(event) => {
                                                                                                              event.stopPropagation()
                                                                                                              event.nativeEvent.stopImmediatePropagation();

                                                                                                              setConfirmMove({
                                                                                                                  id: row.id,
                                                                                                                  date: moment().format('YYYY-MM-DD'),
                                                                                                                  text: 'Наистина ли искате да изместите дейността \"' + row.name + '\" за '
                                                                                                                      + moment().format('YYYY-MM-DD')
                                                                                                              })
                                                                                                          }}>
                                                                                            {workWeeks.some((date) => Number(date.date) === dateInList) ? <CircleIcon style={{color: '#8888fc'}}/> : null}
                                                                                        </TableCell>
                                                                                    })
                                                                            }
                                                                        </TableRow>




                                                                        {
                                                                            forceRefreshRow && tasksToShow && tasksToShow.id === row.id && tasksToShow.data && tasksToShow.data.map((task) => {
                                                                                return <React.Fragment>
                                                                                    <TableRow
                                                                                        key={row.no}
                                                                                        style={{background: 'white'}}
                                                                                        onClick={() => {
                                                                                            setSelectedDay(moment(task.date_start))

                                                                                            setCommentsOnlyOpen(task.id)
                                                                                            setNameAddDialog(task.subject)
                                                                                        }}
                                                                                    >
                                                                                        <TableCell sx={{border: 1}} width={NUM_PERC} align="left"><b> </b></TableCell>
                                                                                        <TableCell sx={{border: 1}} width={NAME_PERC} align="left"><b>{task.subject}</b></TableCell>
                                                                                        {
                                                                                            (getMonths()).map((dateInList, index, array) => {

                                                                                                let workDates = task.months ?? []

                                                                                                return <TableCell sx={{border: 1}} width={DAILY_MONEY} align="center">

                                                                                                    { workDates.some((date) => Number(date.date) === dateInList)
                                                                                                        ? <CircleIcon style={{color: '#8888fc'}}/> : null }


                                                                                                </TableCell>
                                                                                            })
                                                                                        }
                                                                                    </TableRow>
                                                                                </React.Fragment>
                                                                            })
                                                                        }


                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </React.Fragment>

                                        ))}
                                </TableBody>
                            </Table> :

                            <div style={{padding: 20}}>
                                <Typography>Няма резултати</Typography>
                            </div>
                        }
                    </TableContainer>
                    : null
                }

                <CommentsOnlyForDailyTasksDialog
                    open={commentsOnlyOpen}
                    name={nameAddDialog}
                    handleClose={() => setCommentsOnlyOpen(false)}
                    setForceRefreshMain={setForceRefresh}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}/>
            </Grid>
    )
}
