import Grid from "@mui/material/Grid";
import * as React from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {useEffect, useState} from "react";
import PickObjectDialog from "../components/tasks/PickObjectDialog";
import {fetchWorksDataStock, getObjects} from "../utils/NetworkUtils";
import {useLocalStorage} from "../useLocalStorage";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import MonthlyTasksDialog from "../components/graphsComponent/monthlyTasks/MonthlyTasksDialog";

function ReportsScreen() {
    const OBJ_WIDTH = '30%';
    const EXEC_WIDTH = '20%';
    const DAY_WIDTH = '5%';

    const DAYS = ['пон', 'вт', 'ср', 'четв', 'пет', 'съб', 'нед'];

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    let navigate = useNavigate();


    const [pickObjectOpen, setPickObjectOpen] = useState(null);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [items, setItems] = useState([]);



    const [objectToAddTo, setObjectToAddTo] = useState(null);




    const [objects, setObjects] = React.useState([]);
    const [selectedObject, setSelectedObject] = React.useState(0);
    const [objectName, setObjectName] = React.useState(0);

    const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [displayName, setDisplayName] = useState("");



    const [worksDataStock, setWorksDataStock] = React.useState([]);
    const [autoCompleteWorkOpen, setAutoCompleteWorkOpen] = React.useState(false);
    const [loadingWork, setLoadingWork] = React.useState(false);
    const [displayNameWork, setDisplayNameWork] = useState("");
    const [workName, setWorkName] = useState(0);


    const [selectedDay, setSelectedDay] = useState(moment());


    useEffect(() => {
        getObjects(navigate, accessToken, setAccessToken, setObjects, setSelectedObject)
    }, [])


    useEffect(() => {
        if (objectName) {
            fetchWorksDataStock({id: objectName.id}, navigate, accessToken, setAccessToken, setWorksDataStock, displayNameWork)
        }
    }, [objectName])

    useEffect(() => {
        console.log('objectName ', objectName)
        if (objectName) {
            setItems([...items, {
                id: objectName.id,
                object_name: objectName.name_project
            }])
            setObjectName(0)
            setDisplayName(null)
        }

    }, [objectName])


    const handleWorkSelect = (workName) => {
        console.log('workName ', workName)
        if (workName) {

            let newItems = items;
            console.log('newItems ', newItems)

            let taskIndex = newItems.findIndex((task) => {
                return task.id === objectToAddTo
            })

            console.log('taskIndex ', taskIndex)
            console.log('newItems[taskIndex] ', newItems[taskIndex])
            newItems[taskIndex].subs = [
                ...(newItems[taskIndex].subs ?? []),
                {
                    name: workName.name_work
                },
                {
                    name: null
                }
            ]
            console.log('setting ', newItems)

            setItems(newItems)
            setWorkName(0)
        }

    }

    return (
        <Grid container spacing={1} justifyContent={'flex-start'}>

            <Grid item>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg">

                    <DatePicker
                        label={'От'}
                        value={selectedDay}
                        onChange={(newValue) => setSelectedDay(newValue)}
                        views={['year', 'month', 'day']}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
                <MonthlyTasksDialog selectedObject={selectedObject}/>
            </Grid>


            <PickObjectDialog
                open={pickObjectOpen}
                handleClose={() => setPickObjectOpen(false)}/>
        </Grid>
    )
}
export default ReportsScreen;