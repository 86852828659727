import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, {useEffect} from "react";
import Slide from "@mui/material/Slide";
import InputAdornment from "@mui/material/InputAdornment";
import {createPartner, getUsers} from "../../utils/NetworkUtils";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import {AutocompleteProps} from "../../inputs/AutocompleteProps";

// open, handleSubmit, handleClose, comment, setComment, date, setDate, userId, taskId, details, setDetails addDetail
export default function AddDetailDialog(props) {

    const [users, setUsers] = React.useState([]);

    const [attachment, setAttachment] = React.useState(null);
    const [count, setCount] = React.useState(1);

    useEffect(() => {
        getUsers(props.navigate, props.accessToken, props.setAccessToken, setUsers)
    }, []);

    useEffect(() => {
        if (!props.open) {
            props.setDate(null)
            props.setComment(null)
        }
    }, [props.open])

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <form>
                <DialogTitle>Добави коментар</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Въведете данни
                    </DialogContentText>
                    <Grid container direction="row" spacing={2}>

                        <Grid item xs={12}>
                            <FormControl fullWidth style={{marginTop: 15}}
                            >

                                <LocalizationProvider
                                    adapterLocale={'bg'}
                                    dateAdapter={AdapterMoment}>

                                    <MobileDatePicker
                                        label="Дата"
                                        inputFormat="DD MMM yyyy"
                                        value={props.date}
                                        onChange={(newValue) => {
                                            props.setDate(newValue);
                                        }}
                                        closeOnSelect={true}
                                        renderInput={(params) => <TextField
                                            fullWidth
                                            variant="outlined" {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>


                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="comment"
                                fullWidth
                                multiline
                                value={props.comment ? props.comment : null}
                                onChange={(event) =>
                                    props.setComment(event.target.value)
                                }
                                InputLabelProps={{ shrink: props.comment !== null && props.comment !== "" && props.comment !== null }}
                                label="Коментар"
                                type="text"
                                variant="outlined"
                            />
                        </Grid>{/*
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                id="detailQuantity"
                                fullWidth
                                value={props.detailQuantity ? props.detailQuantity : null}
                                onChange={(event) =>
                                    props.setDetailQuantity(event.target.value)
                                }
                                InputLabelProps={{ shrink: props.detailQuantity !== null && props.detailQuantity !== "" && props.detailQuantity !== null }}
                                label="Количество"
                                type="number"
                                variant="outlined"
                            />
                        </Grid>*/}

                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                component="label">
                                Прикачи файл
                                <input
                                    type="file"
                                    multiple="multiple"
                                    onChange={(event => {
                                        if (event && event.target && event.target.files && event.target.files.length > 0) {
                                            // Create a new array from the current files
                                            let currentFiles = attachment ? attachment : [];

                                            // Add the new files to the array
                                            let arrayFiles = Array.from(event.target.files);
                                            arrayFiles.forEach(item => {
                                                // if they don't exist yet
                                                if (!currentFiles.some(el => el.name === item.name)) {
                                                    currentFiles.push(item)
                                                }
                                            })

                                            // Set the new attachments

                                            for (const file of currentFiles) {
                                                file.id = Math.random();
                                            }
                                            setAttachment(currentFiles);
                                            setCount(count + 1);

                                            console.log('currentFiles ', currentFiles)
                                        }
                                    })}
                                    hidden
                                />
                            </Button>
                        </Grid>

                        <Grid item xs={8} alignItems={'center'}>
                            <List key={attachment ? attachment.length : -1}>

                            {
                                attachment && count && attachment.map((item, index) => {
                                    console.log('item ', item.id)
                                    return <ListItem key={item.id}>
                                        <Button  variant="text" onClick={() => {
                                        let attachmentsAfterRemoval = attachment.filter(attachmentItem => attachmentItem.name !== item.name)

                                        setAttachment(attachmentsAfterRemoval)
                                    }}
                                            startIcon={<AttachFileIcon/>}
                                            endIcon={<HighlightOffIcon/>}>
                                        {attachment ? item.name : ""}
                                    </Button></ListItem>
                                })

                            }
                            </List>

                        </Grid>

{/*
                        <Grid item xs={3}>
                            <Button onClick={() => {props.setRoadListOpen(!props.isRoadListOpen)}}>Пътен лист</Button>
                        </Grid>*/}


                        {
                            props.isRoadListOpen &&
                            <Slide direction="up" in={props.isRoadListOpen}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <AutocompleteProps
                                                autocompleteProperty={props.roadAssignee}
                                                dataset={users}
                                                setAutocompleteProperty={props.setRoadAssignee}
                                                restApiAddPropsFunction={(name) => createPartner(props.navigate, props.accessToken,
                                                    props.setAccessToken, props.setAssignee, name)}
                                                title={"Изпълнител"}
                                                propName={"name_firm"}
                                                additional={{}}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                margin="dense"
                                                id="fuelPrice"
                                                fullWidth
                                                value={props.fuelPrice ? props.fuelPrice : null}
                                                onChange={(event) =>
                                                    props.setFuelPrice(event.target.value)
                                                }
                                                InputLabelProps={{shrink: props.fuelPrice !== null && props.fuelPrice !== "" && props.fuelPrice !== null}}
                                                label="Цена на гориво"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">лв</InputAdornment>
                                                }}
                                                type="number"
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                margin="dense"
                                                id="dashboardStart"
                                                fullWidth
                                                value={props.dashboardStart ? props.dashboardStart : null}
                                                onChange={(event) =>
                                                    props.setDashboardStart(event.target.value)
                                                }
                                                InputLabelProps={{shrink: props.dashboardStart !== null && props.dashboardStart !== "" && props.dashboardStart !== null}}
                                                label="Километраж начало"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">км</InputAdornment>
                                                }}
                                                type="number"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                margin="dense"
                                                id="dashboardEnd"
                                                fullWidth
                                                value={props.dashboardEnd ? props.dashboardEnd : null}
                                                onChange={(event) =>
                                                    props.setDashboardEnd(event.target.value)
                                                }
                                                InputLabelProps={{shrink: props.dashboardEnd !== null && props.dashboardEnd !== "" && props.dashboardEnd !== null}}
                                                label="Километраж край"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">км</InputAdornment>
                                                }}
                                                type="number"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Slide>
                        }


                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Отказ</Button>

                    <Button onClick={() => {
                        let roadCard = null;
                        if (props.isRoadListOpen) {
                            roadCard = {
                                "id_partner": props.roadAssignee ? props.roadAssignee.id : 0,
                                "dashboard_begin": props.dashboardStart,
                                "dashboard_end": props.dashboardEnd,
                                "price_fuel": props.fuelPrice,
                            }
                        }

                        props.addDetail(props.navigate, props.accessToken, props.setAccessToken, props.userId, props.taskId, props.date, props.comment, props.detailQuantity, props.hour, roadCard, attachment)
                          
                        props.handleClose();
                    }} disabled={(attachment && attachment.length > 0 ? false : !props.comment) ||
                            (props.isRoadListOpen && (!props.roadAssignee || !props.fuelPrice || !props.dashboardStart || !props.dashboardEnd))
                    }>Добави</Button>
                </DialogActions>
            </form>
        </Dialog>
    )

}