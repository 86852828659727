import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {CommonFetch} from "../../../../services/api-fetch";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../../../useLocalStorage";
import {useState} from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CreateAvtoparkDialog({open, handleClose, setForceRefresh}) {

    let navigate = useNavigate();

    // login data
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [name, setName] = useState();
    const [regPlate, setRegPlate] = useState();
    const [fuelConsumption, setFuelConsumption] = useState();

    return (<Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
        TransitionComponent={Transition}
    >

        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Създай автомобил
                </Typography>
            </Toolbar>
        </AppBar>

        <Grid container spacing={1} style={{padding: 10, marginTop: 15, marginBottom: 15}}>
            <Grid item xs={6}>
                <TextField fullWidth label={'Име'} value={name} onChange={(e) => setName(e.target.value)}/>
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth label={'Рег номер'} value={regPlate} onChange={(e) => setRegPlate(e.target.value)}/>
            </Grid>
            <Grid item xs={4}>
                <TextField label={'Л/100км'} value={fuelConsumption} onChange={(e) => setFuelConsumption(e.target.value)}/>
            </Grid>

            <Grid item xs={6}>
                <Button onClick={() => {
                    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/addResource?`+ new URLSearchParams({
                        "token": accessToken,
                        "name": name,
                        "reg_plate":regPlate,
                        "fuelconsumation":fuelConsumption,
                        "type":2
                    }), {
                        method: "POST",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json",
                        }
                    })
                        .then((result) => {
                            setForceRefresh(Math.random())
                            handleClose()
                        },
                        (error) => {

                        }
                    );
                }}>Запис</Button>
            </Grid>



        </Grid>


    </Dialog>)
}
export default CreateAvtoparkDialog;